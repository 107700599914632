import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getAssuranceOviewViewAPI } from '../../../services/routes.services';
import { Link, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import { IoMdClose } from "react-icons/io";
import Breadcrumb from "./BreadCrumb";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
const AssuranceServices = ()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.parentTab : ''; 
    
    const [assuranceData, setAssuranceData] = useState([]);
    const [assuranceSlider, setAssuranceSlider] = useState([]);
    const [custmorData, setCustmorData] = useState([]);
    const [downloadData, setDownloadData] = useState([]);
    const [popup, setPopup] = useState(null);
    const [loading, setLoading] = useState(true);
    const PdfPopupHandel = (item) => {
        if (item.fields.length === 1) {
            openPdfNewWindow(constants.Image_BASE_URL + item.fields[0].pdf, `${item.title}-${item.fields[0].language}.pdf`);
        } else {
            setPopup(item);
        }
    };
    const adjustPath = (path) => {
       
        // Example function to adjust paths
        return path.replace('/assurance-service/', '/');
       
    };
    const openPdfNewWindow = (pdf) => {
        window.open(pdf, '_blank');
    };
    const ClosePopupHandel = () => {
        setPopup(null);
    };
    const assuranceResponsive = {
        superLargeDesktop: {
          breakpoint: { max: 3000, min: 0 },
          shouldResetAutoplay:true,
          items: 4,
        },
        desktop:{
            breakpoint: { max: 1200, min: 0 },
            items: 3,
        },
        tablet:{
            breakpoint: { max: 767, min: 521 },
            items: 2,
        },
        mobile:{
            breakpoint: { max: 520, min: 0 },
            items: 1,
        },
    };
      const customerResponsive = {
        superLargeDesktop: {
          breakpoint: { max: 3000, min: 1200 },
          shouldResetAutoplay:true,
          items: 1,
          bots:true,
        },
        
      };
      useEffect(()=>{
        getAssuranceOviewViewAPI().then((res)=>{
            setAssuranceData(res.data.assuranceOverview)
            setAssuranceSlider(res.data.homeserviceimage)
            setCustmorData(res.data.customers)
            setDownloadData(res.data.downloads)
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
      }, []);
      if (loading ) {
        return <Loader />;
    }
    return(
        <div className={'assuracePage ' + Styles.aservicesPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
{BannerImage &&<img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Breadcrumb />
            <div className={Styles.AsOverView}>
                <Container>
                    <Row>
                        {assuranceData && assuranceData.length > 0 &&(
                            <>
                                <Col className={Styles.AsPaddingLeft}>
                                    <div className={Styles.AsImg}>
                                        <figure className={Styles.imgBgStyle}>
                                           {assuranceData[0].image && <img src={constants.Image_BASE_URL+""+assuranceData[0].image} alt="" width='631' height='443' />}
                                        </figure>
                                    </div>
                                </Col>
                                <Col className={Styles.AsPaddingRight}>
                                    <div className={Styles.AsContent}>
                                        <h3>{assuranceData[0].text}</h3>
                                        {parse(`${assuranceData[0].description}`)}
                                    </div>
                                </Col>  
                            </>
                        )}
                    </Row>
                </Container>
            </div>
            <div className={Styles.AssuranceServies}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.centerAlight}>
                                <h3>Service Offerings</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className={Styles.AsCarousel}>
                    <Carousel autoPlaySpeed={2000} transitionDuration={500} responsive={assuranceResponsive} autoPlay={true} infinite={true} arrows={true} containerClass="assuranceOwl arrowStyle">
                        {assuranceSlider && assuranceSlider.length > 0 &&(
                            assuranceSlider.map((item)=>{
                                const adjustedLink = adjustPath(item.link);
                                return(
                                    <div className={Styles.AsItem} key={item._id}>
                                        <figure>
                                           {item.innerimage && <img src={constants.Image_BASE_URL+""+item.innerimage} alt="" width='400' height='183' />}
                                        </figure>
                                        <Link to={`/${adjustedLink}`} >
                                        <h5>{item.text}</h5>
                                        <div className={Styles.boxHeight}>{parse(`${item.description}`)}</div>
                                        </Link>
                                    </div>
                                )
                            })
                        )}
                    </Carousel>
                </div>
            </div>
            <div className={Styles.AsRank}>
                <Container>
                    <Row>
                        <Col>
                            <ul>
                            {assuranceData && assuranceData.length > 0 &&(
                                assuranceData[0].fields.map((item)=>{
                                    return(
                                        <li key={item._id}>{item.field1}{item.field2} <span>{item.field3}</span></li>
                                    )
                                })
                            )}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <div className={Styles.AsCustomer}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.centerAlight}>
                                <h3>Customers</h3>
                            </div>
                            <div className={Styles.customerOwl}>
                                <Carousel autoPlaySpeed={2000} transitionDuration={500} responsive={customerResponsive} autoPlay={false} infinite={true} arrows={false} showDots={true} containerClass="dotsStyle">
                                    <div className={Styles.customerItem}>
                                        <figure>
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                            <img src={require('../../../assest/images/sail-logo.png')} />
                                            <img src={require('../../../assest/images/tata-steel-logo.png')} />
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                        </figure>
                                        <figure>
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                            <img src={require('../../../assest/images/sail-logo.png')} />
                                            <img src={require('../../../assest/images/tata-steel-logo.png')} />
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                        </figure>
                                    </div>
                                    <div className={Styles.customerItem}>
                                        <figure>
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                            <img src={require('../../../assest/images/sail-logo.png')} />
                                            <img src={require('../../../assest/images/tata-steel-logo.png')} />
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                        </figure>
                                        <figure>
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                            <img src={require('../../../assest/images/sail-logo.png')} />
                                            <img src={require('../../../assest/images/tata-steel-logo.png')} />
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                        </figure>
                                    </div>
                                    <div className={Styles.customerItem}>
                                        <figure>
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                            <img src={require('../../../assest/images/sail-logo.png')} />
                                            <img src={require('../../../assest/images/tata-steel-logo.png')} />
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                        </figure>
                                        <figure>
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                            <img src={require('../../../assest/images/sail-logo.png')} />
                                            <img src={require('../../../assest/images/tata-steel-logo.png')} />
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                        </figure>
                                    </div>
                                    <div className={Styles.customerItem}>
                                        <figure>
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                            <img src={require('../../../assest/images/sail-logo.png')} />
                                            <img src={require('../../../assest/images/tata-steel-logo.png')} />
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                        </figure>
                                        <figure>
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                            <img src={require('../../../assest/images/sail-logo.png')} />
                                            <img src={require('../../../assest/images/tata-steel-logo.png')} />
                                            <img src={require('../../../assest/images/npcil-logo.png')} />
                                        </figure>
                                    </div>
                                </Carousel>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}
            {/* <div className={Styles.AsPdf}>
                {/* <Container>
                    <Row>
                        <Col md={3} className={Styles.flexCenter}>
                            <h3>Client</h3>
                        </Col>
                        <Col md={9}>
                            <div className={Styles.AsPDFContainer}>
                                {downloadData &&
                                    downloadData.map((item)=>{
                                        return(
                                            <div className={Styles.box} key={item.order}>
                                                <button type="button" onClick={() => PdfPopupHandel(item)}>
                                                        <figure>
                                                            <img src={require('../../../assest/images/pdf-icon.png')} />
                                                        </figure>
                                                        <h6>{item.title} </h6>
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container> */}
                {popup &&
                    <div className={Styles.popupModel}>
                        <div className={Styles.popupElem}>
                            <button type="button" onClick={ClosePopupHandel} className={Styles.ClosePopup}><IoMdClose /></button>
                            <h6>Select Policy Language & Download</h6>
                            <div className={Styles.LanguageButton}>
                                {downloadData.fields.map((pdfLanguage) => (
                                    <button 
                                        key={pdfLanguage._id} 
                                        type="button" 
                                        onClick={() => openPdfNewWindow(constants.Image_BASE_URL + pdfLanguage.pdf, `${popup.title}-${pdfLanguage.language}.pdf`)}
                                    >
                                        {pdfLanguage.language}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            {/* </div> */} 
        </div>
    )
}

export default AssuranceServices;