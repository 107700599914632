import React, { useState } from 'react';
import parse from 'html-react-parser';
import { getSearchApi } from '../../services/routes.services';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import { useLocation,Link } from 'react-router-dom';

const Search = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({});
  const location = useLocation();
  const { searchResults } = location.state || {};
  // console.log(searchResults)
  // const handleSearch = async () => {
  //   try {
  //     const response = await getSearchApi(query);
  //     setResults(response.data);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  const adjustPath = (path) => {
       
    // Example function to adjust paths
    return path.replace('/search/', '/');
   
};
  return (
      <div className={'directorsPage ' + Styles.boardPage}>
      <div className={`${Styles.innerBanner} ${Styles.InnerSmallBanner} ${Styles.noneMask} ${Styles.smallBanner}`}>
                <img src={require('../../assest/images/searchBanner.jpg')} className={Styles.innerBannerimg} alt="Banner" />
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>Search</h2> 
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
      <Container className={Styles.SearchElem}>
        <Row>
          <Col>
            <div>
              {/* <input type="text" value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Search..." />
              <button onClick={handleSearch}>Search</button>
              {Object.keys(results).map((key) => (
                <div key={key}>
                
                  <ul>
                    {results[key].map((item) => (
                      <li key={item._id}><h4>{parse(`${item.title}`)} : </h4> {parse(`${item.description}`)}</li>
                    ))}
                  </ul>
                </div>
              ))} */}
              {Object.keys(searchResults).map((key) => (
                <div key={key}>
                
                  <ul>
                    {searchResults[key].map((item) => {
                       if(item.link){var adjustedLink = adjustPath(item.link);}
                       return(
                        <>
                        {!item.link  || item.link==='/' || adjustedLink.includes('youtube.com') ?(
                            <li key={item._id}>
                            {item.title !== undefined ? (<>{item.title && <h4> {parse(`${item.title}`)}</h4>} </>):null}
                            {item.titlename !== undefined ? (<h4>{parse(`${item.titlename}`)} </h4>):null}
                            {item.description !== undefined ? (<>{item.description != "undefined" ?(<p> {parse(`${item.description}`)}</p>):null} </>):null}
                              </li>
                        ) :(
                        <Link to={`/${adjustedLink}`} > 
                        <li key={item._id}>
                        {item.title !== undefined ? (<>{item.title && <h4> {parse(`${item.title}`)}</h4>} </>):null}
                            {item.titlename !== undefined ? (<h4>{parse(`${item.titlename}`)} </h4>):null}
                            {item.description !== undefined ? (<>{item.description != "undefined" ?(<p> {parse(`${item.description}`)}</p>):null} </>):null}
                           </li>
                          
                           </Link>)}
                     
                         <hr/>
                         </>)
})}
                  </ul>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Search;
