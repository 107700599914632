import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import { getSectorDetailAPI } from '../../../services/routes.services';
import { Link, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import SectorBreadCrumb from "./SectorBreadCrumb";
import Loader from "../../Common/PageLoader/LoadingSpinner";
import $ from 'jquery';
const Healthcare=()=>{
    const [sectorData, setSectorData] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    console.log(location)
    useEffect(()=>{
        getSectorDetailAPI('healthcare').then((res)=>{
            setSectorData(res.data.sector[0])
            setLoading(false);
        }).catch(error=>{
            console.log(error);
            setLoading(false);
        })
    }, []);
    const handleClickMenu = (e) => {
        $('.mobMenuInner').toggleClass('active-class');
      };
    if (loading) {
        return <Loader />;
    }
    return(
        <div className={'CertificationPage ' + Styles.MSCPage + ' ' + Styles.CFTStyleSame}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
                <img src={require('../../../assest/images/hospality_banner.jpg')} className={Styles.innerBannerimg} />
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>Healthcare</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={'breadCrmStyle ' + Styles.IDList}>
                <Container>
                    <Row>
                        <Col>
                            <button type="button" onClick={handleClickMenu}>Menu</button>
                            <ul className="mobMenuInner">
                                <li><Link to='/real-state' title="Real Estate">Real Estate</Link></li>
                                <li><Link to='/hospitality' title="Hospitality">Hospitality</Link></li>
                                <li><Link className="active" to='/healthcare' title="Healthcare">Healthcare</Link></li>
                                <li><Link to='/industrial' title="Industrial & Manufacturing">Industrial & Manufacturing</Link></li>
                                <li><Link to='/warehoues' title="Warehousing">Warehousing</Link></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.sectorBlock}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.sectorHeading}>
                                {parse(`${sectorData.subdescription}`)}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className={Styles.sectorProject}>
                                <h4>{sectorData.projecttitle}</h4>
                                <Row>
                                    {sectorData.fields &&
                                    sectorData.fields.map((item, index)=>{
                                        return(
                                            <Col md={4} key={index}>
                                                <div className={Styles.projectBox}>
                                                    <figure>
                                                        {item.pageimage &&
                                                        <img src={constants.Image_BASE_URL+""+item.pageimage} alt='' width='492' height='306' />
                                    }
                                                    </figure>
                                                    <div className={Styles.projectDetails}>
                                                        <h6>{item.subtitle}</h6>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Healthcare;