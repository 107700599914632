import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import $ from 'jquery';

const Global = () => {
	useEffect(() => {
		$(".indiaCount").on("mouseover", function() {$('.india').addClass("show");});
		$(".indiaCount").on("mouseout", function() {$('.india').removeClass("show");});
		$(".somalilandCount").on("mouseover", function() {$('.somali').addClass("show");});
		$(".somalilandCount").on("mouseout", function() {$('.somali').removeClass("show");});
		$(".kuwaitCount").on("mouseover", function() {$('.kuwait').addClass("show");});
		$(".kuwaitCount").on("mouseout", function() {$('.kuwait').removeClass("show");});
		$(".israelCount").on("mouseover", function() {$('.israel').addClass("show");});
		$(".israelCount").on("mouseout", function() {$('.israel').removeClass("show");});
		$(".greeceCount").on("mouseover", function() {$('.greece').addClass("show");});
		$(".greeceCount").on("mouseout", function() {$('.greece').removeClass("show");});
		$(".finlandCount").on("mouseover", function() {$('.finland').addClass("show");});
		$(".finlandCount").on("mouseout", function() {$('.finland').removeClass("show");});
		$(".lithuaniaCount").on("mouseover", function() {$('.lithu').addClass("show");});
		$(".lithuaniaCount").on("mouseout", function() {$('.lithu').removeClass("show");});
		$(".swedebCount").on("mouseover", function() {$('.sweden').addClass("show");});
		$(".swedebCount").on("mouseout", function() {$('.sweden').removeClass("show");});
		$(".norwayCount").on("mouseover", function() {$('.norway').addClass("show");});
		$(".norwayCount").on("mouseout", function() {$('.norway').removeClass("show");});
		$(".denmarkCount").on("mouseover", function() {$('.denmark').addClass("show");});
		$(".denmarkCount").on("mouseout", function() {$('.denmark').removeClass("show");});
		$(".ukCountry").on("mouseover", function() {$('.uk').addClass("show");});
		$(".ukCountry").on("mouseout", function() {$('.uk').removeClass("show");});
		$(".germanyCount").on("mouseover", function() {$('.germany').addClass("show");});
		$(".germanyCount").on("mouseout", function() {$('.germany').removeClass("show");});
		$(".netherlandCount").on("mouseover", function() {$('.nether').addClass("show");});
		$(".netherlandCount").on("mouseout", function() {$('.nether').removeClass("show");});
		$(".belgiumCount").on("mouseover", function() {$('.belgium').addClass("show");});
		$(".belgiumCount").on("mouseout", function() {$('.belgium').removeClass("show");});
		$(".polandCount").on("mouseover", function() {$('.poland').addClass("show");});
		$(".polandCount").on("mouseout", function() {$('.poland').removeClass("show");});
		$(".italyCount").on("mouseover", function() {$('.italy').addClass("show");});
		$(".italyCount").on("mouseout", function() {$('.italy').removeClass("show");});
		$(".romaniaCount").on("mouseover", function() {$('.roma').addClass("show");});
		$(".romaniaCount").on("mouseout", function() {$('.roma').removeClass("show");});
		$(".hungaryCount").on("mouseover", function() {$('.hungary').addClass("show");});
		$(".hungaryCount").on("mouseout", function() {$('.hungary').removeClass("show");});
		$(".croatiaCount").on("mouseover", function() {$('.croatia').addClass("show");});
		$(".croatiaCount").on("mouseout", function() {$('.croatia').removeClass("show");});
		$(".switzCount").on("mouseover", function() {$('.switz').addClass("show");});
		$(".switzCount").on("mouseout", function() {$('.switz').removeClass("show");});
		$(".slovakiaCount").on("mouseover", function() {$('.slova').addClass("show");});
		$(".slovakiaCount").on("mouseout", function() {$('.slova').removeClass("show");});
		$(".czechCount").on("mouseover", function() {$('.czechia').addClass("show");});
		$(".czechCount").on("mouseout", function() {$('.czechia').removeClass("show");});
		$(".austriaCount").on("mouseover", function() {$('.aust').addClass("show");});
		$(".austriaCount").on("mouseout", function() {$('.aust').removeClass("show");});
		$(".chinaCount").on("mouseover", function() {$('.china').addClass("show");});
		$(".chinaCount").on("mouseout", function() {$('.china').removeClass("show");});

	  }, []);
  return (
    <div className={'sectors ' + Styles.SectorsSec +" " + Styles.GlobalSec}>
      <Container>
        <Row>
          <Col>
            <div className={Styles.SectorsHeadaing + ' ' + Styles.centerAlight}>
              <h3>Global Presence</h3> 
            </div>
            <div className={Styles.globalStyle}>
              <svg version="1.1" x="0px" y="0px" viewBox="0 0 527.26 260.08" xmlns="http://www.w3.org/2000/svg">
<g id="BACKGROUND">
</g>
<g id="OBJECTS">
	<g>
		<g>
			
			<path class="st0" d="M271.5,210.97c0-0.3,0.52-0.86,0.82-0.43c0.3,0.43,0.17,1.04,0.78,1.04c0.6,0,1.69,0.22,1.86-0.04
				c0.17-0.26,0.95-0.48,0.95-0.95c0-0.48,0-9.3,0-9.65c0-0.35,1.47,0,1.47-0.56c0-0.56,0-5.02,0-5.02s1.08-0.09,1.38-0.17
				c0.3-0.09,1.39-0.65,1.64-0.48c0.26,0.17,0.6,0.78,0.78,0.56c0.17-0.22,0.61-0.65,1.04-0.65c0.24,0,0.62-0.1,0.98-0.19
				c-0.07-0.13-0.12-0.25-0.12-0.34c0-0.37-2.63,0.07-3.07,0.22c-0.44,0.15-1.83,0.37-2.27,0.15c-0.44-0.22-3.22,0-3.22,0
				l-0.51-0.51c0,0-4.54,0-5.05,0c-0.51,0-0.88-0.07-1.32-0.36c-0.44-0.29-0.88-0.29-1.39-0.07c-0.51,0.22-0.29-0.29-0.66-0.29
				c-0.37,0-0.51,0.37-0.51,0.37l-0.46,0.12c-0.05,0.45,0.35,1.27,0.82,1.91c0.5,0.67,1.1,1.93,1.43,2.99
				c0.32,1.06,1.26,2.25,1.49,3.05c0.24,0.8-0.17,1.95,0.07,2.47c0.24,0.52,0.39,1.41,0.52,2.29c0.13,0.89,0.19,2.77,0.99,3.55
				c0.3,0.3,0.62,0.71,0.91,1.17C271.23,211.16,271.5,211.2,271.5,210.97z"/>
			<path class="st0" d="M290.98,191.63l-0.22-0.47l-1.26,0.04c-0.82,0.22-0.82,0.48-0.82,0.74c0,0.26-0.78,0.86-1.34,1.04
				c-0.56,0.17-1.17,1.47-1.51,1.64c-0.35,0.17-1.51-0.26-1.86-0.3c-0.16-0.02-0.44,0.04-0.75,0.11c0.22,0.38,0.67,0.83,0.83,0.83
				c0.22,0,0.29,1.54,0.8,1.76c0.51,0.22,1.46,0.73,1.46,1.02c0,0.29,0.59,0.66,0.59,1.1c0,0.44,0.58,1.04,1.02,1.02
				c0.94-0.03,1.03,0.36,1.03,0.62c0,0.26,1.23,0,1.4,0.26c0.15,0.24,1.37,0.15,1.66,0.31c0.51-0.49,1.24-1.15,1.48-1.48
				c0.39-0.52,0.06-1.23,0.39-1.43c0.32-0.2,1.04-1.04,0.78-1.1c-0.26-0.06-0.07-0.71-0.45-1.1c-0.39-0.39,0.45-0.58,0.39-1.3
				c-0.06-0.71,0.13-2.14-0.06-2.27c-0.19-0.13-1.1-0.19-1.69-0.58C292.27,191.7,290.98,191.63,290.98,191.63z"/>
			<path class="st0" d="M287.93,200.16c-0.44,0.02-1.02-0.58-1.02-1.02c0-0.44-0.59-0.8-0.59-1.1c0-0.29-0.95-0.8-1.46-1.02
				c-0.51-0.22-0.59-1.76-0.8-1.76c-0.17,0-0.62-0.45-0.83-0.83c-0.36,0.09-0.75,0.19-0.98,0.19c-0.43,0-0.87,0.43-1.04,0.65
				c-0.17,0.22-0.52-0.39-0.78-0.56c-0.26-0.17-1.34,0.39-1.64,0.48c-0.3,0.09-1.38,0.17-1.38,0.17s0,4.46,0,5.02
				c0,0.56-1.47,0.22-1.47,0.56c0,0.16,0,2.16,0,4.3c0.32,0.12,0.62,0.27,0.69,0.46c0.17,0.43,0.56,1.38,0.48,1.64
				c-0.09,0.26-0.3,0.09-0.3,0.35c0,0.26-0.17,0.65,0.26,0.65c0.43,0,1.17,0.26,1.47-0.09c0.3-0.35,1.3-1.13,1.34-1.51
				c0.04-0.39,0.26-1.25,0.99-0.78c0.74,0.47,0.78,0.56,1.73,0.56s1.21-0.04,1.38-0.65c0.17-0.61,0-1.08,0.56-1.08
				s1.25-0.52,1.25-0.91c0-0.39,0.26-0.99,0.61-0.99c0.35,0,1.08-0.43,1.17-0.87c0.09-0.43,0.69-0.26,0.91-0.52
				c0.11-0.13,0.32-0.43,0.5-0.71c0,0-0.01,0-0.01,0C288.96,200.52,288.87,200.13,287.93,200.16z"/>
			<path class="st0" d="M292.29,206.63c-0.36,0-1,0.87-1,1.05c0,0.18,0.45,1.33,0.9,1.36c0.45,0.03,0.78,0,0.82-0.33
				c0.01-0.16,0.13-0.3,0.25-0.39c-0.04-0.28-0.11-0.89-0.14-1.26C292.86,206.88,292.52,206.63,292.29,206.63z"/>
			<path class="st0" d="M288.34,211.14c-0.17-0.35-1.3,0.26-1.47,0.61c-0.17,0.35-0.87,1.3-0.87,1.3l0.87,1.21l0.6,0.09
				c0,0,0.17-0.43,0.35-0.78c0.17-0.35,0.95,0.09,0.95-0.26c0-0.35,0.52-0.69,0.52-1.04C289.29,211.92,288.51,211.49,288.34,211.14z
				"/>
		<g className="mouseOver southAfCount">
			<path class="lightBlue" d="M293.28,208.47c0,0-0.01-0.06-0.02-0.15c-0.12,0.09-0.23,0.23-0.25,0.39c-0.03,0.33-0.36,0.36-0.82,0.33
				c-0.45-0.03-0.9-1.18-0.9-1.36c0-0.18,0.63-1.05,1-1.05c0.23,0,0.57,0.25,0.83,0.43c-0.01-0.13-0.01-0.23-0.01-0.27
				c0.03-0.2,0.26-2.43-0.13-2.66c-0.39-0.23-0.78-2.5-0.91-2.73c-0.13-0.23-1.56-0.1-1.72-0.36c-0.16-0.26-1.36-0.01-1.39-0.25
				c-0.19,0.28-0.4,0.58-0.5,0.71c-0.22,0.26-0.82,0.09-0.91,0.52c-0.09,0.43-0.82,0.87-1.17,0.87c-0.35,0-0.61,0.6-0.61,0.99
				c0,0.39-0.69,0.91-1.25,0.91s-0.39,0.48-0.56,1.08c-0.17,0.6-0.43,0.65-1.38,0.65s-1-0.09-1.73-0.56
				c-0.74-0.48-0.95,0.39-0.99,0.78c-0.04,0.39-1.04,1.17-1.34,1.51c-0.3,0.35-1.04,0.09-1.47,0.09c-0.43,0-0.26-0.39-0.26-0.65
				c0-0.26,0.22-0.09,0.3-0.35c0.09-0.26-0.3-1.21-0.48-1.64c-0.07-0.19-0.38-0.34-0.69-0.46c0,2.45,0,5.09,0,5.35
				c0,0.48-0.78,0.69-0.95,0.95c-0.17,0.26-1.26,0.04-1.86,0.04c-0.61,0-0.48-0.61-0.78-1.04c-0.3-0.43-0.82,0.13-0.82,0.43
				c0,0.23-0.27,0.19-0.63,0.15c0.47,0.75,0.89,1.63,1.1,2.34c0.35,1.15,1.19,2.23,1.41,2.9c0.22,0.67,0.45,1.3,0,1.36
				c-0.45,0.07-0.45,0.45,0.02,1.19c0.48,0.73,0.07,1.17,0.48,1.25c0.41,0.09,0.28,0.35,0.6,0.37c0.32,0.02,0.56,0.35,0.78,0.63
				c0.22,0.28,0.71,0.28,0.95-0.06c0.24-0.35,0.97-0.45,1.73-0.45c0.76,0,0.61-0.32,1.34-0.54c0.74-0.22,1.62-0.24,2.25-0.02
				c0.63,0.22,1.1,0.02,1.41-0.17c0.3-0.19,0.89,0.3,0.93-0.19c0.04-0.5,0.65-0.41,1.21-0.41c0.56,0,1.92-0.89,3.01-1.9
				c1.08-1.02,2.88-3.25,3.48-4.26c0.61-1.02,1.6-1.88,2.1-2.34c0.37-0.34,0.59-1.54,0.69-2.41
				C293.86,208.38,293.28,208.47,293.28,208.47z M288.77,213.31c0,0.35-0.78-0.09-0.95,0.26c-0.17,0.35-0.35,0.78-0.35,0.78
				l-0.6-0.09l-0.87-1.21c0,0,0.69-0.95,0.87-1.3c0.17-0.35,1.3-0.95,1.47-0.61c0.17,0.35,0.95,0.78,0.95,1.12
				C289.29,212.62,288.77,212.96,288.77,213.31z"/>
		    <g className="hoverEf southAF">
			<g>
				<path d="M265.6,219.02c0.26,0.15,0.65,0.28,1.06,0.28c0.51,0,0.8-0.24,0.8-0.6c0-0.33-0.22-0.53-0.78-0.73
					c-0.72-0.26-1.19-0.64-1.19-1.27c0-0.72,0.6-1.26,1.55-1.26c0.48,0,0.82,0.1,1.05,0.22l-0.19,0.65
					c-0.16-0.08-0.46-0.21-0.88-0.21c-0.51,0-0.72,0.27-0.72,0.53c0,0.34,0.25,0.49,0.84,0.72c0.76,0.29,1.13,0.67,1.13,1.3
					c0,0.71-0.53,1.32-1.66,1.32c-0.46,0-0.94-0.13-1.18-0.28L265.6,219.02z"/>
				<path d="M271.97,218.28c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
					C271.34,216.66,271.97,217.33,271.97,218.28z M269.58,218.32c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
					c0-0.5-0.22-1.06-0.77-1.06C269.81,217.24,269.58,217.79,269.58,218.32z"/>
				<path d="M275.46,218.95c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V218.95z"/>
				<path d="M277.24,215.83v0.91H278v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49H276v-0.6h0.45v-0.72
					L277.24,215.83z"/>
				<path d="M278.61,215.28h0.8v1.88h0.01c0.1-0.15,0.23-0.27,0.4-0.36c0.16-0.09,0.35-0.14,0.55-0.14c0.54,0,1.11,0.36,1.11,1.37
					v1.86h-0.8v-1.78c0-0.46-0.17-0.81-0.61-0.81c-0.31,0-0.53,0.21-0.62,0.45c-0.03,0.07-0.04,0.15-0.04,0.24v1.89h-0.8V215.28z"/>
				<path d="M284.57,218.66l-0.37,1.24h-0.83l1.41-4.38h1.02l1.42,4.38h-0.86l-0.4-1.24H284.57z M285.83,218.05l-0.35-1.08
					c-0.08-0.27-0.16-0.57-0.22-0.82h-0.01c-0.06,0.26-0.13,0.56-0.21,0.82l-0.34,1.08H285.83z"/>
				<path d="M287.89,219.9v-2.57h-0.43v-0.6h0.43v-0.13c0-0.41,0.11-0.82,0.38-1.08c0.23-0.22,0.55-0.31,0.83-0.31
					c0.21,0,0.38,0.04,0.51,0.08l-0.06,0.62c-0.09-0.03-0.19-0.06-0.34-0.06c-0.4,0-0.53,0.33-0.53,0.72v0.17h0.69v0.6h-0.69v2.57
					H287.89z"/>
				<path d="M289.88,217.75c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V217.75z"/>
				<path d="M293.04,215.85c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C292.86,215.42,293.03,215.6,293.04,215.85z M292.19,219.9v-3.16h0.8v3.16H292.19z"/>
				<path d="M296.18,219.8c-0.17,0.08-0.5,0.16-0.89,0.16c-0.99,0-1.63-0.64-1.63-1.62c0-0.95,0.65-1.68,1.76-1.68
					c0.3,0,0.59,0.06,0.77,0.14l-0.14,0.6c-0.13-0.06-0.32-0.12-0.61-0.12c-0.61,0-0.97,0.45-0.97,1.03c0,0.65,0.43,1.02,0.97,1.02
					c0.27,0,0.47-0.06,0.63-0.12L296.18,219.8z"/>
				<path d="M298.55,219.9l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H298.55z M298.45,218.35c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V218.35z"/>
			</g>
		</g>
		</g>
			<path class="st0" d="M265.61,193.21c0.37,0,0.15,0.51,0.66,0.29c0.51-0.22,0.95-0.22,1.39,0.07c0.44,0.29,0.8,0.36,1.32,0.36
				c0.51,0,5.05,0,5.05,0l0.51,0.51c0,0,2.78-0.22,3.22,0c0.44,0.22,1.83,0,2.27-0.15c0.08-0.03,0.22-0.06,0.4-0.1l-1.67-2.28
				l0.15-4.87l2.17,0.08l0.37-0.38l0.31-2.91c-0.11-0.05-0.25-0.08-0.4-0.01c-0.45,0.19-0.84,0.13-1.3,0.13
				c-0.45,0-0.52,0.45-0.78,0.39c-0.26-0.07-0.06-0.84,0-1.17c0.07-0.32-0.39-0.91-0.71-1.17c-0.32-0.26,0.39-1.88,0.19-2.08
				c-0.19-0.19-0.26-0.71-0.26-1.17c0-0.45-0.32-0.19-0.97-0.19s-0.71-0.32-0.71-0.32s-0.78-0.19-1.17,0
				c-0.39,0.2-0.32,1.36-0.58,1.3c-0.26-0.07-1.04,0.06-1.69,0.26c-0.65,0.19-0.91-0.13-1.23-0.91c-0.32-0.78-0.71-0.52-0.58-1.1
				c0.13-0.58-0.26-1.23-0.26-1.23s-4.28-0.06-4.74,0c-0.18,0.03-0.51,0.22-0.83,0.45c0.27,1,1.1,2.66,1.28,3.05
				c0.19,0.41-0.24,0.78-0.24,1.54c0,0.76,0.82,2.14,0.91,3.07c0.09,0.93-0.52,1.51-1.04,2.12c-0.52,0.61-1.06,1.97-1.06,2.81
				c0,0.84-0.78,1.67-0.76,2.06c0.02,0.39-0.11,1.54-0.19,1.97c0,0.02,0,0.04,0,0.06l0.46-0.12
				C265.1,193.58,265.25,193.21,265.61,193.21z"/>
			<path class="st0" d="M291.72,180.55c-0.2,0-0.66-0.31-1.06-0.6c-0.28,0.31-1.27,0.26-1.76,0.26c-0.52,0-0.06,0.71-0.39,0.97
				c-0.32,0.26-0.45,0.65-0.19,0.78c0.26,0.13-0.06,1.23-0.06,1.62s-0.52,1.23-0.32,1.36c0.19,0.13,0.52,1.04,0.97,1.17
				c0.45,0.13,0.78-0.32,0.97-0.32c0.19,0,0.19,0.58,0.19,1.04c0,0.45-0.26,0.65-0.26,0.65s-0.71,0.26-1.04,0
				c-0.32-0.26-0.58-0.97-0.58-1.23c0-0.26-0.78-0.26-1.1-0.26c-0.32,0-0.65-0.58-0.78-0.84c-0.13-0.26-0.52,0.07-0.52,0.26
				c0,0.2-0.32,0.2-0.65,0.2c-0.32,0-0.97-0.52-1.23-0.39c-0.26,0.13-0.52-0.45-0.45-0.71c0.07-0.26-0.65-0.06-0.91,0.07
				c-0.26,0.13-0.39-0.32-0.39-0.32s-0.15-0.26-0.38-0.37l-0.31,2.91l-0.37,0.38l-2.17-0.08l-0.15,4.87l1.67,2.28
				c0.87-0.18,2.67-0.43,2.67-0.12c0,0.09,0.05,0.21,0.12,0.34c0.3-0.07,0.59-0.13,0.75-0.11c0.35,0.04,1.52,0.48,1.86,0.3
				c0.35-0.17,0.95-1.47,1.51-1.64c0.56-0.17,1.34-0.78,1.34-1.04c0-0.26,0-0.52,0.82-0.74l1.26-0.04l0.09,0l-0.3-1.08
				c0,0,3.83-1.43,4.05-1.47c-0.17-0.35-0.34-0.6-0.26-0.64c0.15-0.07,0.22-1.12,0.22-1.42c0-0.3,0.9-0.08,0.6-0.38
				c-0.3-0.3-0.22-2.02-0.07-2.4c0.15-0.37,0.52,0,0.52-0.3c0-0.3-0.45-1.27-0.67-1.57c-0.16-0.22-1.5-0.75-1.87-0.82
				C292.69,181,292.09,180.55,291.72,180.55z"/>
			<path class="st0" d="M291.1,169.42c-0.32,0.09-0.67,0.19-0.81,0.27c-0.21,0.11-0.32,0.17-0.73,0.13
				c-0.05,0.06-0.11,0.11-0.18,0.14c-0.36,0.13-0.29,0.94-0.29,0.94s-0.32,0.03-0.32,0.42c0,0.26,0.18,0.38,0.29,0.42
				c0.18,0.01,0.35,0.02,0.48,0.05c0.82,0.15,0.75-0.45,0.75-0.45s1.2,0.07,1.27-0.3c0.08-0.37-0.22-0.97-0.22-0.97L291.1,169.42z"
				/>
		<g className="mouseOver somalilandCount">
			<path class="st0" d="M309.93,153.63c0.26,0.13,0.68,0.81,0.97,0.88c0.29,0.06,1.91,0.62,2.95,1.04c1.04,0.42,1.69,0.45,2.01,0.45
				c0.32,0,0.81,0.1,0.52,0.32c-0.29,0.23-3.47,3.44-3.89,3.89c-0.42,0.45-0.68,0.29-1.62,0.29c-0.94,0-1.65,1.04-1.91,1.01
				c-0.15-0.02-0.71,0.08-1.19,0.18l-0.7,1.02l-0.67,1.05l0.08,5.24l0.73,1.15c0.57-0.76,2.11-2.24,3.25-3.43
				c1.23-1.3,1.86-1.6,2.55-1.95c0.69-0.35,2.44-2.06,3.64-3.57c0.75-0.95,1.58-2.01,1.69-2.7c0.11-0.69,0.8-1.6,1.32-2.27
				c0.52-0.67,1.38-2.38,1.36-2.9c-0.02-0.52,0.43-1.62,0.5-2.55c0.06-0.93-0.71-1.02-0.95-0.5c-0.24,0.52-2.1,0.86-3.29,0.89
				c-1.19,0.02-1.9,0.13-2.34,0.54c-0.43,0.41-1.51-0.11-1.9,0.35c-0.39,0.45-1.93,0.84-2.42-0.19c-0.21-0.44-0.49-0.68-0.75-0.83
				c-0.42,0.43-0.92,1.06-0.83,1.27C309.15,152.59,309.67,153.5,309.93,153.63z"/>
		</g>
			<path class="st0" d="M234.71,126.55c-0.02-0.96-0.06-1.9-0.03-2c0.05-0.19,2-1.34,2.34-1.53c0.33-0.19,0.95,0.1,0.95-0.29
				c0-0.38,0.81-0.19,1.29-0.29c0.48-0.1,0.76-0.43,0.76-0.86c0-0.43,0.67-0.33,1.1-0.38c0.43-0.05,0.38-0.43,0.67-0.43
				c0.29,0,0.14-0.43,0.19-0.91c0.05-0.48,0.05-0.38,0.48-0.38c0.43,0,0.86-0.24,0.86-0.53c0-0.29,1.86-0.1,2.15-0.1
				c0.29,0,0.38-0.33,0.33-0.57c-0.05-0.24-0.29-0.95-0.57-1c-0.29-0.05-0.1-0.76-0.1-1.34c0-0.57-0.1-1.1-0.43-1.53
				c-0.07-0.09-0.15-0.26-0.24-0.45c-0.52,0.01-1.15-0.38-1.7-0.4c-0.76-0.02-1.93,0.37-2.49-0.26c-0.56-0.63-0.95-0.82-1.36-0.63
				c-0.41,0.19-1.17,2.36-1.6,2.99c-0.43,0.63-1.64,1.06-2.23,1.06c-0.58,0-0.65,0.89-1.1,1.25c-0.45,0.37-0.28,0.95-0.82,1.38
				c-0.54,0.43-0.28,1.75,0,2.23c0.28,0.48-1,1.97-1.73,2.4c-0.74,0.43-0.97,1.25-2.14,1.45c-0.45,0.08-0.79,0.27-1.06,0.52
				c1.82,0,4.52,0,5.44,0C234.78,125.99,234.53,126.25,234.71,126.55z"/>
		
			<path class="st0" d="M245.12,115.97c0,0.57-0.19,1.29,0.1,1.34c0.29,0.05,0.53,0.76,0.57,1c0.05,0.24-0.05,0.57-0.33,0.57
				c-0.29,0-2.15-0.19-2.15,0.1c0,0.29-0.43,0.53-0.86,0.53c-0.43,0-0.43-0.1-0.48,0.38c-0.05,0.48,0.1,0.91-0.19,0.91
				c-0.29,0-0.24,0.38-0.67,0.43c-0.43,0.05-1.1-0.05-1.1,0.38c0,0.43-0.29,0.76-0.76,0.86c-0.48,0.1-1.29-0.1-1.29,0.29
				c0,0.38-0.62,0.1-0.95,0.29c-0.33,0.19-2.29,1.34-2.34,1.53c-0.02,0.09,0.01,1.04,0.03,2c0.05,0.09,0.13,0.17,0.29,0.26
				c0.72,0.41,13.79,9.05,14.2,9.47c0.41,0.41,0.93,1.34,0.93,1.34s0.93,0.1,1.65,0.51c0.72,0.41,0.72,1.54,0.72,1.54
				s0.93-0.21,1.44-0.31c0.51-0.1,2.26-0.72,2.26-0.72l2.67-2.16l6.28-3.91c0,0,0.01,0,0.02,0c-0.19-0.69-0.4-1.25-0.74-1.25
				c-0.38,0-0.65-0.32-1.08-0.32c-0.43,0-0.97-0.22-1.03-0.59c-0.05-0.38,0.16-0.65-0.16-1.13c-0.32-0.49-0.65-0.7-0.65-0.92
				c0-0.22,0.55-0.64,0.63-0.72c0.09-0.09-0.22-0.43-0.22-0.67c0-0.24-0.22-0.65,0-0.91c0.22-0.26,0.35-0.52,0.11-1
				c-0.24-0.48,0.32-0.91,0.04-1.54c-0.28-0.63-0.71-1.12-0.67-1.34c0.02-0.09,0.16-0.19,0.32-0.31c-0.08-0.1-0.13-0.23-0.13-0.38
				c0-0.61-0.43-2.25-0.69-2.6c-0.26-0.35-1.13-0.61-1.13-0.87c0-0.26,0-0.69-0.61-0.95c-0.61-0.26-0.95-1.56-0.61-1.56
				c0.35,0,0.61-0.78,0.95-0.95c0.35-0.17,0.78-1.04,0.43-1.56c-0.23-0.35-0.11-1.42,0.01-2.28c-0.57,0-1.21-0.21-1.38-0.27
				c-0.26-0.09-1.02,0.13-1.49,0.15c-0.48,0.02-1.64,0.78-2.03,0.41c-0.39-0.37-4.37,0.28-5.5,0.32c-1.13,0.04-1.6,1.21-2.27,1.21
				c-0.67,0-1.73,0.56-2.29,1.19c-0.16,0.18-0.35,0.24-0.55,0.25c0.09,0.2,0.17,0.36,0.24,0.45
				C245.02,114.87,245.12,115.4,245.12,115.97z"/>
			<path class="st0" d="M263.85,118.46c-0.15,0.06-0.93,0.89-1.12,1.06c-0.19,0.17-0.22,0.48-0.13,0.65
				c0.09,0.17-0.06,0.8-0.26,1.17c-0.19,0.37-0.89,0.63-0.93,0.84c-0.04,0.22,0.39,0.71,0.67,1.34c0.28,0.63-0.28,1.06-0.04,1.54
				c0.24,0.48,0.11,0.73-0.11,1c-0.22,0.26,0,0.67,0,0.91c0,0.24,0.3,0.58,0.22,0.67c-0.09,0.09-0.63,0.51-0.63,0.72
				c0,0.22,0.32,0.43,0.65,0.92c0.32,0.48,0.11,0.76,0.16,1.13c0.05,0.38,0.59,0.59,1.03,0.59c0.43,0,0.7,0.32,1.08,0.32
				c0.34,0,0.54,0.56,0.74,1.25c0.16,0,1.56,0.02,2.14,0.41c0.62,0.41,0.93,0.93,0.93,0.93l2.47-1.34l11.17,6.01v-0.77h1.35
				c0,0,0-13.36,0-14.35c0-0.99-0.45-1.62,0-2.17c0.45-0.54-0.18-0.81,0.18-1.8c0.04-0.12,0.09-0.24,0.13-0.36
				c-0.01-0.02-0.03-0.04-0.04-0.06c-0.11-0.3-0.69-0.32-1.97-0.52c-1.28-0.19-0.8-1.06-2.23-1.15c-1.43-0.09-3.24,1.06-3.27,1.67
				c-0.02,0.61,0.67,1.28,0.19,1.9c-0.48,0.63-1.51,0.69-2.25,0.02c-0.74-0.67-1.82-0.97-2.7-0.97c-0.89,0-1.51-0.56-1.45-1.04
				c0.07-0.48-0.22-0.69-0.95-0.84c-0.74-0.15-1.36-0.76-2.66-0.54c-0.69,0.12-1.28-0.09-1.69-0.35c-0.03,0.28-0.05,0.57-0.04,0.76
				C264.49,118.49,264,118.4,263.85,118.46z"/>
			<path class="st0" d="M225.08,147.97c0.3-0.27,0.79,0.27,1.23,0.33c0.44,0.05,0.9-0.25,0.9-0.25s-0.08-0.05-0.79-0.11
				c-0.71-0.05-0.77-0.9-1.4-0.6c-0.63,0.3-1.07,0.38-1.85,0.3c-0.01,0.24-0.15,0.56-0.19,0.88c0.2-0.05,0.36-0.08,0.43-0.09
				C223.85,148.41,224.78,148.24,225.08,147.97z"/>
			<path class="st0" d="M225.42,151.36c0.32-0.23,0.97-0.81,1.2-0.71c0.23,0.1,0.52-0.1,0.52-0.23s-0.45-0.55-0.1-0.68
				c0.25-0.09,0.31-0.37,0.32-0.55c-0.13-0.06-0.26-0.11-0.37-0.1c-0.3,0.03-2.13,0.16-2.13,0.16s-0.55,0.36-0.82,0.33
				c-0.23-0.02,0-0.39-0.74-0.2c0.37,0.46,0.42,0.69,1.17,0.91c0.84,0.24-0.06,0.67,0.63,1.1c0.07,0.04,0.12,0.08,0.17,0.12
				C225.33,151.44,225.39,151.38,225.42,151.36z"/>
			<path class="st0" d="M285.2,158.47c0-0.27,0-0.54-0.54-0.99c-0.54-0.45-0.9-0.54-0.9-0.81c0-0.27-0.18-0.36-0.63-0.9
				c-0.45-0.54-1.17-0.54-1.17-0.54l-0.18-0.45l-0.63-0.09c0,0-0.36-0.27,0.18-0.54c0.54-0.27,0-0.99-0.09-1.26
				c-0.08-0.24-0.84-1.15-1.04-1.39c-0.1,0-0.19-0.02-0.25-0.06c-0.3-0.17-0.91,0.17-1.21,0.48c-0.3,0.3-0.09,0.48-0.3,0.69
				c-0.22,0.22-0.95,0.86-1.43,1.47c-0.48,0.61-1.69,0.22-2.08,0.43c-0.39,0.22,0.17,0.56-0.09,0.78c-0.26,0.22-1.04,0.56-1.3,0.69
				c-0.26,0.13-1.3,0.09-1.6,0.48c-0.3,0.39-0.65-0.43-0.95,0c-0.3,0.43-0.65,0.48-0.82,0.35c-0.04-0.03-0.08-0.08-0.13-0.15
				c-0.28,0.26-0.62,0.55-0.74,0.91c-0.19,0.62-0.71,1.17-0.71,1.46c0,0.29,0.19,0.68,0.19,1.2c0,0.52,0.13,0.78,0.42,1.17
				c0.29,0.39,0.23,0.88,0.23,0.88s0.1,0.19,0.32,0.49c0.23,0.29,0.88,0.71,0.94,0.94c0.03,0.1,0.14,0.19,0.25,0.25
				c0.03-0.05,0.06-0.1,0.09-0.14c0.36-0.45,0.27-1.08,0.27-1.08l0.81-0.18l0.63-0.18l1.26,0.18v-0.99c0,0,0.9-1.17,1.53-1.17
				c0.63,0,1.17,0.81,1.17,0.81l2.8,0.54c0,0,0.18-0.81,0.45-0.99c0.27-0.18,0.81,0.18,1.17,0c0.36-0.18,0.9-0.45,1.53-0.45
				c0.63,0,0.9,0.36,1.17-0.18c0.27-0.54,1.62,0.18,1.62,0.18l1.08-0.18l-0.36-0.9C286.19,159.2,285.2,158.74,285.2,158.47z"/>
			<path class="st0" d="M262.2,157.66c0.17-0.04,0.56,0.09,0.87-0.13c0.3-0.22,0.95,0.3,1.04,0.73c0.09,0.43,0.35-0.17,0.61-0.65
				c0.26-0.48,1.08-1.9,1.08-2.25c0-0.35,0.69-0.65,0.69-0.87c0-0.22-0.04-0.69,0.17-0.78c0.22-0.09,0.39-0.73,0.35-0.95
				c-0.04-0.22,0.73-1.43,0.86-1.6c0.13-0.17,0.82-0.09,0.87-0.48c0.04-0.39,0.17-0.82-0.09-0.91c-0.26-0.09-0.6-0.39-0.6-0.61
				c0-0.12,0.02-0.48,0.09-0.79c-0.35-0.05-0.36-0.4-0.57-0.6c-0.22-0.22-0.22-0.39-0.52-0.3c-0.3,0.09-0.65,0.82-1.04,0.95
				c-0.39,0.13-0.95-0.17-1.21-0.3c-0.26-0.13-1.47,0-1.86-0.04c-0.39-0.04-0.78,0.52-1.08,0.69c-0.3,0.17-1.17,0.09-1.43,0
				c-0.26-0.09-0.87-0.6-1.13-0.6c-0.26,0-0.78,0.09-1.21,0.35c-0.43,0.26-0.56-0.48-0.82-0.78c-0.26-0.3-0.82-0.04-1.17-0.35
				c-0.35-0.3-0.56,0.35-0.87,0.3c-0.3-0.04-1.08-0.22-1.34,0c-0.26,0.22-0.22,1.04-0.35,1.17c-0.13,0.13-0.65,0.74-0.65,1.04
				c0,0.14-0.05,0.24-0.14,0.29c0.06,0.36,0.13,0.82,0.15,0.99c0.03,0.29,0.16,1.75-0.16,2.08c-0.32,0.32-0.55,0.84-0.55,0.84
				s-0.52,0.36-0.62,1.07c-0.1,0.71,0.03,1.17,0.03,1.72c0,0.39,0.29,0.67,0.45,1.15c0.01,0.01,0.01,0.01,0.02,0.01
				c0.26,0.35,1.49,0.41,2.18,0.56c0.69,0.15,1.41,1.12,1.39,1.49c-0.02,0.37,0.45,1.08,0.78,1.25c0.32,0.17,1.12-0.32,1.49-0.32
				c0.37,0,1.69,0.02,1.95-0.22c0.08-0.07,0.15-0.06,0.23-0.01c0.31-0.83,0.43-1.57,0.43-1.57S262.03,157.71,262.2,157.66z"/>
			<path class="st0" d="M251.58,155.19c0.1-0.71,0.62-1.07,0.62-1.07s0.23-0.52,0.55-0.84c0.32-0.32,0.19-1.78,0.16-2.08
				c-0.02-0.17-0.09-0.63-0.15-0.99c-0.1,0.06-0.26,0.06-0.47-0.03c-0.39-0.17-0.04-0.56-0.48-0.69c-0.43-0.13-0.61,0.35-0.52,0.69
				c0.09,0.34-0.22,0.34-0.39,0.56c-0.17,0.22-0.95,0.26-1.21,0.26c-0.26,0-0.56,0.48-0.82,0.56c-0.06,0.02-0.14,0.02-0.23,0.02
				c0.06,1.14,0.25,1.05,0.79,1.37c0.62,0.36,0.03,0.84,0.29,1.1c0.26,0.26,0.16,0.91,0.23,1.72c0.04,0.49,0.09,1.76,0.12,2.7
				c0.36-0.07,0.73-0.09,0.98-0.09c0.47,0,0.75-0.62,1-0.32c-0.16-0.48-0.45-0.76-0.45-1.15
				C251.61,156.36,251.48,155.9,251.58,155.19z"/>
			<path class="st0" d="M249.73,154.05c-0.26-0.26,0.32-0.75-0.29-1.1c-0.54-0.31-0.73-0.23-0.79-1.37
				c-0.31-0.02-0.77-0.16-1.11-0.19c-0.07-0.01-0.13,0.01-0.18,0.03c0.07,0.41,0.17,0.87,0.29,0.94c0.23,0.13,0.39,0.39,0.36,0.88
				c-0.03,0.49-0.16,1.56,0.03,2.04c0.19,0.49,0.39,1.27,0.39,2.01c0,0.47,0.26,1.16,0.54,1.68c0.11-0.06,0.21-0.12,0.3-0.19
				c0.19-0.16,0.5-0.26,0.82-0.32c-0.03-0.93-0.09-2.2-0.12-2.7C249.89,154.96,249.99,154.31,249.73,154.05z"/>
			<path class="st0" d="M264.63,164.43c0,0,3.43,0.18,3.88,0.09c0.45-0.09,2.07,0.72,2.07,0.72s0.08-0.82,0.36-1.31
				c-0.11-0.06-0.22-0.14-0.25-0.25c-0.06-0.23-0.71-0.65-0.94-0.94c-0.23-0.29-0.32-0.49-0.32-0.49s0.06-0.49-0.23-0.88
				c-0.29-0.39-0.42-0.65-0.42-1.17c0-0.52-0.19-0.91-0.19-1.2c0-0.29,0.52-0.84,0.71-1.46c0.11-0.36,0.46-0.65,0.74-0.91
				c-0.14-0.22-0.3-0.63-0.43-1.06c-0.17-0.56-0.65-0.74-1.21-1.25c-0.56-0.52-0.35-0.82-0.35-1.04c0-0.22,0.74-0.22,1.25-0.17
				c0.52,0.04,0.82-0.09,0.61-0.22c-0.22-0.13-0.65-1.13-0.61-1.82c0.04-0.69-0.22-0.91-0.17-1.3c0.04-0.39-0.48-1.38-0.91-1.38
				c-0.03,0-0.05-0.01-0.08-0.01c-0.07,0.31-0.09,0.67-0.09,0.79c0,0.22,0.35,0.52,0.6,0.61c0.26,0.09,0.13,0.52,0.09,0.91
				c-0.04,0.39-0.74,0.3-0.87,0.48c-0.13,0.17-0.91,1.39-0.86,1.6c0.04,0.22-0.13,0.87-0.35,0.95c-0.22,0.09-0.17,0.56-0.17,0.78
				c0,0.22-0.69,0.52-0.69,0.87c0,0.35-0.82,1.77-1.08,2.25c-0.26,0.48-0.52,1.08-0.61,0.65c-0.09-0.43-0.74-0.95-1.04-0.73
				c-0.3,0.22-0.69,0.09-0.87,0.13c-0.17,0.04-1.68,1.6-1.68,1.6s-0.11,0.74-0.43,1.57c0.19,0.12,0.4,0.51,0.61,0.81
				c0.3,0.43,0.89,0.11,1.12,0.24c0.24,0.13,0.5,1.15,0.41,1.43c-0.08,0.27-0.44,0.96-0.28,1.3H264L264.63,164.43z"/>
			<path class="st0" d="M243.37,159.73c-0.19-0.26-0.65-1.36-0.62-1.62c0.03-0.26,0.58-1.04,0.55-1.43
				c-0.03-0.39,0.55-0.65,0.55-0.97c0-0.25-0.15-1.47-0.51-2.27c-0.07,0-0.16-0.03-0.27-0.14c-0.26-0.26-1.34-0.22-1.73,0.22
				c-0.39,0.43-1.17-0.39-1.3-0.6c-0.13-0.22-0.69-0.69-1.13-0.39c-0.43,0.3-0.56,0.22-0.52-0.17c0.04-0.39-0.65-0.26-0.74,0.09
				c-0.09,0.35-0.65,0.65-0.82,0.39c-0.17-0.26-0.56-0.61-0.74-0.22c-0.12,0.27-0.32,0.25-0.5,0.12c-0.11,0.48-0.24,1.03,0,1.17
				c0.29,0.16,0.32,0.49,0.26,0.68c-0.06,0.19,0.49,0.68,0.26,0.91c-0.23,0.23-0.52-0.23-0.68,0.1c-0.16,0.32,0.32,0.62,0.13,0.88
				c-0.19,0.26-0.49,0.29-0.42,0.81c0.06,0.52,0.03,0.88-0.16,1.04c-0.19,0.16,0.03,0.16,0.49,0.45c0.45,0.29,1.2,0.62,1.14,1.1
				c-0.06,0.44-0.25,0.55-0.43,1.36c0.88,0.02,2.46-0.62,4.32-0.94c1.16-0.2,2.14-0.04,2.84,0.17
				C243.42,160.17,243.47,159.86,243.37,159.73z"/>
			<path class="st0" d="M248.04,155.29c-0.19-0.49-0.07-1.56-0.03-2.04c0.03-0.49-0.13-0.75-0.36-0.88
				c-0.12-0.07-0.22-0.53-0.29-0.94c-0.26,0.12-0.28,0.54-0.39,0.36c-0.13-0.22-3.51-0.35-3.68-0.09c-0.17,0.26,0.3,1.21,0.26,1.47
				c-0.03,0.15-0.1,0.26-0.21,0.27c0.36,0.8,0.51,2.03,0.51,2.27c0,0.32-0.58,0.58-0.55,0.97c0.03,0.39-0.52,1.17-0.55,1.43
				c-0.03,0.26,0.42,1.36,0.62,1.62c0.1,0.13,0.05,0.44-0.03,0.72c0.59,0.17,0.98,0.37,1.1,0.4c0.26,0.07,2.1-0.78,2.83-1.15
				c0.59-0.29,1.24-0.48,1.69-0.72c-0.27-0.53-0.54-1.22-0.54-1.68C248.43,156.55,248.24,155.77,248.04,155.29z"/>
			<path class="st0" d="M228.96,153.5c0.03-0.36,0.32-0.23,0.68-0.16c0.36,0.07,0.78-0.23,1.1-0.23c0.32,0,1.17,1.14,1.17,1.46
				c0,0.32,0.03,0.62,0.19,0.88c0.02,0.03,0.04,0.07,0.05,0.1c0.52-0.12,0.96-0.18,1.06-0.18c0.16,0,0.48,0.77,0.41,1.24
				c-0.06,0.48,0.48,0.83,0.7,0.51c0.22-0.32,0.51-0.73,0.57-0.45c0.02,0.09,0.14,0.16,0.29,0.22c0.08-0.19,0.25-0.26,0.39-0.44
				c0.19-0.26-0.29-0.55-0.13-0.88c0.16-0.32,0.45,0.13,0.68-0.1c0.23-0.23-0.32-0.71-0.26-0.91c0.07-0.19,0.03-0.52-0.26-0.68
				c-0.24-0.13-0.11-0.69,0-1.17c-0.07-0.05-0.14-0.13-0.2-0.21c-0.17-0.26-0.04-0.82-0.35-0.82c-0.3,0-0.26-0.43,0-0.52
				c0.26-0.09-0.52-0.39-0.52-0.69c0-0.3-0.39-1.04-0.56-1.08c-0.17-0.04-0.78,0.74-1.04,0.74c-0.26,0-0.82-0.52-0.99-0.09
				c-0.17,0.43-0.56-0.22-0.82,0.13c-0.26,0.34-0.43-0.39-0.43-0.39s0-0.1,0.01-0.24c-0.05,0.02-0.11,0.04-0.16,0.06
				c-0.75,0.26-2.08,0.1-2.14-0.13c-0.07-0.23-1.04-0.42-1.04-0.42s0.03,0.55-0.32,0.68c-0.36,0.13,0.1,0.55,0.1,0.68
				s-0.29,0.32-0.52,0.23c-0.23-0.1-0.88,0.49-1.2,0.71c-0.04,0.03-0.09,0.08-0.16,0.16c0.5,0.4,0.46,0.85,1.04,1.28
				c0.54,0.4,1.23,0.94,1.65,1.8C228.38,154.24,228.94,153.74,228.96,153.5z"/>
			<path class="st0" d="M235.48,158.76c-0.45-0.29-0.68-0.29-0.49-0.45c0.19-0.16,0.23-0.52,0.16-1.04c-0.02-0.17,0-0.28,0.04-0.37
				c-0.15-0.06-0.27-0.13-0.29-0.22c-0.06-0.29-0.35,0.13-0.57,0.45c-0.22,0.32-0.77-0.03-0.7-0.51c0.06-0.48-0.26-1.24-0.41-1.24
				c-0.1,0-0.53,0.06-1.06,0.18c0.07,0.23-0.09,0.49-0.14,0.74c-0.06,0.29-0.84,0.71-1.17,0.97c-0.09,0.07-0.21,0.26-0.33,0.49
				c0.61,0.39,1.32,0.83,1.9,1.27c1.06,0.8,2.66,1.86,3.42,2.14c0.1,0.04,0.22,0.05,0.36,0.06c0.18-0.82,0.37-0.92,0.43-1.36
				C236.68,159.37,235.94,159.05,235.48,158.76z"/>
			<path class="st0" d="M232.01,156.29c0.06-0.29,0.26-0.58,0.1-0.84c-0.16-0.26-0.19-0.55-0.19-0.88c0-0.32-0.84-1.46-1.17-1.46
				c-0.32,0-0.75,0.29-1.1,0.23c-0.36-0.07-0.65-0.19-0.68,0.16c-0.02,0.24-0.58,0.74-1,1.09c0.08,0.17,0.16,0.34,0.21,0.54
				c0.35,1.19,0.67,1.43,1.17,1.84c0.22,0.19,0.66,0.46,1.17,0.79c0.12-0.23,0.24-0.42,0.33-0.49
				C231.17,157.01,231.95,156.58,232.01,156.29z"/>
			<path class="st0" d="M228.23,133.67c0-0.33,1.53-0.76,1.53-1.1c0-0.33,0-3.91,0-3.91s4.77,0.1,4.91,0
				c0.07-0.05,0.06-1.09,0.03-2.11c-0.18-0.31,0.08-0.56-1.05-0.56c-0.92,0-3.62,0-5.44,0c-0.42,0.39-0.64,0.92-0.8,1.34
				c-0.26,0.69-1.88,1.71-1.95,2.68c-0.07,0.97-1.58,2.49-2.57,4.56c-0.27,0.57-0.4,1.02-0.43,1.38h5.77
				C228.23,135.96,228.23,134,228.23,133.67z"/>
			<path class="st0" d="M223.78,143.38c0.51,0.1,1.34,0,1.34,0s0.72-0.31,1.13-0.1c0.41,0.21,0.82,0.72,0.82,0.72h0.51
				c0,0,0.41,1.13,1.03,1.34c0.35,0.12,0.57,0.44,0.69,0.69c0.44-0.01,0.78-0.16,0.78-0.47c0-0.39,0.2-0.92,0.85-0.33
				c0.66,0.59,0.66,0.06,0.79-0.13c0.13-0.2,0.59,0.07,0.98,0.07c0.39,0,0.92-0.26,0.92-0.26s5.45,0.07,5.71-0.07
				c0.26-0.13,0.2-1.25,0.2-1.25l-0.46-0.46l-1.38-12.93h2.47c-2.63-1.74-4.89-3.22-5.17-3.38c-0.16-0.09-0.24-0.18-0.29-0.26
				c0.03,1.02,0.04,2.06-0.03,2.11c-0.14,0.1-4.91,0-4.91,0s0,3.58,0,3.91c0,0.33-1.53,0.76-1.53,1.1c0,0.33,0,2.29,0,2.29h-5.77
				c-0.08,0.94,0.49,1.28,0.82,1.65c0.45,0.52-0.35,1.43,0.32,2.36c0.67,0.93-0.15,2.36-0.48,2.88c-0.18,0.28-0.19,0.77-0.25,1.27
				c0.03-0.01,0.06-0.01,0.07-0.02C223.37,144,223.27,143.28,223.78,143.38z"/>
			<path class="st0" d="M252.48,139.68c0,0,0-1.13-0.72-1.54c-0.72-0.41-1.65-0.51-1.65-0.51s-0.51-0.93-0.93-1.34
				c-0.25-0.25-5.06-3.47-9.02-6.09h-2.47l1.38,12.93l0.46,0.46c0,0,0.06,1.12-0.2,1.25c-0.26,0.13-5.71,0.07-5.71,0.07
				s-0.53,0.26-0.92,0.26c-0.39,0-0.85-0.26-0.98-0.07c-0.13,0.2-0.13,0.72-0.79,0.13c-0.66-0.59-0.85-0.07-0.85,0.33
				c0,0.32-0.34,0.46-0.78,0.47c0.09,0.19,0.13,0.34,0.13,0.34l0.27,0.4c0,0-0.04,0.65,0.22,0.91c0.26,0.26,0.74,0.74,0.78,0.95
				c0.04,0.22,0,1.17,0,1.17s0.17,0.73,0.43,0.39c0.26-0.35,0.65,0.3,0.82-0.13c0.17-0.43,0.74,0.09,0.99,0.09
				c0.26,0,0.87-0.78,1.04-0.74c0.17,0.04,0.56,0.78,0.56,1.08c0,0.3,0.78,0.61,0.52,0.69c-0.26,0.09-0.3,0.52,0,0.52
				c0.3,0,0.17,0.56,0.35,0.82c0.17,0.26,0.52,0.48,0.69,0.09c0.17-0.39,0.56-0.04,0.74,0.22c0.17,0.26,0.74-0.04,0.82-0.39
				c0.09-0.35,0.78-0.48,0.74-0.09c-0.04,0.39,0.09,0.48,0.52,0.17c0.13-0.09,0.27-0.11,0.41-0.09c0.15-0.5,0.33-0.97,0.41-1.13
				c0.13-0.26-0.3-0.86-0.09-0.95c0.22-0.09,0.95,0.09,1.17-0.52c0.22-0.6,0.48-0.91,0.48-0.91s0.09-1.3,0.48-1.08
				c0.39,0.22,0.87,0.56,0.91,0.04c0.04-0.52,0.52-0.22,0.65-0.69c0.13-0.48,0.39-0.43,0.69-0.35c0.3,0.09,0.56-0.04,0.56-0.3
				c0-0.26,0.69-0.13,1.26-0.52c0.56-0.39,0.86-0.69,1.08-0.48c0.22,0.22,1.65,0.17,1.86,0.09c0.22-0.09,0.65-0.48,1.17-0.39
				c0.52,0.09,1.77-0.22,2.29-0.22c0.52,0,0.74-0.48,0.91-0.65c0.17-0.17,0.3-0.95,0.48-1.08c0.14-0.1,0.11-2.82,0.09-3.89
				C253.21,139.51,252.48,139.68,252.48,139.68z"/>
			<path class="st0" d="M251.3,150.16c-0.22-0.2-0.52-0.27-0.59-0.27c-0.13,0-0.03-0.71-0.1-0.81c-0.07-0.1-0.49,0.19-0.75,0.1
				c-0.26-0.1-0.36-0.52-0.58-0.52c-0.23,0-0.26-0.26-0.26-0.55c0-0.29-0.42-0.58-0.68-0.78c-0.26-0.19-0.32-0.75-0.49-0.84
				c-0.09-0.06-0.06-0.45-0.01-0.79c-0.4-0.01-0.8-0.05-0.91-0.16c-0.22-0.22-0.52,0.09-1.08,0.48c-0.56,0.39-1.26,0.26-1.26,0.52
				c0,0.26-0.26,0.39-0.56,0.3c-0.3-0.09-0.56-0.13-0.69,0.35c-0.13,0.48-0.61,0.17-0.65,0.69c-0.04,0.52-0.52,0.17-0.91-0.04
				c-0.39-0.22-0.48,1.08-0.48,1.08s-0.26,0.3-0.48,0.91c-0.22,0.61-0.95,0.43-1.17,0.52c-0.22,0.09,0.22,0.69,0.09,0.95
				c-0.08,0.16-0.25,0.62-0.41,1.13c0.32,0.05,0.62,0.32,0.71,0.48c0.13,0.22,0.91,1.04,1.3,0.6c0.39-0.43,1.47-0.48,1.73-0.22
				c0.26,0.26,0.43,0.13,0.48-0.13c0.04-0.26-0.43-1.21-0.26-1.47c0.17-0.26,3.55-0.13,3.68,0.09c0.13,0.22,0.13-0.43,0.56-0.39
				c0.43,0.04,1.08,0.26,1.34,0.17c0.26-0.09,0.56-0.56,0.82-0.56c0.26,0,1.04-0.04,1.21-0.26c0.17-0.22,0.48-0.22,0.39-0.56
				C251.3,150.17,251.3,150.17,251.3,150.16z"/>
		<g className="mouseOver senegalCount">
			<g className="hoverEf senegal">
				<g>
					<path d="M210.83,152.76c0.26,0.15,0.65,0.28,1.06,0.28c0.51,0,0.8-0.24,0.8-0.6c0-0.33-0.22-0.53-0.78-0.73
						c-0.72-0.26-1.19-0.64-1.19-1.27c0-0.72,0.6-1.26,1.55-1.26c0.48,0,0.82,0.1,1.05,0.22l-0.19,0.65
						c-0.16-0.08-0.46-0.21-0.88-0.21c-0.51,0-0.72,0.27-0.72,0.53c0,0.34,0.25,0.49,0.84,0.72c0.76,0.29,1.13,0.67,1.13,1.3
						c0,0.71-0.53,1.32-1.66,1.32c-0.46,0-0.94-0.13-1.18-0.28L210.83,152.76z"/>
					<path d="M214.76,152.27c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
						c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H214.76z
						M216.15,151.72c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H216.15z"/>
					<path d="M217.55,151.41c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
						c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
						c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V151.41z"/>
					<path d="M221.83,152.27c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
						c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H221.83z
						M223.22,151.72c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H223.22z"/>
					<path d="M227.51,150.47c-0.02,0.21-0.03,0.48-0.03,0.91v1.79c0,0.66-0.13,1.14-0.47,1.43c-0.33,0.29-0.79,0.38-1.22,0.38
						c-0.4,0-0.83-0.09-1.1-0.25l0.17-0.61c0.2,0.12,0.54,0.24,0.92,0.24c0.52,0,0.91-0.28,0.91-0.96v-0.27h-0.01
						c-0.18,0.28-0.5,0.46-0.92,0.46c-0.78,0-1.34-0.65-1.34-1.54c0-1.04,0.68-1.67,1.43-1.67c0.49,0,0.77,0.23,0.92,0.49h0.01
						l0.03-0.42H227.51z M226.69,151.74c0-0.08-0.01-0.15-0.03-0.22c-0.08-0.29-0.31-0.51-0.65-0.51c-0.44,0-0.78,0.39-0.78,1.03
						c0,0.54,0.27,0.97,0.77,0.97c0.3,0,0.56-0.19,0.65-0.49c0.03-0.08,0.04-0.19,0.04-0.28V151.74z"/>
					<path d="M230.13,153.64l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H230.13z M230.02,152.09c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V152.09z"/>
					<path d="M231.65,149.02h0.8v4.62h-0.8V149.02z"/>
				</g>
			</g>
			<path class="lightBlue" d="M224.04,149.58c0.27,0.03,0.82-0.33,0.82-0.33s1.83-0.14,2.13-0.16c0.11-0.01,0.24,0.04,0.37,0.1
				c0.01-0.08,0-0.14,0-0.14s0.97,0.19,1.04,0.42c0.06,0.23,1.4,0.39,2.14,0.13c0.05-0.02,0.11-0.04,0.16-0.06
				c0.01-0.3,0.02-0.78-0.01-0.93c-0.04-0.22-0.52-0.69-0.78-0.95c-0.26-0.26-0.22-0.91-0.22-0.91l-0.27-0.4
				c0,0-0.21-0.82-0.82-1.03c-0.62-0.21-1.03-1.34-1.03-1.34h-0.51c0,0-0.41-0.51-0.82-0.72c-0.41-0.21-1.13,0.1-1.13,0.1
				s-0.82,0.1-1.34,0c-0.51-0.1-0.41,0.62-0.82,0.72c-0.02,0-0.05,0.01-0.07,0.02c-0.05,0.42-0.12,0.85-0.33,1.17
				c-0.48,0.71,0.19,1.75,0.54,2.08c0.07,0.07,0.09,0.16,0.09,0.28c0.79,0.08,1.23,0,1.85-0.3c0.63-0.3,0.68,0.55,1.4,0.6
				c0.71,0.05,0.79,0.11,0.79,0.11s-0.47,0.3-0.9,0.25c-0.44-0.05-0.93-0.6-1.23-0.33c-0.3,0.27-1.23,0.44-1.67,0.46
				c-0.07,0-0.23,0.04-0.43,0.09c-0.03,0.24-0.01,0.48,0.17,0.68c0.06,0.06,0.1,0.12,0.15,0.17
				C224.04,149.19,223.81,149.56,224.04,149.58z"/>
		</g>
			<path class="st0" d="M269.47,135.16c0-0.21-0.05-1.17-0.09-1.88l-1.16,0.63c0,0-0.31-0.51-0.93-0.93
				c-0.62-0.41-2.16-0.41-2.16-0.41l-6.28,3.91l-2.67,2.16c0,0-1.75,0.62-2.26,0.72c-0.06,0.01-0.13,0.02-0.19,0.04
				c0.02,1.07,0.04,3.79-0.09,3.89c-0.17,0.13-0.3,0.91-0.48,1.08c-0.17,0.17-0.39,0.65-0.91,0.65c-0.52,0-1.77,0.3-2.29,0.22
				c-0.52-0.09-0.95,0.3-1.17,0.39c-0.11,0.04-0.53,0.08-0.95,0.07c-0.05,0.33-0.08,0.73,0.01,0.79c0.16,0.1,0.23,0.65,0.49,0.84
				c0.26,0.19,0.68,0.49,0.68,0.78c0,0.29,0.03,0.55,0.26,0.55c0.23,0,0.32,0.42,0.58,0.52c0.26,0.1,0.68-0.19,0.75-0.1
				c0.06,0.1-0.03,0.81,0.1,0.81c0.08,0,0.37,0.06,0.59,0.27c-0.08-0.34,0.09-0.8,0.52-0.68c0.43,0.13,0.09,0.52,0.48,0.69
				c0.39,0.17,0.61,0.04,0.61-0.26c0-0.3,0.52-0.91,0.65-1.04c0.13-0.13,0.09-0.95,0.35-1.17c0.26-0.22,1.04-0.04,1.34,0
				c0.3,0.04,0.52-0.61,0.87-0.3c0.35,0.3,0.91,0.04,1.17,0.35c0.26,0.3,0.39,1.04,0.82,0.78c0.43-0.26,0.95-0.35,1.21-0.35
				c0.26,0,0.87,0.52,1.13,0.6c0.26,0.09,1.12,0.17,1.43,0c0.3-0.17,0.69-0.74,1.08-0.69c0.39,0.04,1.6-0.09,1.86,0.04
				c0.26,0.13,0.82,0.43,1.21,0.3c0.39-0.13,0.73-0.86,1.04-0.95c0.1-0.03,0.16-0.02,0.21,0c0.01-0.17,0.01-0.33,0.01-0.47
				c0-0.78,0.17-0.48,0.3-1.25c0.13-0.78,2.16-2.81,2.34-2.94c0.17-0.13,0.17-3.2,0.17-3.63c0-0.43,0.09-0.95,0.26-1.56
				c0.17-0.61-0.3-0.56-0.26-1.13C270.12,135.95,269.47,135.51,269.47,135.16z"/>
			<path class="st0" d="M281.86,138.58l-11.17-6.01l-1.31,0.71c0.04,0.71,0.09,1.67,0.09,1.88c0,0.35,0.65,0.78,0.6,1.34
				c-0.04,0.56,0.43,0.52,0.26,1.13c-0.17,0.61-0.26,1.13-0.26,1.56c0,0.43,0,3.5-0.17,3.63c-0.17,0.13-2.21,2.16-2.34,2.94
				c-0.13,0.78-0.3,0.48-0.3,1.25c0,0.14,0,0.3-0.01,0.47c0.11,0.04,0.16,0.16,0.31,0.31c0.22,0.22,0.22,0.61,0.65,0.61
				c0.43,0,0.95,1,0.91,1.38c-0.04,0.39,0.22,0.61,0.17,1.3c-0.04,0.69,0.39,1.69,0.61,1.82c0.22,0.13-0.09,0.26-0.61,0.22
				c-0.52-0.04-1.25-0.04-1.25,0.17c0,0.22-0.22,0.52,0.35,1.04c0.56,0.52,1.04,0.69,1.21,1.25c0.17,0.56,0.39,1.08,0.56,1.21
				c0.17,0.13,0.52,0.09,0.82-0.35c0.3-0.43,0.65,0.39,0.95,0c0.3-0.39,1.34-0.35,1.6-0.48c0.26-0.13,1.04-0.48,1.3-0.69
				c0.26-0.22-0.3-0.56,0.09-0.78c0.39-0.22,1.6,0.17,2.08-0.43c0.48-0.61,1.21-1.26,1.43-1.47c0.22-0.22,0-0.39,0.3-0.69
				c0.3-0.3,0.91-0.65,1.21-0.48c0.07,0.04,0.16,0.05,0.25,0.06c-0.03-0.03-0.05-0.06-0.05-0.06l0.18-0.63c0,0-0.72-0.36-0.72-0.9
				c0-0.54,0.18-1.08-0.27-1.08c-0.45,0-0.54-0.45-0.18-0.81c0.36-0.36-0.54-1.08,0.09-1.08c0.63,0,0.18-0.99,0.63-1.08
				c0.45-0.09,0.54-0.45,0.54-0.9c0-0.45,0.99-0.72,1.26-0.81c0.27-0.09,0.18-1.53,0.18-1.53V138.58z"/>
		<g className="mouseOver egyptCount">
			<path class="lightBlue" d="M297.73,122.58l-1.05-2.58c-0.08,0.12-0.13,0.2-0.16,0.23c-0.17,0.17-0.89-0.26-1.56-0.09
				c-0.67,0.17-1.19-0.06-1.84-0.39c-0.65-0.32-2.77,0.09-3.05,0.5c-0.28,0.41-1,0.41-1.64,0.15c-0.65-0.26-1.49-0.65-2.68-0.76
				c-1.11-0.1-2.01-0.26-2.21-0.53c-0.05,0.13-0.09,0.25-0.13,0.36c-0.36,0.99,0.27,1.26-0.18,1.8c-0.45,0.54,0,1.17,0,2.17
				c0,0.69,0,7.26,0,11.27h8.93c0,0,0.26-0.17,0.52-0.35c0.26-0.17,0.69,0.26,0.69,0.26h1.9c0,0,0.35,0.43,0.78,0.43
				c0.43,0,0.17-0.69,0.87-0.69c0.69,0,0.52-1.04,0.95-1.04c0.43,0,0.35,0.09,0.78-0.52c0.01-0.02,0.03-0.04,0.05-0.06
				c-0.03-0.19-0.04-0.38,0-0.59c0.11-0.69-0.37-0.65-0.67-1.62c-0.3-0.97-1.52-2.42-1.56-2.99c-0.04-0.56-0.71-0.89-0.69-1.49
				c0.02-0.61-0.32-0.67-1-1.62c-0.67-0.95-0.87-2.62-0.67-2.68c0.19-0.06,0.76,1.41,0.97,1.88c0.22,0.48,0.95,1.93,1.47,1.93
				c0.52,0,0.71-2.19,0.89-2.08c0.02,0.01,0.03,0.03,0.04,0.06l0.06-0.4L297.73,122.58z"/>
				<g className="hoverEf egypt">
			<g>
				<path d="M281.51,125.78h-1.65v1.29h1.85v0.66h-2.65v-4.38h2.55V124h-1.75v1.13h1.65V125.78z"/>
				<path d="M285.27,124.55c-0.02,0.21-0.03,0.48-0.03,0.91v1.79c0,0.66-0.13,1.14-0.47,1.43c-0.33,0.29-0.79,0.38-1.22,0.38
					c-0.4,0-0.83-0.09-1.1-0.25l0.17-0.61c0.2,0.12,0.54,0.24,0.92,0.24c0.52,0,0.91-0.28,0.91-0.96v-0.27h-0.01
					c-0.18,0.28-0.5,0.46-0.92,0.46c-0.78,0-1.34-0.65-1.34-1.54c0-1.04,0.68-1.67,1.43-1.67c0.49,0,0.77,0.23,0.92,0.49h0.01
					l0.03-0.42H285.27z M284.44,125.82c0-0.08-0.01-0.15-0.03-0.22c-0.08-0.29-0.31-0.51-0.65-0.51c-0.44,0-0.78,0.39-0.78,1.03
					c0,0.54,0.27,0.97,0.77,0.97c0.3,0,0.56-0.19,0.65-0.49c0.03-0.08,0.04-0.19,0.04-0.28V125.82z"/>
				<path d="M286.6,124.55l0.57,1.7c0.06,0.2,0.13,0.44,0.18,0.61h0.02c0.05-0.18,0.11-0.42,0.17-0.62l0.5-1.69h0.85l-0.79,2.16
					c-0.43,1.18-0.73,1.71-1.1,2.03c-0.32,0.27-0.64,0.38-0.84,0.4l-0.18-0.67c0.13-0.03,0.3-0.11,0.46-0.22
					c0.15-0.1,0.33-0.29,0.44-0.5c0.03-0.05,0.05-0.1,0.05-0.14c0-0.04-0.01-0.08-0.04-0.16l-1.16-2.91H286.6z"/>
				<path d="M289.35,125.6c0-0.41-0.01-0.75-0.03-1.05h0.7l0.04,0.49h0.01c0.23-0.36,0.6-0.56,1.08-0.56c0.72,0,1.33,0.62,1.33,1.61
					c0,1.15-0.72,1.7-1.44,1.7c-0.4,0-0.73-0.17-0.89-0.42h-0.01v1.64h-0.8V125.6z M290.15,126.39c0,0.08,0.01,0.15,0.02,0.22
					c0.08,0.33,0.36,0.56,0.7,0.56c0.51,0,0.81-0.42,0.81-1.04c0-0.56-0.27-1.02-0.79-1.02c-0.33,0-0.63,0.24-0.71,0.59
					c-0.02,0.06-0.03,0.13-0.03,0.2V126.39z"/>
				<path d="M294.07,123.65v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L294.07,123.65z"/>
			</g>
		</g>
		</g>
			<path class="st0" d="M264.48,173.18c0.72,0,0.45-0.45,0.18-1.18c-0.27-0.72,0.45-0.63,0.91-0.63c0.45,0,0.27-0.81,0.63-0.81
				c0.36,0,0.45,1,0.81,0.9c0.36-0.09,0.63,0.27,1.09,0.18c0.45-0.09,0.45-1,0.45-1.72c0-0.72,0.09-1.27-0.36-1.54
				c-0.45-0.27-0.81-0.9-0.27-1.18c0.54-0.27,0.63-0.81,0.18-1.18c-0.45-0.36-1.45,0.36-1.45,0c0-0.2,0.08-0.9,0.15-1.51
				c-1.05-0.03-2.17-0.09-2.17-0.09l-0.27,0.08c0.06,1.06-0.06,1.88-0.06,1.88s-1.63,0.09-1.99,0.09c-0.08,0-0.33,0.03-0.68,0.09
				c-0.07,0.12-0.17,0.23-0.25,0.31c-0.19,0.19,0.45,0.45,0.56,0.73c0.11,0.28-0.82,0.19-0.8,0.61c0.02,0.41-0.32,0.74-0.58,0.93
				c-0.26,0.19,0.69,1.02,0.71,1.3c0.02,0.28,0.24,0.54,1.06,1.34c0.78,0.76,0.41,0.96,1.45,1.8
				C263.92,173.38,264.14,173.18,264.48,173.18z"/>
			<path class="st0" d="M264.3,166.39c0,0,0.12-0.82,0.06-1.88l-0.36,0.1h-2.03c0.01,0.01,0.01,0.03,0.02,0.04
				c0.22,0.32,0.11,0.8-0.35,0.95c-0.45,0.15-0.43,0.56-0.07,0.58c0.23,0.01,0.17,0.2,0.05,0.38c0.35-0.05,0.6-0.09,0.68-0.09
				C262.67,166.48,264.3,166.39,264.3,166.39z"/>
			<path class="st0" d="M266.56,174.53c0.27,0.09,0.45,0.54,0.99,0.09c0.54-0.45,1.18-0.36,1.18,0.09c0,0.45,0.18,0.36,0.63-0.09
				c0.45-0.45,0.9,0.09,0.9-0.82c0-0.9,0.54-0.09,0.54-1.08c0-1,0.09-1.99,0.09-2.44c0-0.45,1.27-0.9,1.27-1.63
				c0-0.72,1.09-2.17,1.09-3.08s0.27-1.09,0.54-1.54c0.19-0.31,0.33-0.93,0.22-1.52v0.01l-1.26-0.18l-0.63,0.18l-0.81,0.18
				c0,0,0.09,0.63-0.27,1.08c-0.36,0.45-0.45,1.44-0.45,1.44s-1.62-0.81-2.07-0.72c-0.19,0.04-0.93,0.03-1.71,0
				c-0.07,0.6-0.15,1.31-0.15,1.51c0,0.36,1-0.36,1.45,0c0.45,0.36,0.36,0.9-0.18,1.18c-0.54,0.27-0.18,0.9,0.27,1.18
				c0.45,0.27,0.36,0.81,0.36,1.54c0,0.72,0,1.63-0.45,1.72c-0.45,0.09-0.72-0.27-1.09-0.18c-0.36,0.09-0.45-0.9-0.81-0.9
				c-0.36,0-0.18,0.81-0.63,0.81c-0.45,0-1.18-0.09-0.91,0.63c0.27,0.72,0.54,1.18-0.18,1.18c-0.34,0-0.56,0.2-0.7,0.42
				c0.05,0.04,0.1,0.08,0.15,0.12c0.61,0.47,0.99,1.01,1.25,1.54C265.79,174.85,266.41,174.49,266.56,174.53z"/>
			<path class="st0" d="M297.86,133.35c-0.43,0-0.26,1.04-0.95,1.04c-0.69,0-0.43,0.69-0.87,0.69c-0.43,0-0.78-0.43-0.78-0.43h-1.9
				c0,0-0.43-0.43-0.69-0.26c-0.26,0.17-0.52,0.35-0.52,0.35h-8.93c0,1.8,0,3.08,0,3.08h-1.35v4.78c0,0,0.09,1.44-0.18,1.53
				c-0.27,0.09-1.26,0.36-1.26,0.81c0,0.45-0.09,0.81-0.54,0.9c-0.45,0.09,0,1.08-0.63,1.08c-0.63,0,0.27,0.72-0.09,1.08
				c-0.36,0.36-0.27,0.81,0.18,0.81c0.45,0,0.27,0.54,0.27,1.08c0,0.54,0.72,0.9,0.72,0.9l-0.18,0.63c0,0,0.99,1.17,1.08,1.44
				c0.09,0.27,0.63,0.99,0.09,1.26c-0.54,0.27-0.18,0.54-0.18,0.54l0.63,0.09l0.18,0.45c0,0,0.72,0,1.17,0.54
				c0.45,0.54,0.63,0.63,0.63,0.9c0,0.27,0.36,0.36,0.9,0.81c0.54,0.45,0.54,0.72,0.54,0.99c0,0.27,0.99,0.72,0.99,0.72l0.34,0.84
				c0.17,0.09,0.31,0.2,0.38,0.38c0.18,0.45,0.63,0.72,0.63,0.72s0.54,0.36,0.9,0c0.36-0.36,0.81,0.09,0.81,0.09
				s0.45-0.36,0.72-0.18c0.18,0.12,0.99,0.79,1.69,1.43c0.1-0.04,0.78-0.29,1.08-0.32c0.32-0.03,0.78,0.52,0.91,0.32
				s0.81-0.49,1.07-0.36c0.26,0.13,0.88,0.16,0.91-0.06c0.02-0.15,0.3-0.31,0.49-0.47c0.09-0.08,0.17-0.16,0.19-0.24
				c0.06-0.26,0.84-0.71,1.17-0.78c0.33-0.06,0.71-0.1,0.88,0.1c0.08,0.09,0.22,0.16,0.36,0.22c0.11-0.83,0.29-1.29,0.02-1.29
				c-0.36,0-1.09-0.36-1.36-1.09c-0.27-0.72-1.09-1.18-1.27-1.63c-0.18-0.45-1.18-0.36-1.36-0.72c-0.18-0.36,0.27-0.36,0.27-0.81
				c0-0.45,1.09-0.18,1.18-0.27c0.09-0.09,0.18-1.08,0.18-1.63c0-0.54,0.72-1.08,0.45-1.36c-0.27-0.27,0.27-0.36,0.63-0.63
				c0.36-0.27,0.09-1.09,0.45-1.45c0.36-0.36,0.63-1.08,0.9-1.08c0.27,0,0.63-0.36,0.63-0.72c0-0.36,0.63-1.08,0.54-1.72
				c-0.09-0.63-0.36-1.45,0.09-1.99c0.45-0.54,0.63-0.99,0.54-1.45c-0.09-0.45,0.54-0.54,0.81-0.81c0.19-0.19,0.88-0.48,1.4-1.3
				c-0.33-0.4-0.68-0.74-0.91-0.84c-0.52-0.24-0.87-1.15-0.87-2.29c0-1.15-0.28-2.06-0.32-2.42c-0.04-0.37-0.61-1.15-1.28-1.52
				c-0.47-0.26-0.74-0.65-0.82-1.1c-0.01,0.02-0.04,0.04-0.05,0.06C298.2,133.44,298.29,133.35,297.86,133.35z"/>
			<path class="st0" d="M308.49,149.31c-0.08,0.14-0.43,0.7-0.71,1.02c-0.32,0.36-0.14,1.05-0.05,1.23
				c0.09,0.18,0.55,0.14,0.82-0.05c0.21-0.14,0.37-0.06,0.69,0.24c0.16-0.24,0.4-0.51,0.6-0.72c-0.36-0.21-0.7-0.25-0.78-0.33
				c-0.15-0.15,0.43-0.58,0.84-0.67c0.41-0.09,0.22-0.56-0.13-0.87c-0.06-0.05-0.12-0.11-0.19-0.18c-0.19,0.11-0.35,0.22-0.4,0.28
				C309.08,149.41,308.84,149.35,308.49,149.31z"/>
			<path class="st0" d="M301.48,142.24c-0.27,0.27-0.91,0.36-0.81,0.81c0.09,0.45-0.09,0.9-0.54,1.45
				c-0.45,0.54-0.18,1.35-0.09,1.98c0.55-0.04,1.11-0.05,1.22,0.06c0.23,0.23,0.32,0,0.46-0.46c0.14-0.46,0.41-0.41,0.68-0.18
				c0.27,0.23,0.45,0.59,0.91,0.36c0.46-0.23,0.41-0.09,0.64,0c0.23,0.09,1.14-0.05,1.46,0c0.32,0.05,3.1,3.01,3.1,3.01
				s-0.01,0.01-0.02,0.03c0.35,0.04,0.59,0.09,0.7-0.05c0.05-0.06,0.2-0.16,0.4-0.28c-0.36-0.33-0.87-0.88-1.56-1.75
				c-0.82-1.04-1.8-1.6-2.32-1.6c-0.52,0-0.61-0.89-0.93-0.93c-0.32-0.04-1.06-1.32-1.08-2.14c-0.01-0.46-0.4-1.11-0.82-1.63
				C302.36,141.76,301.67,142.05,301.48,142.24z"/>
			<path class="st0" d="M296.14,156.89c0.18,0.45,0.99,0.91,1.27,1.63c0.27,0.72,1,1.09,1.36,1.09c0.27,0,0.09,0.45-0.02,1.29
				c0.17,0.07,0.36,0.13,0.48,0.2c0.23,0.13,1.17,0,1.49,0.29c0.32,0.29,1.36,0.97,1.69,0.97c0.33,0,1.49,0.16,1.69,0.29
				c0.19,0.13,0.45-0.49,0.97-0.78c0.52-0.29,1.17-0.49,1.27-0.26c0.1,0.23,0.45,0.42,0.71,0.29c0.11-0.05,0.4-0.13,0.73-0.21
				c0.48-0.11,1.03-0.2,1.19-0.18c0.26,0.03,0.97-1.01,1.91-1.01c0.94,0,1.2,0.16,1.62-0.29c0.42-0.45,3.6-3.67,3.89-3.89
				c0.29-0.23-0.19-0.32-0.52-0.32c-0.32,0-0.97-0.03-2.01-0.45c-1.04-0.42-2.66-0.97-2.95-1.04c-0.29-0.07-0.71-0.75-0.97-0.88
				c-0.26-0.13-0.78-1.04-0.91-1.33c-0.04-0.1,0.06-0.31,0.22-0.54c-0.32-0.3-0.48-0.38-0.69-0.24c-0.27,0.18-0.73,0.23-0.82,0.05
				c-0.09-0.18-0.27-0.87,0.05-1.23c0.32-0.37,0.73-1.05,0.73-1.05s-2.78-2.96-3.1-3.01c-0.32-0.05-1.23,0.09-1.46,0
				c-0.23-0.09-0.18-0.23-0.64,0c-0.46,0.23-0.64-0.14-0.91-0.36c-0.27-0.23-0.55-0.27-0.68,0.18c-0.14,0.46-0.23,0.68-0.46,0.46
				c-0.11-0.11-0.68-0.1-1.22-0.06c0,0,0,0.01,0,0.01c0.09,0.63-0.54,1.36-0.54,1.72c0,0.36-0.36,0.72-0.63,0.72
				c-0.27,0-0.54,0.72-0.9,1.08c-0.36,0.36-0.09,1.18-0.45,1.45c-0.36,0.27-0.9,0.36-0.63,0.63c0.27,0.27-0.45,0.81-0.45,1.36
				c0,0.54-0.09,1.54-0.18,1.63c-0.09,0.09-1.18-0.18-1.18,0.27c0,0.45-0.45,0.45-0.27,0.81
				C294.96,156.53,295.96,156.44,296.14,156.89z"/>
			<path class="st0" d="M299.88,115.47c0.28-0.24,0.44-1.19-0.71-1.14c-0.01,0.06-0.02,0.13-0.02,0.19c0,0.44-0.7,1.36-1.09,2.19
				l0.69,0.29C299.12,116.37,299.66,115.66,299.88,115.47z"/>
			<path class="st0" d="M299.88,115.47c-0.22,0.19-0.76,0.9-1.12,1.54l0.08,0.03l0.12,0.52l1.33,0.69c0,0,0.98-0.69,1.39-0.75
				c0.4-0.06,5.03-2.54,5.03-2.54s0.41-0.64,0.35-0.87c-0.06-0.23-0.12-0.87,0.12-1.16c0.23-0.29-0.29-0.75-0.12-1.21
				s0.92-0.69,0.92-0.69l0.87-0.64l-0.58-0.17c0,0-0.75,0.29-1.62,0.23c-0.87-0.06-1.04,0.58-1.96,0.58c-0.92,0-1.44,0.23-1.68-0.06
				c-0.23-0.29-0.29-0.12-0.92,0.17c-0.64,0.29-1.04,0.23-1.33,0c-0.29-0.23-0.52,0.06-0.58,0.35c-0.05,0.27,0.03,0.89-0.87,1.02
				c-0.06,0.27-0.17,0.54-0.01,0.74c0.23,0.29-0.05,0.62-0.11,1.08C300.32,114.28,300.15,115.23,299.88,115.47z"/>
		
		<g className="mouseOver indiaCount">
				<path class="brownBlue" d="M376.4,132.7c-0.3-0.6-0.4-1.8-0.4-2.2s0.6-0.1,0.8-0.6c0.2-0.5-1.5-0.5-1-1.2s0.5,0,1.2,0s1,0.4,1,0.8
	c0,0.4,0.7,0.7,0.7,0.7s1.1-0.1,1.7-0.1s1,0,1,0.4s-0.6,1.5-1.1,1.5s-0.5,1.5-0.1,1.6s0.8-1.1,1-1.1c0.2,0,0.3,1.4,0.6,2.8
	c0.1-0.1,0.1-0.1,0.2-0.1c0.4,0,0.2-1.2,0.4-1.6c0.2-0.4,0.1-1.4,0.3-1.4s1.2,0.4,1.2,0.1c-0.1-0.3,0.6-1.3,0.5-1.8
	c-0.1-0.5,0.4-1.2,0.4-1.8c0-0.6,0.6-0.8,1-1.2c0.4-0.3,1.1-0.8,1.2-0.5c0.1,0.3,1,0.5,0.6,0c-0.4-0.5-0.4-0.9-0.1-1
	c0.2-0.1,0.4-0.7,0.4-0.7s-0.8-0.1-0.9-0.5c-0.1-0.3-0.6-0.7-0.6-1s-0.4-0.2-0.8,0.1s-1-0.3-1.7,0.1c-0.8,0.4-0.9,0.9-1.3,0.8
	c-0.4-0.1-0.3,0.3-0.6,0.6c-0.3,0.3-0.5,0.6-0.7,0.6s-0.8,0.3-0.8,0.3s0.4,0.9,0.2,1.3c-0.2,0.4-0.8,0-1.4,0.2
	c-0.6,0.2-0.9-0.2-1.4-0.1c-0.5,0.1-0.8-0.3-1-0.2s-0.6-0.5-0.5-1c0.1-0.5-0.2-1.1-0.5-0.8c-0.2,0.3-0.6,0.2-0.6,0.7
	c0,0.5,0.3,1.2-0.1,1.5c-0.4,0.4-2.2,0.5-2.5,0.2c-0.4-0.3-1.9-0.5-1.9-0.8s-0.5-0.8-0.8-0.8s-1.2,0.4-1.4,0.2s-0.8-0.6-1.2-0.7
	c-0.4-0.1-1-0.3-1.1-0.6s-1-0.3-1.2-0.6c-0.2-0.2-0.8-0.3-0.8-0.6s0.2-0.8,0.2-1.1s0.7-0.7,0.7-0.9c0-0.2-0.6-0.6-0.6-0.6
	s-0.4-0.8-0.9-0.8s-0.8-0.8-1.2-0.9c-0.4-0.1-0.3-0.9-0.5-1.2c-0.2-0.3-0.1-1,0.4-0.6c0.4,0.3,1.2,0,0.9-0.5s-0.8-1-0.8-1.3
	c0-0.3,0.4-1.2-0.1-1.4c-0.4-0.2-0.8-0.6-0.9-1c-0.1-0.4-0.6-0.7-0.6-0.7l-0.6,0.1c0,0-0.4,0.9-0.6,1c-0.2,0.1-1,0.3-1.4,0.6
	s-1.6-0.4-2.2-0.4s-1,0.6-0.8,0.8s0.2,1.4,0.2,1.4s0.4,1.5,0.9,1.4c0.5-0.1,1.2,0.5,0.7,0.6c-0.5,0.1-0.7,0.6-0.8,1.1s0.1,1-0.3,1.1
	s-0.7,0.2-0.7,0.8s-0.8,0.5-0.9,0.7s-0.4,1.4-0.6,1.6c-0.2,0.2-1.2,0.2-1.2,0.8c-0.1,0.5-0.5,1.2-0.8,1.1c-0.3-0.1-0.8-0.3-1,0.1
	s-0.9-0.3-1.1-0.1c-0.2,0.2-1.1,0.9-1.1,1.4c0,0.5,1,0.5,0.9,1c-0.1,0.6,0.4,1.2,0.6,1.5s0.8,1,0.5,1.3s-0.6,0.4-1.1,0.3
	c-0.5-0.1-0.5,0.2-1.4,0.1c-0.9-0.2-1,0.5-1.3,0.9c0,0,0,0.1-0.1,0.1c0.4,0.3,0.6,1,1.4,1.2c0.8,0.3,1.6-0.5,1.6,0.1
	c0,0.6-2,0.5-2,0.7s2,2.7,3.1,2.5c1.1-0.2,1.8-1.3,1.5-1.5s0.2-0.9,0.5-0.8s0,1.2,0.4,1.5c0.3,0.3,0.2,0.7,0,1.3s0.2,1.8,0.3,2.5
	c0.1,0.7,0.5,1.6,0.7,2.6c0.1,1,0.8,2.8,1.4,3.9c0.6,1.1,1,3.3,1.4,3.8c0.4,0.5,1.4,2.1,1.4,3.1s1.1,2.3,1.4,2.6
	c0.4,0.3,1.2-0.3,1.4-0.9c0.2-0.6,1.3-0.8,1.4-1.4c0.1-0.5,0.2-0.9,0.6-1c0.4-0.1,0.4-0.5,0.4-1s0-1.4,0.4-1.9
	c0.4-0.5,0.5-1.8,0.2-2.4c-0.2-0.5-0.1-1.3,0.1-1.8c0.2-0.5,0.1-1.2,0.5-1.2c0.4,0,1-0.4,1.5-1c0.4-0.6,1.8-1.4,2.2-1.8
	s1.9-1.7,2.4-2.4s1.7-0.9,2.2-1.5c0.6-0.6,0.4-0.9,0.5-1.5c0.1-0.6,1.9-0.9,2.6-0.9c0.1,0,0.3,0,0.4,0
	C376.9,134.4,376.6,133.2,376.4,132.7z"/>
		
		</g>
			<path class="st0" d="M419.53,91.61c-0.47-0.56-1.22-0.28-1.41,0.19c-0.19,0.47-0.57-0.19-1.41-0.19c-0.85,0-0.94,0.47-1.51-0.09
				c-0.56-0.57,0.19-0.75,0.19-0.75l0.09-0.75l1.17-2.34c-0.33-0.14-0.82-0.19-1.06-0.1c-0.35,0.13-1.17,0.04-1.25-0.26
				c-0.09-0.3-1.47-0.35-1.82-0.09c-0.35,0.26-1.12,0.48-1.17,0.78c-0.04,0.3-0.86,0.22-1.17,0.43c-0.3,0.22-1.86,0.3-1.9,0.48
				c-0.04,0.17-1.69,0.22-1.95,0c-0.26-0.22-1.69,0-1.86-0.35c-0.17-0.35-0.78-0.52-0.78-0.82c0-0.3-1.08-0.3-1.21-0.56
				c-0.13-0.26-1-0.39-1.12-0.3c-0.13,0.09-1.08-0.26-1.34-0.3c-0.26-0.04-1.08,0.17-1.34,0.17c-0.26,0-0.74,0.48-1.04,0.52
				c-0.3,0.04-1.13-0.52-1.3-0.39c-0.17,0.13-0.69-0.43-0.91-0.69c-0.22-0.26,0-1-0.17-1.26c-0.17-0.26-1.12-0.26-1.43-0.43
				c-0.3-0.17-0.78-0.43-1.08-0.43c-0.3,0-0.78-0.09-0.91-0.22c-0.13-0.13-0.78-0.35-1.08-0.43c-0.3-0.09-0.35,0.3-0.52,0.48
				c-0.17,0.17-0.69,0.22-0.78,0.52c-0.09,0.3-0.69,0.39-0.61,0.74c0.09,0.35,0.3,1.04,0.52,1.21c0.22,0.17,0.09,0.69-0.04,0.87
				c-0.13,0.17-1.04,0.3-1.21,0.61c-0.17,0.3-0.6-0.04-1.04-0.3c-0.43-0.26-0.95-0.09-1.17,0.09c-0.22,0.17-1-0.43-1.21-0.48
				c-0.22-0.04-0.26-0.86-0.48-0.82c-0.22,0.04-1.73-0.26-2.03-0.35c-0.3-0.09-0.95-0.04-1.04,0.17c-0.09,0.22-0.87,0.17-1,0.43
				c-0.13,0.26-0.74,0.39-0.95,0.65c-0.22,0.26-0.95,0.26-1.13,0.65c-0.17,0.39-1.08,0.56-1.34,0.61c-0.26,0.04-0.95,0.17-1,0.39
				c-0.02,0.09-0.13,0.11-0.26,0.09c0.02,0.06,0.04,0.11,0.05,0.17c0.18,0.99,0.6,0.96,1.05,1.35c0.45,0.39,0.84,0.69,1.32,0.72
				c0.48,0.03,1.2,0.6,1.26,1.02c0.06,0.42,0.51,0.9,0.78,1.11c0.27,0.21-0.09,0.93-0.09,1.47c0,0.54-0.66,0.66-0.33,1.2
				c0.33,0.54,2.64,0.81,3.29,0.81c0.66,0,0.9,0.63,1.1,0.66c0.42,0.07,0.47,0.57,0.94,0.66c0.47,0.09,0.94,0.38,1.13,1.23
				c0.19,0.85,0.75,1.13,0.85,1.51c0.09,0.38,1.13,0.09,1.89,0.19c0.75,0.09,2.92,0,3.49,0.09c0.57,0.09,1.88,0,2.64,0.57
				c0.75,0.57,1.13,0.09,1.6,0.38c0.47,0.28,0.85,0.47,1.51,0.38c0.66-0.09,0.66,0.47,1.13,0.57c0.47,0.09,1.32-0.75,2.55-1.32
				c1.22-0.57,2.64-0.19,3.3-0.09c0.66,0.09,1.88-0.28,2.64-1.13c0.75-0.85,1.7-0.66,1.79-1.41c0.09-0.75-0.75-1.04-0.38-1.7
				c0.38-0.66,1.04-0.57,1.42-0.38c0.38,0.19,1.41,0.47,2.36-0.19c0.94-0.66,1.32-0.57,2.17-0.66c0.85-0.09,0.75-0.66,1.32-1.23
				c0.57-0.57,1.23-0.75,1.79-0.75c0.57,0,1.32-0.57,1.88-0.38c0.57,0.19,1.41,0.28,1.51-0.19C421.51,93.12,420,92.18,419.53,91.61z
				"/>
			<path class="st0" d="M298.24,118.02c-0.22,0.09-0.31,0.93-0.31,1.06c0,0.09,0.14,0.52,0.54,0.93c0.08-0.64,0.1-1.48,0.16-1.71
				c0.01-0.06,0.03-0.16,0.05-0.28C298.47,117.99,298.3,117.99,298.24,118.02z"/>
		<g className="mouseOver israelCount">
			<path class="st0" d="M297.67,122.75c0.05,0.03,0.1,0.05,0.15,0.08c0.12-0.88,0.31-1.91,0.47-2.12c0.09-0.12,0.14-0.39,0.19-0.71
				c-0.4-0.41-0.54-0.83-0.54-0.93c0-0.13,0.09-0.97,0.31-1.06c0.06-0.02,0.23-0.02,0.44-0.01c0.04-0.26,0.08-0.63,0.12-0.97
				l-0.74-0.31c-0.11,0.24-0.2,0.47-0.23,0.69c-0.12,0.78-0.82,2.07-1.15,2.6l1.05,2.58L297.67,122.75z"/>
		</g>
			<path class="st0" d="M303.54,116.62c-0.92,0.47-1.72,0.86-1.87,0.88c-0.41,0.06-1.39,0.75-1.39,0.75l-1.33-0.69l-0.12-0.52
				l-0.03-0.01c-0.06,0.5-0.13,1.07-0.17,1.26c-0.09,0.35-0.09,2.08-0.35,2.42c-0.16,0.21-0.35,1.24-0.47,2.12
				c0.48,0.26,1.03,0.53,1.3,0.56c0.52,0.06,1.16-0.52,1.16-0.87c0-0.35,0.46-0.41,0.92-0.41c0.46,0,0.52-0.52,0.87-0.69
				c0.35-0.17-0.75-1.15-1.1-1.27c-0.35-0.12,0.29-0.87,1.44-0.92c1.16-0.06,0.87-0.46,1.5-0.46c0.03,0,0.05,0,0.08,0
				c-0.11-0.51-0.25-1.12-0.34-1.44C303.6,117.15,303.56,116.89,303.54,116.62z"/>
			<path class="st0" d="M363.71,101.24c-0.56-0.65-0.74-0.22-1.38-0.39c-0.65-0.17-3.25-0.43-3.77-0.22
				c-0.52,0.22-1.6-0.39-1.95-0.56c-0.35-0.17-1.47,0-1.51,0.61c-0.04,0.61-0.52,0.6-1.3,0.22c-0.78-0.39-1.95-0.39-1.99,0.04
				c-0.04,0.43-0.61,0.87-0.91,0.99c-0.12,0.05-0.28,0.4-0.44,0.82c0.24,0.09,0.38,0.3,0.54,0.49c0.29,0.35,1.04,0.29,1.16-0.06
				c0.12-0.35,1.33,0.35,2.02,0.92c0.69,0.58-0.35,0.17-0.64,0.75c-0.29,0.58-1.15,0-1.39,0.23c-0.23,0.23-1.73,0.35-2.14,0.29
				c-0.4-0.06-1.1,0.58-1.04,0.87c0.06,0.29,1.56-0.17,2.08,0.17c0.52,0.35,1.04-0.35,1.33,0.06c0.29,0.41,0.92,0.12,1.21,0.23
				c0.29,0.12,1.89-0.11,1.9-0.12c0.18-0.21,0.24-0.49,0.24-0.72c0-0.39,0.32-0.29,0.65-0.39c0.32-0.1,0.55-0.52,0.81-0.52
				c0.26,0,0.62-0.39,0.88-0.29c0.26,0.1,0.58,0.55,0.91,0.29c0.32-0.26,0.42-0.03,0.88-0.58c0.45-0.55,0.75-0.45,1.33-0.39
				c0.68,0.08,0.97-0.26,1.17-0.65c0.19-0.39,0.78-0.39,1.14-0.65c0.36-0.26,1.07-0.55,1.3-0.65c0.11-0.05,0.13-0.16,0.13-0.31
				C364.41,101.3,364.18,101.79,363.71,101.24z"/>
			<path class="st0" d="M309.11,102.63c0.48-0.22,0.65,0.3,1.04,0.82c0.03,0.04,0.04,0.06,0.06,0.1c0.53-0.08,0.94-0.24,1.48-0.3
				c0.39-0.05,0.9,0.04,1.3,0.13c-0.07-0.12-0.12-0.24-0.07-0.32c0.13-0.26,0.78,0.09,1,0.3c0.22,0.22,0.91,0.3,1.12,0.09
				c0.22-0.22-0.35-0.43-0.48-0.65c-0.07-0.11,0.11-0.33,0.29-0.53c-0.38-0.21-0.76-0.51-0.9-0.46c-0.19,0.06-0.19-0.91-0.45-0.91
				c-0.26,0-0.26-0.19-0.52-0.19c-0.26,0-1.23,0-1.49,0.19c-0.26,0.19-0.58-0.45-0.91-0.45c-0.32,0-0.71-0.32-0.91-0.58
				c-0.19-0.26-1.82,0.13-2.27-0.19c-0.45-0.32-1.3-0.58-1.75-0.52c-0.12,0.02-0.32,0.12-0.55,0.26c0.85,0.5,2.09,0.37,2.26,1.26
				c0.19,1.04,0.78,0.63,0.56,1.51c-0.04,0.17-0.17,0.34-0.34,0.5C308.25,102.72,308.89,102.73,309.11,102.63z"/>
		<g className="mouseOver turkeyCount">
			<path class="lightBlue" d="M311.6,109.24c-0.23-0.06,0.12-1.33,0-1.56c-0.12-0.23-0.41-1.21-0.06-1.21c0.31,0-0.03-0.71,0.38-0.75
				c-0.09-0.25-0.18-0.47-0.27-0.54c-0.22-0.17-0.87,0.17-1.12-0.48c-0.26-0.65,0-0.74-0.39-1.26c-0.39-0.52-0.56-1.04-1.04-0.82
				c-0.22,0.1-0.85,0.1-1.53,0.05c-0.7,0.64-2.24,1.08-2.71,0.91c-0.58-0.22-1.71,0.07-2.55,0.07c-0.84,0-1.08-0.61-1.73-0.56
				c-0.65,0.04-0.65-0.58-1.3-0.58c-0.65,0-0.15-0.32-2.18-0.58c-2.03-0.26-3.79,0.8-4.44,1.36c-0.65,0.56-2.99,0.02-3.2,0.17
				c-0.22,0.15,0.04,0.82-0.74,1.13c-0.78,0.3-2.49,0-3.07,0.26c-0.58,0.26-0.8,1.12-0.3,1.08c0.5-0.04,0.76,0.19,0.61,0.41
				c-0.15,0.22,0.41,0.82,0.13,1.08c-0.28,0.26-0.3,0.76,0.04,1.02c0.35,0.26,0.41,0.74,0,0.8c-0.41,0.07,0.71,0.54,0.73,0.76
				c0.02,0.22,0.95,0.32,0.95,0.58c0,0.26,0.28,0.35,0.74,0.22c0.45-0.13,0.63,0.5,0.91,0.93c0.28,0.43,1.88,0.13,1.88-0.35
				c0-0.48,0.48-0.61,1.19-0.54c0.71,0.06,1.65,1.04,1.99,1.17c0.35,0.13,1.06-0.26,1.51-0.26c0.45,0,1.1-0.71,1.36-0.87
				c0.26-0.15,0.71,0.5,1.13,0.28c0.41-0.22,0.86-0.45,1.12-0.3c0.26,0.15-0.63,0.82-0.39,1.17c0.1,0.14,0.07,0.31,0.03,0.5
				c0.9-0.12,0.81-0.74,0.87-1.02c0.06-0.29,0.29-0.58,0.58-0.35c0.29,0.23,0.69,0.29,1.33,0c0.64-0.29,0.69-0.46,0.92-0.17
				c0.23,0.29,0.75,0.06,1.68,0.06c0.92,0,1.1-0.64,1.96-0.58c0.87,0.06,1.62-0.23,1.62-0.23l0.55,0.16
				c0.22-0.21,0.21-0.44,0.65-0.5c0.69-0.09,0.95,0.43,1.3,0.26c0.35-0.17,1.45,1.01,1.55,0.24
				C312.35,110.23,311.83,109.3,311.6,109.24z M287.23,103.67c0.52-0.19,1.69,0.06,1.97-0.19c0.28-0.26-0.74-0.58-1.1-0.89
				c-0.21-0.18-0.29-0.51-0.36-0.76c-0.17,0.12-0.39,0.21-0.59,0.11c-0.39-0.19-1.33-0.06-1.49,0c-0.16,0.06,0.13,0.62,0.13,0.97
				c0,0.36-0.42,0.45-0.42,0.68c0,0.18-0.15,0.29-0.34,0.5c0.29,0.21,0.56,0.42,0.85,0.42
				C286.48,104.51,286.71,103.86,287.23,103.67z"/>
		    <g className="hoverEf turkey">
			<g>
				<path d="M290.86,105.19h-1.25v-0.67h3.31v0.67h-1.26v3.71h-0.8V105.19z"/>
				<path d="M296.24,107.95c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V107.95z"/>
				<path d="M297.11,106.75c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V106.75z"/>
				<path d="M300.22,107.13h0.01c0.08-0.12,0.17-0.26,0.25-0.37l0.77-1.02h0.96l-1.17,1.29l1.34,1.87h-0.98l-0.91-1.4l-0.27,0.32
					v1.08h-0.8v-4.62h0.8V107.13z"/>
				<path d="M303.29,107.54c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H303.29z
					 M304.68,106.98c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H304.68z"/>
				<path d="M306.58,105.74l0.57,1.7c0.06,0.2,0.13,0.44,0.18,0.61h0.02c0.05-0.18,0.11-0.42,0.17-0.62l0.5-1.69h0.85l-0.79,2.16
					c-0.43,1.18-0.73,1.71-1.1,2.03c-0.31,0.27-0.64,0.38-0.84,0.4l-0.18-0.67c0.13-0.03,0.3-0.11,0.46-0.22
					c0.15-0.1,0.33-0.29,0.44-0.5c0.03-0.05,0.05-0.1,0.05-0.14c0-0.04-0.01-0.08-0.04-0.16l-1.16-2.91H306.58z"/>
			</g>
		</g>
		</g>
			<path class="st0" d="M363.28,112.68c-0.52-0.03-1.52,0.48-1.66,0.95c0.1,0.11,0.2,0.25,0.25,0.41c0.12,0.4,0.46,0.75,0.87,0.98
				c0.25,0.14,0.21,0.53,0.15,0.87c0.23-0.01,0.46-0.03,0.62-0.06c0.42-0.1,1.33-1.4,1.46-1.75
				C365.09,113.71,363.83,112.71,363.28,112.68z"/>
			<path class="st0" d="M437.35,100.95c-0.57,0.19-0.75-0.47-1.23-0.47c-0.47,0-0.38,1.13-0.85,1.13c-0.47,0-0.38,0.66-1.04,0.75
				c-0.66,0.09-0.85,0.09-0.57,0.57c0.28,0.47-0.38,0.28-0.94,0.28c-0.57,0-0.57-0.38-0.94-0.38c-0.38,0-0.66,0.66-0.94,1.04
				c-0.28,0.38-2.17,1.23-2.73,1.51c-0.16,0.08-0.3,0.33-0.41,0.64c0.68,0.06,1.37,0.44,1.67,0.82c0.41,0.52-0.48,1.1-0.87,1.58
				c-0.39,0.48,0.24,0.5,0.26,0.89c0.02,0.39,0.54,0.71,0.74,0.37c0.19-0.35,0.52-0.22,1.12-0.22c0.18,0,0.28,0.08,0.35,0.2
				c0.34-0.27,0.73-0.52,0.86-0.65c0.23-0.23,0.81-0.29,1.33-0.29c0.32,0,0.54-0.1,0.77-0.29c-0.55-0.6-1.09-1.09-1.3-1.22
				c-0.5-0.3-0.19-1.15,0.41-1.3c0.61-0.15,2.1-1.3,2.55-1.56c0.45-0.26,0.15-1.1,0.52-1.77c0.19-0.35,0.91-1.02,1.63-1.58
				C437.6,100.94,437.45,100.91,437.35,100.95z"/>
		
			<path class="st0" d="M391.1,149.92c0-0.58-0.46-0.63-0.46-1.1c0-0.46,0-1.27-0.58-1.79c-0.58-0.52-0.87-1.27-0.52-1.56
				c0.35-0.29,0.06-1.1,0.23-1.04c0.17,0.06,0.41-0.52,0.06-0.69c-0.35-0.17,0-0.87-0.29-0.98c-0.29-0.12-1.1-1.04-1.1-1.33
				c0-0.29,0.23-1.91,0.29-2.31c0.06-0.41,0.63-0.46,0.81-0.46c0.17,0,0.87,0.06,1.1-0.35c0.23-0.4,1.21-0.46,1.33-0.75
				c0.12-0.29,0.75-0.29,0.75-0.63c0-0.35,0.75-1.21,0.75-1.21c0.01,0,0.02,0,0.03,0c-0.09-0.15-0.28-0.01-0.66,0.14
				c-0.65,0.26-0.97-0.1-0.91-0.49c0.06-0.39-0.45-0.39-0.81-0.39c-0.36,0-0.13-0.58,0-0.97c0.13-0.39-0.13-0.62-0.55-0.65
				c-0.42-0.03-0.62-0.94-0.45-1.2c0.16-0.26-0.88-0.49-1.33-0.26c-0.45,0.23-0.13-0.39-0.26-0.71c-0.13-0.32,0.58-1.43,1.2-2.04
				c0.62-0.62,0.36-1.43,0.36-2.14c0-0.71-0.29-0.71-0.58-0.71c-0.29,0-0.33-0.42-0.33-0.81c0-0.39-0.62-0.68-0.97-0.42
				c-0.05,0.04-0.14,0.09-0.25,0.14c0.01,0,0.03,0.01,0.03,0.01s-0.12,0.58-0.35,0.69c-0.23,0.12-0.23,0.46,0.12,0.98
				c0.35,0.52-0.52,0.29-0.64,0c-0.12-0.29-0.81,0.17-1.21,0.52c-0.4,0.35-0.98,0.52-0.98,1.16c0,0.63-0.52,1.27-0.4,1.79
				c0.12,0.52-0.58,1.5-0.52,1.79c0.06,0.29-0.98-0.06-1.16-0.06c-0.17,0-0.06,1.1-0.29,1.44c-0.23,0.35-0.06,1.56-0.41,1.56
				c-0.35,0-0.63,1.1-0.63,1.1s-0.28,0.19-0.52,0.43c0.28,0.58,0.8,1.21,1.37,1.45c0.84,0.35,1.41,2.05,1.67,2.96
				c0.26,0.91,0.09,1.84-0.22,2.36c-0.3,0.52,0.11,0.54,0.6,0.8c0.5,0.26,1.06,0.35,1.43-0.17c0.37-0.52,1.13-0.89,1.23-1.47
				c0.11-0.58,0.45,0.28,0.76,0.58c0.3,0.3,0.52,0.89,0.58,1.62c0.06,0.74,0.13,2.57,0.76,3.16c0.63,0.58,0.74,1.86,0.56,2.31
				c-0.17,0.45,0.32,1.23,0.19,1.73c-0.03,0.1-0.05,0.24-0.09,0.4C390.41,152.44,391.1,149.92,391.1,149.92z"/>
			<path class="st0" d="M381,126.12c-0.87-0.8-1.11-0.16-1.69-0.46c-0.62-0.32-0.91-0.42-1.2-0.19c-0.29,0.23-0.75,0.19-1.04,0.91
				c-0.1,0.23-0.21,0.45-0.33,0.62c0.1,0.22,0.26,0.37,0.38,0.34c0.23-0.06,0.52,0.35,0.98,0.23c0.46-0.12,0.75,0.29,1.39,0.12
				c0.63-0.17,1.21,0.23,1.44-0.17c0.23-0.4-0.17-1.27-0.17-1.27S380.86,126.18,381,126.12z"/>
		
      <path class="st0" d="M399.42,152.1c0-0.37,1.48,0.37,1.67,0c0.19-0.37-1.11-1.58,0-1.3c1.11,0.28,0.93-0.37,1.3-0.83
				c0.37-0.46,1.58-0.83,1.02-1.67c-0.39-0.59-0.51-1.27-0.51-1.82c-0.36,0.28-0.79,0.3-1.04,0.21c-0.31-0.13-1-0.19-1,0.31
				c0,0.5-0.5,0.62-0.44,0.37c0.06-0.25-0.5-0.19-0.81-0.56c-0.31-0.37-1.44,0.13-2-0.06c-0.56-0.19-1.44,0.31-2,1
				c-0.56,0.69,0.06,0.69,0.06,1.31c0,0.63,0.38,0.63,0.19,1.12c-0.01,0.01-0.01,0.03-0.01,0.04c0.55,0.24,0.33,0.93,0.4,1.32
				c0.06,0.41,0.61,0.17,0.91,0.15c0.3-0.02-0.19,0.48,0.06,0.78c0.26,0.3,1.02-0.26,1.17,0.11c0.04,0.09,0.08,0.16,0.13,0.22
				C398.92,152.61,399.42,152.32,399.42,152.1z"/>
			<path class="st0" d="M399.51,140.42c0-0.37-1.76-0.93-1.58-1.39c0.19-0.46,1.11-0.19,1.11-0.65c0-0.46-0.37-0.46-0.46-1.11
				c-0.09-0.65-0.74-0.46-1.02-0.28c-0.28,0.19-1.21-0.19-1.21-0.65c0-0.46-0.74-0.74-0.74-1.11c0-0.21-0.28-0.49-0.53-0.77
				c-0.24,0.12-0.48,0.14-0.58,0.04c-0.17-0.17-0.4,0.58-0.23,0.75c0.17,0.17,0.4,1.27-0.06,1.16c-0.46-0.12-0.46-0.75-0.75-0.69
				c0,0-0.75,0.87-0.75,1.21c0,0.23-0.29,0.31-0.51,0.42c0.13,0.12,0.25,0.23,0.29,0.28c0.13,0.19-0.06,0.69,0.13,1.12
				c0.19,0.44,0.81-0.25,1,0.38c0.19,0.62-0.12,0.94-0.12,1.56c0,0.63-0.5,0.56-0.37,1.13c0.12,0.56,0.81-0.12,1.31-0.5
				c0.5-0.38,0.88-0.31,1.06-0.06c0.19,0.25,0.81,0.06,0.94-0.25c0.13-0.31,1.06-0.44,1.37-0.06c0.31,0.37,0.62,0.81,1.06,1.06
				c0.44,0.25-0.31,1.62,0.13,1.75c0.44,0.12,1.06,0.81,1,1.56c-0.05,0.64,0.31,1.1-0.19,1.66c0.31,0.2,0.68,0.2,0.63,0.4
				c-0.06,0.25,0.44,0.12,0.44-0.37c0-0.5,0.69-0.44,1-0.31c0.25,0.1,0.68,0.08,1.04-0.21c0-0.22,0.02-0.43,0.04-0.59
				c0.09-0.56,0-1.2-0.28-1.48c-0.28-0.28-1.02-0.56-1.21-1.11c-0.18-0.56-1.02-1.11-1.2-1.58
				C400.07,141.25,399.51,140.79,399.51,140.42z"/>
		<g className="mouseOver thailandCount">
			<path class="lightBlue" d="M395.68,149.06c0-0.62-0.62-0.62-0.06-1.31c0.56-0.69,1.44-1.19,2-1c0.56,0.19,1.69-0.31,2,0.06
				c0.06,0.07,0.12,0.12,0.19,0.16c0.49-0.55,0.14-1.01,0.19-1.66c0.06-0.75-0.56-1.44-1-1.56c-0.44-0.13,0.31-1.5-0.13-1.75
				c-0.44-0.25-0.75-0.69-1.06-1.06c-0.31-0.38-1.25-0.25-1.37,0.06c-0.13,0.31-0.75,0.5-0.94,0.25c-0.19-0.25-0.56-0.31-1.06,0.06
				c-0.5,0.38-1.19,1.06-1.31,0.5c-0.13-0.56,0.37-0.5,0.37-1.13c0-0.62,0.31-0.94,0.12-1.56c-0.19-0.62-0.81,0.06-1-0.38
				c-0.19-0.44,0-0.94-0.13-1.12c-0.04-0.06-0.15-0.17-0.29-0.28c-0.11,0.05-0.2,0.12-0.24,0.21c-0.12,0.29-1.1,0.35-1.33,0.75
				c-0.23,0.4-0.92,0.35-1.1,0.35c-0.17,0-0.75,0.06-0.81,0.46c-0.06,0.4-0.29,2.02-0.29,2.31c0,0.29,0.81,1.21,1.1,1.33
				c0.29,0.12-0.06,0.81,0.29,0.98c0.35,0.17,0.12,0.75-0.06,0.69c-0.17-0.06,0.12,0.75-0.23,1.04c-0.35,0.29-0.06,1.04,0.52,1.56
				c0.58,0.52,0.58,1.33,0.58,1.79c0,0.46,0.46,0.52,0.46,1.1c0,0-0.69,2.52-1.26,2.41c-0.12,0.63-0.27,1.62-0.41,2.16
				c-0.17,0.67-0.3,1.25,0.09,1.3c0.39,0.04,1.17,0.93,1.92,1.88c0.18,0.23,0.33,0.48,0.44,0.72c0.26-0.01,0.56,0,0.85,0.06
				c0.58,0.11,0.72,0.57,0.72,0.86c0,0.29,0.97,0.18,1.15,0.14c0.14-0.03-0.04-0.43,0.45-0.59c-0.29-0.42-1.06-0.93-1.68-0.98
				c-0.73-0.06-1.08-1.17-1.02-1.67c0.06-0.5-0.54-0.93-0.54-1.36c0-0.43-0.43-0.45-0.93-0.69c-0.5-0.24,0.11-1.71,0.17-2.34
				c0.06-0.63,1.1-2.42,0.8-2.99c-0.3-0.56,1.06-1,1.23-0.37c0.17,0.63,0.24,0.93,1.1,0.89c0.87-0.04,0.87,0.63,1.56,0.82
				c0.04,0.01,0.07,0.03,0.1,0.04c0-0.01,0.01-0.03,0.01-0.04C396.05,149.68,395.68,149.68,395.68,149.06z"/>
		    <g className="hoverEf thai">
			<g>
				<path d="M371.51,147.71h-1.25v-0.67h3.31v0.67h-1.26v3.71h-0.8V147.71z"/>
				<path d="M374.06,146.81h0.8v1.88h0.01c0.1-0.15,0.23-0.27,0.4-0.36c0.16-0.09,0.35-0.14,0.55-0.14c0.54,0,1.11,0.36,1.11,1.37
					v1.86h-0.8v-1.78c0-0.46-0.17-0.81-0.61-0.81c-0.31,0-0.53,0.21-0.62,0.45c-0.03,0.07-0.04,0.15-0.04,0.24v1.89h-0.8V146.81z"/>
				<path d="M379.56,151.42l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H379.56z M379.45,149.87c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V149.87z"/>
				<path d="M381.93,147.37c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C381.75,146.94,381.92,147.13,381.93,147.37z M381.08,151.42v-3.16h0.8v3.16H381.08z"/>
				<path d="M382.74,146.81h0.8v4.62h-0.8V146.81z"/>
				<path d="M386.19,151.42l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H386.19z M386.09,149.87c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V149.87z"/>
				<path d="M387.71,149.2c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V149.2z"/>
				<path d="M394.35,146.81v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
					c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01v-1.79H394.35z M393.55,149.58
					c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
					c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V149.58z"/>
			</g>
		</g>
		</g>
	    <g className="mouseOver polandCount">
			<path class="lightBlue" d="M268.39,79.71c0.1,0.26,0.2,1.17-0.03,1.53c-0.23,0.36-0.13,0.45,0.1,0.58c0.23,0.13,0.32,0.45,0.32,0.71
				c0,0.26,0.16,0.23,0.19,0.78c0.03,0.55,0.16,0.94,0.36,1.17c0.15,0.18,0.12,0.45,0.04,0.66c0.27,0.2,0.58,0.4,0.85,0.49
				c0.68,0.24,0.92,0.44,0.97,0.68c0.05,0.24,0.63,0.68,0.77,0.34c0.14-0.34,0.53-0.14,0.82-0.14c0.29,0,0.34,0.34,0.39,0.53
				c0.05,0.19,0.73,0.1,0.97,0.24c0.24,0.14,0.34,0.68,0.68,0.87c0.34,0.19,0.63-0.24,0.87-0.1c0.24,0.14,0.87,0.29,1.26,0.14
				c0.39-0.14,0.63,0.14,1.11,0.1c0.48-0.05,1.11,0.14,1.26,0.29c0.08,0.08,0.49,0.15,0.86,0.21c0.1-0.2-0.09-0.81-0.06-0.96
				c0.03-0.16,1.17-1.17,1.36-1.4c0.19-0.23,0.49-0.23,0.65-0.52c0.16-0.29-0.45-1.23-0.58-1.43c-0.13-0.19-0.19-0.97-0.1-1.3
				c0.1-0.32-0.49-0.52-0.49-0.75c0-0.23,0.55-0.62,0.84-0.75c0.29-0.13,0.19-1.04-0.06-1.27c-0.26-0.23-0.39-0.65-0.23-1.1
				c0.16-0.45-0.97-0.81-1.14-0.81c-0.03,0-0.04-0.03-0.05-0.07c-1.01,0.19-3.61-0.08-3.8-0.16c-0.13-0.05-0.46-0.17-0.74-0.27
				c-0.13,0.21-0.4,0.41-0.8,0.41c-0.63,0-0.67-0.63-0.69-0.91c-0.02-0.28-2.53,0.24-3.2,0.91c-0.67,0.67-2.23,0.41-2.27,0.78
				c-0.03,0.27-0.27,0.41-0.42,0.33C268.37,79.59,268.37,79.65,268.39,79.71z"/>
		</g>
			<path class="st0" d="M274.12,100.58c-0.06-0.29,0.87-1.63,1.11-1.63c0.19,0,0.27-1.29,0.18-1.95c-0.72-0.5-1.32-0.67-1.32-0.67
				s-1.94-0.1-2.26-0.23c-0.32-0.13-0.65,0.42-0.94,0.19c-0.29-0.23-0.71,0.06-0.65,0.39c0.06,0.32,0.68,0.52,0.68,1
				c0,0.48,1.23,1.2,1.2,1.45c-0.02,0.18,0.74,0.67,1.19,1.34c0.24,0.15,0.5,0.35,0.75,0.56
				C274.11,100.82,274.14,100.66,274.12,100.58z"/>
		<g className="mouseOver croatiaCount">
			<path class="lightBlue" d="M272.12,99.14c0.03-0.26-1.2-0.97-1.2-1.45c0-0.48-0.61-0.68-0.68-1c-0.07-0.32,0.36-0.61,0.65-0.39
				c0.29,0.23,0.61-0.32,0.94-0.19c0.32,0.13,2.26,0.23,2.26,0.23s0.6,0.17,1.32,0.67c-0.02-0.13-0.04-0.25-0.07-0.31
				c-0.17-0.41,0-0.87-0.46-1.05c-0.24-0.09-0.34-0.5-0.38-0.89c-0.22,0.07-0.4,0.12-0.51,0.15c-0.39,0.1-1.21,0.05-1.5-0.34
				c-0.22-0.29-0.89-0.71-1.22-0.91c-0.14,0.12-0.65,0.56-0.87,0.59c-0.26,0.03-0.1,0.71-0.36,0.71c-0.26,0-0.45,0.45-0.52,0.65
				c-0.06,0.19-0.65-0.13-0.91-0.03c-0.19,0.07-0.73,0.16-1.03,0.2c0.05,0.3,0.03,0.62,0.26,0.62c0.39,0,1.1-0.04,1.13,0.48
				c0.02,0.52,0.95,1.67,1.3,2.06c0.35,0.39,1.13,0.3,1.17,0.63c0.04,0.32,0.61,0.63,1.26,0.67c0.18,0.01,0.39,0.11,0.62,0.26
				C272.86,99.82,272.1,99.33,272.12,99.14z"/>
		</g>
			<path class="st0" d="M276.92,105.37c0.32-0.06,0.13-0.32,0.36-0.55c0.23-0.23,0.49-0.68,0.52-0.84c0-0.02,0.02-0.03,0.03-0.04
				c-0.44-0.33-0.75-0.57-0.85-0.91c-0.1-0.35,0-1.02,0.16-1.52c-0.25-0.18-1.02-0.71-1.27-0.71c-0.24,0-0.61,0.53-0.7,1.05
				c0.57,0.3,0.79,0.52,0.4,0.91c-0.41,0.41-0.5,1.8-0.04,2.16c0.43,0.35,0.56,1.05,0.98,1.24
				C276.62,105.81,276.71,105.42,276.92,105.37z"/>
		<g className="mouseOver austriaCount">
			<path class="lightBlue" d="M270.75,89.39c-0.39-0.44-1.35-0.44-1.35-0.44s-0.1,0.44-0.24,0.68c-0.12,0.2-0.82,0.2-1.27,0.08
				c-0.13,0.17-0.71,0.3-0.79,0.54c-0.1,0.29-0.81,0.13-0.58,0.91c0.23,0.78-0.36,0.52-0.58,0.42c-0.23-0.1-1.23,0.03-1.49,0.29
				c-0.26,0.26-0.62,0.19-0.84,0.07c-0.23-0.13-0.68-0.19-0.88,0.13c-0.19,0.32-0.55-0.26-0.84-0.23c-0.29,0.03-0.16,0.65-0.16,0.78
				c0,0.13,0.45,0.52,0.75,0.39c0.29-0.13,0.65,0.03,0.91,0.19c0.26,0.16,0.75-0.13,0.91-0.29c0.16-0.16,1.07-0.16,1.17-0.16
				c0.1,0,0.13,0.52,0.29,0.62c0.16,0.1,0.78,0.16,1.33,0.19c0.55,0.03,1.36,0.29,1.62,0.29c0.26,0,0.68-0.45,1.26-0.45
				c0.58,0,0.91-0.36,1.01-0.32c0,0,0,0,0.01,0c0.04-0.18,0.11-0.33,0.24-0.34c0.24-0.03,0.07-0.44,0.24-0.64
				c0.17-0.2-0.14-0.61,0.17-0.58c0.3,0.03,0.64,0.07,0.64-0.37c0-0.15,0.08-0.28,0.2-0.39c-0.33-0.47-0.58-1.22-0.58-1.22
				S271.14,89.83,270.75,89.39z"/>
		</g>
		<g className="mouseOver hungaryCount">
			<path class="lightBlue" d="M280.14,90.61c-0.11-0.04-0.24-0.11-0.34-0.21c-0.41-0.09-0.8-0.21-0.92-0.33
				c-0.24-0.24-1.21-0.39-1.35-0.43c-0.14-0.05-1.06,0.87-1.35,0.87c-0.29,0-1.55,0.15-1.55,0.48c0,0.34-1.55,0.39-1.98,0
				c-0.07-0.06-0.13-0.14-0.2-0.23c-0.12,0.11-0.2,0.24-0.2,0.39c0,0.44-0.34,0.41-0.64,0.37c-0.31-0.03,0,0.37-0.17,0.58
				c-0.17,0.2,0,0.61-0.24,0.64c-0.13,0.02-0.19,0.16-0.24,0.34c0.1,0.05,0.35,0.55,0.35,0.55s-0.01,0.01-0.03,0.03
				c0.33,0.2,1,0.62,1.22,0.91c0.29,0.39,1.11,0.44,1.5,0.34c0.39-0.1,2.03-0.63,2.03-0.63s0.39,0.14,0.63,0.19
				c0.06,0.01,0.21,0.04,0.41,0.07c0.12-0.12,0.48-0.23,0.87-0.62c0.45-0.45,1.14-2.08,1.33-2.21c0.19-0.13,0.71-0.42,0.97-0.62
				C280.49,90.9,280.43,90.71,280.14,90.61z"/>
		</g>
		<g className="mouseOver czechCount">
			<path class="lightBlue" d="M274.14,87.27c-0.24-0.14-0.92-0.05-0.97-0.24c-0.05-0.19-0.1-0.53-0.39-0.53c-0.29,0-0.68-0.19-0.82,0.14
				c-0.15,0.34-0.73-0.1-0.77-0.34c-0.05-0.24-0.29-0.43-0.97-0.68c-0.27-0.1-0.57-0.29-0.85-0.49c-0.02,0.06-0.05,0.11-0.08,0.16
				c-0.13,0.2-0.58-0.16-0.78,0.03c-0.19,0.19-0.84,0.23-1.2,0.55c-0.36,0.32-1.1,0.49-1.53,0.58c-0.42,0.1-0.06,0.49,0.1,0.78
				c0.16,0.29,0.16,1.01,0.49,1.23c0.32,0.23,1.4,0.91,1.53,1.14c0.02,0.04,0.01,0.08-0.02,0.11c0.45,0.12,1.15,0.12,1.27-0.08
				c0.15-0.24,0.24-0.68,0.24-0.68s0.97,0,1.35,0.44c0.39,0.44,1.11,0.14,1.11,0.14s0.04,0.12,0.11,0.29
				c0.1-0.24,0.24-0.45,0.41-0.47c0.41-0.06,0.99-0.12,1.4-0.7c0.21-0.3,0.56-0.54,0.85-0.7C274.43,87.72,274.32,87.38,274.14,87.27
				z"/>
		</g>
		<g className="mouseOver slovakiaCount">
			<path class="lightBlue" d="M278.05,88.28c-0.48,0.05-0.73-0.24-1.11-0.1c-0.39,0.14-1.02,0-1.26-0.14c-0.24-0.15-0.53,0.29-0.87,0.1
				c-0.07-0.04-0.14-0.1-0.19-0.17c-0.29,0.16-0.64,0.4-0.85,0.7c-0.41,0.58-0.99,0.64-1.4,0.7c-0.17,0.02-0.3,0.23-0.41,0.47
				c0.13,0.34,0.38,0.9,0.67,1.15c0.44,0.39,1.98,0.34,1.98,0c0-0.34,1.26-0.48,1.55-0.48c0.29,0,1.21-0.92,1.35-0.87
				c0.15,0.05,1.11,0.19,1.35,0.43c0.12,0.12,0.51,0.24,0.92,0.33c-0.17-0.15-0.31-0.34-0.31-0.5c0-0.26,0.49-0.94,0.65-1.07
				c0.01-0.01,0.02-0.03,0.03-0.05c-0.37-0.06-0.78-0.13-0.86-0.21C279.16,88.43,278.53,88.23,278.05,88.28z"/>
		</g>
			<path class="st0" d="M237.08,109.03c0.58-0.11-0.16-0.58-0.32-0.74c-0.16-0.16,0.05-0.79,0.37-0.79c0.32,0,0-0.37-0.42-0.79
				c-0.42-0.42,0-0.58,0.37-0.58c0.37,0-0.11-0.63,0.26-1c0.37-0.37-0.11-1.05-0.11-1.37c0-0.32,0.53-0.42,0.95-0.84
				c0.42-0.42-0.37-0.26-0.37-0.58c0-0.32-0.53-0.42-0.89-0.16c-0.37,0.26-0.26,0-0.58,0c-0.32,0-0.9,0.11-0.9-0.21
				c0-0.32-0.26-0.37-0.53-0.05c-0.08,0.09-0.21,0.14-0.39,0.17c-0.04,0.41-0.08,0.8,0.04,0.99c0.28,0.45-0.35,2.86-0.91,3.5
				c-0.56,0.65-0.02,0.82,0.56,1.28c0.58,0.45,0.2,2.1,0.2,2.49c0,0.39,0.52,0.28,1.12,0.28c0.36,0,0.77-0.16,1.17-0.23
				c-0.12-0.18-0.21-0.36-0.21-0.48C236.5,109.61,236.5,109.14,237.08,109.03z"/>
		<g className="mouseOver netherlandCount">
			<path class="lightBlue" d="M254.08,84.4c0.26-0.26,0.73-0.13,0.99,0.13c0.26,0.26,0.66,0.33,1.06,0.73c0.1,0.1,0.21,0.19,0.32,0.28
				c0.04-0.28,0.04-0.83,0.17-1.12c0.13-0.3-0.13-0.69,0.09-0.69c0.22,0,0.82,0,0.82-0.39c0-0.39,0.17-0.26,0.3-0.48
				c0.13-0.22-0.39-0.65-0.48-0.78c-0.09-0.13,0.39-0.17,0.74-0.69c0.12-0.19,0.07-0.58-0.04-0.99c-0.06,0.15-0.17,0.27-0.37,0.15
				c-0.32-0.19-0.82-0.09-1.47,0.04c-0.65,0.13-0.84,0.76-0.61,1.02c0.24,0.26-0.63,0.39-0.69,0.04c-0.06-0.35-0.71-0.11-0.67,0.35
				c0.04,0.45-0.71,0.63-0.67,1.19c0.04,0.56-0.69,1.08-1.17,1.36c-0.07,0.04-0.15,0.09-0.23,0.13c0.24,0.11,0.43,0.18,0.52,0.18
				C253.09,84.86,253.82,84.66,254.08,84.4z"/>
		</g>
			<path class="st0" d="M256.54,86.91c-0.29,0.16-0.55,0.51-0.51,1.24c0.28-0.01,0.68,0.02,0.97,0.11c-0.07-0.8-0.38-1.05-0.46-1.21
				C256.51,87.01,256.52,86.96,256.54,86.91z"/>
		<g className="mouseOver belgiumCount">
			<path class="lightBlue" d="M251.38,85.29c0.03,0.23,0.39,0.78,0.52,0.55c0.13-0.23,0.45,0.29,0.75,0.45c0.29,0.16,0.88,0.36,0.91,0.55
				c0.03,0.19,0.13,0.62,0.42,0.49c0.29-0.13,0.65-0.58,0.65-0.32c0,0.26,0.1,0.75,0.42,0.68c0.32-0.07,0.42,0.55,0.75,0.49
				c0.06-0.01,0.14-0.02,0.23-0.02c-0.04-0.73,0.22-1.08,0.51-1.24c0.07-0.13,0.26-0.26,0.29-0.42c0.04-0.22-0.43-0.65-0.39-0.91
				c0-0.01,0-0.03,0.01-0.05c-0.11-0.08-0.22-0.17-0.32-0.28c-0.4-0.4-0.79-0.46-1.06-0.73c-0.26-0.27-0.73-0.4-0.99-0.13
				c-0.26,0.27-0.99,0.46-1.39,0.46c-0.09,0-0.28-0.07-0.52-0.18c-0.31,0.18-0.65,0.35-0.98,0.42
				C251.26,85.18,251.36,85.14,251.38,85.29z"/>
		</g>
		<g className="mouseOver romaniaCount">
			<path class="lightBlue" d="M288.86,95.96c-0.3-0.04-0.69-0.17-0.56-0.35c0.01-0.01,0.03-0.03,0.04-0.05c-0.41-0.33-0.51-1.39-0.25-1.65
				c0.33-0.33-0.53-1.59-0.93-2.05c-0.19-0.22-0.59-0.94-0.96-1.65c-0.34,0.11-0.65,0.24-0.71,0.34c-0.13,0.22-0.56,0.43-0.82,0.43
				c-0.26,0-0.52-0.13-0.74,0.09c-0.22,0.22-0.52,0.3-0.65,0.17c-0.13-0.13-0.56-0.26-0.87-0.26c-0.3,0-1.25-0.26-1.47-0.26
				c-0.13,0-0.34,0.02-0.56,0.05c0.05,0.09,0.01,0.21-0.14,0.32c-0.26,0.19-0.78,0.49-0.97,0.62c-0.19,0.13-0.88,1.75-1.33,2.21
				c-0.45,0.45-0.88,0.52-0.91,0.68c-0.03,0.16,0.52,0.59,0.52,0.84c0,0.26,0.13,0.52,0.49,0.52c0.36,0-0.03,0.84,0.19,0.91
				c0.23,0.06,1.62,0.16,1.62,0.49c0,0.25,0.15,0.46,0.09,0.64c0.39,0.07,0.68,0.6,0.83,0.56c0.17-0.04,1.34,0.22,1.56,0.17
				c0.22-0.04,1.21,0,1.56,0.04c0.35,0.04,0.73-0.13,1.04-0.43c0.3-0.3,1.39-0.35,1.73-0.3c0.34,0.04,1.04,0.13,1.17,0.35
				c0.07,0.11,0.48,0.28,0.89,0.42c0.05-0.21-0.01-0.49,0-0.77c0.02-0.45,0.28-1.15,0.73-1.19c0.45-0.04,0.82-0.04,0.76-0.8
				c0-0.03,0-0.05,0-0.07c-0.23-0.2-0.45-0.36-0.52-0.36C289.5,95.62,288.98,95.98,288.86,95.96z"/>
		</g>
			<path class="st0" d="M289.46,91.38c-0.17-0.56-0.74-0.78-1.04-0.78c-0.3,0-0.82-0.69-1.17-0.65c-0.19,0.02-0.66,0.14-1.07,0.27
				c0.38,0.71,0.78,1.43,0.96,1.65c0.4,0.46,1.26,1.72,0.93,2.05c-0.26,0.26-0.15,1.32,0.25,1.65c0.19-0.25,0.83-1.05,0.83-1.29
				c0-0.26,0.04-0.78,0.56-0.65c0.52,0.13,1,0.35,1,0.17c0-0.17-0.13-0.74-0.35-0.95C290.15,92.63,289.63,91.94,289.46,91.38z"/>
		<g className="mouseOver bulgariaCount">
			<path class="lightBlue" d="M286.65,98.04c-0.35-0.04-1.43,0-1.73,0.3c-0.3,0.3-0.69,0.48-1.04,0.43c-0.35-0.04-1.34-0.09-1.56-0.04
				c-0.22,0.04-1.38-0.22-1.56-0.17c-0.15,0.04-0.44-0.49-0.83-0.56c-0.02,0.05-0.05,0.1-0.12,0.14c-0.29,0.2-0.32,0.71,0,1.01
				c0.32,0.29,0.75,0.94,0.45,0.94c-0.29,0-0.52,0.39-0.49,0.58c0.03,0.2-0.1,0.62,0,0.71c0.1,0.1,0.78,0.75,0.81,0.94
				c0.03,0.17,0.31,0.26,0.29,0.41c0.55-0.04,1.7-0.11,1.96,0.02c0.35,0.17,1.56,0.26,1.56,0.26c0.34,0,0.65-0.13,0.56-0.35
				c-0.07-0.18,0.38-0.3,0.7-0.34c-0.06-0.2-0.1-0.36-0.01-0.4c0.16-0.06,1.1-0.19,1.49,0c0.2,0.1,0.42,0.01,0.59-0.11
				c-0.05-0.18-0.1-0.33-0.2-0.34c-0.24-0.04-0.6-1.04-0.3-1.1c0.3-0.06,0.69-1.1,1.17-1.25c0.19-0.06,0.27-0.17,0.3-0.31
				c-0.41-0.14-0.82-0.31-0.89-0.42C287.69,98.17,286.99,98.08,286.65,98.04z"/>
		</g>
			<path class="st0" d="M277.83,103.94L277.83,103.94c0.01-0.01,0.03-0.02,0.06-0.02c0,0,0.01,0,0.01,0
				c0.02-0.01,0.05-0.01,0.08-0.01c0,0,0.01,0,0.01,0c0.13-0.01,0.3,0,0.47-0.01c0.01,0,0.02,0,0.04,0c0.04,0,0.08,0,0.12-0.01
				c0.01,0,0.03,0,0.05,0c0.05-0.01,0.1-0.01,0.15-0.03c0.39-0.1,0.49-0.52,0.68-0.42c0.19,0.1,1.23-0.43,1.36-0.63
				c0.02-0.02,0.02-0.04,0.03-0.07c0.02-0.15-0.26-0.24-0.29-0.41c-0.03-0.19-0.71-0.84-0.81-0.94c-0.02-0.02-0.03-0.06-0.03-0.1
				c0,0,0-0.01,0-0.01c0-0.05,0-0.1,0.01-0.16c-1.01,0.15-2.52,0.46-2.52,0.46s-0.04-0.03-0.09-0.06c-0.15,0.49-0.26,1.17-0.16,1.52
				C277.08,103.37,277.39,103.61,277.83,103.94z"/>
			<path class="st0" d="M302.69,91.84c0.23-0.03,0.91-0.78,1.14-0.78c0.23,0,1.2,0.19,1.2,0.03c0-0.16,0.32-0.71,0.26-0.88
				c-0.06-0.16-0.39-0.39-0.32-0.55c0.06-0.16,0.52-0.16,0.52-0.36c0-0.19-0.36-0.29-0.36-0.42c0-0.13,0.45-0.36,0.42-0.62
				c-0.03-0.26-0.42-0.29-0.84-0.52c-0.42-0.23-0.84-0.13-1.23-0.32c-0.39-0.19-1.2-0.07-1.2-0.19c0-0.13-0.52-0.55-0.65-0.78
				c-0.13-0.23-0.55-0.1-0.81,0.06c-0.26,0.16-0.49,0.23-0.75,0.07c-0.26-0.16-0.49-0.42-0.71-0.32c-0.23,0.1-0.65,0.03-0.55-0.29
				c0.1-0.32-0.42-1.04-0.65-1.14c-0.23-0.1-0.91,0.06-1.07-0.26c-0.16-0.32-0.45-0.58-0.32-0.81c0.13-0.23-0.03-0.84-0.19-1.1
				c-0.16-0.26-0.97-0.23-1.2-0.13c-0.23,0.1-0.75-0.1-0.97,0.03c-0.23,0.13-0.36,0.36-0.68,0.36c-0.18,0-0.3-0.02-0.4-0.17
				c-0.35,0.16-0.94,0.19-0.94,0.19s-0.87,0.69-0.87,0.95c0,0.26-0.04,0.69-0.26,0.48c-0.22-0.22-1.12-0.22-1.34-0.09
				c-0.22,0.13-0.56-0.52-0.95-0.3c-0.39,0.22-0.91-0.3-0.95-0.09c-0.04,0.22-0.6,0.04-1-0.13c-0.39-0.17-1.08,0.09-1.34-0.22
				c-0.26-0.3-1.95-0.3-2.51-0.3c-0.56,0-0.69,0.3-0.95,0.56c-0.23,0.24-0.51,0.19-0.78,0.17c0.02,0.22,0.07,0.41,0.12,0.5
				c0.13,0.19,0.75,1.14,0.58,1.43c-0.16,0.29-0.45,0.29-0.65,0.52c-0.19,0.23-1.33,1.23-1.36,1.4c-0.03,0.16,0.19,0.88,0.03,1.01
				c-0.16,0.13-0.65,0.81-0.65,1.07c0,0.26,0.36,0.62,0.65,0.71c0.12,0.04,0.2,0.1,0.24,0.17c0.23-0.03,0.43-0.05,0.56-0.05
				c0.22,0,1.17,0.26,1.47,0.26c0.3,0,0.74,0.13,0.87,0.26c0.13,0.13,0.43,0.04,0.65-0.17c0.22-0.22,0.48-0.09,0.74-0.09
				c0.26,0,0.69-0.22,0.82-0.43c0.13-0.22,1.43-0.56,1.77-0.61c0.35-0.04,0.86,0.65,1.17,0.65c0.3,0,0.86,0.22,1.04,0.78
				c0.17,0.56,0.69,1.26,0.91,1.47c0.22,0.22,0.35,0.78,0.35,0.95c0,0.17-0.48-0.04-1-0.17c-0.52-0.13-0.56,0.39-0.56,0.65
				c0,0.26-0.74,1.17-0.87,1.34c-0.13,0.17,0.26,0.3,0.56,0.35c0.13,0.02,0.65-0.35,0.82-0.35c0.07,0,0.29,0.17,0.52,0.36
				c-0.05-0.68,0.09-0.64,0.7-1.1c0.63-0.48,0.69-1.36,1.25-1.47c0.56-0.11,0.8-0.37,1.62-0.24c0.82,0.13-0.41,0.65,0.15,0.95
				c0.56,0.3,2.29-0.09,2.38,0.35c0.09,0.43-1.99,0.87-1.95,1.08c0.04,0.22,1.1,0.43,1.47,0.67c0.37,0.24-0.11,0.91,0.07,1.13
				c0.17,0.22,0.73,0.04,1.19-0.28c0.45-0.32,0.82-0.22,1.41-0.61c0.58-0.39,1.8-0.04,1.95-0.28c0.15-0.24-0.35-0.48-1.47-0.39
				c-1.12,0.09-1.19-0.97-1.19-1.19c0-0.22,1.17-1.1,1.84-1.17c0.67-0.07,1.21-0.26,1.86-0.56c0.26-0.12,0.72-0.25,1.19-0.33
				C302.59,92.23,302.5,91.87,302.69,91.84z"/>
			<path class="st0" d="M293.83,79.51c-0.26-0.07-0.42-0.65-0.84-0.81c-0.42-0.16-0.32-0.68-0.68-0.91
				c-0.36-0.23-0.13-0.68-0.16-1.04c-0.03-0.36,0.07-0.91-0.13-1.01c-0.2-0.1-1.01-0.55-1.36-0.39c-0.36,0.16-0.62,0.29-0.78,0
				c-0.16-0.29-0.39-0.45-0.75-0.36c-0.36,0.1-0.58-0.06-0.78-0.23c-0.03-0.02-0.05-0.06-0.07-0.11c-0.14,0.03-0.33,0.09-0.45,0.18
				c-0.22,0.17-0.26,0.52-0.56,0.52c-0.3,0-0.65-0.13-1,0.13c-0.35,0.26-0.43,0.56-0.39,0.78c0.04,0.22,0.09,0.56-0.17,0.56
				c-0.26,0-0.22,0.3-0.48,0.39c-0.26,0.09-0.69,0.48-0.74,0.69c-0.04,0.22-0.04,0.69-0.22,0.69c-0.17,0-0.78,0.09-0.95,0.3
				c-0.17,0.22-0.39,0.43-0.73,0.35c-0.18-0.04-0.65-0.07-1.07-0.08c0.01,0.04,0.01,0.08-0.01,0.13c-0.16,0.45-0.03,0.88,0.23,1.1
				c0.26,0.23,0.36,1.14,0.06,1.27c-0.29,0.13-0.84,0.52-0.84,0.75c0,0.23,0.58,0.42,0.49,0.75c-0.05,0.19-0.06,0.52-0.03,0.8
				c0.28,0.01,0.55,0.06,0.78-0.17c0.26-0.26,0.39-0.56,0.95-0.56c0.56,0,2.25,0,2.51,0.3c0.26,0.3,0.95,0.04,1.34,0.22
				c0.39,0.17,0.95,0.35,1,0.13c0.04-0.22,0.56,0.3,0.95,0.09c0.39-0.22,0.74,0.43,0.95,0.3c0.22-0.13,1.12-0.13,1.34,0.09
				c0.22,0.22,0.26-0.22,0.26-0.48c0-0.26,0.87-0.95,0.87-0.95s0.59-0.03,0.94-0.19c-0.08-0.11-0.14-0.3-0.22-0.61
				c-0.16-0.71-0.42-1.14-0.23-1.36c0.19-0.23,0.75,0.29,1.04,0.16c0.29-0.13,0.81-0.39,0.75-0.68
				C294.58,79.97,294.09,79.58,293.83,79.51z"/>
			<path class="st0" d="M281.13,74.28c0.4,0,1.32,0.2,1.85,0c0.53-0.2,0.53,0.4,0.86,0.33c0.33-0.07,0.99,0.4,1.39,0.73
				c0.21,0.18,0.46,0.32,0.74,0.48c0.06-0.11,0.15-0.21,0.28-0.32c0.35-0.26,0.69-0.13,1-0.13c0.3,0,0.35-0.35,0.56-0.52
				c0.12-0.1,0.31-0.15,0.45-0.18c-0.12-0.24-0.12-0.71-0.31-0.9c-0.23-0.23-0.55-0.62-0.45-0.84c0.1-0.23,0.1-0.55-0.16-0.81
				c-0.11-0.11-0.2-0.25-0.25-0.37c-0.53-0.07-1.24-0.14-1.4-0.09c-0.26,0.09-1.3-0.74-1.47-0.87c-0.11-0.08-0.88-0.04-1.58,0.02
				c-0.4,0.44-0.11,0.77-0.13,1.45c-0.02,0.69-0.78,0.56-1.19,0.56c-0.41,0-1.08-1.23-1.3-1.52c-0.22-0.28-0.91,0.35-1.41,0.65
				c-0.5,0.3-0.24,0.89-0.58,1.17c-0.35,0.28-0.56,0.76-0.22,1.39c0.01,0.03,0.02,0.05,0.04,0.08c0.43-0.03,0.89-0.11,1.16-0.3
				C279.48,73.95,280.74,74.28,281.13,74.28z"/>
		<g className="mouseOver lithuaniaCount">
			<path class="lightBlue" d="M278.68,76.66c0.16,0.23,0.68,0.42,0.88,0.32c0.19-0.1,0.81,0.23,0.81,0.49s-0.16,1.04,0,1.04
				c0.15,0,1.08,0.29,1.15,0.68c0.42,0.01,0.89,0.03,1.07,0.08c0.35,0.09,0.56-0.13,0.73-0.35c0.17-0.22,0.78-0.3,0.95-0.3
				c0.17,0,0.17-0.48,0.22-0.69c0.04-0.22,0.48-0.6,0.74-0.69c0.26-0.09,0.22-0.39,0.48-0.39c0.26,0,0.22-0.35,0.17-0.56
				c-0.03-0.13,0-0.3,0.11-0.46c-0.28-0.16-0.53-0.3-0.74-0.48c-0.4-0.33-1.06-0.79-1.39-0.73c-0.33,0.07-0.33-0.53-0.86-0.33
				c-0.53,0.2-1.46,0-1.85,0c-0.4,0-1.65-0.33-2.12,0c-0.27,0.19-0.73,0.27-1.16,0.3c0.3,0.6,0.19,1.22-0.14,1.59
				c-0.24,0.27-0.1,0.3,0.07,0.41C278.14,76.54,278.55,76.47,278.68,76.66z"/>
		</g>
		<g className="mouseOver switzCount">
			<path class="lightBlue" d="M262.45,93.01c-0.29,0.13-0.75-0.26-0.75-0.39c0-0.09-0.06-0.41,0-0.61c-0.24-0.12-0.59-0.29-0.84-0.37
				c-0.43-0.13-0.65-0.13-0.99,0.04c-0.22,0.11-0.83,0.08-1.24,0.04c-0.04,0.13-0.09,0.22-0.2,0.22c-0.29,0-0.68,0.29-0.75,0.62
				c-0.07,0.32-0.75,0.58-0.94,1.17c-0.19,0.58-0.1,0.84,0.2,0.49c0.29-0.36,0.65-0.23,0.65,0.06c0,0.29,0.39,0.32,0.26,0.71
				c0,0.01,0,0.01,0,0.02c0.44-0.04,1.06-0.09,1.25-0.09c0.3,0,0.61-0.95,0.74-1.04c0.13-0.09,0.48,0.69,0.82,1
				c0.35,0.3,0.39-0.26,0.39-0.52c0-0.26,0.3-0.35,0.52-0.3c0.22,0.04,0.69,0.13,0.69-0.17c0-0.3,0.26-0.17,0.43-0.17
				c0.17,0,0.22-0.35,0.22-0.35l0.28-0.26C262.95,92.99,262.68,92.91,262.45,93.01z"/>
		</g>
		<g className="mouseOver germanyCount">
			<path class="orangBlue" d="M258.09,81.38c-0.35,0.52-0.82,0.56-0.74,0.69c0.09,0.13,0.61,0.56,0.48,0.78c-0.13,0.22-0.3,0.09-0.3,0.48
				c0,0.39-0.61,0.39-0.82,0.39c-0.22,0,0.04,0.39-0.09,0.69c-0.13,0.3-0.13,0.91-0.17,1.17c-0.04,0.26,0.43,0.69,0.39,0.91
				c-0.04,0.22-0.39,0.39-0.3,0.56c0.08,0.16,0.39,0.41,0.46,1.21c0.15,0.04,0.27,0.1,0.33,0.17c0.2,0.26,0.52,0.45,0.94,0.45
				c0.42,0,0.58,0.29,0.78,0.26c0.19-0.03,0.71,0.13,0.29,0.49c-0.42,0.36-0.49,0.78-0.58,1.2c-0.06,0.27-0.06,0.65-0.13,0.89
				c0.41,0.04,1.02,0.07,1.24-0.04c0.35-0.17,0.56-0.17,0.99-0.04c0.26,0.08,0.6,0.24,0.84,0.37c0.03-0.09,0.07-0.15,0.16-0.16
				c0.29-0.03,0.65,0.55,0.84,0.23c0.2-0.32,0.65-0.26,0.88-0.13c0.23,0.13,0.58,0.19,0.84-0.07c0.26-0.26,1.27-0.39,1.49-0.29
				c0.23,0.1,0.81,0.36,0.58-0.42c-0.23-0.78,0.49-0.62,0.58-0.91c0.1-0.29,0.94-0.42,0.81-0.65c-0.13-0.23-1.2-0.91-1.53-1.14
				c-0.32-0.23-0.32-0.94-0.49-1.23c-0.16-0.29-0.52-0.68-0.1-0.78c0.42-0.1,1.17-0.26,1.53-0.58c0.36-0.32,1.01-0.36,1.2-0.55
				c0.19-0.19,0.65,0.16,0.78-0.03c0.13-0.19,0.23-0.58,0.03-0.81c-0.19-0.23-0.32-0.62-0.36-1.17c-0.03-0.55-0.19-0.52-0.19-0.78
				c0-0.26-0.1-0.58-0.32-0.71c-0.23-0.13-0.32-0.23-0.1-0.58c0.23-0.36,0.13-1.27,0.03-1.53c-0.02-0.05-0.02-0.11-0.01-0.18
				c-0.06-0.03-0.1-0.08-0.12-0.18c-0.07-0.35-0.3-0.48-0.86-0.48c-0.56,0,0.35-0.56,0.13-0.84c-0.22-0.28-0.78,0.52-1.3,0.35
				c-0.52-0.17-1.19,0.61-1.64,0.95c-0.45,0.35-1.1-0.06-0.76-0.56c0.35-0.5,0-0.45-0.52-0.26c-0.52,0.19-1.15-0.04-1.08-0.52
				c0.02-0.12-0.02-0.23-0.09-0.34c-0.25,0.03-0.45,0.04-0.54,0.01c-0.23-0.08-0.76-0.12-1.34-0.13c0.17,0.25,0.36,0.5,0.25,0.69
				c-0.19,0.37,0.11,0.71,0.39,1.25c0.28,0.54-0.61,0.24-0.63,0.58c-0.02,0.35-0.82-0.02-1.08-0.15c-0.26-0.13-1.13-0.04-1.1,0.17
				c0.01,0.09,0,0.2-0.04,0.31C258.17,80.81,258.22,81.19,258.09,81.38z"/>
		</g>
		<g className="mouseOver finlandCount">
			<path class="lightBlue" d="M290.85,62.56c0.35-0.78,1.9-1.38,2.16-2.08c0.13-0.35,0.17-0.52-0.39-1.17c-0.56-0.65-1.82-1.08-1.86-1.43
				c-0.04-0.35,0.95-0.39,0.91-0.82c-0.04-0.43-0.56-0.43-0.78-0.78c-0.22-0.35,0.26-0.56,0.22-0.74c-0.04-0.17-0.69-0.26-0.74-0.48
				c-0.04-0.22,0.43-0.17,0.35-0.43c-0.09-0.26-0.52-0.74-0.22-1.13c0.3-0.39,0.95,0,0.48-0.86c-0.48-0.87-1.21-1.95-1.38-2.21
				c-0.17-0.26,0.17-0.69,0.43-0.86c0.26-0.17,0.91-0.82,0.91-1.08c0-0.26-0.82-0.95-1.04-1.08c-0.22-0.13-0.69-0.09-0.91-0.52
				c-0.22-0.43-0.43-0.56-0.22-0.82c0.22-0.26,0.22-0.52,0.22-0.78c0-0.26,0.48-0.04,0.56-0.43c0.04-0.18,0.16-0.37,0.32-0.53
				c0-0.3,0.03-0.61,0-0.82c-0.07-0.42-1.25-0.56-1.53-1.04c-0.28-0.49-0.97-0.28-1.18,0c-0.21,0.28-1.46-0.07-1.46,0.35
				c0,0.42-0.9,0.49-0.9,0.97c0,0.49,0.42,1.32-0.28,1.25c-0.7-0.07-0.21,0.28-0.63,0.83c-0.42,0.56-0.42-0.35-0.83-0.21
				c-0.42,0.14-0.9-0.42-1.11-0.07c-0.21,0.35-0.9,0.07-1.6-0.07c-0.7-0.14-1.04-1.11-1.6-1.18c-0.37-0.05-0.76,0.25-0.97,0.71
				c0.54,0.35,0.89,0.84,1.6,1.16c0.76,0.35,2.16,0.76,2.09,1.25c-0.07,0.49-0.28,1.18,0.07,1.39c0.35,0.21-0.07,1.11,0.35,1.32
				c0.42,0.21,0.28,1.25-0.07,1.25c-0.35,0,0.07,0.56,0.28,0.76c0.09,0.1,0.17,0.38,0.21,0.72c0.43,0.1,0.81,0.32,1.18,0.48
				c0.58,0.26,0.5,0.67,0.52,1.12c0.02,0.45,0.04,0.71-0.26,0.52c-0.3-0.19-0.63,0-0.8,0.65c-0.17,0.65-0.99,1.43-1.49,1.47
				c-0.5,0.04-0.45,0.56-0.87,0.67c-0.41,0.11-0.8,0.35-0.78,0.8s-0.61,0.5-1.02,0.5c-0.41,0-0.32,0.74-0.65,0.93
				c-0.32,0.2-0.26,0.45,0.04,0.84c0.3,0.39,0.04,0.74,0.15,1.02c0.11,0.28,0.58,0.93,0.26,1.64c-0.32,0.71-0.67,1.6-0.39,1.52
				c0.28-0.09,0.93,0.58,1.36,0.54c0.43-0.04,0.48,0.6,0.89,0.48c0.41-0.13,0.41,0.39,1.6,0.35c1.19-0.04,3.59-1.04,4.54-1.04
				c0.54,0,0.97-0.06,1.31-0.15c0.2-0.31,0.45-0.64,0.7-0.89C289.2,63.77,290.5,63.34,290.85,62.56z"/>
		
		</g>
		<g className="mouseOver saudiACount">
			<path class="greenBlue" d="M315.88,123.92c0,0-2.25-0.29-2.6-0.29c-0.35,0-1.21-0.12-1.73-0.58c-0.52-0.46-3.06-2.37-3.52-2.6
				c-0.46-0.23-2.37-1.62-2.89-1.68c-0.52-0.06-0.58,0-1.21,0c-0.64,0-0.35,0.4-1.5,0.46c-1.16,0.06-1.79,0.81-1.44,0.92
				c0.35,0.12,1.44,1.1,1.1,1.27c-0.35,0.17-0.4,0.69-0.87,0.69c-0.46,0-0.92,0.06-0.92,0.41c0,0.35-0.63,0.92-1.16,0.87
				c-0.3-0.03-0.95-0.37-1.45-0.65l-0.15,0.42l-0.06,0.4c0.1,0.31-0.05,1.57,0.25,1.67c0.32,0.11,0.5,0.69,1.19,1.45
				c0.69,0.76,2.1,2.79,2.12,3.29c0.02,0.5,0.26,1.04,1.12,1.62c0.87,0.58,0.87,1.3,1.32,1.65c0.45,0.35,0.35,0.76,0.37,1.62
				c0.02,0.87,0.41,1.99,1.19,2.47c0.78,0.48,1.32,0.99,1.69,1.92c0.37,0.93,0.84,1.73,1.34,2.23c0.5,0.5,0.22,0.76,0.5,1.12
				c0.14,0.19,0.34,0.51,0.48,0.85c0.38-0.18,0.65-0.33,0.68-0.37c0.08-0.17-0.17-0.63-0.04-0.8c0.12-0.17,0.38-0.46,0.63-0.54
				c0.25-0.08,0.54,0.25,1.09,0.17c0.54-0.08,1.67,0.04,1.8,0.17c0.12,0.13,1.67,0.04,2.05,0.34c0.38,0.29,0.75,0.21,1-0.38
				c0.25-0.58,2.01-1.84,2.26-1.97c0.25-0.13,2.13,0,2.89-0.17c0.75-0.17,5.69-1.76,5.9-1.92c0.21-0.17,1-2.76,1-3.05
				c0-0.29-0.38-1.09-0.75-1.04c-0.38,0.04-3.47-0.29-3.68-0.42c-0.17-0.1-0.99-0.72-1.1-1.57c-0.24-0.19-0.52-0.59-0.61-0.94
				c-0.36,0.05-0.91-0.04-1.22-0.84c-0.29-0.2-1.05-1.28-0.78-1.58c0.32-0.37-0.17-1.15-0.65-1.58c-0.48-0.43-1.45-1.23-1.49-1.73
				c-0.01-0.14-0.09-0.36-0.18-0.62l-1.16-0.06L315.88,123.92z"/>
				<g className="hoverEf saudi">
			<g>
				<path d="M283.66,137.71c0.26,0.15,0.65,0.28,1.06,0.28c0.51,0,0.8-0.24,0.8-0.6c0-0.33-0.22-0.53-0.78-0.73
					c-0.72-0.26-1.19-0.64-1.19-1.27c0-0.72,0.6-1.26,1.55-1.26c0.48,0,0.82,0.1,1.05,0.22l-0.19,0.65
					c-0.16-0.08-0.46-0.21-0.88-0.21c-0.51,0-0.72,0.27-0.72,0.53c0,0.34,0.25,0.49,0.84,0.72c0.76,0.29,1.13,0.67,1.13,1.3
					c0,0.71-0.53,1.32-1.66,1.32c-0.46,0-0.94-0.13-1.18-0.28L283.66,137.71z"/>
				<path d="M288.8,138.58l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H288.8z M288.7,137.03c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V137.03z"/>
				<path d="M293.16,137.63c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V137.63z"/>
				<path d="M296.94,133.97v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
					c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01v-1.79H296.94z M296.14,136.74
					c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
					c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V136.74z"/>
				<path d="M298.65,134.53c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C298.47,134.1,298.65,134.29,298.65,134.53z M297.8,138.58v-3.16h0.8v3.16H297.8z"/>
				<path d="M300.37,137.34l-0.37,1.24h-0.83l1.41-4.38h1.02l1.42,4.38h-0.86l-0.4-1.24H300.37z M301.63,136.74l-0.35-1.08
					c-0.08-0.27-0.16-0.57-0.22-0.82h-0.01c-0.06,0.26-0.13,0.56-0.21,0.82l-0.34,1.08H301.63z"/>
				<path d="M303.6,136.44c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V136.44z"/>
				<path d="M307.69,138.58l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H307.69z M307.59,137.03c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V137.03z"/>
				<path d="M309.19,138.58c0.01-0.21,0.03-0.56,0.03-0.89v-3.73h0.8v1.89h0.01c0.19-0.31,0.54-0.51,1.01-0.51
					c0.77,0,1.33,0.64,1.32,1.6c0,1.14-0.72,1.7-1.44,1.7c-0.41,0-0.77-0.16-1-0.55h-0.01l-0.04,0.48H309.19z M310.01,137.27
					c0,0.07,0.01,0.13,0.02,0.19c0.08,0.32,0.36,0.56,0.71,0.56c0.5,0,0.8-0.4,0.8-1.04c0-0.56-0.27-1.01-0.8-1.01
					c-0.32,0-0.62,0.23-0.71,0.58c-0.01,0.06-0.03,0.13-0.03,0.21V137.27z"/>
				<path d="M313.86,134.53c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C313.69,134.1,313.86,134.29,313.86,134.53z M313.02,138.58v-3.16h0.8v3.16H313.02z"/>
				<path d="M316.46,138.58l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H316.46z M316.36,137.03c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V137.03z"/>
			</g>
		</g>
		</g>
		<g className="mouseOver omanCount">
			<path class="BlueDark" d="M328.63,130.37v1.11l-0.92,1.17l-0.49,1.23c0.14,0.01,0.25,0.01,0.3,0c0.38-0.04,0.75,0.75,0.75,1.04
				c0,0.29-0.79,2.89-1,3.05c-0.15,0.12-2.76,0.98-4.48,1.51c0.37,0.78,1.12,2.32,1.79,3.7c0.12-0.05,0.24-0.1,0.37-0.18
				c0.82-0.48,1.75-0.24,2.38-0.41c0.63-0.17,0.02-0.69,0.56-1.15c0.54-0.45,1.32-0.11,1.6-0.26c0.28-0.15,0.48-1.15,0.95-1.34
				c0.48-0.2,0.99-0.07,0.93-0.48c-0.06-0.41,0.04-1.88,0.5-1.97c0.45-0.09,1.17-0.56,1.19-0.82c0.02-0.26,0.93-1.23,1.21-1.71
				c0.28-0.48,0.3-0.74-0.11-0.8c-0.41-0.07-1.06-1.21-1.23-1.54c-0.17-0.32-1.43-0.11-2.44-0.91c-0.41-0.32-0.64-0.94-0.79-1.56
				l-0.51,0.29H328.63z"/>
				<g className="hoverEf oman">
			<g>
				<path d="M323.72,135.11c0,1.47-0.89,2.31-2.1,2.31c-1.24,0-2.02-0.95-2.02-2.23c0-1.34,0.85-2.3,2.09-2.3
					C322.96,132.89,323.72,133.86,323.72,135.11z M320.44,135.17c0,0.89,0.45,1.6,1.22,1.6c0.77,0,1.21-0.72,1.21-1.64
					c0-0.83-0.41-1.61-1.21-1.61C320.87,133.53,320.44,134.27,320.44,135.17z"/>
				<path d="M324.38,135.12c0-0.37-0.01-0.67-0.03-0.94h0.68l0.04,0.46h0.02c0.15-0.25,0.44-0.54,0.97-0.54
					c0.42,0,0.74,0.23,0.88,0.58h0.01c0.11-0.17,0.24-0.31,0.39-0.4c0.18-0.12,0.38-0.19,0.64-0.19c0.52,0,1.06,0.36,1.06,1.37v1.86
					h-0.78v-1.75c0-0.53-0.18-0.84-0.56-0.84c-0.27,0-0.47,0.19-0.56,0.42c-0.03,0.08-0.04,0.18-0.04,0.27v1.9h-0.78v-1.83
					c0-0.45-0.18-0.76-0.55-0.76c-0.3,0-0.5,0.23-0.58,0.45c-0.03,0.08-0.04,0.17-0.04,0.26v1.87h-0.78V135.12z"/>
				<path d="M331.67,137.34l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H331.67z M331.56,135.79c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V135.79z"/>
				<path d="M333.19,135.12c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V135.12z"/>
			</g>
		</g>
		</g>
		<g className="mouseOver qatarCount">
			<path class="st0" d="M322.12,130.96c-0.04-0.15-0.03-0.3,0.03-0.41c0.28-0.48,0.45-1.71-0.17-1.88
				c-0.63-0.17-0.97,1.08-0.97,1.43c0,0.06-0.04,0.06-0.11,0.02C321.21,130.93,321.76,131.01,322.12,130.96z"/>
				<g className="hoverEf qatar">
			<g>
				<path d="M310.54,134.02c-0.61-0.17-1.16-0.35-1.71-0.56c-0.1-0.04-0.19-0.06-0.29-0.07c-1.01-0.05-1.92-0.8-1.92-2.22
					c0-1.35,0.84-2.3,2.1-2.3c1.27,0,2.02,0.97,2.02,2.21c0,1.05-0.49,1.76-1.13,2.02v0.03c0.38,0.1,0.8,0.2,1.16,0.26
					L310.54,134.02z M309.9,131.12c0-0.83-0.41-1.61-1.2-1.61c-0.8,0-1.23,0.76-1.23,1.64c0,0.88,0.45,1.6,1.21,1.6
					C309.46,132.75,309.9,132.04,309.9,131.12z"/>
				<path d="M313.18,133.32l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H313.18z M313.08,131.77c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V131.77z"/>
				<path d="M315.62,129.25v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L315.62,129.25z"/>
				<path d="M318.77,133.32l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H318.77z M318.66,131.77c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V131.77z"/>
				<path d="M320.29,131.18c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V131.18z"/>
			</g>
		</g>
	    </g>
		
		
      <path class="st0" d="M413.3,162.25c0.52,0,0.61-0.43,0.95-0.35c0.19,0.05,0.12-0.33-0.01-0.85c-0.31,0.1-0.72,0.1-0.72,0.33
				c0,0.38-0.66-0.14-1.01-0.17C412.82,161.8,413.12,162.25,413.3,162.25z"/>
			<path class="st0" d="M315.58,122.52c0.19-0.2,0.22-0.47,0.67-0.54c0.36-0.05,0.58,0.18,0.97,0.54c0.15-0.15,0.4-0.27,0.64-0.34
				c-0.39-0.41-0.86-0.87-0.83-1.15c0.06-0.46-0.46-0.69-0.52-0.98c-0.06-0.29,0.35-0.75,0.12-0.98c-0.23-0.23-0.29-0.81-0.69-0.87
				c-0.41-0.06-1.68-0.69-1.62-1.1c0.06-0.4-0.46-0.69-0.69-0.81c-0.23-0.12-0.46-1.16-0.17-1.33c0.29-0.17,0.23-1.16,0.58-1.16
				c0.35,0,0.06-0.52,0.17-0.87c0.12-0.35-0.29-0.52-0.58-0.52c-0.29,0-0.81-0.46-0.81-0.87c0-0.4-0.58-0.98-0.52-1.15
				c-0.1,0.76-1.2-0.42-1.55-0.24c-0.35,0.17-0.61-0.35-1.3-0.26c-0.44,0.06-0.43,0.29-0.65,0.5l0.03,0.01l-0.87,0.64
				c0,0-0.75,0.23-0.92,0.69s0.35,0.93,0.12,1.21c-0.23,0.29-0.17,0.92-0.12,1.16c0.06,0.23-0.35,0.87-0.35,0.87
				s-1.72,0.92-3.15,1.66c0.03,0.27,0.06,0.53,0.12,0.72c0.09,0.32,0.23,0.93,0.34,1.44c0.56,0,0.64-0.05,1.13,0
				c0.52,0.06,2.43,1.44,2.89,1.68c0.46,0.23,3,2.14,3.52,2.6c0.52,0.46,1.39,0.58,1.73,0.58c0.2,0,1.03,0.1,1.7,0.18
				C315.18,123.31,315.47,122.64,315.58,122.52z"/>
		<g className="mouseOver kuwaitCount">
			<path class="st0" d="M315.88,123.92l0.75,0.64l1.16,0.06c-0.26-0.68-0.7-1.61-0.7-1.85c0-0.08,0.05-0.17,0.13-0.24
				c-0.39-0.36-0.61-0.6-0.97-0.54c-0.45,0.07-0.48,0.34-0.67,0.54c-0.12,0.12-0.41,0.8-0.61,1.29
				C315.47,123.87,315.88,123.92,315.88,123.92z"/>
		</g>
		<g className="mouseOver franceCount">
			<path class="lightBlue" d="M259.04,89.15c-0.19,0.03-0.36-0.26-0.78-0.26c-0.42,0-0.75-0.19-0.94-0.45c-0.19-0.26-1.2-0.32-1.53-0.26
				c-0.32,0.07-0.42-0.55-0.75-0.49c-0.32,0.07-0.42-0.42-0.42-0.68c0-0.26-0.36,0.19-0.65,0.32c-0.29,0.13-0.39-0.29-0.42-0.49
				c-0.03-0.19-0.62-0.39-0.91-0.55c-0.29-0.16-0.62-0.68-0.75-0.45c-0.13,0.23-0.49-0.32-0.52-0.55c-0.02-0.15-0.12-0.1-0.19-0.19
				c-0.11,0.02-0.22,0.04-0.33,0.03c-0.52-0.02-0.97,0.39-0.89,1.26c0.09,0.86-0.63,0.95-1.52,1.02c-0.89,0.07-0.58,0.76-0.95,1.04
				c-0.37,0.28-1.67-0.15-1.84-0.63c-0.17-0.48-1.34,0-0.84,0.56c0.5,0.56,0.48,1.34,0.19,1.56c-0.28,0.22-0.89-0.35-1.28-0.07
				c-0.39,0.28-0.41-0.26-0.89-0.43c-0.48-0.17-0.67,0.22-1.43,0.15c-0.76-0.07-1.19,0.37-0.91,0.69c0.28,0.32-0.04,0.63,0.17,0.91
				c0.22,0.28,1.21,0.24,1.95,0.63c0.74,0.39,0.91,0.06,1.28,0.35c0.37,0.28,0.45,0.11,0.48,0.65c0.02,0.54,0.43,1.06,1.15,1.25
				c0.71,0.19,0.17,0.67,0.39,1.15c0.22,0.48-0.32,1.17-0.22,1.75c0.11,0.58-0.35,2.47-0.69,2.64c-0.02,0.01-0.06,0.01-0.09,0.02
				c0.32,0.16,0.67,0.3,0.83,0.41c0.32,0.21,1,0.42,1.42,0.68c0.42,0.26,1.16,0.21,1.16-0.11c0-0.31,0.42-0.1,0.68,0.16
				c0.26,0.26,1.26,0.32,1.74,0.42c0.4,0.09,0.98,0.03,1.5,0.04c-0.02-0.12-0.05-0.24-0.09-0.38c-0.26-0.84,0.5-1.34,1.21-1.45
				c0.71-0.11,2.27,0.32,2.68,0.58c0.41,0.26,0.97,0.19,1.69-0.56c0.36-0.38,0.66-0.52,0.92-0.6c0.02-0.17,0.11-0.34,0.16-0.5
				c0.1-0.26-0.36-0.2-0.68-0.23c-0.32-0.03-0.55-0.36-0.32-0.71c0.23-0.36-0.19-0.39-0.42-0.75c-0.23-0.36,0.1-0.26,0.29-0.36
				c0.19-0.1,0.45-0.49,0.29-0.58c-0.16-0.1-0.29-0.32-0.16-0.71c0.13-0.39-0.26-0.42-0.26-0.71c0-0.29-0.36-0.42-0.65-0.06
				c-0.29,0.36-0.39,0.1-0.2-0.49c0.2-0.58,0.88-0.84,0.94-1.17c0.07-0.32,0.45-0.62,0.75-0.62c0.29,0,0.23-0.68,0.32-1.1
				c0.1-0.42,0.16-0.84,0.58-1.2C259.76,89.28,259.24,89.12,259.04,89.15z M261.36,100.16c-0.24-0.02-0.3,0.41-0.91,0.78
				c-0.61,0.37,0,2.03,0.61,1.95C261.68,102.8,261.6,100.18,261.36,100.16z"/>
		<g className="hoverEf france">
			<g>
				<path d="M234.32,92.69h2.53v0.66h-1.73v1.21h1.61v0.65h-1.61v1.86h-0.8V92.69z"/>
				<path d="M237.6,94.92c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V94.92z"/>
				<path d="M241.7,97.07l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H241.7z M241.59,95.52c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V95.52z"/>
				<path d="M243.22,94.85c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V94.85z"/>
				<path d="M249.26,96.98c-0.17,0.08-0.5,0.16-0.89,0.16c-0.99,0-1.63-0.64-1.63-1.62c0-0.95,0.65-1.68,1.76-1.68
					c0.3,0,0.59,0.06,0.77,0.14l-0.14,0.6c-0.13-0.06-0.32-0.12-0.61-0.12c-0.61,0-0.97,0.45-0.97,1.03c0,0.65,0.43,1.02,0.97,1.02
					c0.27,0,0.47-0.06,0.63-0.12L249.26,96.98z"/>
				<path d="M250.42,95.71c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H250.42z
					 M251.81,95.15c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H251.81z"/>
			</g>
		</g>
		</g>
			<path class="st0" d="M117.88,147.22c-0.25-0.34-0.91,0.06-1.6-0.41c-0.18-0.12-0.4-0.23-0.64-0.31
				c-0.02,0.01-0.03,0.02-0.05,0.03c-0.15,0.04-0.6,0.45-1.04,0.87c0.5,0.39,2.34,0.97,2.84,0.97c0.24,0,0.36-0.11,0.47-0.19
				C117.94,147.72,117.96,147.34,117.88,147.22z"/>
			<path class="st0" d="M119.41,148.2c0.06-0.13-0.38-0.85,0.47-0.91c0.85-0.06,1.47-0.16,1.6-0.69c0.13-0.53,1.13-0.28,1.41-0.31
				c0.23-0.03,0.98-0.71,1.71-0.69c0.01-0.71-0.96-0.48-1.63-1.15c-0.71-0.71-2.27-0.39-3.12-0.06c-0.84,0.32-1.82-0.36-2.73,0.16
				c-0.01,0.01-0.02,0.01-0.03,0.01c-0.2,0.25-0.47,0.56-0.79,0.82c-0.61,0.5-0.44,0.99-0.67,1.13c0.23,0.09,0.46,0.19,0.64,0.31
				c0.69,0.47,1.35,0.06,1.6,0.41c0.08,0.12,0.06,0.5-0.01,0.95c0.13-0.1,0.22-0.18,0.44,0c0.19,0.16,0.16,0.36,0.13,0.55l0.54-0.13
				C118.97,148.6,119.35,148.32,119.41,148.2z"/>
		<g className="mouseOver mexicoCount">
			<path class="st0" d="M112.05,144.06c0.16-0.03,1.79,0.19,1.94-0.22c0.16-0.41-1.22-1.31-1.07-1.41c0.16-0.09,0.22-1.1,0.47-1.1
				c0.25,0,2.54,0.06,2.66,0c0.13-0.06,0.56-0.88,0.94-0.91c0.17-0.01,0.44,0.2,0.68,0.43c0.42-0.39,0.57-1.27,0.65-1.99
				c0.1-0.88-0.2-1.17,0.26-1.65c0.45-0.49,0.94-0.52,0.81-1.2c-0.13-0.68-0.75,0-1.27-0.32c-0.52-0.32-0.97-0.1-2.3,0.1
				c-1.33,0.19-1.95,0.75-1.88,1.69c0.06,0.94-0.45,0.52-0.42,1.33c0.03,0.81-1.04,0.84-0.97,1.33c0.07,0.49-0.84,0-1.1-0.16
				c-0.26-0.16-3.05,0.68-3.44,0.75c-0.39,0.06-1.1-0.81-1.66-0.78c-0.55,0.03-0.78-0.81-0.84-1.49c-0.06-0.68-1.1-1.3-1.56-1.91
				c-0.45-0.62-0.52-1.98-0.55-2.86c-0.03-0.88-0.13-2.37,0.49-4.09c0.08-0.23,0.12-0.43,0.13-0.61c-0.22-0.14-0.44-0.24-0.61-0.23
				c-0.55,0.03-1.8-0.73-2.05-0.73c-0.24,0-0.21-0.59-0.42-0.9c-0.21-0.31-0.07-0.9-0.35-1.11c-0.28-0.21-1.11-1-1.39-1.98
				c-0.28-0.97-1.98-1.46-2.6-1.49c-0.62-0.04-0.97,1.28-1.14,1.35c-0.17,0.07-1.35-0.69-1.7-0.87c-0.35-0.17-0.52-0.52-0.52-0.94
				c0-0.42-0.73-1.08-0.94-1.14c-0.21-0.07-1.66-1.49-1.66-1.49h-2.29l-0.49,0.62l-4.16,0.03c0,0-3.19-1.18-3.78-1.32
				c-0.59-0.14-1.63-0.87-1.63-0.87l-3.37,0.44c0.17,0.37,0.41,0.77,0.77,1.22c0.68,0.84,1.17,2.34,1.23,2.86
				c0.06,0.52,1.01,0.94,1.82,1.46c0.81,0.52,0.81,1.65,0.71,1.95c-0.1,0.29-1.1-0.19-1.17,0.1c-0.06,0.29,1.3,1.43,1.82,1.36
				c0.52-0.07,0.78,0.26,1.59,0.97c0.81,0.71,0.75,1.56,0.42,1.82c-0.32,0.26,0.65,0.81,1.69,1.49c1.04,0.68,1.46,1.53,1.56,1.88
				c0.1,0.36,0.55,0.03,0.71-0.45c0.16-0.49-0.32-0.62-0.32-1.17c0-0.55-0.84-0.45-1.23-0.52c-0.39-0.07,0.03-0.91-0.36-1.3
				c-0.39-0.39-0.75-1.36-0.94-2.08c-0.19-0.71-1.2-1.43-1.62-2.21c-0.42-0.78-0.88-1.4-1.17-1.59c-0.29-0.19,0.42-0.49,0.16-0.78
				c-0.26-0.29-0.65-0.26-1.14-0.49c-0.49-0.23-0.81-0.62-0.81-1.14c0-0.52-0.32-1.75-0.49-2.17c-0.16-0.42,0.36-0.36,0.52-0.16
				c0.16,0.19,0.29,0.39,0.52,0.26c0.23-0.13,0.62-0.03,0.78,0.32c0.16,0.36,0.71,0.03,0.97,0.19c0.26,0.16-0.58,0.36,0.26,1.85
				c0.84,1.49,0.26,0.65,0.26,1.65c0,1.01,0.52,0.55,0.68,0.39c0.16-0.16,0.62,0.32,1.04,0.84c0.42,0.52,1.23,0.55,1.23,0.84
				c0,0.29,0.16,0.88,0.71,0.97c0.55,0.1,0.65,0.71,1.01,0.78c0.36,0.06,0.42,0.45,0.23,0.88c-0.19,0.42,0,0.78,0.91,1.14
				c0.91,0.36,0.49,0.42,1.2,1.1c0.71,0.68,2.73,2.89,3.15,3.5c0.42,0.62,0.55,1.14,0.71,1.59c0.16,0.45-0.32,0.68-0.06,1.07
				c0.26,0.39-0.49,0.23-0.45,0.52c0.03,0.29,0.45,1.75,1.17,1.82c0.71,0.06,1.53,0.94,2.11,1.43c0.58,0.49,1.46,0.39,2.34,0.71
				c0.88,0.32,1.62,1.1,2.89,1.46c1.27,0.36,2.5,0.91,3.31,1.43c0.81,0.52,1.82,0.29,2.95-0.16c1.14-0.45,2.01,0.13,2.66,0.39
				c0.32,0.13,0.95,0.67,1.57,1.23C111.43,144.82,111.98,144.07,112.05,144.06z"/>
			<g className="hoverEf mexico">
				<g>
					<path d="M89.92,137.11c-0.03-0.57-0.06-1.25-0.05-1.84h-0.02c-0.14,0.54-0.33,1.13-0.52,1.68l-0.64,1.9h-0.62l-0.58-1.87
						c-0.17-0.56-0.33-1.16-0.44-1.71h-0.01c-0.02,0.57-0.04,1.27-0.08,1.87l-0.1,1.76h-0.75l0.29-4.38h1.05l0.57,1.76
						c0.16,0.52,0.3,1.04,0.4,1.53h0.02c0.12-0.48,0.27-1.01,0.44-1.53l0.61-1.76h1.04l0.25,4.38H90L89.92,137.11z"/>
					<path d="M92.13,137.54c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
						c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H92.13z
						M93.52,136.98c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H93.52z"/>
					<path d="M95.45,135.74l0.38,0.6c0.1,0.17,0.2,0.33,0.29,0.49h0.02c0.09-0.18,0.18-0.34,0.28-0.5l0.35-0.58h0.87l-1.06,1.52
						l1.08,1.65h-0.91l-0.38-0.63c-0.1-0.17-0.19-0.34-0.29-0.51h-0.02c-0.09,0.18-0.18,0.34-0.28,0.51l-0.37,0.63h-0.88l1.1-1.61
						l-1.06-1.55H95.45z"/>
					<path d="M98.98,134.85c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
						C98.8,134.42,98.98,134.6,98.98,134.85z M98.13,138.9v-3.16h0.8v3.16H98.13z"/>
					<path d="M102.12,138.8c-0.17,0.08-0.5,0.16-0.89,0.16c-0.99,0-1.63-0.64-1.63-1.62c0-0.95,0.65-1.68,1.76-1.68
						c0.3,0,0.59,0.06,0.77,0.14l-0.14,0.6c-0.13-0.06-0.32-0.12-0.61-0.12c-0.61,0-0.97,0.45-0.97,1.03c0,0.65,0.43,1.02,0.97,1.02
						c0.27,0,0.47-0.06,0.63-0.12L102.12,138.8z"/>
					<path d="M105.72,137.29c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
						C105.09,135.66,105.72,136.33,105.72,137.29z M103.33,137.32c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
						c0-0.5-0.22-1.06-0.77-1.06C103.56,136.25,103.33,136.79,103.33,137.32z"/>
				</g>
			</g>
		</g>
			<path class="st0" d="M116.31,145.38c0.32-0.26,0.59-0.57,0.79-0.82c-0.51,0.28-0.66,0.01-0.59-0.35l-0.73,0.1l0.08-2.96
				c-0.59,0.02-2.25-0.02-2.46-0.02c-0.25,0-0.31,1-0.47,1.1c-0.16,0.09,1.22,1,1.07,1.41c-0.16,0.41-1.79,0.19-1.94,0.22
				c-0.07,0.02-0.62,0.76-1.2,1.58c0.65,0.57,1.29,1.17,1.61,1.37c0.62,0.39,1.62-0.03,2.04,0.36c0.01,0.01,0.03,0.02,0.05,0.04
				c0.44-0.42,0.89-0.83,1.04-0.87C115.91,146.44,115.65,145.91,116.31,145.38z"/>
			<path class="st0" d="M116.06,141.33c-0.02,0.01-0.1,0.02-0.21,0.02l-0.08,2.96l0.73-0.1c0.05-0.25,0.22-0.55,0.46-0.74
				c0.58-0.45-0.29-2.27,0.39-2.43c0.13-0.03,0.23-0.1,0.32-0.19c-0.24-0.23-0.51-0.44-0.68-0.42
				C116.63,140.46,116.19,141.27,116.06,141.33z"/>
			<path class="st0" d="M139.3,137.96c-0.65,0.45,0.97,1.43,0.97,2.01c0,0.58-2.6-0.11-2.86,0.52c-0.13,0.32,0.91,0.78,1.88,0.45
				c0.91-0.3,1.53,0.01,2.03,0.27c0.07-0.98,0.19-2.09,0.29-2.84C140.79,138.15,139.77,137.63,139.3,137.96z"/>
			<path class="st0" d="M143.26,140.62c1.17-0.39,3.05,0.32,3.05-0.58c0-0.91-3.12-1.88-3.89-1.62c-0.21,0.07-0.49,0.03-0.8-0.05
				c-0.1,0.75-0.21,1.86-0.29,2.84c0.04,0.02,0.08,0.04,0.11,0.06C141.96,141.53,142.09,141.01,143.26,140.62z"/>
			<path class="st0" d="M121.75,151.64c0.32,0.16,1.13,0,1.45,0.24c0.16,0.12,0.42,0.12,0.72,0.08c-0.09-0.14-0.17-0.25-0.2-0.31
				c-0.23-0.42,0.16-1.27,0.36-1.88c0.19-0.62-0.16-2.11,0.19-2.5c0.36-0.39,0.16-0.62,0.32-1.53c0.01-0.06,0-0.1,0-0.15
				c-0.73-0.03-1.48,0.66-1.71,0.69c-0.28,0.03-1.28-0.22-1.41,0.31c-0.13,0.53-0.75,0.63-1.6,0.69c-0.85,0.06-0.41,0.78-0.47,0.91
				c-0.06,0.13-0.44,0.41-0.44,0.41l-0.54,0.13c-0.03,0.2-0.04,0.4,0.23,0.55c0.52,0.29,1.23,1.43,1.78,1.72
				c0.27,0.14,0.43,0.37,0.5,0.59C121.26,151.57,121.58,151.56,121.75,151.64z"/>
		<g className="mouseOver brazilCountry">
			<path class="lightBlue " d="M169.09,219.15c0.91-0.65,0.68-1.04,1.1-1.82c0.42-0.78,0.07-1.1,0.78-1.79c0.71-0.68-0.13-1.14,0.78-1.56
				c0.91-0.42,0.78,0.39,0.03,1.33c-0.75,0.94-0.88,1.2,0.1,0.49c0.97-0.71,1.33-1.72,1.88-2.86c0.55-1.14,0.97-1.49,1.46-1.56
				c0.49-0.06,0.49-1.27,0.42-2.11c-0.07-0.84-0.32-1.62,0.03-2.17c0.36-0.55,0-1.07,0.45-1.07c0.45,0,1.14-0.68,1.85-1.4
				c0.71-0.71,1.17-0.78,1.92-0.84c0.75-0.06,0.32-0.32,0.81-0.45c0.49-0.13,0.81-0.58,1.14-0.88c0.32-0.29,1.65,0.03,2.43,0.03
				c0.78,0,1.3,0.07,1.3-0.42c0-0.49,0.36-0.88,0.81-0.88c0.45,0,0.65-0.13,0.65-0.78c0-0.65,0.19-1.04,0.58-1.33
				c0.39-0.29,0.45-0.94,0.58-1.36c0.13-0.42,0.55-0.16,0.55-0.78c0-0.62,0.23-1.3,0.23-1.78c0-0.49,0.52-0.42,0.75-0.78
				s-0.19-0.81,0.16-1.82c0.36-1.01,0-1.95,0-2.86c0-0.91,0-1.85,0.32-2.14c0.32-0.29,0.16-0.45-0.1-0.58
				c-0.26-0.13,0.03-0.49,0.26-0.42c0.23,0.06,0.49,0.55,0.91,0.29c0.42-0.26,0.81-1.36,1.17-2.27c0.36-0.91,0.94-0.88,1.4-1.07
				c0.45-0.19,1.43-1.3,2.04-2.89c0.62-1.59-0.03-3.15-0.29-4.25c-0.26-1.1-0.62-0.88-1.27-0.84c-0.65,0.03-1.88-0.13-3.51-1.88
				c-1.62-1.75-3.21-1.62-4.28-1.33c-1.07,0.29-2.17-0.52-2.89-0.68c-0.71-0.16-1.4,0.75-1.91,0.94c-0.52,0.19,0.26-0.78,0.29-1.4
				c0.03-0.62-1.95-1.49-3.44-2.08c-1.49-0.59-2.14-0.29-2.14,0.65s-0.58-0.29-1.04,0.71c-0.45,1.01-1.3,0.36-0.75,0.03
				c0.55-0.32,1.07-1.07,1.27-1.69c0.19-0.62-2.14-1.1-2.95-0.78c-0.81,0.32-0.29,1.49-0.94,1.14c-0.65-0.36-0.13-1.23,0.39-1.2
				c0.52,0.03,1.1-1.04,1.46-1.82c0.36-0.78-0.62-0.91-1.2-1.56c-0.58-0.64-0.46-2.51-1.05-2.81c-0.64,0.63-1.49,1.61-1.73,2.33
				c-0.31,0.92-0.7,0.4-1.54,0.66c-0.83,0.26-1.23-0.09-1.45-0.44c-0.22-0.35-1.05,0-1.63-0.04c-0.57-0.04-0.22,0.7-0.61,0.92
				c-0.4,0.22-1.41-0.44-1.76-0.04c-0.35,0.4-0.57,0-0.75,0.35c-0.18,0.35-0.79,0.13-0.97,0.35c-0.18,0.22-0.79,0.35-1.58-0.53
				c-0.79-0.88-0.66-2.33-0.31-2.59c0.35-0.26,0.35-1.01-0.13-1.23c-0.48-0.22,0-1.14-0.61-1.23c-0.62-0.09-0.53,0.7-0.92,0.83
				c-0.4,0.13-1.36,0.79-1.8,0.7c-0.44-0.09-1.01,0.09-1.01,0.57c0,0.48-0.48,0.35-0.57-0.04c-0.09-0.39-1.01-0.18-1.27-0.44
				c-0.26-0.26-1.49-0.13-0.97,0.18c0.53,0.31,0.57,0.62,0.57,1.1c0,0.48,0.4,0.35,0.4,0.92c0,0.57,0.44,0.18,0.75,0.18
				c0.31,0,0.31,0.53-0.18,0.62c-0.48,0.09-0.7,0.35-0.83,0.79c-0.13,0.44-0.57,0.13-0.97,0.48c-0.4,0.35-0.75,0.7-1.01,0.53
				c-0.26-0.17-0.48-0.04-0.79,0.13c-0.31,0.18-0.44,0-0.88-0.35c-0.33-0.26-0.54-0.9-1.12-1.48c-0.35,0.16-1.05,0.42-1.43,0.42
				c-0.51,0-2.12-0.12-2.21,0.16c-0.09,0.28,0.12,0.75,0.38,0.8c0.26,0.06,0.82,0.22,0.29,0.6c-0.53,0.38-1-0.13-1,0.79
				c0,0.92,1.18,1.52,0.94,2.37c-0.13,0.44-0.3,1.56-0.3,2c0,0.43-0.17,1.9-0.56,1.99c-0.39,0.09-0.74-0.34-1.26,0.09
				c-0.52,0.43-1.17,0.17-1.77,0.65c-0.61,0.48-1.25,0.3-1.3,0.86c-0.04,0.56-0.61,0.87-0.48,1.38c0.13,0.52-0.26,0.39-0.65,0.78
				c-0.39,0.39,0.04,0.65-0.22,0.95c-0.26,0.3,0.09,0.78,0.39,1.25c0.3,0.48,0.65,0.82,0.61,1.26c-0.04,0.43,0.3,0.61,0.74,0.56
				c0.43-0.04,0.09,0.82,0.78,0.82c0.69,0,1.38,0.17,1.56-0.22c0.17-0.39,0.65-0.69,0.65-0.13c0,0.56-0.13,2.08,0.35,1.9
				c0.48-0.17,1.43-0.13,1.9,0.04c0.48,0.17,0.95,0,1.25-0.3c0.3-0.3,0.65-0.3,1.25-0.65c0.61-0.35,1.17-0.78,1.73-0.78
				c0.56,0,1.51-0.35,1.38,0.09c-0.13,0.43-0.22,2.03,0.48,2.86c0.69,0.82,1.08,1.13,1.73,1.04c0.65-0.09,0.56,0.26,1.04,0.22
				c0.48-0.04,0.17,0.52,0.61,0.52c0.43,0,1.21,0.09,1.21,0.56c0,0.48,1.3,0.09,1.6,0.35c0.3,0.26,0.65,0.48,0.56,0.99
				c-0.09,0.52,0.65,0.91,0.22,1.13c-0.43,0.22,0.13,0.65,0.17,1.21c0.04,0.56,0.65,0.78,1.6,0.73c0.95-0.04,1.21,0.04,1.21,0.74
				c0,0.69,0.65,0.78,0.87,1.43c0.22,0.65-0.26,1.38-0.17,1.9c0.07,0.4-0.17,0.82-0.45,1c0.33,0.42,0.36,0.62,0.06,0.78
				c-0.39,0.22,0.48,0.83,0.26,1.8c-0.22,0.97-0.26,1.14,0.75,1.19c1.01,0.04,0.92,0.35,1.27,0.13c0.35-0.22,0.88,0.75,1.01,1.23
				c0.13,0.48,0.53,1.62,0.7,1.62c0.18,0,0.7-0.53,1.23-0.26c0.49,0.25-0.12,1.9-0.14,2.62c0.37-0.01,0.81-0.01,0.96,0.06
				c0.26,0.13,0.39,1.91,0.26,2.24c-0.13,0.33-1.22,0.62-1.78,0.99c-0.56,0.36-1.91,1.64-2.53,2.66c-0.37,0.6-0.91,1.1-1.29,1.54
				c0.18,0.03,0.33,0.02,0.44-0.03c0.3-0.13,0.76-0.13,1.25,0.46c0.49,0.59,0.46,1.05,0.66,1.02c0.2-0.03,0.43-0.79,0.82-0.23
				c0.39,0.56,1.41,0.99,1.64,1.25c0.23,0.26,0.99,0.46,0.99,1.15c0,0.49-0.23,1.43,0.15,2.22
				C168.59,219.59,168.8,219.35,169.09,219.15z"/>
			<g className="hoverEf brazil">
			<g>
					<path d="M166.82,180.95c0.25-0.05,0.72-0.09,1.17-0.09c0.59,0,0.96,0.08,1.25,0.28c0.27,0.17,0.45,0.45,0.45,0.82
						c0,0.4-0.25,0.77-0.73,0.94v0.02c0.46,0.12,0.89,0.48,0.89,1.08c0,0.39-0.17,0.69-0.42,0.9c-0.31,0.27-0.83,0.4-1.63,0.4
						c-0.45,0-0.78-0.03-0.98-0.06V180.95z M167.61,182.68h0.41c0.55,0,0.86-0.26,0.86-0.63c0-0.41-0.31-0.6-0.82-0.6
						c-0.24,0-0.37,0.02-0.46,0.04V182.68z M167.61,184.69c0.1,0.02,0.24,0.02,0.42,0.02c0.51,0,0.97-0.19,0.97-0.73
						c0-0.51-0.45-0.72-1-0.72h-0.4V184.69z"/>
					<path d="M170.54,183.12c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
						v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V183.12z"/>
					<path d="M174.63,185.27l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H174.63z M174.52,183.72c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V183.72z"/>
					<path d="M175.85,184.81l1.19-1.52c0.16-0.2,0.3-0.35,0.46-0.53v-0.01h-1.54v-0.64h2.51l0,0.49l-1.16,1.49
						c-0.16,0.19-0.3,0.37-0.47,0.54v0.01h1.67v0.64h-2.67V184.81z"/>
					<path d="M179.93,181.22c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
						C179.75,180.79,179.92,180.97,179.93,181.22z M179.08,185.27v-3.16h0.8v3.16H179.08z"/>
					<path d="M180.74,180.66h0.8v4.62h-0.8V180.66z"/>
				</g>
			</g>
		</g>
			<path class="st0" d="M168.25,217.61c0-0.69-0.76-0.89-0.99-1.15c-0.23-0.26-1.25-0.69-1.64-1.25c-0.39-0.56-0.62,0.2-0.82,0.23
				c-0.2,0.03-0.16-0.43-0.66-1.02c-0.49-0.59-0.95-0.59-1.25-0.46c-0.1,0.04-0.26,0.05-0.44,0.03c-0.26,0.3-0.45,0.57-0.45,0.83
				c0,0.62-0.46,1.31-0.49,2.5c-0.03,1.18-0.36,0.99-0.39,1.91c-0.03,0.92,0.3,0.76,0.43,0.89c0.07,0.07-0.02,0.37-0.11,0.61
				c0.14,0.12,0.28,0.31,0.58,0.28c0.58-0.06,1.17,0.16,1.88,0.49c0.71,0.32,0.45-0.1,1.14,0c0.68,0.1,1.46,0.16,2.21-0.29
				c0.51-0.31,0.77-0.85,1.17-1.37C168.02,219.03,168.25,218.1,168.25,217.61z"/>
			<path class="st0" d="M149.81,257.8c-0.81-0.03-2.4-1.53-2.89-1.91c-0.49-0.39-0.13-0.78-0.71-0.68c-0.58,0.1-0.19-0.45,0.13-0.62
				c0.3-0.15-0.12-0.7-0.51-0.79c-0.04,0.87-0.22,4.78,0.01,4.82c0.24,0.04,1.96-0.2,2.47,0.13c0.2,0.06,0.46,0.22,0.67,0
				c0.32-0.36,1.3-0.16,1.82-0.49C151.3,257.93,150.62,257.83,149.81,257.8z M167.79,206.56c-0.15-0.07-0.6-0.08-0.96-0.06
				c0,0.05,0,0.11,0.01,0.15c0.09,0.61-0.31,2.2-0.75,2.15c-0.44-0.04-0.79,0.66-1.14,0.57c-0.35-0.09-0.84,0.53-1.27,0.26
				c-0.44-0.26-1.05,0.09-1.41-0.13c-0.35-0.22-1.36-0.04-1.36-0.31c0-0.26,0.48-0.18,0.48-0.97c0-0.79,1.32-1.67,1.14-1.98
				c-0.18-0.31-2.42-0.97-2.72-1.36c-0.31-0.4-0.75-0.75-1.63-0.83c-0.88-0.09-0.83-0.53-1.63-1.01c-0.66-0.4-1.69-1.48-2.03-2.08
				c-0.54-0.06-1.29-0.17-1.45,0.01c-0.22,0.26-0.56,1.47-0.87,0.87c-0.3-0.6-1.04-0.6-1.69-0.69c-0.65-0.09-0.61-0.69-1.3,0.04
				c-0.31,0.33-0.71,0.65-1.07,0.89c-0.04,0.7-0.17,1.49-0.3,1.71c-0.18,0.31-1.63,0.97-1.67,1.32c-0.04,0.35,0.18,0.83-0.09,1.05
				c-0.26,0.22,0.61,1.32,0.13,1.58c-0.48,0.26,0.35,0.7,0.04,0.97c-0.31,0.26-0.88,0.4-0.88,0.7c0,0.31-0.04,1.05-0.44,1.23
				c-0.4,0.18-0.66,0.83-0.66,1.32c0,0.48-0.62,0.18-0.39,0.79c0.22,0.62,0.48,1.36,0,1.49c-0.48,0.13-0.35,1.14-0.62,1.14
				c-0.26,0-0.4,1.05,0,1.58c0.4,0.53,0.48,0.97,0.48,1.45c0,0.48,0.53,0.75,0.35,1.27c-0.18,0.53-0.09,1.01-0.4,1.23
				c-0.31,0.22-0.04,0.88-0.44,1.05c-0.39,0.18,0.31,1.58,0,1.84c-0.31,0.26-1.1,0.48-1.1,1.45c0,0.97,0.15,1.51,0.04,1.93
				c-0.09,0.35,0.44,0.26,0.35,0.83c-0.09,0.57-0.79,0.22-0.83,0.57c-0.04,0.35,0.18,1.06-0.09,1.1c-0.26,0.04-0.4,0.31-0.4,0.92
				c0,0.61-0.35,0.7-0.39,1.01c-0.04,0.31,0.31,0.66,0.31,1.01c0,0.35,0.22,1.54-0.09,1.58c-0.31,0.04-0.48,1.36-0.22,1.54
				c0.26,0.17,0.26,0.53,0.13,0.79c-0.13,0.26,0.61,0.53,0.22,0.92c-0.4,0.4-0.08,0.9,0.44,0.75c0.44-0.13,0.7,0.66,0.22,0.66
				c-0.48,0-0.97,0-0.57,0.31c0.4,0.31,0.79,0.75,0.22,0.97c-0.57,0.22-0.09,0.61-0.22,1.14c-0.13,0.53,0.4,1.01-0.04,1.14
				c-0.44,0.13,0.18,0.92-0.44,1.01c-0.61,0.09-0.22,0.66-0.61,0.97c-0.4,0.31,0.53,0.83,0.04,1.14c-0.48,0.31-0.13,1.01-0.61,1.01
				c-0.48,0-0.75,0.66-0.79,0.97c-0.04,0.31-0.35,0.92,0.04,1.36c0.4,0.44-0.09,0.79,0.26,0.97c0.35,0.18,1.54-0.09,1.36,0.44
				c-0.18,0.53-0.13,1.67,0.09,1.67c0.22,0,0.35,0.66,0.7,0.61c0.35-0.04,1.93-0.22,2.59-0.04c0.33,0.09,0.6,0.28,0.79,0.46
				c0.38-0.17,1.2,0.37,1.39,0.18c0.23-0.23-0.81-1.07-0.78-1.36c0.03-0.29-0.16-0.78-0.16-1.1c0-0.32-0.16-1.04,0.29-1.36
				c0.45-0.32,0.49-0.65,0.78-0.58c0.29,0.06,0.88-0.26,0.91-1.04c0.03-0.78,0.39-1.07,1.04-1.62c0.71-0.6,1.88-1.17,1.72-1.43
				c-0.16-0.26,0.26-1.1,0.26-1.43c0-0.32-1.1-0.26-1.46-0.39c-0.36-0.13-1.65-1.04-1.01-2.24c0.65-1.2,2.01-1.33,2.4-1.36
				c0.39-0.03,0.03-0.58,0.36-0.78c0.32-0.19,0.39-0.52,0.39-1.01c0-0.55,0-1.43,0.58-1.49c0.58-0.07,0.75-0.39,0.39-0.49
				c-0.36-0.1-0.52-0.52,0.07-0.58c0.58-0.06,0.39,0.49,0.81,0.49c0.42,0,1.14-0.71,0.58-1.14c-0.55-0.42-0.71,0.19-1.01,0.26
				c-0.29,0.06-0.52-0.39-0.81-0.39c-0.29,0-0.1-0.81-0.23-1.1c-0.13-0.29-0.32-1.01-0.07-1.36c0.26-0.36,1.36,0.55,2.34,0.62
				c0.97,0.07,1.69-0.49,1.69-0.84c0-0.36-0.45-1.01,0.13-1.39c0.58-0.39,0.1-0.52-0.06-1.04c-0.16-0.52,0.26-0.84,0.52-0.58
				c0.26,0.26,2.17,0.1,3.76-0.23c1.59-0.33,2.69-0.91,2.79-1.36c0.1-0.45,1.1-1.53,1.33-1.91c0.23-0.39,0.03-0.88-0.62-0.97
				c-0.65-0.1-0.62-0.84-0.26-1.27c0.36-0.42-0.19-0.81-0.81-0.88c-0.62-0.07-1.59-0.49-1.04-1.01c0.27-0.25,0.4-0.2,0.52-0.09
				c0.09-0.25,0.18-0.54,0.11-0.61c-0.13-0.13-0.46,0.03-0.43-0.89c0.03-0.92,0.36-0.72,0.39-1.91c0.03-1.18,0.49-1.87,0.49-2.5
				c0-0.62,1.12-1.35,1.74-2.37c0.62-1.02,1.97-2.3,2.53-2.66c0.56-0.36,1.64-0.66,1.78-0.99
				C168.19,208.47,168.05,206.69,167.79,206.56z"/>
			<path class="st0" d="M141.31,252.6c-0.35,0.04-0.48-0.61-0.7-0.61c-0.22,0-0.26-1.14-0.09-1.67c0.18-0.53-1.01-0.26-1.36-0.44
				c-0.35-0.18,0.13-0.53-0.26-0.97c-0.4-0.44-0.09-1.05-0.04-1.36c0.04-0.31,0.31-0.97,0.79-0.97c0.48,0,0.13-0.7,0.61-1.01
				c0.48-0.31-0.44-0.83-0.04-1.14c0.4-0.31,0-0.88,0.61-0.97c0.62-0.09,0-0.88,0.44-1.01c0.44-0.13-0.09-0.61,0.04-1.14
				c0.13-0.53-0.35-0.92,0.22-1.14c0.57-0.22,0.18-0.66-0.22-0.97c-0.4-0.31,0.09-0.31,0.57-0.31c0.48,0,0.22-0.79-0.22-0.66
				c-0.52,0.16-0.83-0.35-0.44-0.75c0.4-0.4-0.35-0.66-0.22-0.92c0.13-0.26,0.13-0.62-0.13-0.79c-0.26-0.18-0.09-1.49,0.22-1.54
				c0.31-0.04,0.09-1.23,0.09-1.58c0-0.35-0.35-0.7-0.31-1.01c0.04-0.31,0.39-0.4,0.39-1.01c0-0.62,0.13-0.88,0.4-0.92
				c0.26-0.04,0.04-0.75,0.09-1.1c0.04-0.35,0.75,0,0.83-0.57c0.09-0.57-0.44-0.48-0.35-0.83c0.11-0.42-0.04-0.97-0.04-1.93
				c0-0.97,0.79-1.19,1.1-1.45c0.31-0.26-0.39-1.67,0-1.84c0.4-0.18,0.13-0.83,0.44-1.05c0.31-0.22,0.22-0.7,0.4-1.23
				c0.18-0.53-0.35-0.79-0.35-1.27c0-0.48-0.09-0.92-0.48-1.45c-0.4-0.53-0.26-1.58,0-1.58c0.26,0,0.13-1.01,0.62-1.14
				c0.48-0.13,0.22-0.88,0-1.49c-0.22-0.61,0.39-0.31,0.39-0.79c0-0.48,0.26-1.14,0.66-1.32c0.4-0.18,0.44-0.92,0.44-1.23
				c0-0.31,0.57-0.44,0.88-0.7c0.31-0.26-0.53-0.7-0.04-0.97c0.48-0.26-0.4-1.36-0.13-1.58c0.26-0.22,0.04-0.7,0.09-1.05
				c0.04-0.35,1.49-1.01,1.67-1.32c0.13-0.22,0.26-1.01,0.3-1.71c-0.45,0.29-0.85,0.45-0.92,0.28c-0.13-0.3,0.04-1.21-0.35-1.6
				c-0.39-0.39-0.13-1.17-0.52-1.34c-0.39-0.17-0.87-0.95-0.52-1.21c0.35-0.26,0-0.78,0.3-1.04c0.3-0.26-0.17-0.3-0.61-0.82
				c-0.43-0.52,0.17-1.17-0.35-1.56c-0.52-0.39-0.52-0.87-0.65-0.65c-0.13,0.22-0.17,0.95-0.61,1.17c-0.07,0.04-0.18,0.12-0.32,0.23
				c0,0.36-0.13,0.96,0.01,1.32c0.19,0.49,0.29,2.86,0.1,3.76c-0.19,0.91-0.39,1.65-0.58,2.21c-0.19,0.55,0.45,0.58,0.19,0.84
				c-0.26,0.26-0.16,0.91-0.16,1.95c0,1.04-0.62,2.79-0.65,3.34c-0.03,0.55-0.45,0.91-0.39,1.53c0.07,0.62-0.29,0.97-0.65,1.27
				c-0.36,0.29,0.58,1.23,0.55,1.75c-0.03,0.52-0.71,0.75-0.55,1.59c0.2,1.05,0,2.86,0,3.21c0,0.36-0.42,0.32-0.32,1.01
				c0.1,0.68-0.42,0.84-0.52,1.82c-0.1,0.97-0.84,2.37-1.14,3.12c-0.29,0.75-0.29,1.17-0.68,1.17c-0.39,0-0.49,0.42-0.2,1.07
				c0.29,0.65-0.19,1.2,0.2,1.85c0.39,0.65,0.26,0.88,0.06,1.49c-0.19,0.62-0.45,0.65-0.45,1.23s-0.45,1.07-0.19,1.72
				c0.26,0.65,0.19,0.88-0.13,0.88c-0.32,0-0.49,0.52-0.62,1.59c-0.13,1.07-0.1,1.04,0.68,0.97c0.78-0.06,0.16-2.63,0.65-2.63
				c0.49,0,0.06-0.42,0.55-0.55c0.49-0.13,0.06,0.45,0.39,0.55c0.32,0.1,0.55,0.36,0.1,0.65c-0.45,0.29,0.1,0.88-0.13,1.23
				c-0.23,0.36-0.55,0.75-0.36,1.17c0.19,0.42-0.23,0.39-0.26,0.94c-0.03,0.55,0.29,0.58,0.07,0.71c-0.23,0.13,0.29,0.55-0.03,0.62
				c-0.32,0.06-0.13,0.49,0.06,0.88c0.19,0.39-0.42,0.16-0.75,0.42c-0.32,0.26,0.32,0.62,0.13,0.97c-0.19,0.36-0.71-0.19-0.94-0.39
				c-0.23-0.19,0.36-0.58,0.65-0.97c0.29-0.39-0.29-1.27-0.78-1.07c-0.49,0.19,0.49,0.75,0.49,1.17s-0.81-0.1-0.78,0.36
				c0.03,0.45-0.55,0.29-0.84,0.62c-0.29,0.32,0.39,0.62,0.06,0.71c-0.32,0.1-0.88,0.55-0.91,0.88c-0.03,0.32,0.36,0.39,0.36,0.06
				c0-0.32,0.52-0.42,0.68,0c0.16,0.42,0.39,0,0.84,0.1c0.45,0.1,0.39,0.78,0.13,0.75c-0.26-0.03-0.52,0.42-0.45,0.91
				c0.06,0.49,0.97,0,1.36,0.13c0.39,0.13,0.26,0.81,0.03,0.58c-0.23-0.23-0.75-0.62-0.97-0.23c-0.23,0.39-0.58,0.19-0.88,0.06
				c-0.29-0.13-1.33,0.55-0.97,0.81c0.36,0.26,0.32,0.68,0.55,0.65c0.23-0.03,0.58-0.2,0.71-0.45c0.13-0.26,0.58-0.52,0.62,0.06
				c0.03,0.59-0.62,0.29-0.75,0.62c-0.13,0.32-0.39,0.58-0.78,0.88c-0.39,0.29,0,0.78,0.32,0.62c0.32-0.16-0.1,0.39,0.36,0.45
				c0.42,0.06,0.55-0.26,0.45-0.59c-0.1-0.32,0.23-1.04,0.62-0.68c0.39,0.36-0.19,0.71-0.19,1.04c0,0.32-0.23,0.75-0.23,1.1
				c0,0.36-0.42,0.16-0.39,0.55c0.03,0.39-0.91,0.13-0.49,0.39c0.42,0.26-0.26,0.62-0.03,0.88c0.23,0.26,0.42-0.36,0.78-0.23
				c0.36,0.13,0.29,0.23,0.75,0.03c0.45-0.19,0.71,0.36,0.29,0.49c-0.42,0.13-0.88,0.62-0.68,0.68c0.19,0.06,0.75-0.32,0.88,0.1
				c0.13,0.42,0.45,0.29,0.49-0.16c0.03-0.45,0.81-0.06,0.94,0.32c0.13,0.39-0.81,0.62-1.3,0.52c-0.49-0.1-0.75,0.45-0.42,0.88
				c0.32,0.42,0.71,0,1.07-0.36c0.36-0.36,2.11-0.26,2.24,0.13c0.13,0.39-0.81,0.06-0.91,0.29c-0.1,0.23-0.81,0.42-0.91,0.16
				c-0.1-0.26-0.78,0.1-0.81,0.52c-0.03,0.42-0.55,0.42,0.03,0.58c0.58,0.16,0.42-0.62,1.33-0.55c0.91,0.06,1.4-0.84,1.66-0.32
				c0.26,0.52-0.75,0.94-1.1,0.62c-0.36-0.32-0.78,0.23-0.75,0.71c0.03,0.49-1.04-0.13-1.56-0.19c-0.52-0.06-0.29,0.49,0.16,0.97
				c0.45,0.49,0.71-0.06,1.27,0.29c0.55,0.36,0.55,0.75,0.94,0.39c0.39-0.36,0-0.32-0.26-0.49c-0.26-0.16-0.03-0.71,0.58-0.42
				c0.62,0.29,1.33-0.26,1.14-1.04c-0.19-0.78,0.55-1.14,1.3-1.4c0.75-0.26,0.36,0.49-0.19,0.71c-0.55,0.23-0.65,0.39-0.23,0.84
				c0.42,0.45,0.97-0.29,1.33,0.06c0.36,0.36-0.65,0.29-0.81,0.65c-0.16,0.36,0.62,0.78,0.07,1.04c-0.55,0.26-0.29-1.07-0.62-1.2
				c-0.32-0.13-0.62,0.16-0.29,0.91c0.32,0.75-0.39,0.1-0.71-0.26c-0.32-0.36-0.78,0.16-0.39,0.55c0.39,0.39-0.75-0.03-0.75,0.58
				c0,0.62,0.49,0.1,1.07,0.23c0.58,0.13,0.32,0.97,0.75,1.3c0.42,0.32,0.06-1.04,0.52-1.14c0.45-0.1,0.03,1.04,1.04,1.36
				c1.01,0.32,0.06-0.19,0.52-0.52c0.45-0.32,1.04,0.97,1.53,1.1c0.49,0.13,0.16-0.58-0.2-0.97c-0.36-0.39,0.36-0.16,0.84,0
				c0.49,0.16,0.88,0.23,0.97-0.13c0.04-0.13,0.13-0.13,0.24-0.09c-0.51-0.33-2.23-0.09-2.47-0.13c-0.23-0.04-0.05-3.93-0.01-4.82
				c-0.03-0.01-0.07-0.02-0.1-0.02c-0.42,0-1.36,0.1-1.2-0.55c0.03-0.11,0.09-0.18,0.17-0.22c-0.19-0.17-0.46-0.37-0.79-0.46
				C143.24,252.38,141.66,252.55,141.31,252.6z"/>
			<path class="st0" d="M166.97,203.88c-0.53-0.26-1.05,0.26-1.23,0.26c-0.18,0-0.57-1.14-0.7-1.62c-0.13-0.48-0.66-1.45-1.01-1.23
				c-0.35,0.22-0.26-0.09-1.27-0.13c-1.01-0.04-0.97-0.22-0.75-1.19c0.22-0.97-0.66-1.58-0.26-1.8c0.3-0.16,0.27-0.36-0.06-0.78
				c-0.08,0.05-0.17,0.09-0.25,0.09c-0.35,0-0.78-0.61-1.38-0.61c-0.61,0-1.26-0.04-1.69,0.17c-0.43,0.22-1.26-0.04-1.77,0.3
				c-0.52,0.35-1.17,1.08-1.12,1.38c0.04,0.3-0.17,2.16-0.43,2.25c-0.08,0.03-0.27,0.01-0.5-0.01c0.35,0.6,1.37,1.68,2.03,2.08
				c0.79,0.48,0.75,0.92,1.63,1.01c0.88,0.09,1.32,0.44,1.63,0.83c0.31,0.4,2.55,1.05,2.72,1.36c0.18,0.31-1.14,1.19-1.14,1.98
				c0,0.79-0.48,0.7-0.48,0.97c0,0.26,1.01,0.09,1.36,0.31c0.35,0.22,0.97-0.13,1.41,0.13c0.44,0.26,0.92-0.35,1.27-0.26
				c0.35,0.09,0.7-0.61,1.14-0.57c0.44,0.04,0.83-1.54,0.75-2.15C166.75,206.03,167.49,204.14,166.97,203.88z"/>
			<path class="st0" d="M125.15,154.7c-0.05-0.17-0.02-0.6,0.22-0.93c-0.53-0.57-1.13-1.34-1.45-1.81c-0.3,0.04-0.57,0.04-0.72-0.08
				c-0.32-0.24-1.13-0.08-1.45-0.24c-0.17-0.08-0.49-0.07-0.81-0.05c0.08,0.24,0.04,0.47-0.11,0.58c-0.29,0.19-0.32,0.97,0.39,1.27
				c0.71,0.29,0.49-0.16,0.84-0.16c0.36,0,0.32,0.55,0.65,0.55c0.32,0,1.07,0.39,1.04,0.91c-0.03,0.52,0.26,0.75,0.84,0.75
				c0.11,0,0.23,0.01,0.35,0.03C125.05,155.16,125.2,154.88,125.15,154.7z"/>
			<path class="st0" d="M132.58,154.7c-0.52-0.78-1.46-0.71-2.04-0.97c-0.58-0.26-1.33,0.1-2.08,0.78
				c-0.75,0.68-1.69,0.29-2.34-0.07c-0.19-0.11-0.46-0.36-0.75-0.67c-0.24,0.33-0.27,0.76-0.22,0.93c0.05,0.18-0.1,0.46-0.2,0.81
				c0.64,0.08,1.48,0.36,1.89,0.85c0.49,0.58,0.78,0.19,1.07,0.71c0.29,0.52,0.97,0.03,1.14-0.29c0.16-0.32-0.36-0.45-0.42-0.81
				c-0.06-0.36,0.65-0.23,0.78-0.71c0.13-0.49,1.17-0.71,1.62-0.52c0.45,0.19,0.84,0.52,0.42,1.01c-0.31,0.36,0.33,1.01,0.86,1.52
				c0.38-0.39,0.81-0.83,0.99-0.96c0.1-0.07,0.16-0.25,0.2-0.46C133.24,155.73,132.94,155.24,132.58,154.7z"/>
			<path class="st0" d="M133.48,167.42c0.41,0.24,0.98,0.17,1.12,0c0.13-0.17,0.51,0.2,1.05,0.81c0.02,0.03,0.04,0.05,0.07,0.07
				c0.45-0.1,1.03-0.19,1.26-0.04c0.41,0.26,0.31,1.17,0.87,1.22c0.56,0.05,0.97,0.61,0.92,0.92c-0.05,0.31,0.61,0.46,0.56,0.92
				c-0.05,0.46,1.48,0.36,1.68,0.2c0.2-0.15,0.61-0.25,0.87-0.1c0.26,0.15,0.71-0.15,0.97-0.05c0.25,0.1,1.27,0.51,1.07,0.87
				c-0.2,0.36-0.92,0.92-0.76,1.22c0.12,0.24,0.65,0.39,0.87,0.69c0.02,0,0.05,0,0.07-0.01c0.39-0.09,0.56-1.56,0.56-1.99
				c0-0.43,0.17-1.56,0.3-2c0.25-0.85-0.94-1.45-0.94-2.37c0-0.92,0.47-0.41,1-0.79c0.53-0.38-0.03-0.54-0.29-0.6
				c-0.26-0.06-0.47-0.53-0.38-0.8c0.09-0.28,1.7-0.16,2.21-0.16c0.51,0,1.62-0.47,1.64-0.54c0.01-0.07,0.13-0.44-0.26-0.75
				c-0.39-0.31-0.25-0.75,0.06-1.1c0.31-0.35-0.19-0.7-0.57-1.13c-0.38-0.43-0.09-1.96,0.35-2.68c0.44-0.72-0.89-0.56-1.71-0.45
				c-0.82,0.1-1.52-0.22-2.05-0.89c-0.53-0.67-1.24-0.25-2.5-0.22c-1.26,0.03-1.01-1.39-0.95-1.93c0.06-0.54-0.64-0.61-0.64-0.97
				s-0.37-0.35-0.69-0.32c-0.32,0.03,0-0.39,0.22-0.67c0.22-0.28,0.2-0.5,0.22-1.08c0.02-0.59,0.86-1.08,1.29-1.65
				c0.1-0.14,0.26-0.23,0.44-0.28c0.05-0.14,0.16-0.25,0.36-0.29c0.65-0.13,0.97-0.55,0.36-0.91c-0.62-0.36-0.97,0.68-1.65,0.78
				c-0.68,0.1-0.81,0.52-1.27,0.75c-0.45,0.23-1.3,0.03-1.33,0.42c-0.04,0.43-0.29,0-0.68-0.1c-0.39-0.1-1.4,0.78-1.36,1.27
				c0.03,0.49,0.19,0.94-0.52,1.4c-0.71,0.45-1.14,1.3-1.46,1.69c-0.1,0.12-0.21,0.13-0.32,0.07c-0.04,0.21-0.1,0.39-0.2,0.46
				c-0.18,0.13-0.61,0.57-0.99,0.96c0.18,0.17,0.35,0.33,0.47,0.46c0.45,0.49,0.16,0.88,0.29,1.14c0.13,0.26,0.26,0.65,0,0.81
				c-0.26,0.16-0.03,1.95,0.26,2.27c0.29,0.32,0,0.62-0.32,1.27c-0.32,0.65-0.62,0.94-1.2,0.97c-0.58,0.03-0.55,0.94-0.75,0.94
				c-0.15,0-0.27,0.33-0.29,0.61c0.55,0.46,1.52,1.01,1.81,1.01C132.88,166.74,133.08,167.18,133.48,167.42z"/>
			<path class="st0" d="M162.29,194.49c-0.22-0.65-0.87-0.74-0.87-1.43c0-0.69-0.26-0.78-1.21-0.74c-0.95,0.04-1.56-0.17-1.6-0.73
				c-0.04-0.56-0.61-1-0.17-1.21c0.43-0.22-0.3-0.61-0.22-1.13c0.09-0.52-0.26-0.74-0.56-0.99c-0.3-0.26-1.6,0.13-1.6-0.35
				c0-0.48-0.78-0.56-1.21-0.56c-0.43,0-0.13-0.56-0.61-0.52c-0.48,0.04-0.39-0.3-1.04-0.22c-0.65,0.09-1.04-0.22-1.73-1.04
				c-0.69-0.82-0.61-2.42-0.48-2.86c0.13-0.43-0.82-0.09-1.38-0.09c-0.56,0-1.13,0.43-1.73,0.78c-0.61,0.35-0.95,0.35-1.25,0.65
				c-0.3,0.3-0.78,0.48-1.25,0.3c-0.13-0.05-0.3-0.09-0.49-0.11c0.26,0.64,0.87,1.68,1.01,2.06c0.17,0.48-0.22,0.6-0.39,0.78
				c-0.17,0.17,0.09,1.73,0.13,2.08c0.04,0.35-0.61,1-0.35,1.21c0.26,0.22-0.35,0.87-0.35,1.26c0,0.39,0.56,0.39,0.56,0.69
				c0,0.3-0.48,1-0.78,1.17c-0.16,0.09-0.16,0.34-0.12,0.6c0.09-0.03,0.15,0.36,0.6,0.7c0.52,0.39-0.09,1.04,0.35,1.56
				c0.43,0.52,0.91,0.56,0.61,0.82c-0.3,0.26,0.04,0.78-0.3,1.04c-0.35,0.26,0.13,1.04,0.52,1.21c0.39,0.17,0.13,0.95,0.52,1.34
				c0.39,0.39,0.22,1.3,0.35,1.6c0.13,0.3,1.3-0.43,1.99-1.17c0.69-0.74,0.65-0.13,1.3-0.04c0.65,0.09,1.38,0.09,1.69,0.69
				c0.3,0.61,0.65-0.6,0.87-0.87c0.22-0.26,1.69,0.09,1.95,0c0.26-0.09,0.48-1.95,0.43-2.25c-0.04-0.3,0.61-1.04,1.12-1.38
				c0.52-0.35,1.34-0.09,1.77-0.3c0.43-0.22,1.08-0.17,1.69-0.17c0.61,0,1.04,0.61,1.38,0.61c0.35,0,0.78-0.56,0.69-1.08
				C162.04,195.87,162.51,195.14,162.29,194.49z"/>
			<path class="st0" d="M144.55,194.14c0.02-0.03,0.03-0.04,0.05-0.05c-0.04-0.26-0.04-0.51,0.12-0.6c0.3-0.17,0.78-0.87,0.78-1.17
				c0-0.3-0.56-0.3-0.56-0.69c0-0.39,0.61-1.04,0.35-1.26c-0.26-0.22,0.39-0.86,0.35-1.21c-0.04-0.35-0.3-1.9-0.13-2.08
				c0.17-0.17,0.56-0.3,0.39-0.78c-0.14-0.38-0.75-1.42-1.01-2.06c-0.48-0.07-1.07-0.06-1.41,0.07c-0.48,0.17-0.35-1.34-0.35-1.9
				c0-0.56-0.48-0.26-0.65,0.13c-0.17,0.39-0.87,0.22-1.56,0.22c-0.69,0-0.35-0.86-0.78-0.82c-0.43,0.04-0.78-0.13-0.74-0.56
				c0.04-0.43-0.3-0.78-0.61-1.26c-0.3-0.48-0.65-0.95-0.39-1.25c0.26-0.3-0.17-0.56,0.22-0.95c0.39-0.39,0.78-0.26,0.65-0.78
				c-0.13-0.52,0.43-0.82,0.48-1.38c0.04-0.56,0.69-0.39,1.3-0.86c0.61-0.48,1.25-0.22,1.77-0.65c0.49-0.41,0.82-0.06,1.18-0.08
				c-0.22-0.3-0.75-0.45-0.87-0.69c-0.15-0.31,0.56-0.87,0.76-1.22c0.2-0.36-0.81-0.76-1.07-0.87c-0.26-0.1-0.71,0.2-0.97,0.05
				c-0.25-0.15-0.66-0.05-0.87,0.1c-0.2,0.15-1.73,0.25-1.68-0.2c0.05-0.46-0.61-0.61-0.56-0.92c0.05-0.3-0.36-0.87-0.92-0.92
				c-0.56-0.05-0.46-0.97-0.87-1.22c-0.23-0.15-0.81-0.06-1.26,0.04c0.49,0.55,0.66,0.78,0.34,1.04c-0.43,0.34,0,0.41-0.81,1.49
				c-0.81,1.08-2.2,1.35-2.88,1.56c-0.68,0.2-0.95,1.45-1.32,2.33c-0.37,0.88-0.68,0.57-1.05,0.07c-0.37-0.51-0.68,0.1-1.12-0.3
				c-0.44-0.41-0.24-0.41,0-0.74c0.13-0.18-0.01-0.47-0.28-0.75c-0.48,0.1-0.97,0.77-1.21,1.28c-0.26,0.55,0.45,1.62,0.55,2.01
				c0.1,0.39-0.39,0.03-0.45,0.39c-0.07,0.36,1.07,0.88,1.59,1.27c0.52,0.39,0.97,1.49,1.46,2.01c0.49,0.52,0.52,0.97,1.04,2.24
				c0.52,1.27,1.56,3.41,2.27,4.32c0.71,0.91,1.04,1.62,0.88,1.88c-0.16,0.26,0.03,0.97,0.55,1.33c0.52,0.36,0.94,0.94,2.3,1.62
				c1.36,0.68,3.86,1.98,4.22,2.6c0.36,0.62,1.53,1.2,1.75,1.33c0.06,0.04,0.08,0.14,0.08,0.27c0.13-0.11,0.25-0.2,0.32-0.23
				C144.38,195.09,144.42,194.36,144.55,194.14z"/>
			<path class="st0" d="M140.93,151.05c-0.42,0.57-1.27,1.07-1.29,1.65c-0.01,0.58,0,0.8-0.22,1.08c-0.22,0.28-0.54,0.7-0.22,0.67
				c0.32-0.03,0.69-0.03,0.69,0.32s0.7,0.42,0.64,0.97c-0.06,0.54-0.31,1.96,0.95,1.93c1.26-0.03,1.98-0.45,2.5,0.22
				c0.53,0.67,1.23,1,2.05,0.89c0.82-0.1,2.15-0.26,1.71,0.45c-0.44,0.72-0.73,2.25-0.35,2.68c0.38,0.42,0.88,0.77,0.57,1.13
				c-0.31,0.35-0.45,0.79-0.06,1.1c0.4,0.31,0.28,0.67,0.26,0.75c0,0.02-0.08,0.06-0.21,0.12c0.58,0.58,0.79,1.21,1.12,1.48
				c0.44,0.35,0.57,0.53,0.88,0.35c0.31-0.17,0.53-0.31,0.79-0.13c0.26,0.18,0.62-0.17,1.01-0.53c0.4-0.35,0.83-0.04,0.97-0.48
				c0.13-0.44,0.35-0.7,0.83-0.79c0.48-0.09,0.48-0.62,0.18-0.62c-0.31,0-0.75,0.4-0.75-0.18c0-0.57-0.4-0.44-0.4-0.92
				c0-0.48-0.04-0.79-0.57-1.1c-0.53-0.31,0.7-0.44,0.97-0.18c0.26,0.26,1.19,0.04,1.27,0.44c0.09,0.4,0.57,0.53,0.57,0.04
				c0-0.48,0.57-0.66,1.01-0.57c0.44,0.09,1.41-0.57,1.8-0.7c0.21-0.07,0.28-0.32,0.39-0.53c-0.29-0.39-0.75-1.01-0.96-1.18
				c-0.32-0.26,0.21-0.42,0.16-0.79c-0.05-0.37,0-0.63,0.68-0.68c0.68-0.05,0.58-0.58,0.21-0.79c-0.37-0.21,0.16-0.79,0.53-1
				c0.15-0.09,0.35-0.34,0.54-0.7c-0.7-0.26-1.7-0.24-2.12-0.22c-0.55,0.03,0.36-0.62,0.39-1.2c0.03-0.58-1.56-0.68-2.17-0.81
				c-0.62-0.13-0.45-0.78,0.19-0.81c0.65-0.03-0.36-0.49-1.23-0.36c-0.88,0.13-2.11,0.62-2.82,1.01c-0.71,0.39-1.95-1.14-2.76-0.81
				c-0.81,0.32-1.95,0.32-1.91-0.32c0.03-0.65-0.32-1.2-1.14-1.14c-0.81,0.06-0.62-0.81-1.23-0.81s0,0.75-0.42,0.94
				c-0.42,0.19-1.88,0.55-1.98,1.14c-0.1,0.58,0.78,1.56,0.49,2.14c-0.29,0.58-1.27,0.1-1.56-0.52c-0.29-0.62,0.84-1.56,0.65-1.91
				c-0.14-0.25-0.31-0.71-0.19-1.01C141.19,150.82,141.03,150.91,140.93,151.05z"/>
			<path class="st0" d="M163.13,160.06c0.21,0.42-0.26,0.63-0.68,0.68c-0.42,0.05-0.32,1-0.58,1.26c-0.26,0.26,1,0.95,1.21,1.52
				c0.12,0.34,0.54,1.09,0.87,1.64c0.29,0.07,0.57,0.14,0.73,0.05c0.4-0.22,0.04-0.97,0.61-0.92c0.57,0.04,1.41-0.31,1.63,0.04
				c0.04,0.06,0.09,0.13,0.14,0.19c0.18-0.28,0.38-0.58,0.43-0.68c0.11-0.21,0.11-1.31-0.16-1.94c-0.26-0.63-0.05-1.47,0.26-1.74
				c0.07-0.06,0.17-0.34,0.29-0.75c-0.52-0.2-1.02-0.39-1.46-0.33c-0.78,0.1-1.59,0.45-1.88,0.03c-0.29-0.42-0.71-0.36-1.1-0.03
				c-0.04,0.04-0.09,0.05-0.14,0.04C163.16,159.5,163.03,159.85,163.13,160.06z"/>
			<path class="st0" d="M158.67,156.17c-0.37,0.21-0.89,0.79-0.53,1c0.37,0.21,0.47,0.74-0.21,0.79c-0.68,0.05-0.74,0.31-0.68,0.68
				c0.05,0.37-0.47,0.53-0.16,0.79c0.21,0.17,0.67,0.79,0.96,1.18c0.1-0.19,0.23-0.35,0.53-0.31c0.61,0.09,0.13,1.01,0.61,1.23
				c0.48,0.22,0.48,0.97,0.13,1.23c-0.35,0.26-0.48,1.71,0.31,2.59c0.79,0.88,1.41,0.75,1.58,0.53c0.18-0.22,0.79,0,0.97-0.35
				c0.18-0.35,0.4,0.04,0.75-0.35c0.2-0.23,0.63-0.11,1.02,0c-0.32-0.55-0.74-1.3-0.87-1.64c-0.21-0.58-1.47-1.26-1.21-1.52
				c0.26-0.26,0.16-1.21,0.58-1.26c0.42-0.05,0.89-0.26,0.68-0.68c-0.11-0.21,0.03-0.56,0.16-0.94c-0.4-0.04-1-1.21-1.35-1.21
				c-0.39,0-0.84-0.32-0.75-0.88c0.1-0.55-0.91-0.62-1.46-1.23c-0.13-0.15-0.32-0.26-0.54-0.34
				C159.02,155.83,158.82,156.08,158.67,156.17z"/>
			<path class="st0" d="M167.34,161.9c0.26,0.63,0.26,1.74,0.16,1.94c-0.05,0.11-0.25,0.41-0.43,0.68c0.24,0.27,0.63,0.47,1.31,0.25
				c0.83-0.26,1.23,0.26,1.54-0.66c0.24-0.72,1.09-1.7,1.73-2.33c-0.01,0-0.01-0.01-0.02-0.01c-0.62-0.26-1.56-1.75-2.5-1.95
				c-0.41-0.08-0.83-0.26-1.23-0.41c-0.12,0.41-0.22,0.69-0.29,0.75C167.29,160.43,167.08,161.27,167.34,161.9z"/>
			<path class="st0" d="M134.95,136.14c-0.97,0-4.15-2.21-7.07-2.86c-2.92-0.65-5.47,1.45-5.19,1.82c0.39,0.52,1.82-0.65,2.53-1.17
				c0.71-0.52,1.36,0.26,1.43,0.58c0.07,0.32,0.97,0.52,2.47,0.58c1.49,0.07,1.69,1.3,3.11,1.62c1.43,0.32,0,0.91,0.71,1.17
				c0.71,0.26,4.48,0.19,4.74-0.13C137.93,137.44,135.92,136.14,134.95,136.14z"/>
			<path class="st0" d="M131.64,140.62c0.26,0.45,2.73,1.1,2.99,0.52C134.88,140.55,131.32,140.05,131.64,140.62z"/>
			<path class="st0" d="M148.32,140.75c0.58,0.91,2.25,0.37,2.4,0C150.98,140.1,147.73,139.84,148.32,140.75z"/>
			<path class="st0" d="M161.95,251.73c-0.52-0.97-2.92,0.84-2.4,1.1C160.07,253.09,162.2,252.2,161.95,251.73z M159.29,251.02
				c-0.71-0.13-2.43,1.32-1.69,1.69C158.38,253.09,160,251.15,159.29,251.02z"/>
			<path class="st0" d="M135.65,168.23c-0.54-0.61-0.91-0.98-1.05-0.81c-0.14,0.17-0.71,0.24-1.12,0c-0.41-0.24-0.61-0.68-0.91-0.68
				c-0.28,0-1.26-0.55-1.81-1.01c-0.01,0.07-0.01,0.14,0,0.2c0.03,0.29-0.45,0.49-1.01,0.52c-0.55,0.03-0.49,0.65-0.49,1.07
				c0,0.42-0.45,0.42-0.65,0.88c-0.19,0.45,0.06,0.55-0.39,0.78c-0.45,0.23-0.58,0.62-0.32,1.01c0.26,0.39-0.03,0.75-0.1,0.97
				c-0.06,0.23,0.39,0.33,0.84,0.75c0.45,0.42,0.52-0.16,0.71,0.16c0.19,0.32-0.13,0.91-0.65,0.91c-0.04,0-0.08,0.01-0.12,0.02
				c0.27,0.28,0.41,0.57,0.28,0.75c-0.24,0.34-0.44,0.34,0,0.74c0.44,0.4,0.74-0.2,1.12,0.3c0.37,0.51,0.68,0.81,1.05-0.07
				c0.37-0.88,0.64-2.13,1.32-2.33c0.68-0.2,2.06-0.47,2.88-1.56c0.81-1.08,0.38-1.14,0.81-1.49
				C136.39,169.08,136.19,168.84,135.65,168.23z M112.56,167.81c-0.65,0.97,0.64,1.92,0.91,1.49
				C113.79,168.79,113.21,166.84,112.56,167.81z"/>
			<path class="st0" d="M224.73,6.1c-1.6,0-2.9,0.26-2.99,1.38c-0.09,1.13-2.12-0.22-2.99,0.17c-0.87,0.39-0.43-0.91-1.3-0.48
				c-0.87,0.43-1.82,1.47-2.86,1.9c-1.04,0.43-1.82,1.51-2.47,1.6c-0.65,0.09,1.38-1.99,2.42-3.03c1.04-1.04,0.48-2.25-0.91-2.12
				c-1.38,0.13-1.08,1-1.86,1.17c-0.78,0.17-4.5,2.03-4.67,1.47c-0.17-0.56,3.07-1.52,3.03-1.9c-0.04-0.39-4.11-0.43-5.93-0.17
				c-1.82,0.26-5.02,1.3-5.06,0.78c-0.04-0.52,3.72-1.26,5.63-1.47c1.95-0.22,5.41,0.13,6.88-0.48c1.47-0.61,3.68-0.65,4.07-1.13
				c0.39-0.48-1.69-1.12-2.73-1.08c-1.04,0.04-2.08-0.09-1.99-0.61c0.09-0.52-1.6-0.65-1.77-1.04c-0.17-0.39-3.42-0.09-4.11-0.52
				c-0.69-0.43-2.73-0.65-4.46-0.52c-1.73,0.13-5.28,0.09-6.1,0.17c-0.82,0.09-1.34,0.39-2.08,0.35c-0.74-0.04-1.86,0.22-1.43,0.69
				c0.78,0.86-1.17,1.21-1.04,0.56c0.13-0.65-1.69-0.95-2.25-0.48c-0.64,0.54-3.55-0.56-3.98,0c-0.43,0.56-3.85,0.39-4.85,0.56
				c-0.99,0.17,1.56,0.91,1.47,1.34c-0.09,0.43-3.25,0-2.6,0.78c0.65,0.78,2.73,1.34,3.72,2.34c0.99,0.99-0.78,0.39-1.9-0.35
				c-1.13-0.74-2.55-0.48-3.25-1.21c-0.69-0.74-3.29-1.3-3.94-0.87c-0.77,0.52,1.77,1.47,1.77,2.03c0,0.56-1.73-0.13-2.03,0.09
				c-0.3,0.22-1.82-1.47-2.68-1.47c-0.87,0-0.13,0.78-0.13,1.86c0,1.08-1.69,1.73-1.21,1c0.48-0.74,0.13-2.64-0.78-2.99
				c-0.91-0.35-3.24,0.74-4.46,0.69c-1.21-0.04-2.81,0.35-1.77,0.95c1.04,0.61-0.04,0.95-0.95,0.3c-0.91-0.65-3.89,0-3.29,0.43
				c0.61,0.43,0.82,1.69,0.43,2.21c-0.39,0.52-1.64-0.52-2.81-0.43c-1.17,0.09-6.97,2.86-6.62,3.59c0.35,0.74,2.81,0.13,3.59,0.56
				c0.78,0.43-0.26,2.03-1.21,2.68c-0.95,0.65-4.5,0.04-4.59,0.82s-5.58,0.91-5.58,2.16c0,0.48,0.26,0.95,0.86,1.08
				c0.78,0.17,1.73-0.09,2.42,0.69c0.69,0.78,2.64,0.82,3.98,0.3c1.34-0.52,2.34,0.09,2.29,0.86c-0.04,0.78-3.03-0.3-3.94,0.26
				c-0.91,0.56-3.72,0.09-3.59,0.69c0.13,0.61,1.52,0.48,2.64,0.69c1.12,0.22,0.09,0.69,0.04,1.17c-0.04,0.48,0.61,0.39,1.73,0.95
				c1.13,0.56,2.99,0.91,2.38,0.26c-0.61-0.65,1.17-0.56,1.47-0.17c0.3,0.39,1.12-0.56,1.95-0.35c0.82,0.22,1.12-0.78,1.77-0.35
				c0.65,0.43,3.68,0.69,4.72,1.25c1.04,0.56,2.47,0.69,2.34,1.51c-0.13,0.82,0.86,1.43,2.03,1.95c1.17,0.52,1.38,1.65,1.34,2.38
				c-0.04,0.74,1.25,1.25,1.04,1.6c-0.22,0.35-0.04,0.82,0.74,1.51c0.78,0.69-1.21,1.21-0.74,1.82c0.48,0.61-0.69,1.77,0.69,1.99
				c1.38,0.22,1.17-0.95,2.21-0.95c1.04,0-0.04,0.91,0.52,1.47c0.56,0.56,1.73,0.43,2.81,1.34c1.08,0.91,0.39,1.34-0.61,0.61
				c-1-0.74-3.89-0.56-3.89-0.3c0,0.26,3.2,2.08,3.94,1.82c0.74-0.26,1.73,1.08,1.3,1.51c-0.43,0.43-0.22,1.52-0.3,2.03
				c-0.09,0.52-1.17,0-1.86,0.09c-0.69,0.09-1.17,0.3-1.17,1.04c0,0.74-1.08,1.26-1.21,2.16c-0.13,0.91,0.87,0.87,1.43,1.26
				c0.56,0.39-1.04,0.65-1.12,1.3c-0.09,0.65,1.47,1.82,2.03,2.12c0.56,0.3,0.04,1.77,0.26,2.51c0.22,0.74,0.95-0.17,0.87,0.78
				c-0.09,0.95,0.69,0.91,0.74,1.47c0.04,0.56,1.38,0.48,1.12,1.25c-0.26,0.78,0.39,1.34,0.69,1.77c0.3,0.43,1.43,1.17,1.64,1.73
				c0.22,0.56,0.86,1.3,1.64,1.08c0.78-0.22,0.82,0.52,1.43,0.43c0.61-0.09,1.56,0.26,1.73,0.74c0.17,0.48,2.03,0.91,2.55,0.99
				c0.52,0.09,0.61-0.86,1.08-1.12c0.48-0.26,0.3-2.16,0.74-2.29c0.43-0.13,0.3-2.12-0.13-2.29c-0.43-0.17-0.26-0.87,0.74-0.74
				c0.99,0.13,0.65-0.87,1.12-1c0.48-0.13,0.26-1.04,0.61-1.13c0.35-0.09,0.3-0.69,0.04-1.04c-0.26-0.35,0.04-0.61,0.56-0.69
				c0.52-0.09,0.39-0.82-0.3-1.08c-0.69-0.26-0.48-1.04,0.22-0.56c0.69,0.48,1.21,0.13,0.82-0.39c-0.39-0.52,0.48-0.78,1.38-0.87
				c0.91-0.09,1.39-0.56,1.21-1.26c-0.17-0.69,1.04-0.65,0.74-0.04c-0.3,0.6-0.13,1.21,0.3,0.78c0.43-0.43,1.25-0.13,2.73-0.56
				c1.47-0.43,2.9-1.64,3.24-2.81c0.35-1.17,2.03-1.04,1.82-1.82c-0.22-0.78,0.3-1.08,1.38-0.56c1.08,0.52,0.22-0.35,1.43-0.35
				c1.21,0,1-0.43,2.16-0.48c1.17-0.04,4.2-0.56,5.23-1.43c1.04-0.87,3.24-1.6,4.15-2.16c0.91-0.56,1.13-1.04,0.56-0.74
				c-0.56,0.3-1.73,0.35-2.6,0.13c-0.87-0.22-2.25-0.95-3.25-0.39c-0.99,0.56-0.39-0.82,0.43-0.95c0.82-0.13,0.48-0.52,0.26-1.38
				c-0.22-0.87,1.38-0.3,2.08,0.69c0.69,1,2.03,1.43,3.2,1.08c1.17-0.35,0.04-1.3,0.52-1.95c0.48-0.65-3.59-2.68-3.81-3.38
				c-0.22-0.69,1.04-0.22,2.16,0.26c1.12,0.48,1.38-0.91,1.38-1.51c0-0.61-2.42-0.65-3.5-0.04c-1.08,0.61-2.12-0.78-0.65-0.95
				c1.47-0.17,0.13-0.78,0.56-1.21c0.43-0.43,2.29,1.25,3.25,0.95c0.95-0.3,1.64,0.04,2.47-0.61c0.82-0.65-1.47-1.25-1.95-1.82
				c-0.48-0.56,2.38-0.35,3.24-0.3c0.87,0.04,1.08-1.25,0.39-1c-0.69,0.26-3.16-0.86-2.64-1.64c0.52-0.78,1.47,0.13,2.55-0.43
				c1.08-0.56,0.09-2.68-0.69-2.68c-0.78,0-2.6-0.26-2.6-0.65s-1.51-0.74-1.08-1.13c0.43-0.39,1,0.48,1.99-0.26
				c1-0.74,3.07,0.17,3.98,0c0.91-0.17-0.39-1.86-0.91-1.56c-0.52,0.3-2.29,0.39-2.42-0.52c-0.13-0.91,1.69,0,1.99-0.43
				c0.3-0.43-2.51-1.56-2.94-0.65c-0.43,0.91-1.99,0.61-1.17,0.09c0.82-0.52,1-1.99,0.91-2.73c-0.09-0.74,2.94-0.78,2.6-1.99
				c-0.35-1.21,1.43-1.47,2.42-1.47c0.99,0-0.04-1.3-1-1.17c-0.95,0.13-1.95,1.21-2.42,0.78c-0.48-0.43,1.12-1.21,1.99-1.21
				c0.87,0,2.94-0.09,3.68-0.52c0.74-0.43-1.3-0.82-2.68-0.65c-1.38,0.17-1.38-0.43,0.69-0.48c2.08-0.04,1.73-0.48,3.2-0.65
				c1.47-0.17,0.99-0.78,1.9-0.82c0.91-0.04,3.29-1.04,3.29-1.51S226.33,6.1,224.73,6.1z M170.82,43.78
				c0.04-0.69-0.74-0.26-1.17-1.08c-0.43-0.82-2.64-1.16-2.86-0.52c-0.09,0.26-0.78,0.65-0.22,1.25c0.56,0.61,0.95,0.35,1.6,0.95
				C168.83,44.99,170.78,44.47,170.82,43.78z"/>
			<path class="st0" d="M191.41,256.84c0.13,0.57,0.7,0.32,1.27,0.82c0.57,0.51,1.08,1.45,1.65,0.57
				C194.89,257.34,191.34,256.52,191.41,256.84z"/>
		<g className="mouseOver CanadaCountry">
			<path class="st0 lightBlue" d="M65.24,89.18c-0.42-0.45-1.53-0.36-1.69-0.78c-0.16-0.42-1.17-1.95-1.72-1.91
				c-0.55,0.03-1.27,0.06-1.91-0.42c-0.65-0.49-1.07-0.52-1.53-0.23c-0.45,0.29,0.49,0.36,0.45,0.68c-0.03,0.32,0.29,0.52,0.68,0.45
				c0.39-0.06,0.62,0.75,1.01,0.78c0.39,0.03,0.52,0.65,0.97,0.65c0.45,0,0.55,0.52,0.78,0.81c0.23,0.29,0.75,0.03,0.75,0.32
				c0,0.26,0.52,0.5,0.65,0.83c0.89,0.11,1.65,0.19,2.31,0.01c0.01-0.2,0.06-0.43,0.15-0.61C66.38,89.34,65.67,89.64,65.24,89.18z
				 M64.14,37.62c1.17,0.26,1.3,2.34,2.08,2.34c0.52,0,0.13-0.65,0.97-0.65c0.84,0,0.65-0.45,1.43-0.39
				c0.78,0.07,1.43-0.19,1.43-1.1c0-0.91,0.58-1.23,1.36-1.43c0.78-0.19,0.06-1.17,1.17-1.43c1.1-0.26,4.15-1.56,4.67-1.95
				c0.52-0.39-0.65-0.91-1.82-1.62c-1.17-0.71-2.08-0.91-2.99-0.32c-0.91,0.58-0.19-0.65-0.91-0.32c-0.71,0.32-2.66-0.52-2.92-0.84
				c-0.26-0.32-3.76,0.71-4.61,0.71c-0.84,0,0.06,0.91,0.65,1.36c0.58,0.45-1.43,1.88-1.04,2.21c0.39,0.32-0.39,1.17-1.3,2.21
				C61.41,37.42,62.97,37.36,64.14,37.62z M96.59,42.94c0.39-0.45,2.01,0.91,2.08-0.06c0.07-0.97-1.82-1.43-2.6-1.95
				c-0.78-0.52-1.1-0.26-2.34-1.17c-1.23-0.91,0.32-1.36-0.58-2.08c-0.91-0.71-1.23-2.66-0.58-3.31c0.65-0.65,1.36-1.62,0.2-2.08
				c-1.17-0.45-3.05,0.39-2.86,0.65c0.19,0.26,1.82,1.17,1.82,1.56c0,0.39-1.49-1.1-2.01-0.78c-0.52,0.32-1.1-0.65-1.56,0
				c-0.45,0.65,0.58,2.92,0.97,3.57c0.39,0.65-0.39,0.65-0.91,0.78c-0.52,0.13-0.58-1.82-1.1-2.53c-0.52-0.71-2.6-1.56-2.73-1.04
				c-0.13,0.52,1.3,0.52,1.1,1.23c-0.19,0.71-0.97-0.45-1.69,0.13c-0.71,0.58-0.58,0.19-0.45-0.39c0.13-0.58-1.04-1.23-2.34-1.1
				c-1.3,0.13-0.58,1.04-0.97,1.36c-0.39,0.32-2.34-0.19-1.1-0.45c1.23-0.26,0.71-0.97,0.26-1.69c-0.45-0.71-1.69,0.13-3.7,0.84
				c-2.01,0.71-2.92,1.49-2.66,1.56c0.26,0.06,0.32,0.39-0.45,1.04c-0.78,0.65,0,1.23,0.71,1.23c0.71,0,0.09,0.57,0.58,0.71
				c0.45,0.13,2.79-0.91,3.44-0.45c0.65,0.45-3.83,0.71-3.83,1.49s2.47,1.23,4.28,0.91c1.82-0.32,5,0.26,5,0.71
				c0,0.45-2.14,0.32-3.76,0.26c-1.62-0.06-4.41,0.45-4.28,0.97c0.15,0.58,0.32,0.58,2.01,1.36c1.69,0.78,3.44-0.39,3.38,0.71
				c-0.07,1.1,0.91,1.36,3.18,1.43c2.27,0.07,3.31-1.04,4.41-0.97c1.1,0.06,1.62-0.13,2.14-0.91c0.52-0.78,0.97-0.32,1.1,0.06
				c0.13,0.39,1.56,0.32,1.88,0.65c0.83,0.83,3.57,0.58,4.61,0.06c1.04-0.52-0.45-1.95-0.97-1.3c-0.52,0.65-0.97,0.26-1.1-0.06
				C95.03,43.59,96.2,43.39,96.59,42.94z M71.93,26.97c1.17,0.13,2.34-0.91,2.27-1.49C74.14,24.9,71.35,26.91,71.93,26.97z
				 M66.54,25.15c0.45,0.19,0,1.1,0.52,0.84c0.52-0.26,0.65-0.32,1.04-0.19c0.7,0.23,0.84-0.39,1.17-0.71
				c0.32-0.32,0.19,1.17,1.04,1.17c0.71,0,0.58-1.56,1.23-1.1c0.65,0.45,0.84-0.19,0.97-0.65c0.13-0.45,0.13-1.23,0.84-1.36
				c0.71-0.13,0.13,0.71,0.45,1.3c0.47,0.85,1.3,0.06,1.36-0.32c0.07-0.39,1.23-0.13,1.17-0.58s0.32-0.58,0-1.1
				c-0.32-0.52,0.52-0.39,0.91-0.78c0.39-0.39-0.52-0.07-1.17-0.65c-0.65-0.58-0.91,0.19-0.91,0.58c0,0.39-1.23,0.07-1.95-0.19
				c-0.71-0.26-1.62,0.84-2.66,1.36c-1.04,0.52-1.1,1.43-2.08,1.36C67.51,24.05,66.09,24.96,66.54,25.15z M76.67,24.83
				c-0.91,0.07-0.84,0.97,0.2,0.97c1.04,0,1.36,0.32-0.13,0.26c-1.49-0.06-2.14,1.23-1.1,0.97c1.04-0.26,2.27-0.26,1.49,0
				c-0.78,0.26-2.9,0.44-2.6,0.84c0.19,0.26,1.04,0.19,1.88,0.52c0.84,0.32,1.36,0.52,2.01-0.19c0.65-0.71,1.49-1.43,1.17-0.45
				c-0.32,0.97,1.04,0.26,2.79,0.19c1.75-0.06-0.32,1.04-2.14,1.17c-1.82,0.13-1.49,1.04-0.06,1.36c1.43,0.32,4.48-0.91,5.19-1.62
				c0.71-0.71,1.36,0.39,2.08-0.13c0.71-0.52,2.08,0.07,3.31-0.19c1.23-0.26,1.43-2.6,0.52-2.99c-0.91-0.39-0.91,0.45-1.62,0.58
				c-0.71,0.13-1.1-0.78-1.69-1.36c-0.58-0.58-0.19-1.69-1.04-1.56c-0.84,0.13-2.66,1.49-1.56,1.62c1.1,0.13,0.71,0.71,0.13,0.97
				c-0.58,0.26,1.36,0.71,1.1,1.1c-0.26,0.39-3.25,0.07-3.37-0.58c-0.13-0.65-2.47-1.82-3.44-1.56c-0.97,0.26-0.58-0.78-1.56-0.84
				C77.25,23.86,77.57,24.77,76.67,24.83z M80.04,20.16c0.32-0.78-2.15-0.65-1.95-0.39C78.55,20.35,79.72,20.94,80.04,20.16z
				 M85.43,19.83c0.84-0.13,0.45-0.78-0.91-0.58c-1.36,0.19-3.7,0.19-3.7,1.17c0,0.78,0.45,1.17,1.95,1.3
				c1.49,0.13,2.79-1.3,2.08-1.3C84.13,20.42,84.58,19.96,85.43,19.83z M82.25,18.6c0.65,0.26,1.3-0.58,1.75-0.19
				c0.45,0.39,2.47,0.71,2.47-0.32s-2.53-1.17-3.18-0.78c-0.65,0.39-3.63,0.52-2.79,1.17C81.01,18.87,81.6,18.34,82.25,18.6z
				 M93.09,16.59c0.78-0.52,2.21,0.32,1.95,0.91c-0.26,0.58-2.51-0.12-2.34,0.39c0.07,0.19,0.32,0.97,1.88,0.52
				c1.56-0.45,2.86-0.13,3.89,0.71c1.04,0.84,2.01,1.17,2.73,0.32c0.71-0.84-0.91-0.97-0.58-1.62c0.32-0.65-1.04-1.04-1.75-1.04
				c-0.71,0-0.97-1.3-1.56-1.04c-0.58,0.26-0.97,0.71-0.97-0.13s-3.38-0.91-4.22-0.58C91.27,15.35,92.31,17.11,93.09,16.59z
				 M93.54,22.04c0.52-0.65-2.08-2.73-2.21-1.75C91.2,21.26,92.99,22.73,93.54,22.04z M101.98,13.54c0.45-0.78-2.74-1.39-1.95-0.71
				C100.49,13.21,101.52,14.32,101.98,13.54z M94.64,28.27c0.45-0.52-0.97-1.62-1.36-0.71C92.89,28.46,94.18,28.8,94.64,28.27z
				 M101.98,23.73c-0.06,0.65-1.56-0.13-2.73-0.58c-1.17-0.45-0.45,0.91,0.45,1.69c0.91,0.78-0.45,0.39-1.69-0.45
				c-1.23-0.84-1.1,0.58-0.52,1.04c0.58,0.45-0.13,1.1-0.91,0.06c-0.78-1.04-1.17-2.14-2.4-2.21c-1.23-0.07-0.67,1.2-0.19,1.82
				c0.65,0.84,1.3,1.04,2.34,1.62c1.04,0.58,2.73-0.26,3.5-0.07c0.78,0.19-0.84,1.17-0.32,1.82c0.52,0.65,1.95,0.19,3.05,0.13
				c1.1-0.06,0.58-0.71,1.23-1.17c0.65-0.45-0.65-0.39-0.13-1.49C104.19,24.83,102.04,23.08,101.98,23.73z M104.7,19.9
				c0.97,0,1.75-0.13,2.6-0.45c0.84-0.32-0.45-0.32,0.13-1.1c0.58-0.78-1.43-0.84-1.56-0.58c-0.13,0.26-2.66-1.56-3.05-0.97
				C102.43,17.37,103.73,19.9,104.7,19.9z M109.96,20.22c-0.13-0.39-5.05,0.2-4.28,0.78C106.98,21.97,110.09,20.61,109.96,20.22z
				 M109.25,29.7c1.17-0.07,0.58-1.49-0.71-2.47c-1.3-0.97-4.21,1.03-3.7,1.43C105.42,29.11,108.08,29.76,109.25,29.7z
				 M100.94,38.78c1.43,0.97,0.97-0.78,2.6-0.71c1.62,0.07,1.69-2.6,1.95-3.57c0.26-0.97-1.3-0.91-0.91-0.19
				c0.39,0.71-0.07,1.43-0.2,0.58c-0.13-0.84-1.36-0.06-1.82-0.71c-0.45-0.65,1.43-0.71,1.88-1.56c0.45-0.84-1.49-0.71-1.04-1.36
				c0.45-0.65-2.86,0.06-1.88,0.45c0.97,0.39,0.07,0.91-0.84,0.26c-0.91-0.65-3.43,0.44-2.53,1.17c0.71,0.58,2.86-0.39,1.62,1.3
				c-1.23,1.69-1.75-0.45-2.92-0.26c-1.17,0.19-0.65,1.62,1.1,2.14C99.71,36.84,99.51,37.81,100.94,38.78z M111.52,28.4
				c0.45,1.3,0.97,0.39,1.69,0.91c0.71,0.52,1.82,0.84,2.4,0.32c0.58-0.52,0.84-0.91,1.04-0.45c0.32,0.74,1.75,0.84,4.87,0.91
				c3.12,0.07,2.34-1.3,3.25-0.78c0.91,0.52,2.99,0.52,3.89,0.32c0.91-0.19,1.36-1.62,1.3-2.34c-0.06-0.71-7.01-1.3-8.24-0.58
				c-1.23,0.71-3.18-0.32-4.15,0.19c-0.97,0.52-0.65-0.65-2.4-0.71c-1.75-0.06,0.13-0.97,0.45-1.36c0.32-0.39-2.66-1.43-3.89-1.17
				c-1.23,0.26-1.95-0.39-3.12-0.91c-1.17-0.52-4.34-0.4-3.7,0.52c0.45,0.65,4.22,2.01,4.61,1.36c0.39-0.65,1.36,0.78,1.95,1.49
				C112.04,26.84,111.06,27.1,111.52,28.4z M114.83,20.74c-1.17-0.58-2.63,0.65-1.43,1.1C114.44,22.23,116,21.33,114.83,20.74z
				 M105.48,13.47c0.96,0.48,0.39,1.1,1.43,1.56c1.04,0.45,4.02-0.58,4.28,0.13c0.26,0.71-3.05,0.78-2.6,1.23
				c0.45,0.45,2.34,1.17,2.08,1.56c-0.26,0.39,3.12,1.49,3.44,0.97c0.32-0.52,1.23-0.39,2.08-0.07c0.84,0.32,0.91-2.01,1.49-1.62
				c0.58,0.39,0.52-0.84,1.49-1.23c0.97-0.39,2.38-0.39,2.47-0.84c0.06-0.32-0.26-0.78-1.43-0.65c-1.17,0.13-1.95-0.32-1.43-0.97
				c0.52-0.65-1.3-0.84-0.65-1.36c0.65-0.52-1.23-1.04-1.04-0.26c0.2,0.78-1.17,0.06-1.36-0.65c-0.19-0.71-2.86-0.97-4.61-2.53
				c-1.75-1.56-3.57-0.52-2.53-0.32c1.04,0.19,0.97,0.71,0.13,0.65c-0.84-0.07-2.34,0.06-0.97,0.58c1.36,0.52-0.13,0.71-1.23,0.71
				c-1.1,0-1.17,1.43,0.26,1.62c1.43,0.19,0.39,1.04-0.78,0.71C104.83,12.37,104.44,12.95,105.48,13.47z M113.34,7.05
				c0.91-0.58,1.62-0.19,0.84,0.19c-0.78,0.39-0.52,0.65,0.58,0.58c1.1-0.06-0.45,0.32-0.45,0.97c0,0.65,1.3,0.26,1.36,0.91
				c0.06,0.65,2.86,0.91,3.7,0.13c0.84-0.78,0.52,0.19,0.45,0.71c-0.07,0.52,3.96,0.97,4.15,0.32c0.19-0.65,0.78-0.39,1.56-0.19
				c0.78,0.19,4.61-0.71,4.67-1.49c0.07-0.78,1.23,0.39,0,1.23c-1.23,0.84-3.7,0.71-4.8,1.04c-1.1,0.32,0.71,1.1,1.82,1.95
				c1.1,0.84-1.43,0.39-2.47-0.71c-1.04-1.1-3.38-1.1-4.61-1.04c-1.23,0.06-0.84,2.34,0.06,2.34c0.91,0,1.82,0.32,2.73,1.75
				c0.91,1.43,2.86,1.1,2.86,1.75c0,0.65-2.53-0.52-3.96-0.78c-1.43-0.26-3.7,0.65-3.76,1.69c-0.07,1.04,2.08,0.91,3.5,0.07
				c1.43-0.84,0.39,0.26-0.32,0.91c-0.71,0.65,1.69,1.17,1.69,1.95c0,0.78-1.75,0.52-1.95-0.2c-0.19-0.71-0.84-1.43-2.53-1.3
				c-1.69,0.13-1.23,1.3-0.19,1.43c1.04,0.13,1.1,0.91-0.07,0.91c-1.17,0-3.8,0.73-2.99,1.49c0.91,0.84,4.22,0,4.8,0.52
				c0.58,0.52,2.21,0.71,2.6,0.13c0.39-0.58,1.69-0.32,2.99-0.32c1.3,0,1.62,0.19,2.21,0.71c0.58,0.52,1.62,0.32,2.34-0.19
				c0.71-0.52,0.71-0.52,1.62-0.52c0.91,0,1.1-0.58,0.65-1.3c-0.45-0.71-1.62,0.52-1.75,0c-0.16-0.64-1.23-0.84-3.05-0.58
				c-1.82,0.26-1.04-1.17,0.07-0.84c1.1,0.32,2.6,0.19,3.83-0.13c1.23-0.32,0.26-0.91,0.26-1.43c0-0.52,1.56-0.19,2.73-0.19
				c1.17,0,2.86-1.88,2.92-2.99c0.07-1.1-2.6-0.84-3.89-0.84s0.84-0.97,3.31-0.84c2.47,0.13,1.36-0.97,1.82-1.36
				c0.45-0.39,1.62,0.45,2.86,0.19c1.23-0.26,0.39-1.3,1.23-1.3c0.6,0,1.95-0.91,4.61-2.4c2.66-1.49,4.74-1.23,4.87-2.08
				c0.13-0.84-3.7,0-4.35-0.26c-0.65-0.26,2.66-0.97,3.44-0.78c0.78,0.19,1.82,0,4.48-1.3c2.66-1.3,1.49-1.62,0.39-1.43
				c-1.1,0.19-2.34-0.2-2.27-0.91c0.06-0.71-1.62-0.07-1.69-0.45c-0.06-0.39-1.62-0.2-3.31,0.45c-1.69,0.65-0.13-0.65,0.58-0.91
				c0.71-0.26-4.28,0-5.13-0.58c-0.84-0.58-1.82,0.97-2.6,0.19c-0.78-0.78-2.79-0.13-2.53,0.45c0.26,0.58-0.32,0.58-1.1-0.07
				c-0.78-0.65-2.27,0.07-3.63-0.13c-1.36-0.2-0.78,1.1-2.27,0.45c-1.49-0.65-3.11-0.32-2.66-0.13c0.45,0.19-0.13,0.58-0.78,0.39
				c-0.65-0.19-1.23,0.06-0.91,0.71c0.32,0.65-2.4-0.19-2.4,0.52c0,0.71-0.97,1.1-1.75,0.45c-0.78-0.65-3.25-0.84-2.53-0.26
				c0.71,0.58-2.21,0.26-1.43,0.84c0.78,0.58-0.97,1.04-0.97,0.71c0-0.32-1.62-0.91-2.27-0.32c-0.65,0.58-0.84,1.3-1.17,0.91
				c-0.32-0.39-1.75-0.26-3.12,0.26C110.87,7.24,112.43,7.63,113.34,7.05z M129.69,63.06c0.45-0.06,1.36-1.69,0.19-1.62
				C128.72,61.5,128.99,63.16,129.69,63.06z M123.98,61.63c1.04,0.65,2.6-1.1,2.47-1.62C126.32,59.49,122.73,60.85,123.98,61.63z
				 M128.65,58.06c0.13-0.65-2.14-0.91-2.01-1.62c0.13-0.71-2.14-1.3-2.86-1.69c-0.71-0.39-1.82-0.58-1.82-1.36
				c0-0.78-1.56-0.2-1.62,0.97c-0.07,1.17-0.71,0.91-0.39,2.01c0.32,1.1-1.43,1.36-1.23,1.95c0.2,0.58,1.04-0.06,1.82-0.06
				c0.78,0-0.21,1.09,0.78,1.17c0.78,0.07,2.14-0.84,2.47-1.36c0.32-0.52,0.97-0.71,1.88-0.07
				C126.58,58.64,128.52,58.71,128.65,58.06z M149.88,52.35c0.78,0.07,0.91,1.04,2.14,2.08c1.23,1.04,1.36,0.32,1.56-0.39
				c0.2-0.71,1.56-0.13,1.49-0.84c-0.07-0.71,1.1-1.56,1.88-1.88c0.78-0.32-0.65-1.1-1.69-1.04c-1.04,0.07-1.23-0.65-1.23-1.1
				c0-0.45-1.95-1.56-2.6-1.36c-0.65,0.19-1.75-0.84-2.6-0.91c-0.84-0.06-2.08-0.71-1.95-1.62c0.13-0.91,1.69,0.26,2.01-0.45
				c0.32-0.71-1.62-0.32-1.69-0.84c-0.07-0.52,0.45-0.32,0.84-0.65c0.39-0.32-0.32-0.97-0.78-1.23c-0.45-0.26-0.58,0.52-1.04,0.52
				c-0.45,0-0.06-0.71,0.26-1.17c0.32-0.45-1.49-0.97-2.27-0.71c-0.78,0.26-1.04-0.19-1.04-0.71c0-0.52-1.36,0-1.88,0.39
				c-0.52,0.39-1.17-0.39-0.52-0.45c0.65-0.06,1.43-0.58,1.23-1.17c-0.19-0.58-1.88-0.65-2.21,0.07c-0.32,0.71-1.69-0.78-1.69-1.36
				c0-0.58-2.08-0.07-1.82-1.04c0.26-0.97-2.4-1.23-3.12-1.23c-0.71,0-1.75,0.58-1.62,1.23c0.13,0.65-0.78,0.39-0.91-0.32
				c-0.13-0.71-1.88,0.78-2.4,0.78c-0.52,0,0.32-1.88,0.06-2.47c-0.26-0.58-0.65-0.78-1.04-1.75c-0.39-0.97-2.34-0.58-2.86,0
				c-0.52,0.58-2.21,0.32-3.12,1.23c-0.91,0.91,0.06,1.82,0.45,2.14c0.39,0.32-1.36,0.84-0.78,1.36c0.58,0.52,1.49,0.26,1.56,1.1
				c0.06,0.84-2.14-0.26-2.47-0.97c-0.32-0.71,0.13-1.36-0.32-1.88c-0.45-0.52-0.06-1.17,0.78-2.08c0.84-0.91,1.49-0.78,1.49-1.3
				c0-0.52-3.63-0.52-5.78,1.43c-2.14,1.95-1.49,4.54-1.36,5.06c0.13,0.52,2.01,0.26,3.11,0.58c1.1,0.32,0.52,0.78-0.32,0.65
				c-0.84-0.13-2.27-0.39-2.21,0.19c0.06,0.58,1.82,1.69,2.86,1.43c1.04-0.26,1.04-0.26,1.49,0.32c0.45,0.58,1.69,0.39,2.92,0.45
				c1.23,0.06,2.66,0.52,3.25,0.58c0.58,0.07,1.3-0.32,1.49-0.65c0.19-0.32,2.6,0.52,3.31,0.45c0.71-0.06,0.26-0.71-0.19-0.91
				c-0.45-0.19-0.26-1.04,0.32-0.71c0.58,0.32,1.56,0.52,1.75,1.1c0.17,0.5,0.71,0.26,0.78,0.71c0.06,0.45,2.14,0.97,2.21,1.49
				c0.07,0.52-2.01,0.78-1.43,1.23c0.58,0.45,1.49-0.58,2.27-0.65c0.78-0.07,0.78,1.69,1.36,1.43c0.8-0.35,1.43,0.32,2.21,1.43
				c0.78,1.1-0.06,2.14,0,2.53c0.07,0.39,1.56,0.26,2.4-0.19c0.84-0.45,1.62,0.52,2.27,1.1c0.65,0.59-2.4,1.56-2.14,1.04
				c0.26-0.52-1.82-2.08-3.7-1.36c-1.88,0.71-0.58,1.82-0.39,2.47c0.19,0.65-1.69,1.3-3.44,0.71c-1.75-0.58-1.23,0.39-1.95,0.39
				c-0.71,0-1.49,1.1-0.84,1.82c0.65,0.71,2.08,0.07,3.25,0.13c1.17,0.06,1.3,0.39,1.43-0.26c0.13-0.65,1.36-0.32,2.21-0.13
				c0.84,0.19,0.65,1.36,1.69,1.49c1.04,0.13,0.2,1.17,0.84,1.88c0.65,0.71,2.73,0.32,3.5,1.1c0.78,0.78,4.08,2.33,4.54,1.75
				c0.52-0.65-2.86-3.63-3.83-3.89c-0.97-0.26,0.97-0.58,2.21,0.39c1.23,0.97,3.05,1.43,3.96,0.26c0.91-1.17-0.78-0.84-0.78-1.69
				s-0.58-2.01-1.49-2.01c-0.91,0-3.24-1.82-2.4-2.21c0.84-0.39-0.26-0.91,0.39-1.62C148.45,50.92,149.1,52.28,149.88,52.35z
				 M136.18,49.23c0.78-0.13,0.63-1.33,0.19-1.95c-0.32-0.45-1.17-0.52-1.88-0.45c-0.71,0.06-1.95,1.17-1.3,2.27
				C133.85,50.21,135.4,49.36,136.18,49.23z M128.07,32.36c-0.39,0.84,0.84,0.84,0.91,1.82c0.06,0.97,1.3,1.3,2.21,0.71
				c0.91-0.58,3.5,0.17,3.5-0.58c0-0.71-2.73-2.01-3.7-1.82C130.02,32.68,128.46,31.52,128.07,32.36z M152.41,87.59
				c0.13,0.52,3.89,2.08,4.02,1.36C156.56,88.24,152.3,87.15,152.41,87.59z M168.87,91.46c-0.26,0.48-0.65-0.35-0.09-0.78
				c0.56-0.43-0.09-0.6-0.61-0.35c-0.52,0.26-0.39-1.17,0.09-1.43c0.48-0.26-1.17-0.78-1.17-0.3c0,0.48-1.17,0.17-1.3-0.26
				c-0.13-0.43-0.95-0.56-0.65-0.86c0.3-0.3-1-0.48-1.13,0.04c-0.13,0.52-0.52-0.35,0.13-1.04c0.65-0.69,0.43-1.3,1.04-1.64
				c0.61-0.35-0.04-0.73-0.69-0.48c-0.65,0.26-2.38,2.64-2.38,3.29c0,0.65-0.04,1.43-0.65,1.38c-0.61-0.04-1.3,0.52-0.82,0.87
				c0.48,0.35-1.26,1.33-0.43,1.77c0.56,0.3,1.47-0.13,2.25-0.04c0.78,0.09,1.43,0.13,2.08-0.35c0.65-0.48,0.65,0.43,1.34,0.35
				c0.69-0.09-0.39,0.56-0.35,1.04c0.04,0.48,0.74-0.26,1.26-0.52c0.52-0.26,1.17,0,0.91,0.43c-0.26,0.43,1.43,0.86,1.82,0.17
				C169.91,92.07,169.13,90.99,168.87,91.46z M51.56,79.54c-1.17,0.62,1.64,3.58,2.23,3.21c0.4-0.26-0.69-1.5-0.69-2.26
				C53.09,79.72,52.73,78.92,51.56,79.54z M158.51,94.34c-0.45,0.07-0.1-0.65-0.03-1.01c0.07-0.36-0.97,0.16-1.01,0.58
				c-0.03,0.42-0.75,0.58-0.75,0.84c0,0.76-0.1,0.36-0.62,0.62c-0.52,0.26-2.53,0-2.6-0.45c-0.07-0.45-1.53-0.68-1.59-1.3
				c-0.07-0.62-0.78-0.81-0.2-1.49c0.58-0.68-0.1-1.01-0.65-0.49c-0.55,0.52-0.88-0.29-0.13-0.58c0.75-0.29,2.14-0.58,1.43-1.88
				c-0.71-1.3-5.19,0.39-6.33,1.04c-1.14,0.65-2.56,2.73-3.18,2.73c-0.62,0,1.07-1.1,1.33-1.53c0.26-0.42-0.06-0.84,0.29-0.68
				c0.36,0.16,1.36-1.4,2.04-1.91c0.68-0.52,1.43-0.03,1.53-0.45c0.1-0.42,0.42-0.71,1.07-1.3c0.65-0.58,8.4-0.49,9.15-0.45
				c0.75,0.03,2.21-0.97,2.63-1.62c0.42-0.65,0.97-0.65,2.01-0.58c1.04,0.06,1.52-0.88,2.04-1.23c0.52-0.36,0.32-0.52-0.13-0.62
				c-0.45-0.1-0.88-0.39-0.42-0.42c0.45-0.03,0.68-0.29,0.88-0.88c0.19-0.58-0.16-0.32-0.78-1.14c-0.62-0.81-0.94,0.26-1.56,0.19
				c-0.62-0.06,0.42-0.84-0.1-1.17c-0.52-0.32-1.49-0.1-2.27,0.55c-0.78,0.65-0.71-0.03-1.4,0.62c-0.68,0.65-0.68-0.32-0.36-0.58
				c0.32-0.26,0.55,0.03,0.84-0.36c0.29-0.39,0.58,0,1.33-0.49c0.75-0.49,1.4-0.26,1.62-0.62c0.23-0.36-0.36-0.84-1.07-0.68
				c-0.71,0.16-1.2-0.97-1.66-0.65c-0.45,0.32-0.36-0.49-0.88-0.16c-0.52,0.32-0.81-0.1-1.04-0.71c-0.23-0.62-1.62-1.62-2.47-1.88
				c-0.84-0.26-0.29-0.71,0.16-0.39c0.45,0.32,0.97-0.32,1.17-0.65c0.19-0.32-0.32-0.62-0.58-0.65c-0.26-0.03-0.26-0.46-0.19-0.78
				c0.06-0.32-0.91-0.75-0.94-1.07c-0.03-0.32-0.58-0.55-0.55-0.84c0.03-0.29-0.55-0.71-0.55-1.1c0-0.39-0.97-1.17-1.01-1.62
				c-0.03-0.45-0.55-0.78-0.75-1.33c-0.19-0.55-0.45-0.29-0.65,0.49c-0.19,0.78-0.88,0.75-0.68,1.01c0.4,0.53,0.1,0.55-0.19,0.88
				c-0.29,0.32,0.19,0.81-0.29,0.81c-0.49,0-0.26,1.01-0.52,0.55c-0.26-0.45-0.88-0.32-0.88,0.13c0,0.45-1.04,0.88-1.46,0.84
				c-0.42-0.03-0.42-0.91-0.78-0.65c-0.36,0.26-0.29-0.75-0.84-0.71c-0.55,0.03-0.94-0.03-0.78-0.52c0.16-0.49-0.94-0.71-0.58-1.01
				c0.36-0.29-0.1-0.81-0.16-1.65c-0.06-0.84,0.49-0.91,0.49-1.23c0-0.32-0.39-0.32-0.78-0.06c-0.39,0.26-0.39-0.36-0.81-0.32
				c-0.42,0.03-1.69-0.03-1.75-0.62c-0.07-0.58-0.49-0.29-0.68-0.84c-0.2-0.55-0.71-0.13-0.71-0.52c0-0.39-0.23-0.55-0.62-0.58
				c-0.39-0.03-0.52-0.45-1.04-0.52c-0.52-0.07-1.17,0.36-1.46,0.49c-0.29,0.13-1.04-0.45-1.33-0.23c-0.29,0.23-0.36-0.16-1.17-0.39
				c-0.81-0.23-1.88-0.29-1.95,0c-0.07,0.29-0.71,0.39-0.62,1.07c0.1,0.68,0.78,0.55,0.94,1.01c0.16,0.45-0.32,0.29-0.36,0.71
				c-0.03,0.42-0.45,0.39-0.45,0.68c0,0.29,0.29,0.23,0.45,0.81c0.16,0.58,0.49,0.55,0.58,1.33c0.1,0.78-0.26,0.62-0.58,0.71
				c-0.32,0.1,0.03,0.49-0.42,1.01c-0.45,0.52-0.84,1.07-0.49,1.4c0.36,0.32,1.69,0.88,2.21,1.75c0.52,0.88,0.49,2.24,0.32,3.21
				c-0.16,0.97-1.27,1.2-2.08,2.04c-0.81,0.84-1.66,0.71-2.04,0.75c-0.39,0.03-0.16,0.81,0.39,1.43c0.55,0.62,0.1,0.81,0.39,1.46
				c0.29,0.65,0,1.17,0.42,1.75c0.42,0.58,0.16,0.81-0.32,1.23c-0.49,0.42,0.2,0.19,0.23,0.75c0.03,0.55-0.23,0.23-0.65-0.07
				c-0.42-0.29-0.81,0.55-0.81,0.91c0,0.36-1.01-0.49-1.23-0.45c-0.23,0.03,0.06-0.55-0.52-1.01c-0.58-0.45-1.04-0.55-1.04-1.01
				c0-0.45-1.01-0.78-1.01-1.3c0-0.52,0.13-1.85-0.1-2.24c-0.23-0.39-0.03-0.81,0.16-1.17c0.19-0.36-0.23-0.91-0.81-0.91
				s-1.4-0.19-2.21-0.13c-0.81,0.07-1.27-0.06-1.53-0.29c-0.26-0.23-1.01-0.71-2.01-0.97c-1.01-0.26-1.46-0.78-1.56-1.07
				c-0.1-0.29-1.1-0.52-1.33-0.81c-0.23-0.29-1.2-0.45-1.59-0.39c-0.39,0.06-1.36-0.81-1.95-0.78c-0.58,0.03-1.95,0.62-2.17,0.65
				c-0.23,0.03,0.03-0.49,0.19-0.71c0.16-0.23-0.49-1.2-0.65-1.85c-0.16-0.65-0.39-1.36-1.07-1.27c-0.68,0.1-1.43-0.26-1.62-0.39
				c-0.19-0.13-0.23-1.98-0.06-2.76c0.16-0.78,1.01-1.95,1.01-2.43c0-0.49,0.55-0.71,1.04-0.84c0.49-0.13,0.1-1.07,0.58-1.14
				c0.49-0.07,0.52-0.26,0.62-0.65c0.1-0.39,0.68-0.13,0.36-0.45c-0.32-0.32-0.06-0.42,0.68-0.42c0.75,0,1.85-0.26,1.69-1.01
				c-0.16-0.75-2.11-0.19-2.11-0.81c0-0.62-2.47-0.65-2.3-1.01c0.16-0.36,2.47,0.26,3.31,0.58c0.84,0.32,1.07,0.29,1.75,0.26
				c0.68-0.03,0.23-0.91,0.36-1.27c0.13-0.36,1.43,0.55,2.17,0.36c0.75-0.19,1.46-1.72,2.08-2.14c0.62-0.42,0.06-0.81-1.14-0.71
				c-1.2,0.1-1.88-0.36-2.53-0.84c-0.65-0.49-0.58-0.88,0.1-0.81c0.68,0.06,2.3,1.4,2.92,1.43c0.62,0.03,1.69-1.2,2.21-1.59
				c0.52-0.39-0.49-0.55-0.84-0.84c-0.36-0.29,0.62-0.49,1.14-0.49c0.52,0,0.75,0.45,0.94,0.68c0.19,0.23,1.1-0.16,1.56-0.16
				c0.45,0-0.07-0.75-0.81-1.04c-0.75-0.29,0.03-0.84,0.26-0.39c0.23,0.45,0.75,0.36,1.01,0.88c0.38,0.76,0.62,0.26,1.27,0.03
				c0.65-0.23,0.97-0.62,1.33-1.01c0.36-0.39,0.42,0.06,0.84-0.55c0.42-0.62-0.19-1.2-0.71-1.59c-0.52-0.39,0.03-0.78-0.49-1.04
				c-0.52-0.26-0.62-0.75,0.03-0.62c0.65,0.13,1.17-0.07,1.46-0.52s-0.97-0.55-0.39-0.88c0.58-0.32,0.1-0.65-0.49-0.65
				c-0.58,0-1.07-0.29-1.1-0.78s-1.04-0.16-1.65-0.45c-0.62-0.29-1.98-0.23-2.3-0.19c-0.32,0.03-0.36,1.36,0.13,1.4
				c0.49,0.03,0.88,0.55,0.45,0.71c-0.42,0.16,0.07,0.68-0.26,0.65c-0.32-0.03-0.84-0.19-0.94,0.65c-0.1,0.84-0.16,1.17-0.71,1.46
				c-0.55,0.29-0.13-0.94-0.65-0.91c-0.52,0.03-0.58,1.04-0.19,1.14c0.39,0.1,0.45,0.42,0.55,0.91c0.1,0.49-0.71,0.13-1.04,0.71
				c-0.32,0.58-0.36-0.26-0.81-0.65c-0.45-0.39-1.01-1.04-0.97-1.53c0.03-0.43,0.26-0.58,0.62-0.65c0.36-0.07,0-0.39-0.03-0.97
				c-0.03-0.58-0.45-0.39-0.97-1.01c-0.52-0.62-1.07-0.62-1.36-0.32c-0.29,0.29-0.1,0.84-0.55,1.04c-0.45,0.19-0.26,1.1-0.65,1.17
				c-0.39,0.07-0.16-1.59-0.49-1.75c-0.32-0.16-0.29-0.55,0.19-0.62c0.49-0.07,0.78-0.58,0.36-0.58c-0.42,0-1.17-0.45-1.46-0.58
				c-0.29-0.13-0.78,0.55-1.1,0.29c-0.32-0.26,0.03-0.91,0.06-1.27c0.03-0.36,0.52,0.16,0.84-0.29c0.32-0.45-0.91-0.45-0.91-0.94
				c0-0.49-0.97-0.62-1.3-0.78c-0.32-0.16,0.23-0.97,0.06-1.3c-0.16-0.32-1.36-1.4-1.88-1.3c-0.52,0.1-0.65-0.68-0.36-0.65
				c0.29,0.03,0.52-0.16,1.07-0.78c0.55-0.62,0.62-0.81,0.23-0.94c-0.39-0.13-0.58-0.52,0.1-0.49c0.68,0.03,1.88,0.49,2.27,0.19
				c0.39-0.29,1.62-2.21,2.11-2.89c0.49-0.68-0.36-0.75-1.53-0.65c-1.17,0.1-1.43-0.45-2.4-0.52c-0.97-0.06-2.79,0.19-3.05,0.49
				c-0.26,0.29,0.49,0.55,0.39,0.88c-0.1,0.32-0.68-0.23-0.94-0.1c-0.26,0.13,0.1,0.55,0,1.69c-0.1,1.14,0.23,1.2,0.55,1.62
				c0.32,0.42,0.26,0.97,0.26,1.69c0,0.71-0.68,0.68-0.91,0.84c-0.23,0.16,0.58,0.42,0.45,0.88c-0.13,0.45-0.81-0.26-1.2-0.13
				c-0.39,0.13-0.71,1.23-0.29,1.59c0.42,0.36,0.58,0.29,0.26,0.71c-0.32,0.42-0.68,1.04,0.1,1.65c0.78,0.62,2.21,0.55,2.95,1.04
				c0.75,0.49-0.32,0.19-0.03,0.75c0.29,0.55-0.49,0.75-0.45,1.17c0.03,0.42,0.32,0.19,0.71-0.52c0.39-0.71,0.68,0.19,0.65,0.81
				c-0.03,0.62-0.58,0.26-1.01,0.88c-0.42,0.62-0.81,0.71-1.43,0.58c-0.62-0.13-0.26,0.88,0,1.43c0.26,0.55-0.07,0.75-0.78,0.55
				c-0.71-0.19-1.14-0.65-0.78-1.2c0.36-0.55,0.62-1.2,0.2-1.14c-0.42,0.07-1.4-0.03-1.69-0.49c-0.29-0.45,0.2-0.26,1.07-0.16
				c0.88,0.1,0.13-0.58,0.78-0.58c0.65,0,1.14,0.36,1.69-0.13c0.55-0.49-0.36-1.43-0.81-1.27c-0.45,0.16-1.17,0.36-1.17-0.1
				c0-0.45,0.78-0.13,1.2-0.23c0.42-0.1-0.23-0.68-0.88-0.52c-0.65,0.16-1.1,0.19-1.62-0.36c-0.52-0.55-1.46-0.88-1.69,0.39
				c-0.23,1.27-1.14,0.58-1.49,1.01c-0.36,0.42,0.42,0.71,1.3,0.75c0.88,0.03,1.52,0.84,1.1,0.84c-0.42,0-0.23,0.62-0.68,0.39
				c-0.45-0.23-0.91,0-0.58,0.39c0.32,0.39-0.13,0.55-0.1,0.94c0.03,0.39-0.68,0.03-1.36-0.03c-0.68-0.06-1.95,0.16-2.86,0.36
				c-0.91,0.19-1.91-0.13-2.3-0.55c-0.39-0.42-0.91-0.36-1.56-0.36c-0.65,0-0.45-0.62-1.3-0.65c-0.84-0.03-0.65-0.75-0.75-1.17
				c-0.1-0.42-1.78-0.29-2.4,0.1s-1.82,0.23-2.14,0.94c-0.32,0.71,0.29,0.62,0.65,0.62c0.5,0,0.45-0.58,1.36-0.45
				c0.91,0.13,1.56-0.94,1.95-0.55c0.39,0.39-1.56,1.14-2.4,1.27c-0.84,0.13-0.65,0.81,0.16,2.01c0.81,1.2-0.36,0.52-0.36,1.1
				c0,0.58-1.53-0.19-0.91-0.39c0.62-0.19,0.32-0.81-0.16-1.2c-0.49-0.39-0.81,0-0.75-0.42c0.06-0.42-0.52,0.03-0.97-0.32
				c-0.45-0.36-0.68-0.65-1.3-0.32c-0.62,0.32-1.33,0.23-2.69,0.45c-1.36,0.23-3.86,0.16-4.32-0.29c-0.45-0.45,1.17-1.27,1.65-1.14
				c0.47,0.13,0.19-0.52-0.55-1.1c-0.75-0.58-2.4-0.94-2.37-0.58c0.03,0.36-0.94,0.03-2.14-0.19c-1.2-0.23-1.36-0.65-2.14-0.68
				c-0.75-0.03-1.95-0.26-2.86-0.97c-0.91-0.71-3.05-0.52-3.31,0.13c-0.26,0.65-0.97,0.62-1.69,0.62c-0.71,0,0.32-0.8,0.06-0.94
				c-0.34-0.18,0.03-1.07-0.49-1.14c-0.52-0.07-1.01,2.14-1.98,2.08c-0.97-0.06-1.4-2.34-2.21-2.92c-0.81-0.58-1.56-0.62-0.88,0.26
				c0.68,0.88-0.58,0.26-0.52,0.84c0.06,0.58-1.1,1.27-1.07,0.94c0.03-0.32-0.65-0.68-1.43,0.23c-0.78,0.91-1.33,0.78-1.4,0.45
				c-0.06-0.32-2.37,0.84-2.27,1.23c0.1,0.39-0.36,0.68-0.97,0.65c-0.62-0.03-0.13-0.55,0.36-0.81c0.55-0.29,2.04-1.49,2.82-1.62
				c0.78-0.13,2.27-0.75,2.3-1.1c0.03-0.36-0.75-0.07-1.27-0.16c-0.52-0.1-1.43,0.49-2.3,0.88c-0.88,0.39-1.88,0.26-1.62,0.78
				c0.26,0.52-0.81-0.07-1.04,0.29c-0.23,0.36-0.65-0.03-0.32-0.23c0.32-0.19-0.78-0.55-0.78-0.32c0,0.23-0.19,0.52-0.97,0.52
				c-0.78,0-1.56,0.55-1.04,0.94c0.52,0.39,0.84,0.58,0.58,0.84c-0.26,0.26-1.07-0.68-2.21-0.58c-1.14,0.1-3.41-1.27-3.41-1.59
				c0-0.26-1.6-0.1-2.7-0.22v22.43c0,0,0.56,0.42,0.78,0.16c0.22-0.26,0.61,0.09,1.34-0.26c0.74-0.35,0.99,0.39,0.99,0.73
				c0,0.35,1.04,0.91,1.47,1.47c0.43,0.56,0.65,1.04,1.13,0.74c0.48-0.3,1.34-0.48,1.34-1.04c0-0.56,1.99-0.65,1.99,0.09
				c0,0.74,2.03,1.6,3.16,3.59c1.13,1.99,0.95,2.42,2.6,3.07c1.64,0.65,1.6,1,1.38,1.56c-0.22,0.56,1,0.86,0.3,1.34
				c-0.27,0.18-0.64,0.45-1,0.71c0.03,0.29,0.29,0.47,0.62,0.74c0.39,0.32-0.71,0.32-0.39,0.62c0.32,0.29-0.06,0.75,0.26,1.1
				c0.32,0.36,0.91,0.23,1.27-0.06c0.36-0.29,0.62,0.36,0.32,0.84c-0.29,0.49,0.32,0.84,0.62,0.49c0.29-0.36,0.91,0.58,1.2,0.75
				c0.29,0.16,0.58,0.65,0.1,0.65c-0.49,0-0.42,0.84-0.06,0.88c0.36,0.03,0.32,0.49,0.32,0.78c0,0.29,1.14,0.71,1.56,0.58
				c0.42-0.13,0.81,0.26,1.01,0.62c0.19,0.36,0.97,0.62,1.27,0.39c0.29-0.23,0.49,0.42,0.55,0.88c0.06,0.45,0.71,0.19,1.1,0.16
				c0.39-0.03,0.62,0.52,0.88,0.42c0.57-0.22,0.74,0.78,1.12,1.37l40.33-0.22c0,0,0.03-1,0.5-0.66c0.48,0.35,0.04,0.91,0.61,1.13
				c0.56,0.22,0.95,0.56,1.26,0.43c0.3-0.13,0.82-0.56,1.34,0.04c0.52,0.6,0.91,0.09,1.21,0.48c0.3,0.39,0.87,0.69,1.25,0.22
				c0.32-0.39,1.51,0.08,2.09,0.29c0.71-0.57,1.27-1.79,1.91-1.79c0.84,0,2.99,0.19,3.12,1.3c0.13,1.1,1.43,0.07,1.56,1.1
				c0.11,0.88,0.97,1.58,0.82,2.04c0.25,0.15,0.56,0.36,0.83,0.58c0.41-0.14,1.51,0.04,2.24,0.3c0.91,0.32,2.27-0.39,2.73,0.71
				c0.45,1.1,1.51,1.94,0.52,2.01c-0.84,0.07-1.95-1.1-1.88-0.45c0.06,0.65-0.45,0.32-0.45,1.3c0,0.97-0.65,2.34-1.04,2.27
				c-0.01,0-0.02-0.01-0.04-0.02c-0.07,0.37-0.07,0.85,0.24,1.15c0.49-0.36,1.2-0.98,1.81-0.94c0.97,0.06,1.23,0.65,1.88,0.19
				c0.65-0.45,0.78-0.65,0.91-0.32c0.02,0.04,0,0.09-0.02,0.15c0.25-0.13,0.42-0.26,0.43-0.36c0.02-0.16-0.03-0.38-0.08-0.58
				c-0.65,0.09-0.86,0.14-1.04-0.31c-0.26-0.65,1.95-1.3,2.99-1.17c0.98,0.12,1.68-0.57,2.02-0.14c0.62-0.56,1.69-1.48,1.96-1.48
				c0.39,0,5.02-0.04,5.02-0.04s1.6-0.99,1.82-1.9c0.22-0.91,1.25-3.07,1.86-2.6c0.61,0.48,1.9,0.13,1.95,0.56
				c0.04,0.4-0.43,1.87,1.23,4.07c0.59-0.37,1.19-0.66,1.54-0.64c0.58,0.03,1.46-0.75,1.72-1.23c0.26-0.49,0.62,0.45,0.1,0.62
				c-0.52,0.16,0.42,0.55,1.33,0.52c0.91-0.03-0.42,0.32-1.1,0.29c-0.68-0.03-0.97,0.06-1.88,0.97c-0.91,0.91-0.68,1.17-0.16,1.62
				c0.52,0.45,1.2,0.26,1.91-0.36c0.71-0.62,0.68-1.23,1.4-1.2c0.71,0.03,2.56-0.65,3.38-0.91c0.81-0.26,0.29-0.39,0.29-0.75
				c0-0.36,1.43-0.36,1.95-0.71C159.58,94.57,158.96,94.28,158.51,94.34z M74.04,52.28c-0.85,1.06-1.14,1.21-2.01,1.06
				c-0.88-0.15-1.42,0.28-0.98,0.59c0.44,0.31,0.46,0.57-0.44,0.77c-0.9,0.21-1.5,0.9-1.86,0.83c-0.36-0.08,1.55-1.44,1.06-1.81
				c-0.49-0.36-1.7,0.62-1.73,1.06c-0.03,0.44-0.83,0.46-1.32,0.44c-0.49-0.03-1.14-0.49-0.65-0.54c0.49-0.05,0.15-0.59,0.83-0.85
				c0.67-0.26-0.13-0.64,0.08-0.93c0.21-0.28,1.57,0.28,1.6-0.23c0.03-0.52-1.37-0.96-1.83-0.65c-0.46,0.31-1.55,1.03-2.84,0.46
				c-0.41-0.18,3.25-0.57,3.87-1.13c0.62-0.57,3.41-1.21,3.61-0.7c0.21,0.52-1.5,0.85-0.67,1.32c0.83,0.46,2.17,0.16,2.48-0.34
				C73.55,51.15,74.9,51.22,74.04,52.28z M82.25,62.7c-1.01,0.88-0.93,0.64-1.81,0.8c-0.88,0.15-0.18,1.03-1.24,1.11
				c-1.06,0.08-3.61,0.18-3.97-0.41c-0.12-0.2,1.39-0.05,1.5-0.49c0.1-0.44,0.52-1.14,1.16-1.14s0.59-0.85-0.46-1.37
				c-1.06-0.52,1.52-0.85,2.12-0.03c0.59,0.83,1.65,1.42,2.25,0.83c0.59-0.59,3.97-2.25,3.97-1.47
				C85.76,61.31,83.26,61.83,82.25,62.7z M104.83,86.29c-0.71,0.08,0.06-1.88-1.23-2.6c-1.53-0.84-2.21-2.4-2.21-3.24
				c0-0.84,1.3-1.23,1.82-0.58c0.52,0.65,1.3,3.31,1.75,4.15C105.42,84.87,105.42,86.23,104.83,86.29z"/>
			<g className="hoverEf canada">
				<g>
					<path d="M90.07,60.2c-0.19,0.09-0.61,0.2-1.14,0.2c-1.39,0-2.24-0.87-2.24-2.21c0-1.44,1.01-2.31,2.35-2.31
						c0.53,0,0.9,0.11,1.06,0.19l-0.17,0.64c-0.2-0.09-0.5-0.17-0.85-0.17c-0.89,0-1.54,0.56-1.54,1.61c0,0.97,0.56,1.58,1.53,1.58
						c0.32,0,0.66-0.06,0.87-0.16L90.07,60.2z"/>
					<path d="M92.5,60.33l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H92.5z M92.39,58.78c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V58.78z"/>
					<path d="M94.02,58.11c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55c0.55,0,1.12,0.36,1.12,1.36
						v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V58.11z"/>
					<path d="M99.52,60.33l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H99.52z M99.41,58.78c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V58.78z"/>
					<path d="M103.96,55.72v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
						c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01v-1.79H103.96z M103.16,58.49
						c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
						c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V58.49z"/>
					<path d="M106.6,60.33l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H106.6z M106.49,58.78c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V58.78z"/>
				</g>
			</g>
		</g>
		<g className="mouseOver USCountry">
			<path class="st5 lightBlue" d="M55.68,76.1c0.22-0.56,0.26-0.91-1.38-1.56c-1.64-0.65-1.47-1.08-2.6-3.07c-1.13-1.99-3.16-2.86-3.16-3.59
				c0-0.74-1.99-0.65-1.99-0.09c0,0.56-0.87,0.74-1.34,1.04c-0.48,0.3-0.69-0.17-1.13-0.74c-0.43-0.56-1.47-1.12-1.47-1.47
				c0-0.35-0.26-1.08-0.99-0.73c-0.74,0.35-1.13,0-1.34,0.26c-0.22,0.26-0.78-0.16-0.78-0.16V43.56c-0.26-0.03-0.49-0.07-0.67-0.14
				c-0.94-0.36-2.34-1.01-3.08-0.84c-0.75,0.16-2.24,0.36-2.76,0.1c-0.52-0.26-1.23-0.49-2.3-0.32c-1.07,0.16-1.75-0.58-3.12-0.91
				c-1.36-0.32-3.28,0.1-3.73,0.26c-0.45,0.16-0.36-0.49-0.94-0.42c-0.58,0.07-0.13-0.26-0.45-0.75c-0.32-0.49-1.95-0.03-2.53,0.03
				c-0.58,0.06-0.75-0.29-0.71-0.58c0.03-0.29-0.52-0.19-0.75,0.19c-0.23,0.39-0.88,0.45-1.14,0.2c-0.26-0.26,0.55-0.36,0.58-0.65
				c0.03-0.29-0.84-0.45-1.17-0.55c-0.32-0.1-0.84,0.32-1.53,0.94c-0.68,0.62-1.69,0.55-2.3,0.49c-0.62-0.06-1.4,0.07-1.36,0.42
				c0.03,0.36,0.78,0.32,0.32,0.78c-0.45,0.45-0.62-0.62-1.07-0.13c-0.45,0.49-1.82,0.45-2.14,0.39c-0.32-0.06-1.4,1.1-1.72,1.43
				c-0.32,0.32,0.26,0.62-0.62,1.56c-0.88,0.94-3.21,0.75-3.7,0.75c-0.49,0-0.13,0.55-0.39,0.91c-0.26,0.36,0.29,0.68,1.62,1.04
				s2.24,2.11,2.4,2.53c0.16,0.42,1.82-0.03,2.47,0.07c0.65,0.1-0.07,1.01,0.49,1.27c0.55,0.26,1.4-0.13,1.56,0.32
				c0.16,0.45-1.1,0-1.75,0.49c-0.65,0.49-0.97,0.71-1.23,0.36c-0.26-0.36-1.33-0.13-1.88-0.06c-0.55,0.06-0.03-0.68,0-1.01
				c0.03-0.32-0.75-0.55-1.82,0.07C3,52.38,3.39,52.15,3.26,52.64C3.13,53.13,2.68,52.35,2,52.77c-0.68,0.42-1.79,0.71-1.98,1.14
				c-0.19,0.42,1.46,0.71,2.11,0.84c0.65,0.13-0.78,0.55-0.26,0.71c0.52,0.16,0.29,0.65,1.1,1.07c0.81,0.42,2.63,0,3.12,0
				s0.94,0.58,1.33,0.16c0.39-0.42,1.85-1.59,2.6-0.91c0.75,0.68-0.81,0.58-0.42,1.01c0.39,0.42,0.88,1.43,0.16,1.95
				c-0.71,0.52-1.4,0.16-1.79,0.13c-0.39-0.03-0.16,0.71-0.78,1.2c-0.62,0.49-1.07-0.52-1.85-0.52c-0.78,0-0.62,1.01-0.58,1.49
				c0.03,0.49-1.17-0.1-1.46,1.07c-0.29,1.17-1.53,0.16-0.71,1.3c0.81,1.14,0.68,0.42,1.33,0.88c0.65,0.45-0.71,1.27-0.07,1.4
				c0.65,0.13,1.36,1.3,1.82,1.72c0.45,0.42,1.04-0.23,1.72-0.26c0.68-0.03,0.36-0.97,0.75-0.62c0.39,0.36,0.94,1.39,0.58,1.65
				c-0.36,0.26-0.07,1.07-0.13,1.46c-0.07,0.39,1.49,0.26,1.59-0.19c0.1-0.45,1.33-0.58,2.01,0.16c0.68,0.75,0.91,0.88,0.91,0.29
				c0-0.58,0.58-1.23,0.62-0.75c0.03,0.49,0.58,0.62,1.59,0.16c1.01-0.45,0.55,0.03,0.03,0.68c-0.72,0.9,0,2.21-0.52,2.34
				c-0.52,0.13-0.94,1.46-1.79,1.56c-0.84,0.1-2.27,1.69-2.47,1.95c-0.19,0.26-2.01-0.52-2.27,0.23c-0.26,0.75-1.36,1.3-1.07,1.59
				c0.19,0.19,1.69-0.55,1.75-0.94c0.06-0.39,0.26-0.32,0.52-0.03c0.26,0.29,0.91-0.13,1.2-0.39c0.29-0.26,0.78-0.16,1.07-0.13
				c0.29,0.03,0.42-0.36,1.3-0.52c0.88-0.16,0.68-0.42,0.88-0.88c0.2-0.45,2.27-1.43,2.66-1.53c0.39-0.1,0.16-0.75,0.58-0.78
				c0.42-0.03,1.07-0.55,1.62-1.01c0.55-0.45,0.68-0.26,1.1-0.42c0.42-0.16,0.1-1.01,0.52-1.07c0.42-0.06,0.75-0.39,0.75-0.75
				c0-0.36-1.07-0.36-1.17-0.58c-0.1-0.23,0.84-1.07,1.2-1.04c0.36,0.03,0.94-0.36,0.97-0.88c0.03-0.52,0.55-0.62,0.97-1.07
				c0.42-0.45,0.32-0.84,0.75-0.88c0.42-0.03,0.81-0.62,1.27-0.88c0.45-0.26,0,0.32,0.88,0.36c0.88,0.03,0.68,0.84,0.03,0.55
				c-0.65-0.29-0.94-0.26-1.52,0.29c-0.58,0.55-0.13,0.71-0.58,1.33c-0.45,0.62-0.49,1.01,0.1,1.07c0.58,0.06,0.03,0.49-0.52,0.65
				c-0.55,0.16-0.16,0.49,0.58,0.49c0.75,0,1.85-1.07,2.89-1.59c1.04-0.52,1.82,0,2.11-0.1c0.29-0.1-0.32-0.58,0.03-0.75
				c0.36-0.16-0.42-0.26-0.65-0.88c-0.23-0.62,0.36-0.23,0.49-0.71c0.13-0.49,0.49-0.29,0.81-0.07c0.32,0.23,0.78-0.23,1.07,0.39
				c0.29,0.62,0.62-0.16,1.14,0.26c0.52,0.42-0.1,0.36-0.65,0.49c-0.55,0.13-0.26,0.88,0.16,0.52c0.42-0.36,0.78-0.58,1.17-0.23
				c0.39,0.36,0.55,0.19,0.88-0.13c0.32-0.32,0.32,0.03,0.32,0.29s0.68,0.36,1.66,0.52c0.97,0.16,2.73-0.07,3.18-0.1
				c0.45-0.03,0.29,0.68,1.3,0.88c1.01,0.2,1.27-1.01,1.78-0.58c0.52,0.42,0,0.58-0.29,0.91c-0.29,0.32,0.32,0.36,0.71,0.52
				c0.39,0.16,1.3,0.42,1.72,0.84c0.42,0.42,0.68,0.88,1.72,1.4c1.04,0.52,0.03-1.3,0.84-0.71c0.81,0.58,0.52,0.03,1.17,0.62
				c0.65,0.59,0.42-0.26,0.13-1.23c-0.29-0.97,0.29-0.32,0.58,0.13c0.29,0.45,0.29,1.33,0.1,1.91c-0.2,0.58-1.17,0.1-1.07-0.23
				c0.1-0.32-0.97-0.19-1.01,0.26c-0.03,0.45,0.68,1.33,1.2,1.43c0.52,0.1,0.13,0.91,0.45,1.01c0.45,0.13,0.42,0.97,0.68,0.84
				c0.26-0.13,0.16-1.14-0.13-1.59c-0.29-0.45-0.16-1.11,0.23-0.97c0.39,0.13,0,0.81,0.19,1.04c0.19,0.23,0.42-0.23,0.88-0.49
				c0.45-0.26-0.19-1.01-0.03-1.4c0.16-0.39,0.68,0.32,0.88,1.01c0.19,0.68-0.49,0.65-0.49,1.01c0,0.36-0.62,0.19-0.81,0.39
				c-0.19,0.19-0.1,1.49,0.16,1.53c0.26,0.03,0.42-1.07,0.58-0.52c0.16,0.55,0.78-0.71,0.94-0.19c0.16,0.52,0.94,1.1,1.23,0.97
				c0.29-0.13-0.13-0.68-0.58-0.71c-0.45-0.03-0.39-0.78,0.03-0.81c0.42-0.03,1.17,1.07,1.14,1.46c-0.03,0.39-0.39,0.36-0.75,0.75
				c-0.36,0.39-0.84-0.65-1.27-0.62c-0.42,0.03,0,0.68,0.2,1.14c0.19,0.45-0.52,1.07,0.03,1.43c0.55,0.36,0.39-0.23,0.49-0.49
				c0.1-0.26,0.52,0.06,0.81,0.29c0.27,0.21,0.32-0.52,0.32-0.81c0-0.29,0.49-0.13,0.75,0.26c0.26,0.39,0.29-0.42,0.68-0.36
				c0.39,0.07,0.13,0.68,0.13,1.04c0,0.36,0.68-0.29,0.62,0.1c-0.01,0.06-0.01,0.11,0,0.16c0.36-0.26,0.73-0.52,1-0.71
				C56.68,76.97,55.46,76.67,55.68,76.1z M0.92,80.71c0.78-0.32,1.1-0.71,0.78-1.1C1.38,79.22,0.33,80.96,0.92,80.71z M6.44,77.79
				c-0.71-0.26-2.59,0.88-1.95,0.97c0.45,0.06,0.65-0.39,1.17-0.39C6.18,78.38,7.16,78.05,6.44,77.79z M22.89,70.16
				c0.03-0.36-0.49-0.32-1.14,0.42c-0.65,0.75-1.2,1.14-1.3,1.49c-0.1,0.36-0.84-0.07-1.07,0.39c-0.23,0.45,0.16,1.46,0.62,1.14
				c0.45-0.32,0.49,0,0.78,0c0.29,0,1.62-0.91,1.91-1.36c0.29-0.45-0.52-0.58-0.55-0.88c-0.03-0.29,0.68-0.29,1.04-0.49
				C23.54,70.68,22.86,70.52,22.89,70.16z M2.22,66.34c-0.06-0.52-1.46-0.03-1.3,0.45c0.16,0.49,1.48,0.8,2.04,0.62
				c0.49-0.16,0.65-0.68,0.29-1.01C2.9,66.08,2.29,66.85,2.22,66.34z M18.04,137.99c-0.81,0.1-0.36,2.12,0.07,1.79
				c0.31-0.24,0.88-0.29,1.05-0.6C19.38,138.77,18.85,137.89,18.04,137.99z M16.96,136.75c-0.48,0.07-0.17,0.94,0.14,0.72
				C17.63,137.08,17.44,136.68,16.96,136.75z M11.9,135.1c0.43,0.45,0.74,0.33,0.86-0.07C12.88,134.62,11.44,134.62,11.9,135.1z
				 M14.19,136.08c0.31,0.48,0.64,0.57,0.91,0.14C15.36,135.79,13.83,135.52,14.19,136.08z M147.37,92.63
				c-0.04-0.43-1.34-0.09-1.95-0.56c-0.61-0.48-1.64,1.69-1.86,2.6c-0.22,0.91-1.82,1.9-1.82,1.9s-4.63,0.04-5.02,0.04
				c-0.27,0-1.33,0.92-1.96,1.48c0.02,0.02,0.04,0.05,0.06,0.08c0.47,0.84-1.23,1.36-2.86,1.43c-0.5,0.02-0.87,0.07-1.16,0.11
				c0.06,0.2,0.1,0.42,0.08,0.58c-0.01,0.1-0.19,0.23-0.43,0.36c-0.25,0.5-2.24,1.47-3.35,2.06c-1.23,0.65-2.92,0.32-2.92-0.65
				c0-0.97,0.91-0.78,1.1-0.32c0.07,0.17,0.29,0.05,0.59-0.17c-0.31-0.3-0.3-0.78-0.24-1.15c-0.37-0.16,0.21-1.62-0.55-1.87
				c-0.78-0.26-1.69,1.1-1.56,0.26c0.13-0.84,1.3-1.56,0.58-2.66c-0.71-1.1-2.01-1.1-2.27-0.26c-0.26,0.84-1.17,0.45-1.49,1.1
				c-0.32,0.65-0.65,2.08-0.39,3.12c0.26,1.04-0.07,1.56-1.04,2.14c-0.97,0.58-1.48-0.89-1.1-2.79c0.26-1.3,0.97-2.53,0.32-2.53
				c-0.65,0,0.84-1.56,2.21-1.88c1.36-0.32,3.05-0.19,3.05-0.58c0-0.08,0.06-0.13,0.17-0.17c-0.28-0.22-0.59-0.42-0.83-0.58
				c-0.03,0.08-0.08,0.16-0.18,0.23c-0.53,0.37-0.39-0.58-1.36-0.58c-0.97,0-1.95,0.91-2.73,0.52c-0.78-0.39-0.39-0.91-1.1-0.91
				c-0.71,0,0.52-1.36-0.52-0.78c-1.04,0.58-2.6,2.01-3.31,1.36c-0.71-0.65-1.36,0.2-1.88-0.32c-0.52-0.52,1.82-1.95,2.92-1.88
				c0.27,0.01,0.52-0.1,0.75-0.29c-0.58-0.21-1.78-0.68-2.09-0.29c-0.39,0.48-0.95,0.17-1.25-0.22c-0.3-0.39-0.69,0.13-1.21-0.48
				c-0.52-0.61-1.04-0.17-1.34-0.04c-0.3,0.13-0.69-0.22-1.26-0.43c-0.56-0.22-0.13-0.78-0.61-1.13c-0.48-0.35-0.5,0.66-0.5,0.66
				l-40.33,0.22c0.05,0.07,0.09,0.13,0.14,0.19c0.49,0.52,0.1,0.91,0.26,1.53c0.16,0.62,0.03,1.49-0.45,1.49
				c-0.49,0-0.55-0.55-0.19-0.75c0.36-0.19,0.16-0.91-0.19-0.91c-0.21,0-0.29-0.24-0.28-0.53c-0.66,0.18-1.42,0.1-2.31-0.01
				c0.01,0.04,0.03,0.07,0.03,0.12c0.03,0.39,0.23,1.27,0.75,1.95c0.52,0.68,0.16,1.1,0.65,1.36c0.49,0.26,0.45,0.55,0,0.49
				c-0.45-0.06-0.13,0.36-0.29,1.33c-0.16,0.97-0.13,2.99-0.23,3.6c-0.1,0.62-0.81,1.62-0.39,2.27c0.42,0.65,0.71,1.56,0.49,2.37
				c-0.23,0.81-0.19,1.3,0.16,1.91c0.36,0.62,0.07,1.69,0.45,1.91c0.39,0.23,0.75,0.68,1.1,1.27c0.36,0.58,0.68,0.23,0.75,0.81
				c0.06,0.58,0.06,0.68,0.58,0.91c0.52,0.23,0.19,0.81,0.19,1.14c0,0.32,0.68,0.91,1.53,1.66c0.84,0.75,0.13,1.36,0.75,1.36
				c0.62,0,1.2,0.36,1.82,0.75c0.62,0.39,0.81,0.16,1.23,0.26c0.42,0.1,1.2,1.01,1.36,1.62c0.08,0.29,0.18,0.59,0.33,0.92l3.37-0.44
				c0,0,1.04,0.73,1.63,0.87c0.59,0.14,3.78,1.32,3.78,1.32l4.16-0.03l0.49-0.62h2.29c0,0,1.46,1.42,1.66,1.49
				c0.21,0.07,0.94,0.73,0.94,1.14c0,0.42,0.17,0.76,0.52,0.94c0.35,0.17,1.53,0.94,1.7,0.87c0.17-0.07,0.52-1.39,1.14-1.35
				c0.62,0.03,2.32,0.52,2.6,1.49c0.28,0.97,1.11,1.77,1.39,1.98c0.28,0.21,0.14,0.8,0.35,1.11c0.21,0.31,0.17,0.9,0.42,0.9
				c0.24,0,1.49,0.76,2.05,0.73c0.17-0.01,0.38,0.09,0.61,0.23c0.07-1.16-1.13-1.35-0.42-1.89c0.81-0.62-0.03-1.14,0.36-1.4
				c0.39-0.26,1.07-0.52,1.07-0.91c0-0.39,0.36-0.39,0.88-0.32c0.52,0.06,1.49-0.84,1.43-1.2c-0.07-0.36,0.1-0.42,0.71-0.29
				c0.62,0.13,0.52-0.71,0.91-0.58c0.39,0.13,0.71,0.36,0.75,0.06c0.03-0.29,0.42-0.23,0.71,0.16c0.29,0.39,1.14,0.49,1.2,0.03
				c0.07-0.45,0.55,0,0.97,0.45c0.42,0.45,0.62,0.32,1.23,0.36c0.62,0.03,0.62-0.13,0.65-0.52c0.03-0.39,0.68,0.84,1.3,0.91
				c0.62,0.07,0.13-0.39-0.32-0.75c-0.45-0.36,0.1-0.52-0.29-0.81c-0.39-0.29,0.49-0.68,1.27-0.65c0.78,0.03,0.75,0.23,1.04-0.23
				c0.29-0.45,0.58,0,0.58,0.39c0,0.39,1.27-0.23,1.95-0.23c0.68,0,1.3,0.58,1.36,0.97c0.06,0.39,0.52,0.58,1.01,0.16
				c0.49-0.42,0.91-0.91,1.36-0.45c0.45,0.45,0.91,1.07,1.46,1.62c0.55,0.55-0.26,1.23,0.03,1.65c0.29,0.42-0.03,1.01,0.62,1.46
				c0.65,0.45,0.07,1.4,0.58,1.53c0.52,0.13,0.94,0.94,1.01,1.26c0.07,0.32,1.04,0.75,1.1,0.2c0.06-0.55,0.58-1.2,0.65-1.72
				c0.07-0.52-0.39-2.37-0.81-2.95c-0.42-0.58,0.1-0.71-0.42-1.39c-0.52-0.68-0.84-1.88-0.81-2.86c0.03-0.97,1.17-2.56,1.72-3.05
				c0.55-0.49,1.17-0.23,1.3-0.75c0.13-0.52,0.81-1.17,1.2-1.17c0.39,0,0.81,0.1,0.88-0.32c0.07-0.42,0.71-0.94,1.56-1.07
				c0.84-0.13,0.36-0.58,0.13-0.97c-0.23-0.39,0.19-0.78,0.36-0.52c0.16,0.26,0.68,0.29,1.01,0.07c0.32-0.23,1.07-0.84,0.36-0.88
				c-0.71-0.03-0.88-0.32-0.45-0.49c0.42-0.16,0.06-0.94-0.52-1.01c-0.58-0.07-0.39-0.36-0.03-0.71c0.36-0.36-0.52-0.94-0.94-1.23
				c-0.42-0.29,0.26-0.45,0.58-0.52s0.07-1.56,0.23-1.91c0.16-0.36,0.71-0.36,0.49,0c-0.23,0.36-0.55,0.94-0.13,1.43
				c0.42,0.49,0.55,1.01,0.39,1.75c-0.16,0.75,0.16,0.55,0.65-0.42c0.49-0.97,0.55-1.82,0.23-1.91c-0.32-0.1-0.29-1.07,0.06-0.62
				c0.36,0.45,0.49,0.52,1.04-0.07c0.55-0.58,1.14-1.59,0.78-1.82c-0.36-0.23,0.16-0.45,0.81-0.42c0.65,0.03,2.04-0.39,2.14-0.59
				c0.32-0.63-2.24,0.29-2.24-0.06c0-0.36,1.62-0.71,2.37-0.75c0.75-0.03,0.39-1.04,0.71-0.62c0.32,0.42,0.81,0.36,1.23,0.1
				c0.42-0.26,0.1-1.01-0.39-1.14c-0.49-0.13,0.36-0.45,0.19-0.75c-0.16-0.29,0.32-1.46,0.94-1.59c0.62-0.13,0.32-0.52,0.84-0.52
				s0.62-0.62,1.07-1.07c0.45-0.45,1.1,0.71,1.82,0.06c0.29-0.27,0.69-0.56,1.09-0.82C146.94,94.5,147.41,93.03,147.37,92.63z"/>
			<g className="hoverEf US">
				<g>
					<path d="M87.01,108.49v2.55c0,0.86,0.36,1.25,0.9,1.25c0.58,0,0.92-0.39,0.92-1.25v-2.55h0.8v2.5c0,1.35-0.69,1.95-1.75,1.95
						c-1.01,0-1.67-0.57-1.67-1.95v-2.51H87.01z"/>
					<path d="M90.52,110.65c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
						c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
						c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V110.65z"/>
					<path d="M95.08,108.82c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
						C94.91,108.39,95.08,108.58,95.08,108.82z M94.24,112.87v-3.16h0.8v3.16H94.24z"/>
					<path d="M96.82,108.8v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
						c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
						L96.82,108.8z"/>
					<path d="M98.75,111.51c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
						c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H98.75z
						M100.14,110.95c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H100.14z"/>
					<path d="M104.45,108.26v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
						c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01v-1.79H104.45z M103.65,111.03
						c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
						c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V111.03z"/>
					<path d="M106.67,112c0.26,0.15,0.65,0.28,1.06,0.28c0.51,0,0.8-0.24,0.8-0.6c0-0.33-0.22-0.53-0.78-0.73
						c-0.72-0.26-1.19-0.64-1.19-1.27c0-0.72,0.6-1.26,1.55-1.26c0.48,0,0.82,0.1,1.05,0.22l-0.19,0.65
						c-0.16-0.08-0.46-0.21-0.88-0.21c-0.51,0-0.72,0.27-0.72,0.53c0,0.34,0.25,0.49,0.84,0.72c0.76,0.29,1.13,0.67,1.13,1.3
						c0,0.71-0.53,1.32-1.66,1.32c-0.46,0-0.94-0.13-1.18-0.28L106.67,112z"/>
					<path d="M110.95,108.8v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
						c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
						L110.95,108.8z"/>
					<path d="M114.09,112.87l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H114.09z M113.99,111.32c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V111.32z"/>
					<path d="M116.53,108.8v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
						c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
						L116.53,108.8z"/>
					<path d="M118.46,111.51c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
						c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H118.46z
						M119.85,110.95c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H119.85z"/>
					<path d="M121.21,112.14c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
						c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
						c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
						c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L121.21,112.14z"/>
				</g>
			</g>
		</g>
		<g className="mouseOver spainCount">
			<path class="lightBlue" d="M252.27,101.23c-0.52-0.01-1.1,0.04-1.5-0.04c-0.47-0.1-1.47-0.16-1.74-0.42c-0.26-0.26-0.68-0.47-0.68-0.16
				c0,0.32-0.74,0.37-1.16,0.11c-0.42-0.26-1.11-0.47-1.42-0.68c-0.17-0.11-0.51-0.25-0.83-0.41c-0.44,0.09-1.7-0.38-2.53-0.28
				c-0.89,0.11-3.72-0.13-4.83-0.04c-1.1,0.09-1.17-0.45-1.77-0.45c-0.61,0-0.35,0.73-1.21,0.76c-0.87,0.02-0.95,0.56-0.32,1.06
				c0.36,0.28,0.31,0.88,0.26,1.43c0.18-0.02,0.31-0.07,0.39-0.17c0.26-0.31,0.53-0.26,0.53,0.05c0,0.32,0.58,0.21,0.9,0.21
				c0.32,0,0.21,0.26,0.58,0c0.37-0.26,0.89-0.16,0.89,0.16c0,0.32,0.79,0.16,0.37,0.58c-0.42,0.42-0.95,0.53-0.95,0.84
				c0,0.32,0.47,1,0.11,1.37c-0.37,0.37,0.11,1-0.26,1c-0.37,0-0.79,0.16-0.37,0.58c0.42,0.42,0.74,0.79,0.42,0.79
				c-0.32,0-0.53,0.63-0.37,0.79c0.16,0.16,0.9,0.63,0.32,0.74c-0.58,0.11-0.58,0.58-0.58,0.89c0,0.12,0.09,0.29,0.21,0.48
				c0.27-0.05,0.53-0.06,0.78,0.06c0.61,0.28,0.96,1.79,1.77,1.69c0.35-0.04,0.17-0.3,0.35-0.48c0.17-0.17,0.5-0.04,0.84-0.04
				c0.35,0,0.52-0.5,1.34-0.52c0.82-0.02,1.34-0.09,1.86-0.07c0.52,0.02,0.93-0.15,1.06-0.52c0.13-0.37,0.56-0.69,1.15-0.78
				c0.58-0.09,0.5-0.69,0.54-1.04c0.04-0.35,1.02-0.87,1.08-1.13c0.07-0.26-0.56-1.26,0.02-2.14c0.58-0.89,1.21-0.93,1.21-1.43
				c0-0.46,0.61-0.63,1.34-0.71c0.74-0.09,0.97-0.58,1.6-0.84C252.19,102.24,252.38,101.85,252.27,101.23z M252.4,105.96
				c-0.11-0.74-1.6,0.22-1.28,0.43C251.77,106.82,252.51,106.69,252.4,105.96z"/>
				<g className="hoverEf spain">
				<g>
				<path d="M231.17,109.35c0.26,0.15,0.65,0.28,1.06,0.28c0.51,0,0.8-0.24,0.8-0.6c0-0.33-0.22-0.53-0.78-0.73      c-0.72-0.26-1.19-0.64-1.19-1.27c0-0.72,0.6-1.26,1.55-1.26c0.48,0,0.82,0.1,1.05,0.22l-0.19,0.65      c-0.16-0.08-0.46-0.21-0.88-0.21c-0.51,0-0.72,0.27-0.72,0.53c0,0.34,0.25,0.49,0.84,0.72c0.76,0.29,1.13,0.67,1.13,1.3      c0,0.71-0.53,1.32-1.66,1.32c-0.46,0-0.94-0.13-1.18-0.28L231.17,109.35z"/>
				<path d="M234.53,108.11c0-0.41-0.01-0.75-0.03-1.05h0.7l0.04,0.49h0.01c0.23-0.36,0.6-0.56,1.08-0.56      c0.72,0,1.33,0.62,1.33,1.61c0,1.15-0.72,1.7-1.44,1.7c-0.4,0-0.73-0.17-0.89-0.42h-0.01v1.64h-0.8V108.11z M235.33,108.9      c0,0.08,0.01,0.15,0.02,0.22c0.08,0.33,0.36,0.56,0.7,0.56c0.51,0,0.81-0.42,0.81-1.04c0-0.56-0.27-1.02-0.79-1.02      c-0.33,0-0.63,0.24-0.71,0.59c-0.02,0.06-0.03,0.13-0.03,0.2V108.9z"/>
				<path d="M240.11,110.23l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05      c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33      v1.14c0,0.29,0.01,0.57,0.05,0.76H240.11z M240.01,108.68c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46      c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V108.68z"/>
				<path d="M242.48,106.18c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43      C242.31,105.74,242.48,105.93,242.48,106.18z M241.64,110.23v-3.16h0.8v3.16H241.64z"/>
				<path d="M243.3,108c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55c0.55,0,1.12,0.36,1.12,1.36      v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V108z"/>
			</g>
		</g>
		</g>
			<path class="st0 " d="M266.4,95.46c0.35-0.14,0.74-0.2,0.93-0.12c0.17,0.07,0.22,0.25,0.26,0.44c0.3-0.04,0.85-0.13,1.03-0.2
				c0.26-0.1,0.84,0.23,0.91,0.03c0.06-0.19,0.26-0.65,0.52-0.65c0.26,0,0.1-0.68,0.36-0.71c0.26-0.03,0.91-0.62,0.91-0.62
				s-0.26-0.52-0.36-0.55c-0.1-0.03-0.42,0.32-1.01,0.32c-0.58,0-1.01,0.45-1.26,0.45c-0.26,0-1.07-0.26-1.62-0.29
				c-0.19-0.01-0.4-0.03-0.59-0.05C266.42,94.01,266.36,94.76,266.4,95.46z"/>
		<g className="mouseOver greeceCount">
			<path class="lightBlue" d="M285.66,102.33c-0.33,0.03-0.78,0.15-0.7,0.34c0.09,0.22-0.22,0.35-0.56,0.35c0,0-1.21-0.09-1.56-0.26
				c-0.26-0.13-1.42-0.06-1.96-0.02c0,0.02-0.01,0.04-0.03,0.07c-0.13,0.19-1.17,0.72-1.36,0.63c-0.19-0.1-0.29,0.32-0.68,0.42
				c-0.39,0.1-0.97-0.03-1.01,0.13c-0.03,0.16-0.29,0.62-0.52,0.84c-0.23,0.23-0.03,0.49-0.36,0.55c-0.21,0.04-0.3,0.44-0.42,0.79
				c0.02,0.01,0.04,0.03,0.06,0.03c0.48,0.15,0.84,0.71,0.78,1.25c-0.06,0.54,0.69,0.74,1.04,0.35c0.35-0.39,1.32,0.22,1.32,0.48
				c0,0.26-1-0.28-1.56,0.13c-0.56,0.41,0.43,0.8,0.3,1.23c-0.13,0.43,0.26,1.04,0.76,1.02c0.5-0.02,1.06,0.8,1.36,0.65
				c0.3-0.15-0.37-1.3-0.26-1.47c0.11-0.17,0.71,0.3,1.02-0.04c0.3-0.35-0.22-0.6-0.67-0.58c-0.45,0.02-0.02-0.61,0.52-0.28
				c0.54,0.32,0.8-0.09,1.06-0.13c0.26-0.04,0.32-0.97-0.48-1.19c-0.8-0.22-0.69,0.58-1.21,0.04c-0.52-0.54,0.48-0.32,0.48-0.78
				c0-0.45-0.8-1.1-1.19-1.64c-0.39-0.54,0.54-0.8,0.76-0.41c0.22,0.39,0.8,0.32,1.17,0.17c0.37-0.15-0.39-0.84,0.28-1.1
				c0.67-0.26,0.67,0.19,0.89,0.28c0.22,0.09,0.41-0.54,1.1-0.54c0.36,0,0.68,0.24,0.99,0.47c0.19-0.21,0.34-0.33,0.34-0.5
				c0-0.23,0.42-0.32,0.42-0.68C285.78,102.74,285.72,102.52,285.66,102.33z M284.44,113.25c-0.35-0.19-1.9,0.13-2.31-0.26
				c-0.41-0.39-1.25,0.31-0.89,0.37c0.52,0.09,1.3,0.54,2.14,0.56c0.84,0.02,1.99-0.39,1.97-0.61
				C285.33,113.1,284.79,113.44,284.44,113.25z"/>
		</g>
			<path class="st0" d="M296.32,113.86c-0.04-0.24,0.28-0.74,0.78-0.93c0.5-0.19,0.3-0.56-0.02-0.28c-0.32,0.28-1.1,0.58-2.08,0.63
				c-0.97,0.04-1.28,0.68-0.67,1.04C295.11,114.76,296.36,114.09,296.32,113.86z"/>
			<path class="st0" d="M225.12,56.89c0.84-0.04,0.82-0.97,1.17-0.93c0.35,0.04,0.91-0.65,1.13-1.08c0.22-0.43-0.24-1.21-0.76-1.21
				c-0.52,0-0.91-0.41-0.8-0.71c0.11-0.3-0.28-0.69-0.26-0.91c0.02-0.22-0.65-0.02-1,0.07c-0.35,0.09-1.08-0.84-1.41-0.67
				c-0.32,0.17-0.09,0.63-0.17,0.89c-0.09,0.26-0.86-0.24-0.86,0.07c0,0.3-0.32,0.35-0.58,0.02c-0.26-0.32-0.87-0.13-0.84,0.13
				c0.02,0.26-0.26,0.04-0.76-0.26c-0.5-0.3-1.02,0.35-0.97,0.67c0.04,0.32-0.17,0.41-0.58-0.3c-0.41-0.71-0.89,0.06-0.67,0.45
				c0.22,0.39,0.13,0.86-0.19,0.67c-0.32-0.19-0.76,0.13-0.95,0.43c-0.2,0.3-0.89-0.89-0.39-1.17c0.5-0.28-1.58-1.47-2.23-1.47
				c-0.65,0-0.22,0.48,0.22,0.82c0.43,0.35-0.3,0.26-0.54-0.04c-0.24-0.3-0.69-0.06-0.95,0.37c-0.26,0.43-0.69,0.67-0.73,0.97
				c-0.04,0.3,0.73,0.63,1.41,0.35c0.67-0.28,1.43-0.32,1.77,0c0.35,0.32-0.63,0.45-0.28,0.71c0.35,0.26,0.07,0.48-0.76,0.41
				c-0.82-0.06-1.82,0.17-1.67,0.45c0.15,0.28,2.1-0.24,2.12,0.04c0.02,0.28,0,0.76,0.52,0.54c0.52-0.22,0.17,0.39,0.43,0.5
				c0.26,0.11,0.2,0.63-0.5,0.67c-0.69,0.04-1.03,0.18-0.74,0.48c0.37,0.37,1.13,0.11,1.86-0.06c0.74-0.17,1.8,0.71,2.66,1.06
				c0.87,0.35,2.36-0.13,2.53-0.58c0.17-0.45,1.34-0.28,1.82-0.61C223.63,57.32,224.28,56.94,225.12,56.89z"/>
		<g className="mouseOver irelandCount">
			<path class="lightBlue" d="M238.01,79.21c-0.18-0.14-0.35-0.28-0.48-0.44c-0.56-0.65-0.79-0.13-0.85,0.26
				c-0.07,0.39-0.59-0.13-0.92-0.2c-0.33-0.07,0.1-0.69,0.33-0.85c0.15-0.11,0.3-0.41,0.4-0.83c-0.06,0-0.12-0.01-0.18-0.03
				c-0.61-0.24-1.45-0.24-1.47,0.3c-0.02,0.54,0.67,0.35,0.69,0.69c0.02,0.35-0.69,0.26-1.19,0.61c-0.5,0.35-1.04-0.24-1.56,0.02
				c-0.52,0.26,0.56,0.56,0.17,1.1c-0.39,0.54,0.02,0.52,0.67,1.04c0.65,0.52-0.39,0.65-0.39,1.23c0,0.58-0.8,0.56-0.97,0.95
				c-0.17,0.39,0.69,1.17,1.45,1.17c0.54,0,1.79-0.35,2.53-1.04c0.74-0.69,1.12-0.02,1.8-0.37c0.67-0.35,0.26-2.88,0-3.4
				C237.99,79.34,237.99,79.27,238.01,79.21z"/>
				<g className="hoverEf ireland">
			<g>
				<path d="M216.32,75.94v4.38h-0.8v-4.38H216.32z"/>
				<path d="M217.21,78.18c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V78.18z"/>
				<path d="M220.09,78.96c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H220.09z
					 M221.48,78.4c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H221.48z"/>
				<path d="M222.88,75.71h0.8v4.62h-0.8V75.71z"/>
				<path d="M226.33,80.32l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2V78.2
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H226.33z M226.22,78.77c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V78.77z"/>
				<path d="M227.85,78.1c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55c0.55,0,1.12,0.36,1.12,1.36
					v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V78.1z"/>
				<path d="M234.48,75.71v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
					c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01v-1.79H234.48z M233.68,78.48
					c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
					c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V78.48z"/>
			</g>
		</g>
		</g>
			<g className="mouseOver ukCountry">
				<g>
					<path class="st0" d="M238.32,76.86c-0.55-0.53-1.25,0.3-1.83,0.29c-0.09,0.42-0.25,0.72-0.4,0.83
						c-0.23,0.16-0.65,0.78-0.33,0.85c0.33,0.06,0.85,0.59,0.92,0.2c0.06-0.39,0.29-0.92,0.85-0.26c0.13,0.16,0.3,0.3,0.48,0.44
						c0.09-0.3,0.77-0.35,1.13-0.7C239.57,78.07,238.92,77.44,238.32,76.86z M250.15,82.23c0.04-0.65-1.38-1.1-1.54-0.76
						c-0.15,0.35-0.48,0.3-0.71-0.09c-0.24-0.39,0.22-0.76-0.02-0.8c-0.24-0.04-0.54-0.58-0.48-0.8c0.07-0.22-0.37-1.6-1.1-1.77
						c-0.74-0.17-0.84-1.21-0.97-1.77c-0.13-0.56-0.61-0.26-0.97-0.74c-0.37-0.48-1-0.24-1.32-0.22c-0.32,0.02,0-0.61,0.52-0.95
						c0.52-0.35,1.13-2.19,1.13-2.55c0-0.37-2.27-0.35-2.7-0.13c-0.43,0.22-0.87-0.32-0.5-0.48c0.37-0.15,1.15-0.91,1.1-1.21
						c-0.04-0.3,0.54-0.58,0.22-0.87c-0.32-0.28-0.37,0.37-0.65,0.58c-0.28,0.22-0.91,0.15-1.58,0.02
						c-0.67-0.13-0.93,0.82-0.91,1.23c0.02,0.41-0.78,0.65-0.65,1c0.13,0.35-0.15,0.61-0.37,0.41c-0.22-0.19-0.45-0.67-0.89-0.32
						c-0.43,0.35,0.5,0.8,1.06,0.87c0.56,0.06-0.43,0.48-0.54,1.08c-0.11,0.61,0.74,0.35,0.78,0.78c0.04,0.43-1.21,0.5-1.21,0.91
						c0,0.41,0.71-0.19,1.04-0.37c0.32-0.17-0.17,1.02,0.61,0.89c0.78-0.13,0.56-1.19,0.84-1.15c0.28,0.04-0.13,0.56,0.11,1.02
						c0.24,0.45-0.56,1.15-0.52,1.47c0.04,0.32,1.6,0.32,2.1-0.19c0.5-0.52,0.74,0.04,0.39,0.43c-0.35,0.39-0.19,0.76,0.24,0.91
						c0.43,0.15,0.58,0.19,0.39,0.54c-0.19,0.35-0.02,1-0.13,1.32c-0.11,0.32-1.45,0.28-1.49,0.07c-0.04-0.22-0.61-0.07-0.45,0.24
						c0.15,0.3-0.48,0.63-0.43,0.91c0.04,0.28,0.84,0.22,0.86,0.56c0.02,0.35-0.58,0.76-1.41,1c-0.82,0.24,0.17,0.89,0.56,0.6
						c0.39-0.28,0.3,0.19,0.87,0.19c0.56,0,0.91,0.45,1.54,0.22c0.63-0.24,0.61,0,0.13,0.37c-0.48,0.37-1.19-0.02-1.62,0.22
						c-0.43,0.24-2.19,1.89-1.86,2.29c0.19,0.24,0.45-0.35,1.08-0.61c0.63-0.26,0.74,0.17,1.08,0.22c0.35,0.04,0.37-0.71,0.65-0.65
						c0.28,0.07,0.54-0.24,1.06-0.15c0.52,0.09,0.89-0.11,1.15-0.32c0.26-0.22,0.95,0.43,1.17,0.28c0.22-0.15,1.17-0.32,1.64-0.3
						c0.48,0.02,1.71-0.54,2.03-0.84c0.32-0.3-0.19-0.37-0.63-0.3c-0.43,0.07-0.35-0.43,0.11-0.87
						C249.39,83.2,250.11,82.88,250.15,82.23z M237.15,71.37c0.58-0.02,1.25-1.02,0.97-1.3C237.84,69.79,236.72,71.38,237.15,71.37z
						 M245.52,66.67c0.37-0.37,0.8-1.77,0.24-1.62C245.2,65.2,245.36,66.83,245.52,66.67z"/>
				</g>
				<g>
					<path class="lightBlue" d="M238.32,76.86c-0.55-0.53-1.25,0.3-1.83,0.29c-0.09,0.42-0.25,0.72-0.4,0.83
						c-0.23,0.16-0.65,0.78-0.33,0.85c0.33,0.06,0.85,0.59,0.92,0.2c0.06-0.39,0.29-0.92,0.85-0.26c0.13,0.16,0.3,0.3,0.48,0.44
						c0.09-0.3,0.77-0.35,1.13-0.7C239.57,78.07,238.92,77.44,238.32,76.86z M250.15,82.23c0.04-0.65-1.38-1.1-1.54-0.76
						c-0.15,0.35-0.48,0.3-0.71-0.09c-0.24-0.39,0.22-0.76-0.02-0.8c-0.24-0.04-0.54-0.58-0.48-0.8c0.07-0.22-0.37-1.6-1.1-1.77
						c-0.74-0.17-0.84-1.21-0.97-1.77c-0.13-0.56-0.61-0.26-0.97-0.74c-0.37-0.48-1-0.24-1.32-0.22c-0.32,0.02,0-0.61,0.52-0.95
						c0.52-0.35,1.13-2.19,1.13-2.55c0-0.37-2.27-0.35-2.7-0.13c-0.43,0.22-0.87-0.32-0.5-0.48c0.37-0.15,1.15-0.91,1.1-1.21
						c-0.04-0.3,0.54-0.58,0.22-0.87c-0.32-0.28-0.37,0.37-0.65,0.58c-0.28,0.22-0.91,0.15-1.58,0.02
						c-0.67-0.13-0.93,0.82-0.91,1.23c0.02,0.41-0.78,0.65-0.65,1c0.13,0.35-0.15,0.61-0.37,0.41c-0.22-0.19-0.45-0.67-0.89-0.32
						c-0.43,0.35,0.5,0.8,1.06,0.87c0.56,0.06-0.43,0.48-0.54,1.08c-0.11,0.61,0.74,0.35,0.78,0.78c0.04,0.43-1.21,0.5-1.21,0.91
						c0,0.41,0.71-0.19,1.04-0.37c0.32-0.17-0.17,1.02,0.61,0.89c0.78-0.13,0.56-1.19,0.84-1.15c0.28,0.04-0.13,0.56,0.11,1.02
						c0.24,0.45-0.56,1.15-0.52,1.47c0.04,0.32,1.6,0.32,2.1-0.19c0.5-0.52,0.74,0.04,0.39,0.43c-0.35,0.39-0.19,0.76,0.24,0.91
						c0.43,0.15,0.58,0.19,0.39,0.54c-0.19,0.35-0.02,1-0.13,1.32c-0.11,0.32-1.45,0.28-1.49,0.07c-0.04-0.22-0.61-0.07-0.45,0.24
						c0.15,0.3-0.48,0.63-0.43,0.91c0.04,0.28,0.84,0.22,0.86,0.56c0.02,0.35-0.58,0.76-1.41,1c-0.82,0.24,0.17,0.89,0.56,0.6
						c0.39-0.28,0.3,0.19,0.87,0.19c0.56,0,0.91,0.45,1.54,0.22c0.63-0.24,0.61,0,0.13,0.37c-0.48,0.37-1.19-0.02-1.62,0.22
						c-0.43,0.24-2.19,1.89-1.86,2.29c0.19,0.24,0.45-0.35,1.08-0.61c0.63-0.26,0.74,0.17,1.08,0.22c0.35,0.04,0.37-0.71,0.65-0.65
						c0.28,0.07,0.54-0.24,1.06-0.15c0.52,0.09,0.89-0.11,1.15-0.32c0.26-0.22,0.95,0.43,1.17,0.28c0.22-0.15,1.17-0.32,1.64-0.3
						c0.48,0.02,1.71-0.54,2.03-0.84c0.32-0.3-0.19-0.37-0.63-0.3c-0.43,0.07-0.35-0.43,0.11-0.87
						C249.39,83.2,250.11,82.88,250.15,82.23z M237.15,71.37c0.58-0.02,1.25-1.02,0.97-1.3C237.84,69.79,236.72,71.38,237.15,71.37z
						 M245.52,66.67c0.37-0.37,0.8-1.77,0.24-1.62C245.2,65.2,245.36,66.83,245.52,66.67z"/>
				</g>
			</g>
		<g className="mouseOver denmarkCount">
			<path class="st0" d="M262.81,73.9c-0.22-0.11-0.3-0.8-0.04-1.02c0.26-0.22,0.37-1.04,0.15-1.26c-0.22-0.22-1,0-1.06,0.58
				c-0.07,0.58-0.69,0.32-0.87,0.45c-0.17,0.13,0.35,0.39,0.07,0.65c-0.28,0.26-0.39-0.39-0.84-0.43c-0.45-0.04-0.5,0.69-0.74,1.06
				c-0.24,0.37-0.09,1.15,0,1.67c0.09,0.52,0.71,0.84,0.56,1.28c-0.07,0.2,0.07,0.43,0.22,0.65c0.58,0.01,1.1,0.05,1.34,0.13
				c0.09,0.03,0.29,0.02,0.54-0.01c-0.19-0.31-0.62-0.56-0.59-0.79c0.04-0.3,0.45-0.11,0.76,0.15c0.3,0.26,0.97,0.3,1.04-0.02
				c0.04-0.2-0.11-1.12-0.52-0.91c-0.41,0.22-0.52,0.13-0.67-0.09c-0.15-0.22,0.3-0.56,0.41-0.91c0.11-0.35,0.82-0.22,0.93-0.5
				C263.61,74.31,263.02,74.01,262.81,73.9z M266.08,75.22c-0.17-0.11-0.3,0.3-0.5,0.35c-0.19,0.04-0.41-0.82-0.5-0.35
				c-0.09,0.48-0.52-0.2-0.95,0.41c-0.43,0.61,0.26,1.15,0.56,1.34c0.3,0.19-0.02,0.48-0.41,0.39c-0.39-0.09-0.15,0.56,0.39,0.65
				c0.54,0.09,1.13-0.95,1.06-1.21C265.66,76.54,266.28,75.34,266.08,75.22z"/>
		</g>
		<g className="mouseOver swedebCount">
			<path class="lightBlue" d="M282.09,52.2c-0.21-0.21-0.63-0.76-0.28-0.76c0.35,0,0.49-1.04,0.07-1.25c-0.42-0.21,0-1.11-0.35-1.32
				c-0.35-0.21-0.14-0.9-0.07-1.39c0.07-0.49-1.32-0.9-2.09-1.25c-0.76-0.35-1.11-0.9-1.74-1.25c-0.63-0.35-0.83,0.56-0.83,1.11
				c0,0.56-0.56,0.63-0.97,0.28c-0.42-0.35-0.9,0-1.53-0.28c-0.63-0.28-0.35,0.63-0.35,1.04c0,0.42-0.7,0.42-1.11,0.42
				c-0.42,0-1.11,0.35-1.11,0.83c0,0.49-0.63,0.76-0.42,1.04c0.21,0.28-0.07,0.56-0.35,0.76c-0.28,0.21-0.56,0.9-0.76,1.11
				c-0.21,0.21,0.14,0.56-0.28,0.83c-0.42,0.28-0.9,0.07-1.11,0.28c-0.21,0.21,0,0.7-0.07,1.32c-0.07,0.62-0.56,1.32-0.97,1.88
				c-0.42,0.56,0.14,0.7,0.35,0.76c0.21,0.07,0.21,0.42,0,0.83c-0.21,0.42-0.7,0.14-0.97,0.07c-0.28-0.07-1.04,0.28-1.46,0.97
				c-0.42,0.7-0.28,1.11-0.14,1.46c0.14,0.35-0.35,0.76,0,1.46c0.35,0.7-0.14,0.83-0.07,1.39c0.07,0.56,0.9,0.49,0.97,1.04
				c0.07,0.56-0.28,0.76-0.56,0.76c-0.28,0-0.21,0.56,0.07,0.7c0.28,0.14,0.21,1.11,0.07,1.39c-0.14,0.28-1.04,0-0.97,0.35
				c0.07,0.35-0.21,0.9-0.21,1.18c0,0.28-0.07,1.18-0.35,0.97c-0.11-0.08-0.29-0.09-0.46-0.06c0,0.04,0,0.09,0,0.14
				c-0.02,0.54,0.17,1.08,0.65,1.47c0.48,0.39,0.11,0.97,0.52,1.51c0.41,0.54,0.28,0.93,0.73,1.32c0.45,0.39,0.5,0.6,0.3,1.1
				c-0.19,0.5,0.39,0.37,0.41,0.67c0.02,0.3-0.13,0.93,0.02,1.23c0.15,0.3,0.45,0,1.15-0.02c0.69-0.02,0.52-0.45,0.54-0.87
				c0.02-0.41,0.37-0.22,0.45-0.48c0.09-0.26,0.71-0.32,1.21-0.11c0.5,0.22,0.76-0.15,0.84-0.67c0.09-0.52,0.37,0.11,0.52,0.22
				c0.15,0.11,0.61-0.8,0.82-1.38c0.22-0.58-0.02-0.48-0.52,0.09c-0.5,0.56-0.22-0.56-0.02-1.02c0.19-0.45,0.17-1.77,0.24-2.08
				c0.07-0.3,0.19-0.76,0.82-0.89c0.63-0.13,1.45-0.91,1.32-1.23c-0.13-0.32,0.87-0.86,0.87-1.19c0-0.32-1.04-1.32-1.38-1.54
				c-0.35-0.22-1.1-0.04-1.04-0.28c0.06-0.24-0.09-1.17-0.17-1.64c-0.09-0.48,0.54-0.97,0.54-1.43c0-0.45-0.02-0.97,0.39-1.13
				c0.41-0.15,0.15-0.54,0.69-0.67c0.54-0.13,0.43-0.91,1.02-1.15c0.58-0.24,0.63-0.43,1.38-0.8c0.76-0.37,2.03-1.23,2.25-1.69
				c0.22-0.45-0.78-0.93-0.15-1.41c0.63-0.48,0.11-1.02,0.56-1.19c0.45-0.17,0.63-0.41,0.95-0.76c0.32-0.35,0.76-0.02,1.54-0.15
				c0.28-0.05,0.55-0.02,0.79,0.03C282.26,52.58,282.18,52.3,282.09,52.2z M274.77,71.24c-0.82,0.02-1.21,1.64-1,1.9
				C273.95,73.36,275.59,71.21,274.77,71.24z"/>
		</g>
			<path class="st0" d="M287.6,68.48c0.18-0.39,0.65-0.45,0.3-0.96c-0.07,0.06-0.16,0.11-0.32,0.15c-0.63,0.17-1.99-0.43-2.77-0.28
				c-0.78,0.15-2.36,0.15-2.73,0.54c-0.37,0.39-1.3,0.5-0.89,0.84c0.41,0.35,0,0.52,0.3,0.93c0.3,0.41,0.45,0.58,0.87,0.5
				c0.41-0.09,0.74,0.13,0.3,0.58c-0.01,0.01-0.01,0.02-0.02,0.02c0.69-0.06,1.46-0.11,1.58-0.02c0.17,0.13,1.21,0.95,1.47,0.87
				c0.16-0.05,0.87,0.02,1.4,0.09c-0.06-0.16-0.06-0.31,0.05-0.41c0.19-0.16,0.62-0.16,0.39-0.49c-0.23-0.32-0.29-0.39-0.32-0.78
				c-0.03-0.39-0.13-0.58-0.1-0.84C287.15,68.96,287.41,68.9,287.6,68.48z M279.94,69.53c-0.78,0.22-0.89,1.29-0.58,1.28
				c0.39-0.02,1.28-0.76,1.51-1C281.11,69.57,280.72,69.31,279.94,69.53z M280.59,68.9c0.17-0.32-1.25-0.18-0.93,0.11
				C279.9,69.22,280.42,69.22,280.59,68.9z"/>
		
			<path class="st0" d="M296.96,163.25l-0.15-1.05l-0.67-0.61c-0.2,0.16-0.47,0.32-0.49,0.47c-0.03,0.23-0.65,0.19-0.91,0.06
				c-0.26-0.13-0.94,0.16-1.07,0.36s-0.58-0.36-0.91-0.32c-0.32,0.03-1.1,0.32-1.1,0.32s-0.13,0.91-0.13,1.27
				c0,0.36,0.49,0.42,0.49,0.71c0,0.29-0.39,0.55-0.42,0.75c-0.03,0.2-0.29,0.32-0.62,0.42c-0.32,0.1-0.29,0.62-0.55,0.81
				c-0.26,0.19-0.19,0.68-0.36,1.04c-0.16,0.36-0.26,0.84-0.26,1.26c0,0.34-0.04,0.82-0.24,1.06c0.41,0.04,0.52-0.02,0.73-0.13
				c0.3-0.15,1.5-0.45,1.5-0.45l1.22-0.1c0.27-0.76,0.67-1.46,1.11-1.59c0.57-0.17,1.34-0.1,1.95,0.05c0.17-0.44,0.32-0.8,0.43-0.91
				c0.38-0.37,0.9-1.12,0.98-1.72c0.07-0.6,0.07-1.2,0.07-1.2L296.96,163.25z"/>
		<g className="mouseOver norwayCount">
			<path class="lightBlue" d="M291.41,42.96c-0.09,0.32-0.67,0.26-0.65-0.06c0.02-0.32-0.91-0.58-0.95-0.74
				c-0.04-0.15,0.39-0.13,0.74,0.06c0.35,0.2,0.58,0.07,0.97-0.3c0.39-0.37,0.93-0.06,1-0.39c0.07-0.32-0.63-0.54-0.76-0.74
				c-0.13-0.2-0.63-0.45-1.1-0.37c-0.48,0.09-0.5-0.11-0.78-0.37c-0.28-0.26-1.15,0.17-1.15,0.78c0,0.61-0.54,0.45-0.35,0.13
				c0.2-0.32-0.26-0.71,0.17-0.82c0.43-0.11,0.19-0.76-0.61-0.82c-0.8-0.06-1.1,0.61-0.91,0.82c0.19,0.22-0.54,1.15-0.87,1.15
				c-0.32,0,0.04-0.8,0.02-1.23c-0.02-0.43-0.32-0.19-0.93,0.5c-0.6,0.69-1.06,1.32-1.41,1.38c-0.35,0.07-0.3-0.63,0.26-0.93
				c0.56-0.3,0.58-1.13,0.95-1.13c0.37,0,0.37-0.43-0.17-0.56c-0.54-0.13-0.63,0.48-0.8,0.65c-0.17,0.17-1.06-0.3-1.04-0.13
				c0.02,0.17-0.48,0.24-0.26,0.56c0.22,0.32-0.11,0.61-0.32,0.22c-0.22-0.39-0.76-0.09-1.02,0.35c-0.26,0.43-0.61,0.41-0.35,0.56
				c0.26,0.15,0.24,0.74-0.15,0.84c-0.39,0.11-0.24-1.06-0.54-0.95c-0.3,0.11-0.11-0.56,0.3-0.56s0.82-0.61,0.78-0.76
				c-0.07-0.25-0.52-0.19-0.52,0.13c0,0.32-0.65,0.26-1.19,0.24c-0.54-0.02-0.52,0.63,0.09,0.97c0.6,0.35-0.11,0.56-0.41,0.3
				c-0.3-0.26-0.78-0.13-1.1,0.02c-0.32,0.15,0.78,0.54,0.82,0.74c0.04,0.19-0.56-0.24-0.69-0.09c-0.13,0.15-0.73-0.11-0.78-0.48
				c-0.04-0.37-1.02,0.15-0.48,0.28c0.54,0.13,0.35,0.48,0.35,0.95c0,0.48-0.63,0.11-0.5-0.3c0.13-0.41-0.39-0.32-0.82-0.07
				c-0.43,0.26,0.07-0.48-0.11-0.84c-0.17-0.37-0.56,0-1.02,0.09c-0.45,0.09-0.69,0.11-0.41,0.41c0.28,0.3,0.24,0.73-0.13,0.67
				c-0.37-0.06-0.52,0-0.48,0.35c0.04,0.35-0.48,0.37-0.52,0.06c-0.04-0.3-0.78-0.22-1,0.13c-0.22,0.35-0.82,0.61-0.76,1
				c0.06,0.39,0.54-0.2,0.93-0.07c0.39,0.13-0.02,0.32,0.2,0.52c0.22,0.2,0.17,0.67-0.13,0.39c-0.3-0.28-0.69-0.37-0.76,0.09
				c-0.06,0.45-0.39-0.06-0.65-0.39c-0.26-0.32-0.52,0.61-0.8,0.39c-0.28-0.22,0.56-0.89,0.35-1.21c-0.22-0.32-0.37,0.19-0.87,0.69
				c-0.5,0.5-1.17,0.48-0.93,0.71c0.24,0.24-0.5,0.37-0.63,0.78c-0.13,0.41-1.15,0.56-1.8,1c-0.65,0.43,0.13,0.45,0.5,0.04
				c0.37-0.41,0.5-0.17,1.08-0.54c0.59-0.37,1.21-0.67,1.43-0.52c0.22,0.15,0.58,0.26,0.86-0.13c0.28-0.39,0.63-0.3,0.91-0.06
				c0.28,0.24-0.48,0.43-0.13,0.78c0.35,0.35-0.41,0.54-0.41,0.24c0-0.3-0.43-0.56-0.59-0.28c-0.15,0.28-0.41,0.58-0.69,0.61
				c-0.28,0.02-0.58,0.54-0.58,1.06c0,0.52-0.5,0-0.54,0.45c-0.04,0.45-0.74,1.25-1.34,1.84c-0.6,0.58-0.04,0.71-0.24,1.08
				c-0.19,0.37-0.91,0.04-1.15,0.19c-0.24,0.15,0.13,1.06-0.19,1.34c-0.32,0.28,0,0.67,0,1c0,0.32-0.84-0.19-0.91,0.13
				c-0.07,0.32-0.8,0.24-1.02,0.32c-0.22,0.09,0.43,0.6,0.82,0.91c0.39,0.3-0.37,0.54-0.45,0.19c-0.09-0.35-0.63,0.22-1.3,0.52
				c-0.67,0.3-0.39,0.91-0.91,0.93c-0.52,0.02-0.37,0.84-0.71,1.1c-0.35,0.26-0.26-0.6-0.8-0.63c-0.54-0.02-0.5,0.37-0.17,0.82
				c0.32,0.45-0.5,0.11-0.8,0.5c-0.3,0.39-1.17,0.19-1.41,0.52c-0.24,0.32,0.65,0.32,0.78,0.58c0.13,0.26-0.78,0.13-1.25,0.02
				c-0.48-0.11-0.35,0.61-0.78,0.52c-0.43-0.09-0.97,0.37-0.67,0.67c0.3,0.3-0.24,0.35-0.54,0.15c-0.3-0.19-0.56,0.28-0.67,0.65
				c-0.11,0.37,0.58,0.56,0.56,0.73c-0.02,0.17-0.56,0.45-0.17,0.54c0.39,0.09,0.11,0.43-0.15,0.54c-0.26,0.11-0.26,0.37,0,0.69
				c0.26,0.32-0.45,0.39-0.11,0.89c0.35,0.5,0.61-0.13,0.76,0.24c0.15,0.37,0.56,0.04,1.04-0.39c0.48-0.43,0.65,0.48,0.26,0.45
				c-0.39-0.02-0.84,0.22-0.76,0.61c0.09,0.39-0.61,0.32-0.58-0.15c0.02-0.48-0.8,0.09-0.5,0.43c0.3,0.35-0.11,0.54-0.11,0.86
				s0.58,0.26,0.82-0.11c0.24-0.37,0.63-0.28,0.78,0.09c0.15,0.37-0.37,0.09-0.37,0.58c0,0.5-0.32,0.07-0.61,0.45
				c-0.28,0.39,1.1,1.1,1.54,1.21c0.43,0.11,0.84,0.58,1.71,0.45c0.87-0.13,2.51-1.73,2.73-2.01c0.22-0.28,0.69,0.04,1.02-0.22
				c0.32-0.26,0.13-0.93,0.37-1.06c0.24-0.13,0.37,0.71,0.58,0.8c0.2,0.08,0.34,0.23,0.35,0.66c0.17-0.02,0.35-0.02,0.46,0.06
				c0.28,0.21,0.35-0.69,0.35-0.97c0-0.28,0.28-0.83,0.21-1.18c-0.07-0.35,0.83-0.07,0.97-0.35c0.14-0.28,0.21-1.25-0.07-1.39
				c-0.28-0.14-0.35-0.7-0.07-0.7c0.28,0,0.63-0.21,0.56-0.76c-0.07-0.56-0.9-0.49-0.97-1.04c-0.07-0.56,0.42-0.7,0.07-1.39
				c-0.35-0.7,0.14-1.11,0-1.46c-0.14-0.35-0.28-0.76,0.14-1.46c0.42-0.7,1.18-1.04,1.46-0.97c0.28,0.07,0.76,0.35,0.97-0.07
				c0.21-0.42,0.21-0.76,0-0.83c-0.21-0.07-0.76-0.21-0.35-0.76c0.42-0.56,0.9-1.25,0.97-1.88c0.07-0.63-0.14-1.11,0.07-1.32
				c0.21-0.21,0.7,0,1.11-0.28c0.42-0.28,0.07-0.63,0.28-0.83c0.21-0.21,0.49-0.9,0.76-1.11c0.28-0.21,0.56-0.49,0.35-0.76
				c-0.21-0.28,0.42-0.56,0.42-1.04c0-0.49,0.69-0.83,1.11-0.83c0.42,0,1.11,0,1.11-0.42c0-0.42-0.28-1.32,0.35-1.04
				c0.63,0.28,1.11-0.07,1.53,0.28c0.42,0.35,0.97,0.28,0.97-0.28c0-0.56,0.21-1.46,0.83-1.11c0.05,0.03,0.09,0.06,0.14,0.09
				c0.21-0.46,0.6-0.76,0.97-0.71c0.56,0.07,0.9,1.04,1.6,1.18c0.7,0.14,1.39,0.42,1.6,0.07c0.21-0.35,0.7,0.21,1.11,0.07
				c0.42-0.14,0.42,0.77,0.83,0.21c0.42-0.56-0.07-0.9,0.63-0.83c0.7,0.07,0.28-0.77,0.28-1.25c0-0.49,0.9-0.56,0.9-0.97
				c0-0.42,1.25-0.07,1.46-0.35c0.21-0.28,0.9-0.49,1.18,0c0.28,0.49,1.46,0.63,1.53,1.04c0.03,0.2,0,0.52,0,0.82
				c0.19-0.19,0.44-0.33,0.67-0.33c0.43,0,0.82-0.65,1.21-0.56c0.32,0.07,0.64,0.02,0.74-0.52
				C292.15,42.69,291.49,42.67,291.41,42.96z M265.24,15.22c0.62,0.13-0.65,0.81-0.84,1.43c-0.2,0.62,0.97,1.17,1.46,1.62
				c0.49,0.45,1.66,0.39,2.08,0.13c0.42-0.26,0.29-1.17,0.84-1.14c0.55,0.03,0.26-0.49,0.71-0.65c0.45-0.16,0.52,0.42,0.13,0.88
				c-0.39,0.45,0.68,0.42,1.27-0.13c0.58-0.55,0.71-0.03,0.58,0.49c-0.13,0.52-1.33,0.23-1.75,0.81c-0.42,0.58-1.72,0.13-2.3,0.78
				c-0.58,0.65,0.52,0.68,1.33,0.52c0.81-0.16,2.34-0.26,2.92-0.13c0.58,0.13-0.36,0.32-1.33,0.32c-0.97,0-1.3,0.29-1.27,0.55
				c0.03,0.26-1.36-0.29-1.43,0.29c-0.06,0.58,1.3,0.81,1.46,1.17c0.16,0.36,1.07,0.03,1.43,0.16c0.36,0.13-0.58,0.39-0.71,0.55
				c-0.13,0.16,1.01,0.39,1.2,0.71c0.19,0.32,1.01,0.16,0.97-0.29c-0.04-0.56,0.71-2.27,1.69-2.63c0.97-0.36,0.42-0.68,0.55-1.23
				c0.13-0.55,0.91-0.1,0.68-0.55c-0.23-0.45-0.1-0.65,0.52-1.23c0.62-0.58,1.01-0.16,1.75-0.52c0.75-0.36,1.1,0.26,0.29,0.32
				c-0.71,0.06-0.36,0.88,0.23,1.1c0.58,0.23,0.06,0.42,0.52,0.52c0.45,0.1,0.32,0.78-0.26,1.4c-0.58,0.62,0.16,0.75,1.4,0.29
				c1.23-0.45,0.45,0.39,0.81,0.75c0.36,0.36,1.33-0.26,1.85-0.88c0.52-0.62,1.3-0.51,1.27-0.84c-0.03-0.36-0.58-0.45-1.04-0.26
				c-0.45,0.2-1.59,0.07-1.2-0.29c0.39-0.36,0.32-0.65-0.26-0.58c-0.58,0.06-1.62-0.23-1.14-0.42c0.49-0.19-0.36-0.84-0.81-0.81
				c-0.45,0.03-0.32-0.49-0.29-0.84c0.03-0.36-1.3-0.52-1.04-0.65c0.26-0.13-0.55-0.42-0.75-0.16c-0.19,0.26-0.58,0.19-0.58-0.19
				c0-0.39-0.97-0.29-1.36-0.26c-0.39,0.03-0.2-0.84-0.58-1.14c-0.39-0.29-0.91,0.58-1.2,0.52c-0.29-0.06,0.23-0.65,0.39-1.04
				c0.16-0.39-1.36-0.91-1.53-0.52c-0.16,0.39-0.45-0.58-0.78-0.68c-0.32-0.1-0.06,0.65-0.36,0.71c-0.29,0.06-0.68,0.36-0.19,0.68
				c0.49,0.32,0.97,1.95,0.91,2.21c-0.06,0.26-1.56-1.17-1.62-1.85c-0.07-0.68-0.81-1.23-1.01-0.75c-0.19,0.49-0.75,0.68-0.58,1.17
				c0.16,0.49-0.39,0.65-0.39,0.26c0-0.39-0.81-0.84-1.1-0.88c-0.29-0.03,0.75-0.23,1.23-0.45c0.49-0.23-0.42-0.55-0.84-0.29
				c-0.42,0.26-0.97-0.16-1.23,0.19c-0.26,0.36-0.58,0-1.04-0.1s-0.84,0.65-1.17,0.52c-0.32-0.13-0.52,0.65,0,1.46
				C264.24,16.17,264.63,15.09,265.24,15.22z M263.91,17.85c0.39,0.1,0.68,0.75,1.04,0.71c0.16-0.01-0.55-0.81-0.97-1.27
				c-0.42-0.45-0.39-0.91-0.97-0.75C262.42,16.72,263.52,17.76,263.91,17.85z M274.23,12.6c0.29,0.23-0.48,0.23-0.16,0.55
				c0.97,0.97,4.8-0.13,5.32,0.16c0.52,0.29-3.21,0.65-3.05,1.04c0.16,0.39,3.21,0.71,3.44,0.45c0.23-0.26,0.75,0.52,1.75,0.58
				c1.01,0.06,0.91-0.39,1.65-0.42c0.75-0.03,1.85-0.39,1.88-0.81c0.03-0.42,2.21-0.94,2.01-1.65c-0.19-0.71-2.3-0.23-2.89-0.52
				c-0.58-0.29-1.95-0.68-2.17-0.23c-0.23,0.45-0.62,0.58-0.81,0.39c-0.19-0.19,0.1-1.23-0.84-0.94c-0.94,0.29-0.06,1.3-0.26,1.43
				c-0.19,0.13-1.1-0.19-1.17-0.65c-0.07-0.45-1.07,0.49-1.2-0.06c-0.13-0.55-1.33-0.94-1.59-0.91c-0.26,0.03,0.26,0.39,0.1,0.65
				c-0.16,0.26-0.81-0.26-1.07-0.29c-0.26-0.03,0.16,0.55,0.03,0.81c-0.13,0.26-0.65-0.78-1.1-0.78c-0.45,0-0.29,0.52-0.62,0.55
				C273.16,11.98,273.94,12.37,274.23,12.6z"/>
		</g>
			<path class="st0" d="M524.45,58.97c-0.3,0.22-2.22,0.11-1.82,0.56c0.35,0.39,1.99,0.17,2.73,0.6c0.73,0.43,1.77,0.09,1.77-0.26
				C527.14,59.53,524.76,58.75,524.45,58.97z"/>
		<g className="mouseOver japanCount">
			<path class="lightBlue" d="M459.2,98.47c-0.3-0.39-0.82,0.22-1.47,0.35c-0.65,0.13-2.68-1.56-3.2-2.34c-0.52-0.78-1.38-0.13-0.95,0.43
				c0.43,0.56-0.09,0.73-0.09,1.51c0,0.78-0.52,0.78-0.43,1.3c0.09,0.52-0.69,0.43-1.21,0.52c-0.52,0.09,0.04,0.61-0.56,0.87
				c-0.61,0.26-0.56,0.69-0.26,0.86c0.3,0.17,0.09,0.74,0,1.12c-0.09,0.39,0.39,0.09,0.74-0.3c0.35-0.39,0.71,0.19,0.78-0.17
				c0.09-0.43-0.91-0.65-0.87-1.08c0.04-0.43,0.69,0.13,1.21-0.13c0.52-0.26,1.56-0.04,2.21,0.52c0.65,0.56,0.82,0.35,0.95-0.35
				c0.13-0.69,1.12-1,2.12-1c1,0,1.08-0.48,0.78-0.69c-0.3-0.22,1.64-1.47,1.6-1.82C460.5,97.74,459.51,98.86,459.2,98.47z
				 M451.24,104.4c-0.43,0.22-0.74,1.56-0.26,1.95c0.48,0.39-0.78,1.13-0.74,1.95c0.04,0.82-0.78,0.82-0.91,1.43
				c-0.13,0.61-0.6,0.48-1.34,1.04c-0.73,0.56-1.13-0.09-0.95-0.48c0.17-0.39-1.12,0.22-1.08,1.17c0.04,0.95-1.21,1.04-0.91,1.47
				c0.3,0.43-0.95,0.69-0.91,0.3c0.04-0.39-0.78-0.17-1.77,0.22c-1,0.39-1.6-0.43-2.16,0.13c-0.56,0.56-1.52,1.56-2.25,1.69
				c-0.73,0.13-0.31,1.02,0,0.87c0.52-0.26,1.34,0,1.6-0.39c0.26-0.39,2.38-0.86,3.68-0.91c1.3-0.04,0.52,0.6,0.61,1.3
				c0.09,0.69,1.17,0.56,1.73-0.09c0.56-0.65,1-0.61,0.52-1c-0.48-0.39,0.09-0.73,0.43-0.22c0.35,0.52,1.43,0.56,1.86-0.13
				c0.43-0.69,0.56,0.13,1.08,0.09c0.52-0.04,0.82-1.34,0.87-0.86c0.04,0.48,0.86,0.35,1.38-0.22c0.52-0.56,0.04-1.56,0.39-2.08
				c0.35-0.52,0.69-1.6,0.35-2.25c-0.35-0.65,0.78-1.08,1.34-1.6c0.56-0.52-0.65-3.11-0.69-3.89
				C453.06,103.1,451.68,104.18,451.24,104.4z M441.72,115.65c-0.17,0.48-0.69,0.26-1.38,0.39c-0.69,0.13-0.72,1.78-0.22,1.95
				c0.39,0.13,0.69,0.13,0.82-0.39c0.13-0.52,0.82-0.69,1.26-0.48c0.43,0.22,1-0.65,1-1.3C443.19,115.17,441.9,115.17,441.72,115.65
				z M438.74,117.34c0-0.43-0.95-0.35-0.99-0.74c-0.04-0.39-0.69-0.17-1.34,0.3c-0.65,0.48-0.87,0.56-0.61,1.04
				c0.26,0.48,0.65,0.04,0.82-0.43c0.17-0.48,0.61,0.69,0.22,1.47c-0.39,0.78,0.05,1.88,0.56,1.77c0.43-0.09,1-1.64,1.64-2.47
				C439.69,117.47,438.74,117.77,438.74,117.34z"/>
			<g className="hoverEf japan">
				<g>
					<path d="M441.45,109.17h0.8v2.84c0,1.21-0.59,1.61-1.45,1.61c-0.22,0-0.5-0.04-0.67-0.1l0.1-0.65c0.13,0.04,0.3,0.08,0.49,0.08
						c0.46,0,0.73-0.21,0.73-0.97V109.17z"/>
					<path d="M444.87,113.55l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H444.87z M444.77,112c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V112z"/>
					<path d="M446.4,111.43c0-0.41-0.01-0.75-0.03-1.05h0.7l0.04,0.49h0.01c0.23-0.36,0.6-0.56,1.08-0.56c0.72,0,1.33,0.62,1.33,1.61
						c0,1.15-0.72,1.7-1.44,1.7c-0.4,0-0.73-0.17-0.89-0.42h-0.01v1.64h-0.8V111.43z M447.19,112.22c0,0.08,0.01,0.15,0.02,0.22
						c0.08,0.33,0.36,0.56,0.7,0.56c0.51,0,0.81-0.42,0.81-1.04c0-0.56-0.27-1.02-0.79-1.02c-0.33,0-0.63,0.24-0.71,0.59
						c-0.02,0.06-0.03,0.13-0.03,0.2V112.22z"/>
					<path d="M451.98,113.55l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H451.98z M451.87,112c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V112z"/>
					<path d="M453.5,111.32c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
						c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
						c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V111.32z"/>
				</g>
			</g>
		</g>
		<g className="mouseOver">
			<path class="orangBlue chinaCount" d="M442.94,91.38c0.04-0.78-0.87-0.52-1.17-0.3c-0.3,0.22-1.17,0-1.43,0.52c-0.26,0.52-0.69,0.22-1.3,0.3
				c-0.6,0.09-1.47-0.17-1.43-0.56c0.04-0.39-0.35-0.78-0.3-1.25c0.04-0.48-0.61-0.26-1.04-0.69c-0.43-0.43-1.3-0.74-1.47-0.65
				c-0.17,0.09-0.69-0.48-1.43-0.52c-0.73-0.04-0.65-0.52-0.56-0.82c0.09-0.3-0.61-1.25-0.74-1.77c-0.13-0.52-0.65-0.6-0.87-1.73
				c-0.22-1.12-0.82-0.82-0.69-1.34c0.13-0.52-0.73-0.78-0.95-1.08c-0.22-0.3-1.13-0.17-1.77-0.52c-0.65-0.35-1.04-0.09-1.64-0.26
				c-0.61-0.17-1.99,0.17-2.16,0.17c-0.17,0-1.26-0.22-1.86,0.6c-0.61,0.82-0.13,0.78,0.13,0.78c0.26,0,0.39,0.78,0.43,1.04
				c0.04,0.26-0.43,0.65-0.65,0.74c-0.22,0.09-0.82,1.04-0.91,1.39c-0.09,0.35-0.78,1.21-0.69,1.51c0.09,0.3-0.35,0.6-0.65,0.65
				c-0.3,0.04-1.12,0.65-1.38,0.74c-0.26,0.09-1.3-0.09-1.43-0.39c-0.04-0.1-0.17-0.18-0.33-0.24l-1.17,2.34l-0.09,0.75
				c0,0-0.75,0.19-0.19,0.75c0.57,0.57,0.66,0.09,1.51,0.09c0.85,0,1.22,0.66,1.41,0.19c0.19-0.47,0.94-0.75,1.41-0.19
				c0.47,0.57,1.98,1.51,1.89,1.98c-0.1,0.47-0.94,0.38-1.51,0.19c-0.57-0.19-1.32,0.38-1.88,0.38c-0.57,0-1.23,0.19-1.79,0.75
				c-0.57,0.57-0.47,1.13-1.32,1.23c-0.85,0.09-1.23,0-2.17,0.66c-0.94,0.66-1.98,0.38-2.36,0.19c-0.38-0.19-1.04-0.28-1.42,0.38
				c-0.38,0.66,0.47,0.94,0.38,1.7c-0.09,0.75-1.04,0.57-1.79,1.41c-0.75,0.85-1.98,1.23-2.64,1.13c-0.66-0.09-2.07-0.47-3.3,0.09
				c-1.23,0.57-2.07,1.41-2.55,1.32c-0.47-0.09-0.47-0.66-1.13-0.57c-0.66,0.09-1.04-0.09-1.51-0.38c-0.47-0.28-0.85,0.19-1.6-0.38
				c-0.75-0.57-2.07-0.47-2.64-0.57c-0.57-0.09-2.73,0-3.49-0.09c-0.75-0.09-1.79,0.19-1.89-0.19c-0.09-0.38-0.66-0.66-0.85-1.51
				c-0.19-0.85-0.66-1.13-1.13-1.23c-0.47-0.09-0.53-0.59-0.94-0.66c-0.2-0.03-0.44-0.66-1.1-0.66c-0.66,0-2.96-0.27-3.29-0.81
				c-0.33-0.54,0.33-0.66,0.33-1.2c0-0.54,0.36-1.26,0.09-1.47c-0.27-0.21-0.72-0.69-0.78-1.11c-0.06-0.42-0.78-0.99-1.26-1.02
				c-0.48-0.03-0.87-0.33-1.32-0.72c-0.45-0.39-0.87-0.36-1.05-1.35c-0.01-0.06-0.03-0.12-0.05-0.17c-0.18-0.02-0.41-0.09-0.56-0.09
				c-0.12,0-0.26-0.1-0.38-0.23c-0.12,0.41-0.51,0.62-0.51,0.94c0,0.36-0.15,0.81-0.63,0.81c-0.48,0-0.93,0.36-0.96,1.11
				c-0.03,0.75,0.42,0.84,0.21,1.17c-0.21,0.33-1.08,0.66-1.38,0.51c-0.3-0.15-0.87-0.06-1.35-0.12c-0.48-0.06-0.84-0.66-0.99,0
				c-0.15,0.66-1.14,2.6-0.9,2.81c0.24,0.21,0.42,0.69,0,0.69c-0.42,0-0.84-0.06-1.05-0.27c-0.21-0.21-0.9,0.29-1.38,0.27
				c-0.22-0.01-1.16,0.41-0.84,0.57c0.32,0.16,0.32,0.94,0.32,1.27c0,0.32,0.71,1.3,0.58,1.62c-0.13,0.32-0.78,0.68-0.78,1.1
				c0,0.42,0.13,0.81-0.1,0.91c-0.23,0.1-0.94,0.39-1.3,0.65c-0.36,0.26-0.94,0.26-1.14,0.65c-0.19,0.39-0.49,0.72-1.17,0.65
				c-0.58-0.07-0.88-0.16-1.33,0.39c-0.45,0.55-0.55,0.32-0.88,0.58c-0.32,0.26-0.65-0.19-0.91-0.29c-0.26-0.1-0.62,0.29-0.88,0.29
				c-0.26,0-0.49,0.42-0.81,0.52c-0.33,0.1-0.65,0-0.65,0.39c0,0.24-0.06,0.52-0.24,0.72h0l0.23,1.27c0,0,1.39,0.46,1.45,0.93
				c0.06,0.46,0.29,1.96,0.29,1.96s-0.02,0-0.04,0c0.56,0.44,1.33,0.91,1.33,1.08c0,0.19,0.07,0.78,0.75,1.1
				c0.43,0.21,0.87,0.42,1.14,0.61c0.03-0.08,0.05-0.14,0.05-0.14l0.58-0.06c0,0,0.17,0.11,0.33,0.28c0.14-0.47,1.14-0.98,1.66-0.95
				c0.55,0.03,1.82,1.04,1.69,1.4c-0.13,0.36-1.04,1.65-1.46,1.75c-0.16,0.04-0.39,0.06-0.62,0.06c-0.04,0.21-0.09,0.41-0.09,0.52
				c0,0.29,0.52,0.75,0.81,1.27c0.29,0.52-0.46,0.87-0.87,0.52c-0.4-0.35-0.58,0.35-0.35,0.63c0.23,0.29,0.12,1.1,0.52,1.16
				c0.4,0.06,0.75,0.87,1.21,0.87c0.46,0,0.87,0.75,0.87,0.75s0.63,0.41,0.63,0.64c0,0.03-0.01,0.06-0.03,0.09
				c0.5-0.05,0.84-0.07,0.9-0.28c0.07-0.23,0.71-0.23,1.17,0.23c0.45,0.45,2.3,1.62,2.99,2.17c0.68,0.55,2.27,1.17,2.82,1.14
				c0.55-0.03,1.07,0.39,1.91,0.36c0.13,0,0.24,0,0.34,0.01c0.12-0.13,0.32-0.13,0.48-0.36c0.23-0.35,0.58,0.29,0.46,0.75
				c-0.05,0.21-0.01,0.45,0.08,0.64c0.12-0.16,0.23-0.38,0.33-0.62c0.29-0.71,0.75-0.68,1.04-0.91c0.29-0.23,0.58-0.13,1.2,0.19
				c0.58,0.31,0.82-0.34,1.69,0.46c0.18-0.08,0.41-0.18,0.51-0.18c0.17,0,0.4-0.35,0.69-0.64c0.29-0.29,0.23-0.64,0.58-0.58
				c0.35,0.06,0.52-0.4,1.27-0.81c0.75-0.4,1.39,0.23,1.73-0.06c0.35-0.29,0.75-0.41,0.75-0.12s0.46,0.64,0.58,0.98
				c0.1,0.31,0.71,0.44,0.84,0.46c0.11-0.06,0.2-0.11,0.25-0.14c0.36-0.26,0.97,0.03,0.97,0.42c0,0.39,0.03,0.81,0.33,0.81
				c0.29,0,0.58,0,0.58,0.71c0,0.71,0.26,1.53-0.36,2.14c-0.62,0.62-1.33,1.72-1.2,2.04c0.13,0.32-0.2,0.94,0.26,0.71
				c0.45-0.23,1.49,0,1.33,0.26c-0.16,0.26,0.03,1.17,0.45,1.2c0.42,0.03,0.68,0.26,0.55,0.65c-0.13,0.39-0.36,0.97,0,0.97
				c0.36,0,0.88,0,0.81,0.39c-0.06,0.39,0.26,0.75,0.91,0.49c0.38-0.15,0.57-0.29,0.66-0.14c0.26-0.01,0.28,0.58,0.72,0.69
				c0.46,0.12,0.23-0.98,0.06-1.16c-0.17-0.17,0.06-0.92,0.23-0.75c0.17,0.17,0.69,0,0.98-0.35c0.29-0.35,0.87,0.29,1.1-0.12
				c0.23-0.4,0.75,0.29,1.27,0c0.52-0.29,0.75,0.23,0.98-0.17c0.23-0.4,0.98-0.92,1.16-0.75c0.17,0.17,0.29,0.52,1.21,0.64
				c0.93,0.12,0.4,0.58,0.4,0.87c0,0.29,1.04,1.04,1.44,1.1c0.07,0.01,0.18,0.1,0.3,0.24c0.28-0.14,0.78,0.09,0.89-0.24
				c0.13-0.39,0.6-0.28,0.8,0.04c0.19,0.32,0.41,0.39,0.84,0c0.43-0.39,0.65,0.39,0.39,0.61c-0.26,0.22-0.09,0.89,0.32,1.28
				c0.41,0.39,0.48-0.24,0.39-0.63c-0.09-0.39,0.69-0.78,2.06-1.23c1.36-0.45,2.83-1.45,2.86-1.67c0.02-0.22,0.97,0.39,1.43,0.04
				c0.45-0.35,2.1-0.39,2.53-0.41c0.43-0.02,0.41-0.39,0.87-0.76c0.45-0.37,0.35-0.54,0.87-0.69c0.52-0.15,0.84-0.63,0.84-0.89
				c0-0.26,0.97-0.35,1-0.63c0.02-0.28,0.74-0.37,0.74-0.71c0-0.35,0.43-0.28,0.63-0.54c0.19-0.26-0.15-1.02,0.19-1.12
				c0.34-0.11-0.13-0.52-0.19-0.71c-0.07-0.19,0.65-0.24,1.01-0.48c0.37-0.24,0.61-0.61,0.67-1.02c0.07-0.41,0.67-0.78,0.95-0.89
				c0.28-0.11,0.39-0.69,0.3-1.19c-0.09-0.5,0.5-0.37,0.24-0.56c-0.26-0.19,0.11-0.73,0.63-0.89c0.52-0.15-0.32-0.39-0.6-0.17
				c-0.28,0.22-0.58-0.63-0.84-0.3c-0.26,0.32-0.99-0.19-0.52-0.3c0.48-0.11,1.21-0.65,1.54-0.76c0.32-0.11-0.48-0.82-0.89-0.8
				c-0.41,0.02-0.87-0.95-1.3-0.95c-0.43,0,0.32-0.3,0.82-0.04c0.5,0.26,1.08,0.48,1.3,0.26c0.22-0.22-0.84-1.08-1.23-1.38
				c-0.39-0.3-0.02-0.71-0.26-0.89c-0.24-0.17-0.76-1.28-0.93-1.77c-0.17-0.5-1.08-0.58-1.3-0.91c-0.22-0.32,0.02-1.08,0.69-1.36
				c0.67-0.28,0.39-0.84,0.69-0.78c0.3,0.07,0.56-0.07,0.8-0.56c0.24-0.5,0.65-0.19,0.89-0.45c0.24-0.26,1.3-0.24,1.67-0.5
				c0.37-0.26-0.35-1.06-0.84-0.93c-0.5,0.13-1.08-0.06-1.43-0.58c-0.35-0.52-1.25,0.95-1.95,1.28c-0.69,0.32-1.15-0.58-0.91-1.17
				c0.24-0.58-0.54-0.56-1.51-0.52c-0.97,0.04-0.61-1.73-0.02-1.82c0.58-0.09,1.45,0.54,1.82-0.54c0.37-1.08,0.89-0.24,1.71-1.19
				c0.82-0.95,1.77-1.54,2.49-0.97c0.71,0.56-0.76,1.45-1,1.99c-0.24,0.54,0.22,0.71-0.22,1.12c-0.43,0.41,0.17,0.52,1.02-0.02
				c0.84-0.54,1.88-1.19,2.7-1.51c0.22-0.09,0.46-0.11,0.71-0.09c0.12-0.31,0.25-0.56,0.41-0.64c0.57-0.28,2.45-1.13,2.73-1.51
				c0.28-0.38,0.57-1.04,0.94-1.04c0.38,0,0.38,0.38,0.94,0.38c0.57,0,1.23,0.19,0.94-0.28c-0.28-0.47-0.09-0.47,0.57-0.57
				c0.66-0.09,0.57-0.75,1.04-0.75c0.47,0,0.38-1.13,0.85-1.13c0.47,0,0.66,0.66,1.23,0.47c0.11-0.04,0.25-0.01,0.4,0.06
				c0.12-0.09,0.24-0.18,0.36-0.27c-0.06-0.59,0.01-1.12-0.1-1.4c-0.22-0.52,0-0.91-0.09-1.21c-0.09-0.3-0.13-1.04,0.22-1.04
				c0.35,0,0.78-0.87,1.21-0.56c0.43,0.3,1.3,0.39,1.3,0c0-0.39,0.61-0.43,0.69-0.95c0.09-0.52,0.73-0.65,0.87-1.12
				c0.13-0.48,0.35-1.6,0.78-1.86C443.41,92.33,442.89,92.16,442.94,91.38z M421.73,132.35c-0.48,1.73,0.63,2.61,0.78,2.51
				c0.43-0.3,2.21-4.02,1.82-4.54C423.94,129.8,422.21,130.62,421.73,132.35z M406.29,138.24c-0.56,0-1.99,0.82-1.43,1.99
				c0.56,1.17,2.42,0.39,2.47-0.13c0.04-0.52,0.89-1.34,0.78-1.73C408.02,138.06,406.85,138.24,406.29,138.24z"/>
			
      	</g>
		<g className="mouseOver kenyaCunnt">
			<path class="lightBlue" d="M306.4,163.77l0.67-1.05l0.7-1.02c-0.33,0.07-0.62,0.15-0.73,0.21c-0.26,0.13-0.62-0.07-0.71-0.29
				c-0.1-0.23-0.75-0.03-1.27,0.26c-0.52,0.29-0.78,0.91-0.97,0.78c-0.19-0.13-1.36-0.29-1.69-0.29c-0.32,0-1.36-0.68-1.69-0.97
				c-0.32-0.29-1.27-0.16-1.49-0.29c-0.23-0.13-0.68-0.23-0.84-0.42c-0.16-0.19-0.55-0.16-0.88-0.1c-0.32,0.07-1.1,0.52-1.17,0.78
				c-0.02,0.08-0.1,0.16-0.19,0.24l0.67,0.61l0.15,1.05l0.6,0.52c0,0,0,0.6-0.07,1.2c-0.08,0.6-0.6,1.35-0.98,1.72
				c-0.11,0.11-0.27,0.47-0.43,0.91c0.57,0.14,1,0.36,0.97,0.53c-0.06,0.36-1.04,0.19-1.04,0.94c0,0.05,0,0.1-0.01,0.15l5.53,2.93
				l-0.07,0.9c0,0,1.24,0.91,2.46,1.74c0.54-1.37,1.25-2.9,1.43-3.18c0.32-0.5,0.65-0.41,0.93-0.76c0.28-0.35,0.37,0.17,0.8-0.52
				c0.03-0.05,0.08-0.11,0.13-0.18l-0.73-1.15L306.4,163.77z"/>
			<g className="hoverEf kenya">
				<g>
					<path d="M296.94,162.97h0.79v2.02h0.02c0.11-0.17,0.22-0.32,0.32-0.47l1.15-1.54h0.98l-1.51,1.86l1.61,2.52h-0.94l-1.24-2.01
						l-0.39,0.47v1.54h-0.79V162.97z"/>
					<path d="M301.26,165.99c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
						c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H301.26z
						M302.65,165.43c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H302.65z"/>
					<path d="M304.05,165.13c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
						c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
						c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V165.13z"/>
					<path d="M308.27,164.19l0.57,1.7c0.06,0.2,0.13,0.44,0.18,0.61h0.02c0.05-0.18,0.11-0.42,0.17-0.62l0.5-1.69h0.85l-0.79,2.16
						c-0.43,1.18-0.73,1.71-1.1,2.03c-0.31,0.27-0.64,0.38-0.84,0.4l-0.18-0.67c0.13-0.03,0.3-0.11,0.46-0.22
						c0.15-0.1,0.33-0.29,0.44-0.5c0.03-0.05,0.05-0.1,0.05-0.14c0-0.04-0.01-0.08-0.04-0.16l-1.16-2.91H308.27z"/>
					<path d="M312.8,167.35l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H312.8z M312.69,165.8c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V165.8z"/>
				</g>
			</g>
		</g>
		<g className="mouseOver philippinCount">
      		<path class="lightBlue" d="M424.76,147.93c0.82-0.13,1.95,0.91,1.77,1.3c-0.17,0.39,0.04,1.64,0.43,1.3c0.39-0.34,1,0.09,0.61,0.65
				c-0.39,0.56,0.61,1.13,0.74,1.82c0.13,0.69,0.56,0.13,0.48-0.39c-0.09-0.52,0.09-0.48,0.65-0.87c0.56-0.39-0.04-1.04-0.39-1.77
				c-0.35-0.73-1.64-0.48-1.64-1.17c0-0.69-0.95-0.91-0.99-1.3c-0.04-0.39-1.3-0.78-2.03-0.35c-0.74,0.43-0.22-0.78-0.74-1.34
				c-0.52-0.56,0.39-1.38,1-2.08c0.6-0.69-0.17-1.86-0.09-2.38c0.09-0.52,0.04-0.69-0.26-0.52c-0.3,0.17-1.56-0.52-2.12-0.17
				c-0.56,0.35-0.04,3.2-0.3,3.42c-0.26,0.22-0.6-0.46-0.69-0.17c-0.13,0.39,0.04,1.99,0.39,1.99c0.35,0,0.61,0.43,0.39,1.13
				c-0.22,0.69,0.65,0.78,1.47,0.48C424.24,147.19,423.94,148.06,424.76,147.93z M421.99,148.32c0.13,0.61,0.43,1.86,1.38,1.56
				C424.33,149.57,421.9,147.88,421.99,148.32z M424.42,152.52c0.22-0.04,0.74,0,0.78-0.43c0.04-0.43,0.52,0.78,0.13,1.17
				c-0.39,0.39-0.39,1.47,0.22,1.6c0.61,0.13,1.73-1.95,1.56-2.34c-0.17-0.39-0.69,0.26-0.56-0.26c0.13-0.52-1.56-1.86-2.21-1.73
				C423.68,150.66,423.91,152.62,424.42,152.52z M419.31,153.34c-0.6,0.78-2.05,2.07-1.86,2.42c0.26,0.48,1.86-1.9,2.64-2.47
				c0.78-0.56,0.65-1.04,0.39-1.56C420.22,151.22,419.92,152.56,419.31,153.34z M427.01,154.2c0.17,0.43,1.43-0.26,1.08-0.69
				C427.75,153.08,426.88,153.88,427.01,154.2z M430.91,157.58c0.52-0.61-0.35-2.21-0.35-3.24c0-1.04-1.43-0.74-1.04-0.09
				c0.39,0.65-0.73,0.13-0.82,0.78c-0.09,0.65-0.91,0.13-0.87,0.65c0.04,0.52-0.86,0-1.3-0.3c-0.43-0.3-1.04,0.82-1.69,1
				c-0.65,0.17-0.99,1.55-0.56,1.51c0.52-0.04,0.78-0.74,1.3-0.69c0.52,0.04,0.35-0.52,1.08-0.39c0.74,0.13,0.22,2.34,1.21,2.51
				c0.99,0.17,1.04,0.78,1.51,0.78s-0.09-1.38-0.04-1.86C429.39,157.75,430.39,158.18,430.91,157.58z"/>
			<g className="hoverEf philp">
				<g>
					<path d="M418.13,146.59c0.29-0.05,0.69-0.09,1.22-0.09c0.58,0,1.01,0.12,1.29,0.36c0.26,0.21,0.43,0.56,0.43,0.97
						c0,0.42-0.13,0.76-0.37,0.99c-0.32,0.32-0.82,0.47-1.38,0.47c-0.15,0-0.28-0.01-0.39-0.03v1.65h-0.79V146.59z M418.92,148.64
						c0.1,0.03,0.23,0.04,0.39,0.04c0.6,0,0.96-0.3,0.96-0.81c0-0.48-0.34-0.74-0.89-0.74c-0.22,0-0.38,0.02-0.46,0.04V148.64z"/>
					<path d="M421.74,146.3h0.8v1.88h0.01c0.1-0.15,0.23-0.27,0.4-0.36c0.16-0.09,0.35-0.14,0.55-0.14c0.54,0,1.11,0.36,1.11,1.37
						v1.86h-0.8v-1.78c0-0.46-0.17-0.81-0.61-0.81c-0.31,0-0.53,0.21-0.62,0.45c-0.03,0.07-0.04,0.15-0.04,0.24v1.89h-0.8V146.3z"/>
					<path d="M426.3,146.87c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
						C426.13,146.44,426.3,146.62,426.3,146.87z M425.45,150.92v-3.16h0.8v3.16H425.45z"/>
					<path d="M427.12,146.3h0.8v4.62h-0.8V146.3z"/>
					<path d="M429.64,146.87c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
						C429.46,146.44,429.63,146.62,429.64,146.87z M428.79,150.92v-3.16h0.8v3.16H428.79z"/>
					<path d="M430.45,148.8c0-0.41-0.01-0.75-0.03-1.05h0.7l0.04,0.49h0.01c0.23-0.36,0.6-0.56,1.08-0.56c0.72,0,1.33,0.62,1.33,1.61
						c0,1.15-0.72,1.7-1.44,1.7c-0.4,0-0.73-0.17-0.89-0.42h-0.01v1.64h-0.8V148.8z M431.25,149.59c0,0.08,0.01,0.15,0.02,0.22
						c0.08,0.33,0.36,0.56,0.7,0.56c0.51,0,0.81-0.42,0.81-1.04c0-0.56-0.27-1.02-0.79-1.02c-0.33,0-0.63,0.24-0.71,0.59
						c-0.02,0.06-0.03,0.13-0.03,0.2V149.59z"/>
					<path d="M434.25,148.8c0-0.41-0.01-0.75-0.03-1.05h0.7l0.04,0.49h0.01c0.23-0.36,0.6-0.56,1.08-0.56c0.72,0,1.33,0.62,1.33,1.61
						c0,1.15-0.72,1.7-1.44,1.7c-0.4,0-0.73-0.17-0.89-0.42h-0.01v1.64h-0.8V148.8z M435.05,149.59c0,0.08,0.01,0.15,0.02,0.22
						c0.08,0.33,0.36,0.56,0.7,0.56c0.51,0,0.81-0.42,0.81-1.04c0-0.56-0.27-1.02-0.79-1.02c-0.33,0-0.63,0.24-0.71,0.59
						c-0.02,0.06-0.03,0.13-0.03,0.2V149.59z"/>
					<path d="M438.91,146.87c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
						C438.73,146.44,438.9,146.62,438.91,146.87z M438.06,150.92v-3.16h0.8v3.16H438.06z"/>
					<path d="M439.72,148.7c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
						c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
						c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V148.7z"/>
					<path d="M444,149.56c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
						c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H444z M445.4,149
						c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H445.4z"/>
					<path d="M446.75,150.18c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
						c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
						c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
						c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L446.75,150.18z"/>
				</g>
			</g>
		</g>
			<path class="st0" d="M465.56,183.11c-0.3-0.13-1.08-0.65-1.04-1c0.04-0.35-0.87-0.3-1.47-0.69c-0.61-0.39-0.39-1.64-1.04-1.95
				c-0.65-0.3-1.13-1.25-0.35-1.21c0.78,0.04,0.91-0.39,0.3-0.95c-0.61-0.56-2.81-0.78-2.9-1.6c-0.09-0.82-4.02-2.77-5.75-3.16
				c-0.31-0.07-0.68-0.18-1.08-0.32v9.24c0.4,0.27,0.9,0.49,1.56,0.52c1.9,0.09,1.43-1,1.77-1c0.35,0,0.48-0.61,0.87-1.08
				c0.39-0.47,2.08-0.09,3.07,0.69c1,0.78,2.16,3.07,3.2,2.86c1.04-0.22,2.12,0.61,3.07,0.56
				C466.73,183.97,465.87,183.24,465.56,183.11z M468.03,175.41c0,0.39-0.69,0.61-1.77,1.04c-1.08,0.43-3.05-0.5-3.12,0.13
				c-0.04,0.39,1.21,1.08,2.38,1.08c1.17,0,3.03-1.17,3.03-1.51c0-0.35,0.74-0.95,0.43-1.3
				C468.68,174.5,468.03,175.02,468.03,175.41z M469.5,173.76c-0.69-0.43-1.3-0.74-0.43,0.09c0.87,0.82,0.4,1.52,0.91,1.39
				C470.8,175.02,470.19,174.19,469.5,173.76z"/>
			<path class="st0" d="M364.58,153.81c-0.39,0.04-0.3,0.82-0.52,1.38c-0.22,0.56-0.17,1.12-0.09,1.95c0.09,0.82,0,1.69,0.74,2.16
				c0.84,0.55,2.21-0.86,2.25-2.08C367,156.02,364.9,153.78,364.58,153.81z"/>
			<path class="st0" d="M318.58,185.75c-0.19-0.71-0.35,0.26-0.52,0.43c-0.17,0.17-0.26,0.69-0.22,1.04
				c0.04,0.35-0.52,0.69-0.86,0.65c-0.35-0.04-0.91,0.17-0.74,0.65c0.17,0.48-0.26,0.39-0.04,0.78c0.22,0.39-0.48,0.87-1.08,0.91
				c-0.61,0.04-1.38,1.13-2.03,1.04c-0.65-0.09-1.3,0.43-1.86,0.35c-0.56-0.09-0.17,1.17-0.61,1.95c-0.43,0.78-0.22,1.69,0.17,2.64
				c0.39,0.95,0.69,1.73-0.35,2.99c-1.04,1.26-0.95,2.64-0.56,3.5c0.39,0.87,0.39,2.16,0.95,2.77c0.56,0.61,2.12,1.12,2.6,0.65
				c0.48-0.48,0.98,0.06,1.51-0.56c0.52-0.61,1.39-3.94,2.12-6.14c0.74-2.21,1.69-5.11,1.6-5.71c-0.09-0.61,0.52-1,0.26-1.65
				c-0.26-0.65,0.09-1.43,0.39-0.91c0.3,0.52,0.74,0.52,0.87-0.17c0.13-0.69-0.52-1.77-0.56-2.77
				C319.58,187.17,318.71,186.22,318.58,185.75z"/>
			<path class="st0" d="M321.37,139.91c-0.75,0.17-2.63,0.04-2.89,0.17c-0.25,0.13-2.01,1.38-2.26,1.97c-0.25,0.58-0.63,0.67-1,0.38
				c-0.38-0.29-1.92-0.21-2.05-0.34c-0.13-0.13-1.25-0.25-1.8-0.17c-0.54,0.08-0.84-0.25-1.09-0.17c-0.25,0.08-0.5,0.38-0.63,0.54
				c-0.13,0.17,0.12,0.63,0.04,0.8c-0.02,0.04-0.29,0.18-0.68,0.37c0.13,0.32,0.21,0.66,0.12,0.9c-0.17,0.5-0.02,1.67,0.43,2.34
				c0.45,0.67,0.28,1.9,0.5,2.14c0.22,0.24,1.9,0.19,2.66-0.3c0.76-0.5,1.28-0.58,1.88-0.58c0.61,0,1.41-0.52,1.6-0.78
				c0.19-0.26,1.08-0.28,1.45-0.24c0.37,0.04,0.74-0.37,1.15-0.78c0.41-0.41,1.71-0.58,3.09-1.02c1.38-0.43,1.08-1.08,1.41-1.47
				c0.27-0.33,0.65-0.23,1.25-0.47c-0.67-1.38-1.42-2.92-1.79-3.7C322.11,139.71,321.57,139.86,321.37,139.91z M324.93,149.6
				c0.48,0.5,1.44-0.08,1.49-0.28C326.47,149.12,324.38,149.04,324.93,149.6z"/>
			<path class="st0" d="M347.85,246.57c-0.19-0.44-1.78,1.08-0.51,1.65c0.57,0.25,0.82-0.32,1.2,0c0.38,0.32,1.14,0.19,1.27-0.57
				C349.94,246.89,348.04,247.02,347.85,246.57z"/>
			<g>
				<g>
					<path class="st0" d="M472.53,176.44c-0.09,0.39,1.3,2.45,1.82,2.12C475.3,177.96,472.62,176.05,472.53,176.44z M475.3,178.26
						c-0.03,0.16,1.01,1.17,1.23,1.01C476.76,179.1,475.36,177.97,475.3,178.26z M480.17,180.86c0.36-0.23-2.01-1.52-2.04-1.1
						C478.09,180.14,479.81,181.08,480.17,180.86z M479.91,182.25c0.13,0.65,1.1,1.04,1.49,0.81
						C481.79,182.84,479.77,181.58,479.91,182.25z M482.57,183.68c0.06,0.16,0.91,0.94,1.33,0.65
						C484.32,184.04,482.46,183.4,482.57,183.68z M481.43,180.79c-0.32,0.71,0.97,1.95,1.17,1.98
						C482.8,182.8,481.68,180.24,481.43,180.79z"/>
				</g>
				<g>
					<path class="st0" d="M472.53,176.44c-0.09,0.39,1.3,2.45,1.82,2.12C475.3,177.96,472.62,176.05,472.53,176.44z M475.3,178.26
						c-0.03,0.16,1.01,1.17,1.23,1.01C476.76,179.1,475.36,177.97,475.3,178.26z M480.17,180.86c0.36-0.23-2.01-1.52-2.04-1.1
						C478.09,180.14,479.81,181.08,480.17,180.86z M479.91,182.25c0.13,0.65,1.1,1.04,1.49,0.81
						C481.79,182.84,479.77,181.58,479.91,182.25z M482.57,183.68c0.06,0.16,0.91,0.94,1.33,0.65
						C484.32,184.04,482.46,183.4,482.57,183.68z M481.43,180.79c-0.32,0.71,0.97,1.95,1.17,1.98
						C482.8,182.8,481.68,180.24,481.43,180.79z"/>
				</g>
			</g>
      <g className="mouseOver">
			<path class="lightBlue australiaCount" d="M470.59,210.71c0.26-0.19-0.13-1.23-0.36-1.07c-0.23,0.16-0.23-1.62-0.06-2.43
				c0.16-0.81,0.06-0.71-0.36-0.29c-0.42,0.42-0.88-0.81-0.97-1.14c-0.1-0.32-0.58-1.23-1.14-1.1c-0.55,0.13-0.19-0.78-0.71-0.68
				c-0.52,0.1-0.62-0.75-0.52-1.53c0.1-0.78-0.36-0.16-0.58-0.49c-0.23-0.32-0.49-0.07-0.84,0.06c-0.36,0.13-0.45-0.68-0.36-1.01
				c0.1-0.32-0.06-0.71-0.55-1.07c-0.49-0.36-0.52-0.65-0.49-1.07c0.03-0.42-2.76-1.85-3.37-1.98c-0.62-0.13-0.1-0.78-0.36-1.01
				c-0.26-0.23-0.36-1.07-0.36-1.78c0-0.71-0.91-0.94-0.88-1.53c0.03-0.58-0.26-1.14-0.19-1.79c0.06-0.65-0.94-0.81-0.97-1.17
				c-0.03-0.36-0.58-0.03-1.14,0.03c-0.55,0.06-0.52-0.71-0.45-1.46c0.06-0.75-0.23-1.98-0.68-2.34c-0.45-0.36-0.49-1.59-0.71-1.79
				c-0.23-0.19-0.97,0.65-0.94,1.07c0.03,0.42-0.06,0.91-0.36,1.17c-0.29,0.26,0.19,1.27-0.2,1.62c-0.39,0.36-0.03,1.78-0.13,2.6
				c-0.1,0.81-0.07,1.69-0.45,2.24c-0.39,0.55-0.45,1.43-1.23,1.72c-0.78,0.29-1.82-0.32-1.78-0.75c0.03-0.42-0.84-0.68-1.3-0.62
				c-0.45,0.06-0.71-0.58-1.43-1.14c-0.71-0.55-1.27,0.13-1.49-0.52c-0.23-0.65-0.45-0.75-0.97-1.1c-0.52-0.36-0.36-0.36,0.13-0.71
				c0.49-0.36,0.39-0.81,0.13-1.23c-0.26-0.42,0.29-0.32,0.94-0.68c0.65-0.36,0-0.78,0.49-0.97c0.49-0.19,0.19-0.62-0.32-0.71
				c-0.52-0.1-0.36,0.52-0.55,0.58c-0.19,0.07-0.39-0.81-0.55-0.78c-0.16,0.03-0.78,0.88-1.1,0.45c-0.32-0.42-2.14-0.78-2.86-0.88
				c-0.71-0.1-0.94-1.01-1.36-0.84c-0.42,0.16,0.06,0.45,0.55,0.97c0.49,0.52-1.62,0.88-2.24,0.68c-0.62-0.19,0.1,0.45-0.42,0.42
				c-0.52-0.03-0.62,0.88-1.04,1.33c-0.42,0.45-0.13,0.78-0.59,1.07c-0.45,0.29-0.49,0.62,0.07,1.23c0.55,0.62-0.26,0.65-0.75,0.16
				c-0.49-0.49-0.97-0.49-0.94,0.06c0.03,0.55-0.58,0.29-0.49-0.32c0.1-0.62-0.68-0.32-0.81-0.68c-0.13-0.36-0.81-0.94-1.23-0.91
				c-0.42,0.03-0.42,0.49-0.84,0.49c-0.42,0-0.52,0.23-0.45,0.68c0.07,0.45-0.65-0.03-1.14,0.03c-0.49,0.06,0,0.62-0.26,0.65
				c-0.26,0.03-0.26,0.49-0.68,0.49c-0.42,0-0.36,0.94-0.29,1.43c0.06,0.49-0.65-0.23-1.07-0.1c-0.42,0.13,0.1,0.55,0.23,1.1
				c0.13,0.55-0.23,0.52-0.45,0.75c-0.23,0.23-0.58-0.62-0.58-1.1c0-0.49-0.55-0.03-1.14,0.58c-0.58,0.62,0.2,1.56-0.06,1.72
				c-0.26,0.16-1.43,1.27-2.11,2.08c-0.68,0.81-2.14,0.32-2.6,0.71c-0.45,0.39-0.94,0.1-1.4,0.45c-0.45,0.36-1.3,1.04-1.39,0.62
				c-0.1-0.42-1.27-0.1-1.75,0.42c-0.49,0.52-1.27,0.97-1.82,1.01c-0.55,0.03-0.55,0.88-0.78,1.07c-0.23,0.19-0.16-0.68-0.16-0.91
				c0-0.23-0.52,0.39-0.75,1.07c-0.23,0.68,0.55,0.94,0.07,1.65c-0.49,0.71-0.94,1.43-0.26,2.34c0.68,0.91,1.07,1.49,0.84,1.95
				c-0.23,0.45-0.71-0.91-0.88-0.75c-0.16,0.16,0.62,0.75,0.42,1.04c-0.19,0.29-0.89-1.1-1.04-0.78c-0.13,0.29,1.46,2.5,1.49,3.31
				c0.03,0.81,1.3,1.59,1.23,2.76c-0.07,1.17,1.1,3.05,1.3,3.34c0.19,0.29-0.45,1.07-0.33,1.95c0.13,0.88-0.29,1.2-0.84,1.2
				c-0.55,0-0.23,0.91,0.39,0.97c0.62,0.07,0.39,0.49,1.07,0.94c0.68,0.45,2.3,0.29,2.86,0.29c0.55,0,0.71-0.84,1.46-0.88
				c0.75-0.03,0.68-0.26,1.04-0.71c0.36-0.45,2.04-0.32,3.77-0.23c1.72,0.1,2.47-0.42,2.73-1.14c0.22-0.61,1.46-0.65,1.95-1.1
				c0.49-0.45,0.91-0.49,2.17-0.42c1.27,0.07,2.43-0.62,3.25-0.94c0.81-0.32,2.3-0.19,3.08-0.39c0.78-0.2,0.91,0.97,1.78,0.88
				c0.88-0.1,1.27,0.26,1.72,0.23c0.45-0.03,0.39,0.32,0.84,0.42c0.45,0.1,0.39,0.49,0.1,0.91c-0.29,0.42,0.68,0.39,1.3,1.23
				c0.62,0.84,0.03,0.97,0.36,1.62c0.32,0.65,0.55,0.49,1.2-0.42c0.65-0.91,1.36-0.32,1.46-1.1c0.1-0.78,0.78-1.69,1.23-1.3
				c0.45,0.39-0.32,0.58-0.62,2.08c-0.29,1.49-0.81,0.71-0.91,1.14c-0.1,0.42,1.36,0.65,1.36-0.13c0-0.78,0.26-1.14,0.81-0.42
				c0.55,0.71-0.32,1.3-0.19,1.62c0.13,0.32,0.75-0.1,1.07-0.06c0.58,0.06,1.36,1.33,1.23,2.01c-0.13,0.68-0.32,1.33,0.78,1.88
				c1.1,0.55,0.68,0.65,1.49,0.65c0.81,0,2.5,0.65,2.95,1.04c0.45,0.39,1.62-0.29,1.85-1.07c0.23-0.78,1.07-0.03,0.62,0.23
				c-0.45,0.26,0.23,0.88,0.26,0.45c0.03-0.42,0.23-0.78,0.39-0.29c0.16,0.49,0.75,0.49,0.97,0.88c0.23,0.39,0.49,0.68,0.49,0.36
				c0-0.32,0.13-0.62,0.65-0.81c0.52-0.19,0.84-0.55,1.4-1.01c0.55-0.45,2.82-0.55,3.05-0.55c0.23,0,0.36-1.95,0.49-2.95
				c0.13-1.01,0.97-1.17,1.1-2.24c0.13-1.07,1.27-3.05,1.56-3.15c0.29-0.1,0.84-0.39,0.88-1.01c0.03-0.62,0.78-1.3,0.78-2.04
				c0-0.75,0.42-1.62,0.36-1.85c-0.06-0.23-0.32-0.94,0.16-1.46C471.02,211.52,470.33,210.91,470.59,210.71z M438.08,185.3
				c-0.07-0.39-2.37,0.06-1.88,0.45C436.52,186.02,438.14,185.69,438.08,185.3z M445.38,223.72c0.36,0.32,1.56,0.23,1.75-0.23
				C447.33,223.04,445.06,223.43,445.38,223.72z M460.05,232.75c-0.88,0.1-2.19-0.8-2.5-0.71c-0.36,0.1,1.14,2.34,0.68,2.92
				c-0.45,0.58,1.33,2.17,1.95,2.27c0.62,0.1,0.39-0.62,0.81-0.58c0.42,0.03,0.26-0.42,0.32-0.75c0.06-0.32,0.49,0.36,0.84,0.16
				c0.36-0.19-0.1-1.59,0.42-1.59c0.52,0,0.1-1.69-0.07-2.24C462.35,231.67,460.92,232.65,460.05,232.75z"/>
			<g className="hoverEf">
			<g>
				<path d="M431.6,207.81l-0.37,1.24h-0.83l1.41-4.38h1.02l1.42,4.38h-0.86l-0.4-1.24H431.6z M432.86,207.21l-0.35-1.08
					c-0.08-0.27-0.16-0.57-0.22-0.82h-0.01c-0.06,0.26-0.13,0.56-0.21,0.82l-0.34,1.08H432.86z"/>
				<path d="M437.66,208.1c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V208.1z"/>
				<path d="M438.49,208.32c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
					c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
					c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
					c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L438.49,208.32z"/>
				<path d="M442.15,204.98v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L442.15,204.98z"/>
				<path d="M443.52,206.9c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V206.9z"/>
				<path d="M447.61,209.05l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H447.61z M447.51,207.5c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V207.5z"/>
				<path d="M449.14,204.44h0.8v4.62h-0.8V204.44z"/>
				<path d="M451.65,205c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C451.48,204.57,451.65,204.76,451.65,205z M450.81,209.05v-3.16h0.8v3.16H450.81z"/>
				<path d="M454.25,209.05l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H454.25z M454.14,207.5c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V207.5z"/>
			</g>
		</g>
      </g>
      <path class="st0" d="M500.84,232.45c-0.45-0.32-0.68,0.13-1.17,0.36c-0.49,0.23-0.81-1.07-1.43-0.58
				c-0.62,0.49-0.06,0.68-0.75,1.27c-0.68,0.58-0.39,0.78-1.2,2.04c-0.81,1.27-3.6,1.98-4.02,2.66c-0.42,0.68-1.82,1.23-1.85,1.75
				c-0.03,0.52-0.88,0.89-0.39,1.59c0.39,0.55,0.91,0.16,1.33,0.58c0.42,0.42,0.84,0.07,1.04,0.42s1.62,0.36,2.69-0.62
				c1.07-0.97,0.55-1.43,0.97-1.69c0.42-0.26,0.49-1.04,0.75-1.53c0.26-0.49,0.45-1.01,1.62-1.01c1.17,0,0.55-0.23,0.49-0.78
				c-0.06-0.55,0.97-0.65,0.97-1.36c0-0.71,1.43-1.36,1.14-1.91C500.74,233.1,501.29,232.78,500.84,232.45z M491.46,243.65
				c0.23,0.06,0.97-0.13,0.55-0.65C491.59,242.48,490.92,243.5,491.46,243.65z M506.13,227.23c-0.29,0.49-0.88-0.13-1.43-0.13
				c-0.55,0-0.88-0.39-0.88-0.88c0-0.49-0.13-1.2-0.65-1.17c-0.52,0.03,0.16,0.55-0.03,0.81c-0.19,0.26-0.32-0.29-0.58-0.29
				c-0.26,0-0.55-0.36-0.55-1.01s-0.55-0.58-0.55-1.33c0-0.75-1.17-1.33-1.82-1.33s0.13,1.17,0.45,1.56
				c0.32,0.39,1.04,0.75,1.01,1.04c-0.03,0.29,0.49,1.04,0.88,1.17c0.39,0.13-0.23,1.27-0.23,2.17c0,0.91-0.71,0.75-1.2,1.33
				c-0.49,0.58,1.23,1.17,1.95,1.75c0.71,0.58-0.61,2.02-0.42,2.34c0.1,0.16,0.58,0.39,1.33,0.19c0.75-0.19,1.1-1.82,1.62-2.27
				c0.52-0.45,0.19-1.17,0.45-1.53c0.26-0.36,0.62-0.32,0.97-0.32c0.36,0,0.16-0.88,0.62-0.88c0.45,0,0.23-0.62,0.49-1.3
				C507.82,226.48,506.42,226.74,506.13,227.23z"/>
			<path class="st0" d="M506.03,195.39c0.55,0.52,1.65,0.49,1.69-0.45C507.75,194,505.54,194.93,506.03,195.39z M508.04,193.67
				c0.36,0.13,1.53-0.26,1.53-0.81C509.57,192.31,507.5,193.48,508.04,193.67z"/>
			<path class="st0" d="M344.76,109.12c0,0-0.95-0.48-1.3-0.43c-0.35,0.04-0.91-0.78-1.51-1c-0.61-0.22-1.43-0.6-1.69-1
				c-0.26-0.39-1.34-0.78-1.47-1.08c-0.13-0.3-0.35-0.82-0.65-1.21c-0.3-0.39-0.3-0.91-0.6-0.95c-0.3-0.04-2.16,0-2.16-0.26
				c0-0.26-0.13-1.47-0.52-1.56c-0.39-0.09-0.91-0.17-1.12-0.39c-0.22-0.22-0.91-0.48-1.04-0.26c-0.13,0.22-0.48,0.3-0.6,0.3
				c-0.13,0-0.09,0.61-0.52,0.56c-0.43-0.04-1.04,0.48-1.04,0.78c0,0.3,0.04,0.74-0.52,0.69c-0.28-0.02-0.68-0.08-1.03-0.24
				c-0.08,0.06-0.55,0.39-1.02-0.24c-0.52-0.69-1.33-1.39-1.79-1.39c-0.42,0-1.85,0-1.51,0.8c0.1-0.18,0.3-0.3,0.68-0.26
				c1.04,0.09,0.56,1.3,1.12,1.34c0.56,0.04,1.21,0.91,0.69,0.86c-0.52-0.04-0.95,0.48-1.47,0.3c-0.52-0.17-1.17-0.61-1.34,0
				c-0.17,0.6-0.39,1.25,0.3,1.21c0.69-0.04,0.56,0.43,0.09,0.91c-0.48,0.48,0.74-0.09,0.82,0.35c0.09,0.43,0.48,0.48,0.3,1.17
				c-0.11,0.44-0.03,1.31,0,1.96c0.46,0,1.08-0.04,1.21-0.23c0.22-0.3,0.91-0.91,1.3-0.91s0.82,0.17,1.04-0.13
				c0.22-0.3,1.47-0.22,1.69,0.04c0.22,0.26,0.91,0.48,1.08,0.48c0.17,0,0.26,0.39,0.69,0.35c0.43-0.04,1.25,0.13,1.25,0.39
				c0,0.26,0.3,0.52,0.65,0.52c0.35,0,0.65,0.87,1,0.82c0.35-0.04,1.04-0.04,1.04,0.17c0,0.18,0.06,1.21,0.26,1.79
				c0.12-0.06,0.24-0.07,0.31-0.03c0.17,0.12,0.93-0.12,1.04,0.29c0.12,0.41,1.33,0.17,1.27-0.46c-0.06-0.64,1.91-0.64,2.08-1.21
				c0.17-0.58,0.41-1.62,0.87-1.44c0.46,0.17,0.75-0.75,1.15-0.69c0.37,0.05,0.64,0.34,1.09,0.26L344.76,109.12z"/>
			<path class="st0" d="M339.33,126.69c-0.35-0.12,0-1.33-0.69-1.62c-0.69-0.29-1.31-0.76-1.5-1.27c-0.17-0.46-1.04-0.93-0.98-1.27
				c0.06-0.35,1.73-1.44,1.5-2.08c-0.23-0.63-1.33-0.52-1.44-0.75c-0.12-0.23,0.06-1.04-0.23-1.68c-0.29-0.64,0.23-1.21,0-1.56
				c-0.23-0.35,0.12-1.04,0.17-1.27c0.06-0.23,0.63-0.58,0.58-1.15c-0.03-0.35,0.14-0.57,0.32-0.67c-0.2-0.59-0.26-1.61-0.26-1.79
				c0-0.22-0.69-0.22-1.04-0.17c-0.35,0.04-0.65-0.82-1-0.82c-0.35,0-0.65-0.26-0.65-0.52c0-0.26-0.82-0.43-1.25-0.39
				c-0.43,0.04-0.52-0.35-0.69-0.35c-0.17,0-0.86-0.22-1.08-0.48c-0.22-0.26-1.47-0.35-1.69-0.04c-0.22,0.3-0.65,0.13-1.04,0.13
				s-1.08,0.61-1.3,0.91c-0.13,0.19-0.75,0.23-1.21,0.23c0.02,0.37,0.02,0.67-0.05,0.77c-0.23,0.34-1.9,0.65-3.16,0.69
				c-1.25,0.04-1.34-1.6-2.68-1.6c-0.98,0-1.72-0.89-1.77-1.73c-0.58-0.22-1.13-0.6-1.05-0.76c0.12-0.23,0.4-1.68-0.29-1.33
				c-0.69,0.35-1.68,1.44-2.95,1.27c-1.27-0.17-0.35-0.29-0.98-0.58c-0.64-0.29-0.23-0.98-0.87-1.1c-0.63-0.12-0.17,0.75-0.52,0.75
				c-0.35,0-0.06,0.98,0.06,1.21c0.12,0.23-0.23,1.5,0,1.56c0.23,0.06,0.75,0.98,0.69,1.16c-0.06,0.17,0.52,0.75,0.52,1.15
				c0,0.41,0.52,0.87,0.81,0.87c0.29,0,0.69,0.17,0.58,0.52c-0.12,0.35,0.17,0.87-0.17,0.87c-0.35,0-0.29,0.98-0.58,1.16
				c-0.29,0.17-0.06,1.21,0.17,1.33c0.23,0.12,0.75,0.4,0.69,0.81c-0.06,0.4,1.21,1.04,1.62,1.1c0.4,0.06,0.46,0.64,0.69,0.87
				c0.23,0.23-0.17,0.69-0.12,0.98c0.06,0.29,0.58,0.52,0.52,0.98c-0.03,0.28,0.43,0.75,0.83,1.15c0.13-0.04,0.25-0.06,0.36-0.06
				c0.39,0,0.37-0.43,0.67-0.17c0.3,0.26,0.71-0.02,1.02-0.04c0.3-0.02,1.12,1.54,1.49,2.29c0.37,0.76,0.35,1.02,0.84,1.34
				c0.5,0.32,1.06,0.15,1.77,0.89c0.71,0.74,2.55,1.23,3.33,1.28c0.78,0.04,1.36-0.84,2.36-0.65c0.99,0.19,0.76,1.47,1.12,1.93
				c0.37,0.45,4.41,0.76,5.11,0.95c0.25,0.07,0.86,0.12,1.53,0.13c-0.02-0.14-0.03-0.27-0.04-0.36c-0.06-0.69,0.46-1.27,0.93-1.5
				c0.46-0.23,0.87-0.35,1.33-0.41C340.14,127.73,339.68,126.81,339.33,126.69z"/>
			<path class="st0" d="M374.22,88.09c-0.04-0.3-0.3-0.17-0.52-0.04c-0.22,0.13-0.91,0.22-1.17,0.22s-0.73-0.35-0.73-0.61
				c0-0.26-0.06-0.55-0.31-0.55c-0.26,0-0.65-0.13-0.91-0.75c-0.26-0.62-0.65-0.62-0.94-0.78c-0.29-0.16-1.1,0.29-1.46,0.42
				c-0.36,0.13-0.97-0.13-1.43-0.23c-0.45-0.1-0.71-0.91-1.14-0.81c-0.42,0.1-0.52,0.94-0.84,0.94c-0.32,0-0.91-1.69-1.53-2.63
				c-0.62-0.94-1.72-2.82-2.69-3.18c-0.97-0.36-0.68-0.84-0.42-1.1c0.26-0.26-0.58-0.32-0.97-0.1c-0.39,0.23-0.88,0.65-1.33,0.75
				c-0.45,0.1-1.1,0.81-1.59,0.65c-0.49-0.16-0.71,0.62-1.1,0.26c-0.39-0.36,0.52-0.62,0.52-0.94c0-0.32-0.62,0.1-0.78-0.1
				c-0.16-0.19-0.68-0.39-0.68-0.1c0,0.29-0.42,0.13-0.42-0.1c0-0.23-0.29-0.65-0.55-0.36c-0.26,0.29-1.14,0.45-1.33,0.23
				c-0.19-0.23,0.13-0.32,0.16-0.62c0.03-0.29-0.32-0.68-0.26-1.04c0.07-0.36-0.62-0.71-0.94-0.58c-0.32,0.13-0.78,0-1.14-0.16
				c-0.36-0.16-1.75,0.1-1.82,0.42c-0.06,0.32-1.82,0.58-2.14,0.71c-0.32,0.13-1.62,0.32-2.08,0.39c-0.45,0.06-0.91,0.45-1.49,0.45
				c-0.58,0-1.69,0.07-2.21,0.45c-0.52,0.39-1.36,0.07-1.75,0.2c-0.39,0.13-1.04-0.07-1.23,0.06c-0.19,0.13-0.13,0.91,0.07,1.23
				c0.19,0.32,1.23,0.45,1.3,0.65c0.06,0.19-1.04,0.19-1.56,0.26c-0.52,0.07-0.39,0.71-0.26,1.04c0.13,0.32-0.52,0.65-0.91,0.78
				s0,0.78,0.39,0.78s0.71,0.32,1.04,0.32c0.32,0,0.39,0.65,0.39,1.04c0,0.39-0.39,0.52-0.91,0.52c-0.52,0-0.78-0.39-1.1-0.45
				c-0.32-0.06-0.26,0.58-0.52,0.71c-0.26,0.13-0.32-0.45-0.71-0.45c-0.39,0-0.84-0.06-0.97-0.45c-0.13-0.39-1.04-0.45-1.43-0.06
				c-0.39,0.39-0.52,0.19-0.84-0.07c-0.32-0.26-1.3,0.13-1.43,0.45c-0.13,0.32-0.84,0.45-1.36,0.13c-0.52-0.32-0.84-0.45-0.84,0
				c0,0.45-0.39,0.06-0.39-0.19c0-0.26-0.84-0.78-1.23-1.04c-0.39-0.26-1.62-0.26-1.69-0.52c-0.07-0.26-0.84-0.45-1.04,0
				c-0.2,0.45-0.52,0.13-0.78,0c-0.26-0.13-0.84,0.19-1.1,0.32c-0.26,0.13-1.1,0.71-1.56,0.71c-0.45,0-0.2,0.58-0.78,0.71
				c-0.58,0.13-0.45,0.52-0.39,0.78c0.06,0.26,0.39,0.71-0.13,0.91c-0.52,0.19-0.71-0.65-1.17-0.97c-0.45-0.32-0.84,0.07-0.84,0.52
				s-0.52,0.45-0.78,0.91c-0.26,0.45,0.45,0.52,0.45,0.71c0,0.19-0.39,0.39-0.52,0.45c-0.13,0.07-0.45,0.71-0.32,0.91
				c0.13,0.19,0.91,0.13,0.84,0.58c-0.06,0.45-0.13,0.91,0.26,0.78c0.39-0.13,0.78-0.07,1.04-0.07c0.26,0,0.45,0.52,0.91,1.04
				c0.45,0.52,0.65,0.78,0.19,0.91c-0.45,0.13-0.26,0.39,0.32,0.65c0.15,0.07,0.32,0.17,0.47,0.28c0.09-0.06,0.18-0.13,0.26-0.2
				c0.73-0.65,2.08-1.26,3.07-1c1,0.26,1.82-0.17,2.25,0.43c0.43,0.6,0,1.73,0.17,2.16c0.17,0.43-0.78,0.48-1.43,0.39
				c-0.65-0.09-1.99,0.26-1.82,0.65c0.17,0.39,0.65,1.3-0.22,0.82c-0.86-0.48-1.21,0.48-0.73,0.48c0.48,0,1.13,0.56,1.13,0.95
				c0,0.39,0.04,1.25,0.48,1.12c0.43-0.13,0.43,0.65,1.04,0.61c0.61-0.04,1.17,0.17,0.69,0.74c-0.48,0.56,0.3,2.12,0.48,1.77
				c0.11-0.22,0.01-0.69,0.18-0.99c-0.34-0.8,1.1-0.8,1.51-0.8c0.46,0,1.27,0.69,1.79,1.39c0.52,0.69,1.04,0.23,1.04,0.23l0.17-6.47
				l3.7-0.99c0.04-0.09,0.08-0.18,0.12-0.25c0.48-0.69,1.12-0.12,1.34,0.04c0.19,0.14,0.09-0.74,0.61-0.69
				c0.52,0.04-0.04,0.86,0.35,0.86c0.3,0,1,1.08,0.78,1.92c0.58,0.47,1.62,1.59,1.71,1.87c0.12,0.35,1.62-0.23,1.91-0.12
				c0.29,0.12,2.54,0.12,2.95,0.12c0.4,0,0.69,0.75,1.15,0.87c0.46,0.12,0.29,0.87,0.35,1.39c0.06,0.52,0.75,1.04,0.81,1.5
				c0.06,0.46,0.81,0.23,1.27,0.35c0.46,0.12,1.15,0.29,1.21,0.75c0.06,0.46,0.64,0.12,0.69-0.23c0.06-0.35,0.64-0.81,1.5-1.15
				c0.39-0.16,0.65-0.16,0.84-0.08c0.16-0.41,0.33-0.77,0.44-0.82c0.3-0.13,0.86-0.56,0.91-0.99c0.04-0.43,1.21-0.43,1.99-0.04
				c0.78,0.39,1.26,0.39,1.3-0.22c0.04-0.61,1.17-0.78,1.51-0.61c0.35,0.17,1.43,0.78,1.95,0.56c0.52-0.22,3.12,0.04,3.77,0.22
				c0.65,0.17,0.82-0.26,1.38,0.39c0.47,0.55,0.7,0.05,1.23,0.47c0-0.17-0.04-0.37-0.04-0.59c0-0.42,0.65-0.78,0.78-1.1
				c0.13-0.32-0.58-1.3-0.58-1.62c0-0.32,0-1.1-0.32-1.27c-0.32-0.16,0.62-0.58,0.84-0.57c0.48,0.02,1.17-0.48,1.38-0.27
				c0.21,0.21,0.63,0.27,1.05,0.27c0.42,0,0.24-0.48,0-0.69c-0.24-0.21,0.75-2.16,0.9-2.81c0.15-0.66,0.51-0.06,0.99,0
				c0.48,0.06,1.05-0.03,1.35,0.12c0.3,0.15,1.17-0.18,1.38-0.51c0.21-0.33-0.24-0.42-0.21-1.17c0.03-0.75,0.48-1.11,0.96-1.11
				c0.48,0,0.63-0.45,0.63-0.81c0-0.33,0.39-0.53,0.51-0.94C374.38,88.47,374.25,88.26,374.22,88.09z M337.19,94.43
				c-0.17,0.24-1.88-0.21-2.03-0.55C335.01,93.5,337.57,93.92,337.19,94.43z M361.76,94.28c-1.21,0-3.89-0.61-4.98,0.56
				c-1.08,1.17-0.3,1.77-0.78,1.86c-0.51,0.09-0.78-1.77-0.17-2.38c0.61-0.6,2.55-0.86,3.76-0.56c1.21,0.3,3.37-0.26,3.68-0.17
				C363.58,93.67,362.98,94.28,361.76,94.28z"/>
			<g>
				<g>
					<path class="st0" d="M279.55,76.98c-0.19,0.1-0.71-0.1-0.88-0.32c-0.13-0.18-0.53-0.11-0.89-0.08
						c0.08,0.05,0.17,0.11,0.23,0.22c0.19,0.35-0.78,0.39-0.48,0.8c0.3,0.41-0.69,0.43-0.69,0c0-0.43-1.04-0.22-1,0.06
						c0.02,0.1-0.02,0.23-0.09,0.35c0.28,0.1,0.61,0.22,0.74,0.27c0.2,0.08,2.79,0.35,3.8,0.16c-0.05-0.19,0.05-0.76,0.05-0.97
						C280.36,77.21,279.75,76.88,279.55,76.98z M342.16,10.34c1.64-0.17,1.6-1.08,0.73-1.25c-0.86-0.17-0.99,0.52-1.43,0.48
						c-0.43-0.04-2.07,0.27-1.73,0.65C340.13,10.64,341.34,10.42,342.16,10.34z M350.51,34.39c0.56,0.09,2.08-0.22,2.25-0.52
						c0.17-0.3-0.61-0.65-1.6-0.65C350.17,33.23,350.03,34.32,350.51,34.39z M382.49,12.28c0.82,0.35,0.52,0.56-0.35,0.56
						c-0.86,0-1.61,0.29-1.04,0.52c0.43,0.17,0.39,0.65,1.3,0.86c0.91,0.22,1.82-0.52,1.73-1.04c-0.09-0.52,3.16-0.99,4.02-1.21
						c0.86-0.22-0.56-1.12,0.39-1.21c0.95-0.09,0.56-0.52-0.61-0.69c-1.17-0.17-0.69-1.17-1.08-1.51c-0.39-0.35-0.61,0.43-2.12,0.74
						c-1.51,0.3-2.25,0.69-1.64,0.99c0.61,0.3,0,1.04-0.65,1C381.8,11.24,381.67,11.94,382.49,12.28z M384.78,13.49
						c0,0.48-1.39,0.95-1,1.3c0.35,0.3,0.65,0.22,1.08,0.3c0.43,0.09,0.65,1.17,1.43,1c0.78-0.17,2.9,0.86,4.15,0.86
						c1.26,0,1.38-1.04,0.99-1.08c-0.39-0.04-0.04-0.65,0.65-1.64c0.69-0.99-2.16-1.73-2.16-0.95s-0.82-0.17-1.08-0.65
						C388.59,12.15,384.78,13.02,384.78,13.49z M363.15,35.04c0.69,0.35,1.21,0.22,1.12-0.48C364.19,33.87,362.6,34.77,363.15,35.04
						z M381.36,9.21c0.17-0.65-2.7-0.37-1.95,0.26C379.68,9.69,381.19,9.86,381.36,9.21z M508.7,40.58
						c0.86,0.43,1.38-0.52,2.08-0.04c0.69,0.48,2.38-0.22,3.07-0.35c0.69-0.13,0.35-1-1.6-1.25
						C510.3,38.68,507.77,40.11,508.7,40.58z M391.49,19.6c0.61,0.35,2.55-0.43,3.85-0.39c1.3,0.04,4.93-1,5.06-1.69
						c0.13-0.69-1.34-0.65-1.9-1.26c-0.56-0.61-1.82-0.09-2.12,0.43c-0.3,0.52-0.65,0.04,0.17-0.78c0.82-0.82-0.82-1.47-0.86-0.95
						c-0.04,0.52-1.56,0.09-1.56,0.56c0,0.48-0.56,0.69-0.52,1.17c0.04,0.48-1.04-0.26-1.08,0.82
						C392.48,18.6,390.85,19.23,391.49,19.6z M447.31,28.51c0.61,0.3,0.82,0.61,1.17,0.95c0.35,0.35,1.64-0.17,2.16-0.35
						c0.52-0.17,0.56,0.78,1.38,0.26c0.82-0.52,1.64-0.26,2.6-0.26c0.95,0-0.17-1.17-0.13-1.69c0.04-0.52,1.12-0.17,0.78,0.26
						c-0.35,0.43,0.52,1.43,1.9,1.34c1.38-0.09,0.35-0.95,1.04-1.25c0.69-0.3,0.6-0.65-0.39-1.26c-0.99-0.61-2.29-0.22-3.16-0.61
						c-0.92-0.42-2.03-0.43-2.08,0.52c-0.04,0.95-1.86-1.04-2.81-1.34C448.82,24.79,445.87,27.79,447.31,28.51z M456.35,34.05
						c0.78-0.17-0.56-1.6-1.9-1.82c-1.34-0.22-2.59,0.89-2.42,1.13C452.41,33.92,455.57,34.22,456.35,34.05z M452.89,31.19
						c0-0.65-2.36,0.24-1.38,0.56C452.02,31.93,452.89,31.84,452.89,31.19z M460.42,28.46c1.04,0.09,1.56,1.08,3.55,1.21
						c1.99,0.13,3.46-0.26,3.5-0.69c0.04-0.43-1.82-1.08-2.38-0.74c-0.56,0.35-1-0.56-1.69-0.35c-0.69,0.22-1.64,0.17-1.99-0.43
						C461.06,26.86,459.9,28.42,460.42,28.46z M315.85,10.9c1-0.91,2.29-0.04,2.47-0.48c0.17-0.43-1.86-0.56-2.6-0.17
						c-0.74,0.39-2.46,0.08-2.25,0.56C313.61,11.11,314.86,11.81,315.85,10.9z M456,84.02c-0.48-1.25,0.69-2.47-0.17-3.12
						c-0.86-0.65-0.52-1.99-0.95-1.73c-0.43,0.26,0,1.43-0.69,1.51c-0.69,0.09-0.04,0.74-0.3,1.6c-0.26,0.87,0.13,2.12,0.48,2.9
						c0.35,0.78-0.48,4.93-0.13,5.63c0.35,0.69-0.56,3.75-0.26,4.11c0.52,0.61,0.17-0.82,0.95-0.95c0.78-0.13,0.78,0.86,1.26,1.08
						c0.48,0.22,0.17-1.38-0.26-1.3c-0.43,0.09-0.78-1.04-1-1.73c-0.22-0.69,0.43-1.51,0.43-2.38c0-0.86,1.08-0.82,1.73-0.17
						c0.65,0.65,0.73,0.22,0.48-0.26C457.3,88.74,456.48,85.28,456,84.02z M319.92,10.51c0.61,0.43-1.69,0.3-1.99,0.74
						c-0.3,0.43-1.64,0.28-1.38,0.78c0.26,0.52,2.42,0.56,2.42,0.13c0-0.43,1.13-0.04,1.17-0.43c0.04-0.39,0.52-0.82,1.73-0.91
						c1.21-0.09,1.21-0.52,0.09-1.04C320.83,9.25,319.32,10.08,319.92,10.51z M318.06,45.47c1.17,1.08,2.16-0.48,2.73-0.56
						c0.56-0.09-0.35-0.69-1.3-0.95C318.54,43.7,317.18,44.65,318.06,45.47z M525.34,52.85c-0.43-0.15-1.82-1.04-2.16-1.54
						c-0.35-0.5-2.01-0.74-2.01-0.48c0,0.26-0.54-0.06-0.67-0.35c-0.13-0.28-1.88-0.32-1.9-0.02c-0.02,0.3,0.41,0.32,0.67,0.67
						c0.26,0.35-0.35,0.54-0.22,0.93c0.13,0.39-0.65-0.19-0.93-0.41c-0.28-0.22-0.15-1.06-0.06-1.49c0.09-0.43-0.5-0.35-0.56-0.78
						c-0.07-0.43-2.01-1.32-2.83-1.67c-0.82-0.35-1.88-0.65-2.16-1.08c-0.28-0.43-1.75-0.48-2.31-1.06
						c-0.56-0.58-3.03-1.62-4.09-1.69c-1.06-0.07-0.82-0.65-1.34-0.56c-0.52,0.09-2.75-0.02-3.44-0.13
						c-0.69-0.11-0.84,0.41-1.34,0.22c-0.5-0.19-3.07-1.13-3.38-0.78c-0.3,0.35-0.17,0.84-0.48,0.93c-0.3,0.09,0,0.52,0.71,1.17
						c0.71,0.65,0.28,1.15-0.37,1.43c-0.65,0.28-1.69-0.26-2.01-0.76c-0.32-0.5-1.21-0.28-1.43-0.93c-0.22-0.65,0.26-0.76,0.69-0.39
						c0.43,0.37,1.02,0.06,1.08-0.41c0.07-0.48-1.17-0.8-1.84-0.76c-0.67,0.04-1.04,0.97-1.73,1.36c-0.69,0.39-3.37-0.15-3.66-0.41
						c-0.28-0.26-4.02,0-4.41,0.26c-0.39,0.26-0.11,1.28-0.3,1.36c-0.19,0.09-0.39-1.17-0.39-1.41c0-0.24-0.45-0.41-1.13-0.41
						c-0.67,0-1.06-0.02-0.78-0.3c0.28-0.28-0.26-0.56,0.26-0.93c0.52-0.37-0.95-1.52-2.38-2.08c-1.43-0.56-4.67-0.24-5.54,0.02
						c-0.86,0.26-2.6,0.17-3.42,0.19c-0.82,0.02-0.15-0.32-0.39-0.71c-0.24-0.39-1.64-0.86-1.84-0.54c-0.19,0.32-0.56,0-0.56-0.24
						c0-0.24-1.32-0.71-1.84-0.61c-0.52,0.11-0.76-0.48-0.15-0.48c0.61,0,1.64,0.13,1.19-0.56c-0.45-0.69-4.35-0.91-4.72-0.78
						c-0.37,0.13-0.02,0.63-0.87,1.43c-0.84,0.8-1.67,0.26-1.56-0.13c0.11-0.39,1.15-0.32,1.15-0.71c0-0.39-1.43-0.17-1.64-0.5
						c-0.22-0.32,0.3-0.54,0.82-0.39c0.52,0.15,1.43,0.24,1.47,0c0.04-0.24-0.54-0.28-1.41-0.43c-0.87-0.15-2.96-0.65-4.07-0.54
						c-1.1,0.11-1.6-0.41-2.21-0.45c-0.61-0.04-0.61,0.32-0.32,0.67c0.28,0.35-0.58,0.56-1.3,0.45c-0.71-0.11-1.75,0.39-1.34,0.91
						c0.41,0.52,0.82-0.15,1.17,0.02c0.35,0.17-0.91,0.54-0.39,0.78c0.52,0.24,0.43,0.73,0.45,0.99c0.02,0.26-0.87,0.39-1.19,0
						c-0.32-0.39-0.78-0.04-1.39-0.09c-0.61-0.04-1.06,0.24-0.41,0.32c0.65,0.09,0.86,0.5,0,0.58c-0.87,0.09-0.65-0.8-1.17-0.69
						c-0.52,0.11-1.64-0.41-2.25-0.39c-0.61,0.02-0.89,0.5-1.69,0.56c-0.8,0.07-1.88-0.41-2.21-0.84c-0.32-0.43-0.43-0.56-0.82-0.07
						c-0.39,0.5-0.41,1.45-0.8,1.51c-0.39,0.07-0.54,0.91-0.93,1.13c-0.39,0.22-0.41-0.32-0.91-0.28c-0.5,0.04-2.1-1.51-2.19-1.97
						c-0.09-0.45-1.08-1.32-1.34-1.49c-0.26-0.17,0.26-0.26,0.58,0.02c0.32,0.28,0.73,0.28,1.06,0.11c0.32-0.17,0.28-0.97-0.28-1
						c-0.56-0.02-0.35-0.5-0.07-0.58c0.28-0.09-0.65-0.93-0.3-1.02c0.35-0.09,0.19-0.45-0.28-0.52c-0.48-0.07-0.97-0.45-1-0.69
						c-0.02-0.24-2.03-0.32-1.95,0.02c0.09,0.35-0.65,0.24-0.54-0.04c0.11-0.28-0.71-0.19-1.67-0.45c-0.95-0.26-0.97-0.93-1.3-0.97
						c-0.32-0.04-0.22,0.93-0.69,0.71c-0.48-0.22-1,0.2-0.74,0.76c0.26,0.56,0.02,0.65-0.02,1.1c-0.04,0.45-0.3,0.26-0.87,0.06
						c-0.56-0.19-0.56,0.48-1.77,0.17c-1.21-0.3-1.75,0.02-1.97-0.37c-0.22-0.39-0.58-0.43-0.63-0.11c-0.04,0.32-1.9,0.19-2.05-0.28
						c-0.15-0.48,0.32-0.56,0.61-0.69c0.28-0.13-0.3-0.32-1.32-0.26c-1.02,0.06-1.67-0.52-2.7-0.41c-1.04,0.11-3.35,0.32-3.68,0.48
						c-0.32,0.15,0.3,0.74-0.19,0.78c-0.5,0.04-0.15-0.95-0.24-1.43c-0.09-0.48-0.84-0.54-0.74-0.17c0.11,0.37-1.19,0.5-1.51,0.22
						c-0.32-0.28-0.84-0.65-1.69-0.86c-0.84-0.22-1.88,0.69-1.64,0.95c0.24,0.26,0.86,0.06,0.89,0.24c0.04,0.36-1.79,0.22-1.79,0.52
						c0,0.3-1.71,0.71-2.23,0.78c-1.42,0.18-1.73,0.17-2.4,0.74c-0.67,0.56-0.15-0.5,0.37-1.02c0.52-0.52,1.06-0.3,1.36-0.71
						c0.3-0.41,0.78-0.37,1.6-0.5c0.82-0.13,0.78-0.61,1.17-0.78c0.39-0.17,1.34-0.54,1.51-0.93c0.17-0.39,2.12-1.04,2.51-1.02
						c0.39,0.02,0.48-0.78,0.69-0.78c0.22,0,1.34-0.5,1.77-0.69c0.43-0.19,0.65-0.61,0.67-0.89c0.02-0.28-0.58-0.24-0.65-0.5
						c-0.06-0.26,0.28-0.09,0.74-0.26c0.45-0.17,0-0.78-0.37-0.67c-0.37,0.11,0.19-0.32-0.13-0.71c-0.32-0.39-0.97,0.24-0.95-0.17
						c0.02-0.41-0.04-0.93-0.3-1.19c-0.26-0.26-0.74,0.45-1.28,0.04c-0.54-0.41-2.12-0.67-2.12-0.5c0,0.17-0.76-0.26-0.91-0.09
						c-0.15,0.17-1.41,0.15-1.8,0.04c-0.39-0.11-0.93,0.15-0.95,0.54c-0.02,0.39-1.56,0.24-1.84,0.17
						c-0.28-0.06,1.06-1.17,1.06-1.36c0-0.19-2.27,0.04-2.4-0.17c-0.13-0.22-1.23-0.28-1.73-0.26c-0.5,0.02-0.02-0.13,0.43-0.17
						c0.45-0.04,0.87-0.43,1.36-0.52c0.5-0.09,0.39-0.26,0.17-0.56c-0.22-0.3-0.74-0.15-1.23-0.3c-0.5-0.15-1.1-0.37-1.54-0.39
						c-0.43-0.02-0.91,0.22-1.93,0.41c-1.02,0.19-1.1,0.97-1.62,1.21c-0.52,0.24-1.43,1.19-1,1.28c0.43,0.09,0.22,0.35,0.28,0.63
						c0.07,0.28-0.32,0.35-0.82,0.26c-0.5-0.09-2.25-0.06-2.23,0.3c0.02,0.37,1.02,0.54,0.95,0.73c-0.06,0.19-0.76-0.02-1.1-0.22
						c-0.35-0.19-1.13-0.09-1.28,0.28c-0.15,0.37-0.76,0.28-0.95,0.19c-0.19-0.09-0.63,0.17-0.95,0.07
						c-0.32-0.11,0.65-0.58,0.41-0.82c-0.24-0.24-1.28-0.02-1.38,0.37c-0.11,0.39-0.74-0.28-0.97-0.15
						c-0.24,0.13-0.5,0.28-0.95,0.48c-0.45,0.19-1.23-0.22-1.41,0.13c-0.17,0.35,0.87,0.45,0.89,0.61c0.02,0.15-2.36,0.13-2.42,0.37
						c-0.06,0.24-0.87,0.24-1.84,0.26c-0.97,0.02-0.58,0.5-1.36,0.65c-0.78,0.15-1.12,0.17-1.38,0.56
						c-0.26,0.39-0.87,0.45-1.13,0.15c-0.26-0.3-0.97,0.15-0.58,0.19c0.39,0.04,0.02,0.43-0.22,0.35c-0.24-0.09-0.93,0.41-0.54,0.37
						c0.39-0.04,0.58,0.28,0.39,0.52c-0.19,0.24-1.04-0.28-1.26-0.07c-0.22,0.22,0.41,0.5,0.8,0.45c0.39-0.04,0.61,0.22,0.65,0.5
						c0.04,0.28-0.91-0.17-1.1,0.07c-0.19,0.24,0.15,0.35,0.56,0.39c0.41,0.04,0.11,0.19,0.48,0.52c0.03,0.03,0.05,0.05,0.07,0.07
						c0.22,0.27-0.2,0.36-0.16,0.62c0.04,0.28-0.41,0.39-0.41,0.13c0-0.26-1.15-0.35-1.38-0.06c-0.24,0.28-0.41,0.5-0.69,0.24
						c-0.28-0.26-1.1,0.06-2.16,0.11c-1.06,0.04-3.24,0.13-3.79,0.32c-0.54,0.19-1,0.95-0.5,1.45c0.5,0.5,0.02,0.71,0.02,1.04
						c0,0.32,1.3,1,1.99,1.06c0.69,0.06,1.25,0.91,0.84,1.43c-0.41,0.52-1.67-0.15-2.49-0.8c-0.82-0.65-2.73-0.91-3.35-0.86
						c-0.63,0.04-0.26-0.56-1.06-0.54c-0.8,0.02-1.47,0.74-0.93,0.74c0.54,0,0.89-0.04,0.61,0.22c-0.28,0.26,0.15,0.19,0.8,0.48
						c0.65,0.28-0.54,0.67-1.21,0.22c-0.67-0.45-1.38-0.02-1.58,0.32c-0.19,0.35,0.78,1.34,1.95,1.51c1.17,0.17,0.78,0.54,1.32,0.74
						c0.54,0.19,0.15,0.41-0.22,0.43c-0.37,0.02-1.1-0.56-1.69-0.87c-0.58-0.3-2.16,0.13-2.49-0.22c-0.32-0.35,0.22-0.69-0.09-0.95
						c-0.3-0.26-0.04-0.84,0.35-1.45c0.39-0.61-0.06-1.64-0.63-1.82c-0.56-0.17-0.52,0.41-0.39,0.58c0.13,0.17,0.06,1-0.37,1.54
						c-0.43,0.54-1.75,0.54-1.75,0.91c0,0.37-0.89,0.65-0.58,0.91c0.3,0.26,1.49,1.6,1.67,2.01c0.17,0.41-1.06,1.71-0.87,2.47
						c0.19,0.76-0.17,1.1,0.06,1.47c0.24,0.37,0.78,0.04,1.13,0.19c0.35,0.15,0.91-0.22,1.71-0.37c0.8-0.15,2.27,0.69,2.81,0.95
						c0.54,0.26,0.26,0.69,0.54,1.12c0.28,0.43-0.65,0.43-0.67,1.08c-0.02,0.65,1.62,0.93,1.69,1.12c0.07,0.19-1.17,0.04-1.64-0.13
						c-0.48-0.17-0.52-0.8-0.67-0.93c-0.15-0.13,0.32-0.65,0.37-1.06c0.04-0.41-0.54-0.65-0.73-0.93c-0.2-0.28-0.54-0.69-0.93-0.61
						c-0.39,0.09-1.82,0.22-2.25,0.61c-0.43,0.39,0.06,1.49,0.3,2.03c0.24,0.54-1.26,1.3-1.21,1.73c0.04,0.43-0.45,0.63-1.1,0.97
						c-0.65,0.35-1.36,0.6-1.34,1.17c0.02,0.56-0.86,0.26-1.32,0c-0.45-0.26-1.04,0.15-1.69,0.13c-0.65-0.02-0.65-0.56-1.17-0.37
						c-0.52,0.19-0.86-0.24-0.71-0.63c0.15-0.39,0.73-0.09,0.78,0.19c0.04,0.28,0.41,0.15,0.84-0.09c0.43-0.24,0.22,0.3,0.84,0.35
						c0.63,0.04,0.28-0.32,0.76-0.28c0.48,0.04,0.5-0.11,0.35-0.35c-0.15-0.24,0.35-0.41,0.65-0.54c0.34-0.15,0.17-0.63,0.45-0.78
						c0.28-0.15,0.09-0.45,0.39-0.48c0.3-0.02,0.43-0.48,0.71-0.48c0.28,0,0.43-0.45,0.26-0.69c-0.17-0.24,0.11-0.89,0.39-0.97
						c0.28-0.09,0.3-0.35,0.04-0.54c-0.26-0.19-1.21-0.84-1.15-1.25c0.06-0.41-0.17-1.17-0.04-1.49c0.13-0.32,0.02-1-0.02-1.39
						c-0.04-0.39,0.24-0.82,0.37-1.54c0.13-0.71-0.76-1.47-1.19-1.67c-0.43-0.19-0.15-0.58,0.48-1.1c0.63-0.52,0.67-2.14,0.63-2.49
						c-0.04-0.35-1.32-0.65-1.82-0.61c-0.5,0.04-2.34-0.04-2.79-0.13c-0.45-0.09-0.61,0.39-0.82,0.82c-0.22,0.43-0.69,1.1-0.97,2.08
						c-0.28,0.97-1.43,1.28-1.95,1.49c-0.52,0.22-0.84,0.93-0.61,1.28c0.24,0.35,0.58,0.07,0.82,0.19c0.24,0.13-0.02,1.04-0.17,1.17
						c-0.15,0.13,0.28,0.45-0.09,0.69c-0.37,0.24-0.71,0.74-0.5,1c0.22,0.26,1.28,0.39,1.73,0.65c0.45,0.26,0.28,0.74,0.63,1.19
						c0.35,0.45,0.71,0.17,0.89,0.45c0.17,0.28-0.63,1.41-0.91,1.45c-0.28,0.04-1.43-1.15-1.77-1.41c-0.35-0.26-1.69-0.67-2.36-1.02
						c-0.67-0.35-1.21-0.3-1.95-0.8c-0.73-0.5-1.36-0.74-2.7-0.71c-1.34,0.02-2.21-0.3-2.66-0.24c-0.45,0.06-0.28-0.45-1.08-0.8
						c-0.8-0.35-1.32-0.82-1.77-0.58c-0.45,0.24-0.28,1.1,0.41,1.21c0.69,0.11,0.35,0.48,1.08,0.43c0.73-0.04,0.95,0.22,0.93,0.67
						c-0.02,0.45,0.35,0.91,0.67,1.21c0.32,0.3,0.13,0.8-0.45,0.8c-0.58,0-1.25,0.09-0.99,0.5c0.26,0.41-0.17,0.52-0.76,0.3
						c-0.58-0.22-0.24-0.63,0-0.87c0.24-0.24-0.52-0.54-0.89-0.67c-0.37-0.13-1.84,0.95-2.25,1.12c-0.41,0.17-1.43-0.26-2.47,0
						c-1.04,0.26-0.97,1.08-1.36,1c-0.39-0.09-1.54,0.11-1.97-0.15c-0.43-0.26-0.09-0.41,0.37-0.39c0.45,0.02,0.61-0.13,0.35-0.43
						c-0.26-0.3,0.35-0.78,0.32-1c-0.02-0.22-1.71,0.35-1.99,0.58c-0.28,0.24,0.02,0.67-0.28,0.82c-0.3,0.15-0.3-0.26-0.65-0.39
						c-0.35-0.13-2.88,0.45-3.35,1c-0.48,0.54-1.41,0.56-1.41,0.87c0,0.3-1.15,0.28-1.6,0.5c-0.45,0.22-0.15,1.1-0.32,1.54
						c-0.17,0.43-1.95,0.3-2.49,0.32c-0.54,0.02-0.74-1.04-1.17-1.1c-0.43-0.07-0.17-0.67-0.04-0.93c0.13-0.26,0.48-0.04,1.02-0.39
						c0.54-0.35,1.08,0.17,1.3-0.07c0.22-0.24-0.82-0.87-0.89-1.41c-0.07-0.54-1.21-0.69-1.86-0.52c-0.65,0.17-1.36,0.02-1.82-0.19
						c-0.45-0.22-0.41,0.39,0.26,0.56c0.67,0.17,0.32,0.69,0.43,1.02c0.11,0.32-0.17,1.06-0.56,1.62c-0.39,0.56-0.11,0.54,0.48,0.52
						c0.58-0.02,0.54,0.74,0.54,1.3c0,0.56-0.45,0.71-0.45,1.13c0,0.41-0.48-0.19-0.63-0.02c-0.15,0.17-0.35-0.09-0.43-0.43
						c-0.09-0.35-0.97-0.15-1.47-0.28c-0.5-0.13-0.69,0.09-0.93,0.45c-0.24,0.37-0.93,0.52-1.3,0.56c-0.41,0.05-1.02,0.76-1.41,1.02
						c-0.39,0.26-0.45,0.76,0.09,1.28c0.54,0.52,0.65,0.87,0.61,1.15c-0.04,0.28-1.51,0.2-1.75-0.11c-0.24-0.3-1.19-0.41-1.51-0.35
						c-0.32,0.07-1.21-0.97-1.67-0.99c-0.45-0.02-0.74,0.54-0.89,0.82c-0.15,0.28,0.22,0.37,0.43,0.84c0.22,0.48,1.02,0.5,1.43,0.5
						c0.41,0,0.26,0.63,0.13,0.89c-0.13,0.26-0.89,0.45-1.1,0.17c-0.22-0.28-1.13,0-1.15-0.35c-0.02-0.35-0.67-0.86-1.34-0.76
						c-0.67,0.11-0.8-0.32-0.76-0.84c0.04-0.52-0.37-0.82-0.35-1.06c0.02-0.24-0.3-0.71,0.04-0.95c0.35-0.24,0.09-0.6,0.11-0.93
						c0.02-0.32-0.58-0.82-1.13-0.82c-0.54,0-0.43-0.54-0.89-0.65c-0.45-0.11-1.36-0.87-1.43-1.19c-0.07-0.32-0.76-0.37-0.52-0.48
						c0.24-0.11,0.78,0.04,1.17,0.43c0.39,0.39,1.02,0.74,2.03,0.87c1.02,0.13,1.43,0.6,2.55,0.8c1.13,0.2,1.8,0.32,3.42,0.56
						c1.62,0.24,3.79-1.41,4.24-1.86c0.45-0.45-0.13-1.38-0.13-1.73c0-0.35-0.74-0.39-0.84-0.65c-0.11-0.26-0.58-0.73-1.21-0.73
						s-0.71-0.71-1.21-0.67c-0.5,0.04-0.84-0.22-2.14-1.08c-1.3-0.86-3.09-1.49-3.42-1.34c-0.32,0.15-0.54,0.24-0.76-0.09
						c-0.22-0.32-0.71-0.28-1.06-0.02c-0.35,0.26-0.69-0.24-1.34-0.09c-0.65,0.15-0.99-0.28-0.82-0.48
						c0.17-0.19,1.13-0.02,1.08-0.35c-0.04-0.32-0.39,0-0.97-0.39c-0.58-0.39-0.91-0.13-1.04,0.19c-0.13,0.32-0.58,0.24-0.89-0.04
						c-0.03-0.03-0.06-0.05-0.1-0.07c-0.1,0.54-0.42,0.6-0.74,0.52c-0.39-0.09-0.78,0.56-1.21,0.56c-0.43,0-0.91,0.48-1,0.87
						c-0.09,0.39-0.56,0.17-0.56,0.43c0,0.26,0,0.52-0.22,0.78c-0.22,0.26,0,0.39,0.22,0.82c0.22,0.43,0.69,0.39,0.91,0.52
						c0.22,0.13,1.04,0.82,1.04,1.08c0,0.26-0.65,0.91-0.91,1.08c-0.26,0.17-0.61,0.6-0.43,0.86c0.17,0.26,0.91,1.34,1.38,2.21
						c0.48,0.87-0.17,0.48-0.48,0.86c-0.3,0.39,0.13,0.87,0.22,1.13c0.09,0.26-0.39,0.22-0.35,0.43c0.04,0.22,0.69,0.3,0.74,0.48
						c0.04,0.17-0.43,0.39-0.22,0.74c0.22,0.35,0.74,0.35,0.78,0.78c0.04,0.43-0.95,0.48-0.91,0.82c0.04,0.35,1.3,0.78,1.86,1.43
						c0.56,0.65,0.52,0.82,0.39,1.17c-0.26,0.7-1.82,1.3-2.16,2.08c-0.35,0.78-1.64,1.21-2.25,1.82c-0.25,0.25-0.49,0.58-0.7,0.89
						c0.26-0.07,0.46-0.14,0.61-0.22c0.35-0.17,0.58,0.67,0.93,0.95c0.35,0.28,1.04,0.02,1.28,0.28c0.24,0.26-0.19,0.28-0.69,0.19
						c-0.5-0.09-0.63,0.22-1.41,0.48c-0.58,0.19-0.52,0.4-0.72,0.56c0.35,0.51-0.12,0.57-0.3,0.96c-0.19,0.42-0.45,0.49-0.49,0.75
						c-0.03,0.26,0.06,0.45,0.1,0.84c0.03,0.39,0.1,0.45,0.32,0.78c0.23,0.32-0.19,0.32-0.39,0.49c-0.19,0.16-0.06,0.52,0.19,0.78
						c0.26,0.26,0.26,0.58,0.16,0.81c-0.1,0.23,0.23,0.62,0.45,0.84c0.23,0.23,0.19,0.84,0.39,1.01c0.19,0.16,0.42,0.32,0.78,0.23
						c0.36-0.1,0.58,0.07,0.75,0.36c0.16,0.29,0.42,0.16,0.78,0c0.36-0.16,1.17,0.29,1.36,0.39c0.19,0.1,0.1,0.65,0.13,1.01
						c0.03,0.36-0.19,0.81,0.16,1.04c0.36,0.23,0.26,0.75,0.68,0.91c0.42,0.16,0.58,0.75,0.84,0.81c0.26,0.06,0.75,0.45,0.81,0.75
						c0.06,0.29-0.45,0.55-0.75,0.68c-0.29,0.13-0.84-0.39-1.04-0.16c-0.19,0.23,0.07,0.65,0.23,1.36c0.16,0.71,0.29,0.78,0.62,0.78
						c0.32,0,0.45-0.23,0.68-0.36c0.23-0.13,0.75,0.06,0.97-0.03c0.23-0.1,1.04-0.13,1.2,0.13c0.16,0.26,0.32,0.88,0.19,1.1
						c-0.13,0.23,0.16,0.49,0.32,0.81c0.16,0.32,0.84,0.16,1.07,0.26c0.23,0.1,0.75,0.81,0.65,1.14c-0.1,0.32,0.32,0.39,0.55,0.29
						c0.23-0.1,0.45,0.16,0.71,0.32c0.26,0.16,0.49,0.1,0.75-0.07c0.26-0.16,0.68-0.29,0.81-0.06c0.13,0.23,0.65,0.65,0.65,0.78
						c0,0.13,0.81,0,1.2,0.19c0.39,0.19,0.81,0.1,1.23,0.32c0.42,0.23,0.81,0.26,0.84,0.52c0.03,0.26-0.42,0.49-0.42,0.62
						c0,0.13,0.36,0.23,0.36,0.42c0,0.19-0.45,0.19-0.52,0.36c-0.07,0.16,0.26,0.39,0.32,0.55c0.07,0.16-0.26,0.71-0.26,0.88
						c0,0.16-0.97-0.03-1.2-0.03c-0.23,0-0.91,0.75-1.14,0.78c-0.19,0.03-0.1,0.39-0.03,0.69c0.7-0.13,1.42-0.19,1.49-0.03
						c0.13,0.26-1.51,0.95-1.82,1.06c-0.3,0.11,0.58,0.56,0.56,0.82c-0.02,0.26-0.95,0.32-0.87,0.84c0.09,0.52-0.3,0.63-0.8,0.63
						c-0.5,0-0.63,0.3-0.39,0.34c0.24,0.04,0.22,0.69,1.15,0.93c0.93,0.24,1.93,1.1,2.73,1.97c0.11,0.12,0.24,0.22,0.38,0.3
						c0.23-0.14,0.43-0.24,0.55-0.26c0.45-0.06,1.3,0.19,1.75,0.52c0.45,0.32,2.08-0.06,2.27,0.19c0.19,0.26,0.58,0.58,0.91,0.58
						c0.32,0,0.65,0.65,0.91,0.45c0.26-0.19,1.23-0.19,1.49-0.19c0.26,0,0.26,0.19,0.52,0.19c0.26,0,0.26,0.97,0.45,0.91
						c0.19-0.07,0.91,0.58,1.36,0.65c0.45,0.06,0.91,0.84,1.1,0.78c0.19-0.07,0.52,0.32,0.65,0c0.12-0.31,0.76-0.16,0.94-0.69
						c-0.82-1.01-1.7-2.12-1.68-2.55c0.04-0.74,0.35-1.21-0.56-1.99c-0.91-0.78,0.09-1.3,0.74-2.12c0.58-0.73,1.81-1.06,2.6-1.58
						c-0.16-0.11-0.32-0.21-0.48-0.28c-0.58-0.26-0.78-0.52-0.32-0.65c0.45-0.13,0.26-0.39-0.19-0.91
						c-0.45-0.52-0.65-1.04-0.91-1.04c-0.26,0-0.65-0.06-1.04,0.07c-0.39,0.13-0.32-0.32-0.26-0.78c0.06-0.45-0.71-0.39-0.84-0.58
						c-0.13-0.19,0.19-0.84,0.32-0.91c0.13-0.07,0.52-0.26,0.52-0.45c0-0.19-0.71-0.26-0.45-0.71c0.26-0.45,0.78-0.45,0.78-0.91
						s0.39-0.84,0.84-0.52c0.45,0.32,0.65,1.17,1.17,0.97c0.52-0.19,0.19-0.65,0.13-0.91c-0.06-0.26-0.19-0.65,0.39-0.78
						c0.58-0.13,0.32-0.71,0.78-0.71c0.45,0,1.3-0.58,1.56-0.71c0.26-0.13,0.84-0.45,1.1-0.32c0.26,0.13,0.58,0.45,0.78,0
						c0.19-0.45,0.97-0.26,1.04,0c0.07,0.26,1.3,0.26,1.69,0.52c0.39,0.26,1.23,0.78,1.23,1.04c0,0.26,0.39,0.65,0.39,0.19
						c0-0.45,0.32-0.32,0.84,0c0.52,0.32,1.23,0.19,1.36-0.13c0.13-0.32,1.1-0.71,1.43-0.45c0.32,0.26,0.45,0.45,0.84,0.07
						c0.39-0.39,1.3-0.32,1.43,0.06c0.13,0.39,0.58,0.45,0.97,0.45c0.39,0,0.45,0.58,0.71,0.45c0.26-0.13,0.2-0.78,0.52-0.71
						c0.32,0.07,0.58,0.45,1.1,0.45c0.52,0,0.91-0.13,0.91-0.52c0-0.39-0.06-1.04-0.39-1.04c-0.32,0-0.65-0.32-1.04-0.32
						s-0.78-0.65-0.39-0.78s1.04-0.45,0.91-0.78c-0.13-0.32-0.26-0.97,0.26-1.04c0.52-0.06,1.62-0.06,1.56-0.26
						c-0.06-0.19-1.1-0.32-1.3-0.65c-0.19-0.32-0.26-1.1-0.07-1.23c0.2-0.13,0.84,0.06,1.23-0.06c0.39-0.13,1.23,0.19,1.75-0.2
						c0.52-0.39,1.62-0.45,2.21-0.45c0.58,0,1.04-0.39,1.49-0.45c0.45-0.06,1.75-0.26,2.08-0.39c0.32-0.13,2.08-0.39,2.14-0.71
						c0.07-0.32,1.46-0.58,1.82-0.42c0.36,0.16,0.81,0.29,1.14,0.16c0.32-0.13,1.01,0.23,0.94,0.58c-0.06,0.36,0.29,0.75,0.26,1.04
						c-0.03,0.29-0.36,0.39-0.16,0.62c0.19,0.23,1.07,0.06,1.33-0.23c0.26-0.29,0.55,0.13,0.55,0.36c0,0.23,0.42,0.39,0.42,0.1
						c0-0.29,0.52-0.1,0.68,0.1c0.16,0.19,0.78-0.23,0.78,0.1c0,0.32-0.91,0.58-0.52,0.94c0.39,0.36,0.62-0.42,1.1-0.26
						c0.49,0.16,1.14-0.55,1.59-0.65c0.45-0.1,0.94-0.52,1.33-0.75c0.39-0.23,1.23-0.16,0.97,0.1c-0.26,0.26-0.55,0.75,0.42,1.1
						c0.97,0.36,2.08,2.24,2.69,3.18c0.62,0.94,1.2,2.63,1.53,2.63c0.32,0,0.42-0.84,0.84-0.94c0.42-0.1,0.68,0.71,1.14,0.81
						c0.45,0.1,1.07,0.36,1.43,0.23c0.36-0.13,1.17-0.58,1.46-0.42c0.29,0.16,0.68,0.16,0.94,0.78c0.26,0.62,0.65,0.75,0.91,0.75
						c0.26,0,0.31,0.29,0.31,0.55c0,0.26,0.48,0.61,0.73,0.61s0.95-0.09,1.17-0.22c0.22-0.13,0.48-0.26,0.52,0.04
						c0.04,0.3,0.43,0.78,0.69,0.78c0.26,0,0.78,0.22,0.82,0c0.04-0.22,0.74-0.35,1-0.39c0.26-0.04,1.17-0.22,1.34-0.61
						c0.17-0.39,0.91-0.39,1.13-0.65c0.22-0.26,0.82-0.39,0.95-0.65c0.13-0.26,0.91-0.22,1-0.43c0.09-0.22,0.73-0.26,1.04-0.17
						c0.3,0.09,1.82,0.39,2.03,0.35c0.22-0.04,0.26,0.78,0.48,0.82c0.22,0.04,1,0.65,1.21,0.48c0.22-0.17,0.74-0.35,1.17-0.09
						c0.43,0.26,0.87,0.61,1.04,0.3c0.17-0.3,1.08-0.43,1.21-0.61c0.13-0.17,0.26-0.69,0.04-0.87c-0.22-0.17-0.43-0.87-0.52-1.21
						c-0.09-0.35,0.52-0.43,0.61-0.74c0.09-0.3,0.61-0.35,0.78-0.52c0.17-0.17,0.22-0.56,0.52-0.48c0.3,0.09,0.95,0.3,1.08,0.43
						c0.13,0.13,0.61,0.22,0.91,0.22c0.3,0,0.78,0.26,1.08,0.43c0.3,0.17,1.26,0.17,1.43,0.43c0.17,0.26-0.04,1,0.17,1.26
						c0.22,0.26,0.73,0.82,0.91,0.69c0.17-0.13,1,0.43,1.3,0.39c0.3-0.04,0.78-0.52,1.04-0.52c0.26,0,1.08-0.22,1.34-0.17
						c0.26,0.04,1.21,0.39,1.34,0.3c0.13-0.09,0.99,0.04,1.12,0.3c0.13,0.26,1.21,0.26,1.21,0.56c0,0.3,0.61,0.48,0.78,0.82
						c0.17,0.35,1.6,0.13,1.86,0.35c0.26,0.22,1.9,0.17,1.95,0c0.04-0.17,1.6-0.26,1.9-0.48c0.3-0.22,1.12-0.13,1.17-0.43
						c0.04-0.3,0.82-0.52,1.17-0.78c0.35-0.26,1.73-0.22,1.82,0.09c0.09,0.3,0.91,0.39,1.25,0.26c0.35-0.13,1.26,0.04,1.39,0.35
						c0.13,0.3,1.17,0.48,1.43,0.39c0.26-0.09,1.08-0.69,1.38-0.74c0.3-0.04,0.73-0.35,0.65-0.65c-0.09-0.3,0.61-1.17,0.69-1.51
						c0.09-0.35,0.69-1.3,0.91-1.39c0.22-0.09,0.69-0.48,0.65-0.74c-0.04-0.26-0.17-1.04-0.43-1.04c-0.26,0-0.73,0.04-0.13-0.78
						c0.6-0.82,1.69-0.6,1.86-0.6c0.17,0,1.56-0.35,2.16-0.17c0.61,0.17,0.99-0.09,1.64,0.26c0.65,0.35,1.56,0.22,1.77,0.52
						c0.22,0.3,1.08,0.56,0.95,1.08c-0.13,0.52,0.48,0.22,0.69,1.34c0.22,1.13,0.73,1.21,0.87,1.73c0.13,0.52,0.82,1.47,0.74,1.77
						c-0.09,0.3-0.17,0.78,0.56,0.82c0.74,0.04,1.26,0.61,1.43,0.52c0.17-0.09,1.04,0.22,1.47,0.65c0.43,0.43,1.08,0.22,1.04,0.69
						c-0.04,0.48,0.35,0.86,0.3,1.25c-0.04,0.39,0.82,0.65,1.43,0.56c0.61-0.09,1.04,0.22,1.3-0.3c0.26-0.52,1.13-0.3,1.43-0.52
						c0.3-0.22,1.21-0.48,1.17,0.3c-0.04,0.78,0.48,0.95,0.04,1.21c-0.43,0.26-0.65,1.39-0.78,1.86c-0.13,0.48-0.78,0.61-0.87,1.12
						c-0.09,0.52-0.69,0.56-0.69,0.95c0,0.39-0.86,0.3-1.3,0c-0.43-0.3-0.86,0.56-1.21,0.56c-0.35,0-0.3,0.73-0.22,1.04
						c0.09,0.3-0.13,0.69,0.09,1.21c0.12,0.28,0.04,0.81,0.1,1.4c0.53-0.38,1.01-0.66,1.22-0.66c0.54,0,1.08,0.93,1.69,0.95
						c0.61,0.02,3.35-1.82,3.37-2.12c0.02-0.3,1.62-1.84,2.38-2.64c0.76-0.8,1.75-1.97,2.14-2.88c0.24-0.56,1.3-1.64,1.56-2.08
						c0.26-0.43,0.43-0.48,0.91-1.49c0.47-1.02,0.47-3.68,0.63-3.85c0.15-0.17,0.06-0.63,0.28-0.91c0.22-0.28,0.11-0.67,0.54-1.04
						c0.43-0.37,0.35-0.69,0.24-0.97c-0.11-0.28,0.02-0.91-0.09-1.1c-0.11-0.2-0.09-0.28,0.17-0.43c0.26-0.15-0.17-0.54-0.54-0.71
						c-0.37-0.17-0.84-0.32-0.89-0.74c-0.04-0.41-0.67-0.91-1.15-0.87c-0.48,0.04-1.36-0.26-1.25,0.04c0.11,0.3-0.04,0.56-0.28,0.5
						c-0.24-0.06-0.35,0.13-0.63,0.56c-0.28,0.43-1.28,0.48-0.82,0.06c0.45-0.41-0.15-0.43-0.07-0.86c0.09-0.43,0.45-0.87-0.04-0.58
						c-0.5,0.28-0.5,0.95-0.91,1.04c-0.41,0.09-0.28-1.17-0.17-1.49c0.11-0.32-0.76,0.02-1.67-0.04c-0.91-0.06-0.67-0.69,0.06-1.08
						c0.74-0.39,0.61-0.76,0.97-0.91c0.37-0.15,1.32-0.78,1.88-1.1c0.56-0.32,0.67-0.69,0.8-1.06c0.13-0.37,1.17-0.82,2.1-1.58
						c0.93-0.76,1.38-1.19,1.6-1.65c0.22-0.45,1.6-1.02,1.62-1.36c0.02-0.35,1.64-1.21,2.75-1.43c1.1-0.22,2.86,0.07,3.18,0.39
						c0.32,0.32,0.56,0.19,0.76-0.02c0.19-0.22,0.63-0.11,1.36-0.02c0.74,0.09,0.91-0.35,1.41-0.19c0.5,0.15,0.91,0.24,1.21-0.35
						c0.3-0.58,1.73-0.48,2.06-0.24c0.32,0.24,0.59,0.56,1.1,0.22c0.52-0.35,0.52,0.45,1.02,0.52c0.5,0.06,0.17,0.5-0.26,0.45
						c-0.43-0.04-0.97,0.22-0.48,0.5c0.5,0.28,1.08-0.26,1.47-0.22c0.39,0.04,0.99,0.09,1.53-0.26c0.54-0.35,0.69,0.28,1.04-0.02
						c0.35-0.3,1.13-0.39,1.51-0.37c0.39,0.02,0.07-0.45-0.58-0.45c-0.65,0-0.63-0.35-0.11-1.23c0.52-0.89,1.51-1.36,2.14-1.9
						s1.08-0.28,1.08-0.63c0-0.35,0.3-1.32,0.78-1.34c0.47-0.02,1.66,0.19,2.4-0.22c0.74-0.41,0.67,0.3,0.97,0.41
						c0.3,0.11,0.71-0.63,1.06-0.41c0.35,0.22-0.52,0.74-0.71,1.36c-0.19,0.63,0.45,0.39,0.82,0.56c0.37,0.17-0.48,0.43-0.39,0.6
						c0.09,0.17,0.82,0.17,1.77-0.78c0.95-0.95,1.82-1.21,2.42-1.15c0.61,0.07,0.26-0.54,0.3-1.43c0.04-0.89,1.9-1.15,2.51-0.89
						c0.6,0.26,0.56,0.5-0.02,0.35c-0.58-0.15-1.12,0.37-1.1,1.1c0.02,0.74-0.52,0.97-0.24,1.26c0.28,0.28-0.41,0.35-0.43,0.65
						c-0.02,0.3,0,0.54-0.43,0.67c-0.43,0.13-1.88,0.28-1.92,0.84c-0.04,0.56-0.82,0.63-1.19,1.32c-0.37,0.69-1.77,1.1-2.7,2.49
						c-0.93,1.38-2.4,1.38-2.4,1.64c0,0.26-1.02,0.24-1.36,0.28c-0.35,0.04,0.32,0.89-0.5,1.77c-0.82,0.89-1.36,2.12-1.34,3.35
						c0.02,1.23,0.43,4.78,0.8,5.41c0.37,0.63,0.15,2.16,0.45,2.49c0.3,0.32,0.19,0.86,0.39,1.06c0.19,0.19,1-0.87,1.54-1.32
						c0.54-0.45,0.37-0.6,0.74-0.84c0.37-0.24,0.22-1.23,0.32-1.49c0.11-0.26,0.78-0.39,0.99-0.67c0.22-0.28,0.78-0.11,1.08-0.22
						c0.3-0.11-0.02-0.82-0.13-1.23c-0.11-0.41,0.89-0.97,1.41-1.34c0.52-0.37,1.17,0.24,1.75-0.3c0.58-0.54-0.04-1.17-0.26-1.45
						c-0.22-0.28,0.58-1.65,1.04-1.77c0.45-0.13,0.69,0.32,1.13-0.06c0.43-0.39-0.39-1.02-0.69-0.97c-0.3,0.04-0.41-1.1,0.3-1.64
						c0.71-0.54,0.43-0.65-0.04-0.78c-0.48-0.13-0.56,0.04-0.93,0.06c-0.37,0.02-0.74-0.76-0.17-1.51c0.56-0.76,1.1-0.67,1.23-1.3
						c0.13-0.63,0.91-1.52,1.1-1.84c0.19-0.32,0.97,0,1.28-0.06c0.3-0.07,0.2,0.58,0.56,0.26c0.37-0.32,0.97-1.41,1.47-1.41
						c0.5,0,0.17,0.8,0.3,1.28c0.13,0.48,0.41-0.13,1.36-0.78c0.95-0.65,3.03-0.58,3.66-0.28c0.63,0.3,0.74,1.02,1.08,0.93
						c0.53-0.13,0.09-0.71,0.61-0.87c0.52-0.15,1.43-0.69,2.12-1.19c0.69-0.5,0.5-0.13,0.86-0.58c0.37-0.45,0.74-0.26,0.84-0.56
						c0.11-0.3,1.1-0.8,2.47-1.1c1.36-0.3,3.14-1.21,3.05-1.49c-0.09-0.28,0.52-0.41,0.58-0.13c0.06,0.28,0.58,0.17,1.3,0.32
						c0.71,0.15,1.04,0.43,1.6-0.13c0.56-0.56-0.07-0.76-0.04-1.19c0.02-0.43-0.97-0.89-0.84-1.34c0.13-0.45-0.74-1.67-0.99-1.43
						c-0.26,0.24-0.87-0.19-0.87-0.54c0-0.35-0.04-0.76-0.45-0.5c-0.41,0.26-1.1,0.13-1.23-0.26c-0.13-0.39,0.8-0.67,1.45-0.41
						c0.65,0.26,0.45,0.52,0.78,0.71c0.32,0.19,1.21,0.11,1.67-0.11c0.45-0.22,1.73-0.63,1.95-1c0.22-0.37-0.02-0.54,0.41-0.76
						c0.43-0.22,0.11-0.67-0.26-0.86c-0.37-0.19-0.28-0.71,0.11-0.71c0.39,0,0.28-0.45,0.54-0.48c0.26-0.02,0.3,0.19,0.78-0.04
						c0.48-0.24,0.02,0.37-0.19,0.78c-0.22,0.41,0.45,0.76,0.52,1c0.06,0.24,0.99,0.32,1.51,0.04c0.52-0.28,2.19,0.35,2.29,0.76
						c0.11,0.41,0.35,0.91,1.04,1.26c0.69,0.35,1.32,0.19,1.45,0.52c0.13,0.32,0.39,0.5,0.78,0.41c0.39-0.09,0.61,0.35,0.89,0.09
						c0.28-0.26,0.43,0.15,0.8-0.17c0.37-0.32-0.8-0.54-0.65-0.8c0.15-0.26,0.39,0.19,0.71,0.17c0.32-0.02-0.09-0.65,0.15-0.71
						c0.24-0.06,0.04-1.36-0.22-1.45c-0.26-0.09-0.04-0.54,0.45-0.17c0.5,0.37,1.28,0.35,1.64,0.35c0.37,0,0.02-0.35-0.35-0.41
						c-0.37-0.06,0.06-0.37,0.32-0.13c0.26,0.24,0.76,0.35,0.8,0.09c0.04-0.26,0.3-0.82,0.76-0.8
						C525.62,53.26,525.77,53,525.34,52.85z M404.69,81.23c-1.23,1.1-3.7,1.36-3.7,2.27c0,0.91-2.99,1.36-3.25,0.97
						c-0.23-0.34,2.21-0.45,2.79-1.75c0.58-1.3,2.8-1.97,3.89-3.7c0.78-1.23,1.43-3.11,1.88-2.99
						C406.76,76.17,405.92,80.13,404.69,81.23z M410.18,30.41c-0.17,0.35-1.11,0.37-0.87,0.69c0.43,0.56,2.6,0.26,2.64-0.43
						C412,29.98,410.35,30.07,410.18,30.41z M331.39,9.04c1.04,0,0.73-0.65,1.43-0.56c0.69,0.09,1.6,0.04,1.25-0.39
						c-0.34-0.43-2.86-0.26-2.6,0.09c0.26,0.35-2.03,0.22-1.99,0.35C329.57,8.78,330.35,9.04,331.39,9.04z M326.02,11.94
						c0-0.69-2.25,0.07-1.82,0.22C324.59,12.28,326.02,12.63,326.02,11.94z M331.6,11.37c0.13,0.35-0.22,0.39-1.08,0.39
						c-0.87,0-1.32,0.49-0.87,0.86c0.52,0.43,2.81,0.26,3.2-0.26c0.39-0.52,1.38-0.09,1.56-0.61
						C334.59,11.24,331.47,11.03,331.6,11.37z M339,10.55c0.3-0.39-0.56-0.39-0.65-0.78c-0.09-0.39-2.51-0.48-2.38-0.04
						c0.13,0.43-1.68,0.9-1.12,1.34C335.89,11.89,338.7,10.94,339,10.55z M332.12,9.86c0.13-0.91-1.38-0.17-2.51-0.65
						c-1.12-0.48-1.79-0.44-1.04,0.22c0.35,0.3-1.93,0.54-1.56,0.91C327.84,11.16,331.99,10.77,332.12,9.86z M325.68,33.87
						c0.04,0.43-0.22,0.74-1.04,0.74c-0.82,0,0.39,0.52,0.48,0.99c0.09,0.48-0.82,0.13-0.86,0.87c-0.04,0.74-1.6,0-1.82,0.95
						c-0.22,0.95,0.78,0.82,1.47,0.87c0.69,0.04-0.26,0.69,0.26,1.08c0.52,0.39,0.91,0.26,0.56-0.48c-0.35-0.74,1.82,0.26,1.17,0.82
						c-0.65,0.56,1.04,0.99,1.86,1.04c0.82,0.04,3.74,0.68,3.81,0c0.04-0.43-1.08-0.82-1.99-1.77c-0.91-0.95-1.56-2.51-0.52-3.16
						c1.04-0.65,0.13-0.95,1.17-1.69c1.04-0.74,0.43-1.43,1.3-1.52c0.87-0.09-0.09-0.86,0.78-0.99c0.87-0.13,1.04-1.08,0.91-1.43
						c-0.13-0.35,0.91,0.04,1.38-0.48c0.48-0.52,1.6-0.17,1.95-0.91c0.35-0.74,5.32-2.42,8.14-3.07c2.81-0.65,4.76-1.82,3.76-2.6
						c-1-0.78-3.81,0.3-4.5,0.87c-0.69,0.56-1.69,0.17-2.38,0.56c-0.69,0.39-2.16,0.74-2.9,0.26c-0.74-0.48-1.6,0.56-2.16,0.52
						c-0.56-0.04-1.21,0.56-1.82,0.52c-0.61-0.04-1.82,0.48-1.9,0.82c-0.09,0.35-1.13,0.26-1.13,0.69c0,0.43-0.82,0.82-1.26,0.43
						c-0.43-0.39-0.95,0.43-0.35,1c0.61,0.56-1.12,0.61-0.82,0.95c0.3,0.35-0.65,0.56-0.43,0.95c0.22,0.39-0.61,0.52-1.26,0.65
						c-0.65,0.13-1,0.99-0.17,1.04c0.82,0.04-0.48,0.17-0.39,0.82C327.06,33.87,325.63,33.44,325.68,33.87z"/>
				</g>
				<g>
					<g className="mouseOver">
						<path class="lightBlue russiaCount" d="M279.55,76.98c-0.19,0.1-0.71-0.1-0.88-0.32c-0.13-0.18-0.53-0.11-0.89-0.08
							c0.08,0.05,0.17,0.11,0.23,0.22c0.19,0.35-0.78,0.39-0.48,0.8c0.3,0.41-0.69,0.43-0.69,0c0-0.43-1.04-0.22-1,0.06
							c0.02,0.1-0.02,0.23-0.09,0.35c0.28,0.1,0.61,0.22,0.74,0.27c0.2,0.08,2.79,0.35,3.8,0.16c-0.05-0.19,0.05-0.76,0.05-0.97
							C280.36,77.21,279.75,76.88,279.55,76.98z M342.16,10.34c1.64-0.17,1.6-1.08,0.73-1.25c-0.86-0.17-0.99,0.52-1.43,0.48
							c-0.43-0.04-2.07,0.27-1.73,0.65C340.13,10.64,341.34,10.42,342.16,10.34z M350.51,34.39c0.56,0.09,2.08-0.22,2.25-0.52
							c0.17-0.3-0.61-0.65-1.6-0.65C350.17,33.23,350.03,34.32,350.51,34.39z M382.49,12.28c0.82,0.35,0.52,0.56-0.35,0.56
							c-0.86,0-1.61,0.29-1.04,0.52c0.43,0.17,0.39,0.65,1.3,0.86c0.91,0.22,1.82-0.52,1.73-1.04c-0.09-0.52,3.16-0.99,4.02-1.21
							c0.86-0.22-0.56-1.12,0.39-1.21c0.95-0.09,0.56-0.52-0.61-0.69c-1.17-0.17-0.69-1.17-1.08-1.51
							c-0.39-0.35-0.61,0.43-2.12,0.74c-1.51,0.3-2.25,0.69-1.64,0.99c0.61,0.3,0,1.04-0.65,1
							C381.8,11.24,381.67,11.94,382.49,12.28z M384.78,13.49c0,0.48-1.39,0.95-1,1.3c0.35,0.3,0.65,0.22,1.08,0.3
							c0.43,0.09,0.65,1.17,1.43,1c0.78-0.17,2.9,0.86,4.15,0.86c1.26,0,1.38-1.04,0.99-1.08c-0.39-0.04-0.04-0.65,0.65-1.64
							c0.69-0.99-2.16-1.73-2.16-0.95s-0.82-0.17-1.08-0.65C388.59,12.15,384.78,13.02,384.78,13.49z M363.15,35.04
							c0.69,0.35,1.21,0.22,1.12-0.48C364.19,33.87,362.6,34.77,363.15,35.04z M381.36,9.21c0.17-0.65-2.7-0.37-1.95,0.26
							C379.68,9.69,381.19,9.86,381.36,9.21z M508.7,40.58c0.86,0.43,1.38-0.52,2.08-0.04c0.69,0.48,2.38-0.22,3.07-0.35
							c0.69-0.13,0.35-1-1.6-1.25C510.3,38.68,507.77,40.11,508.7,40.58z M391.49,19.6c0.61,0.35,2.55-0.43,3.85-0.39
							c1.3,0.04,4.93-1,5.06-1.69c0.13-0.69-1.34-0.65-1.9-1.26c-0.56-0.61-1.82-0.09-2.12,0.43c-0.3,0.52-0.65,0.04,0.17-0.78
							c0.82-0.82-0.82-1.47-0.86-0.95c-0.04,0.52-1.56,0.09-1.56,0.56c0,0.48-0.56,0.69-0.52,1.17c0.04,0.48-1.04-0.26-1.08,0.82
							C392.48,18.6,390.85,19.23,391.49,19.6z M447.31,28.51c0.61,0.3,0.82,0.61,1.17,0.95c0.35,0.35,1.64-0.17,2.16-0.35
							c0.52-0.17,0.56,0.78,1.38,0.26c0.82-0.52,1.64-0.26,2.6-0.26c0.95,0-0.17-1.17-0.13-1.69c0.04-0.52,1.12-0.17,0.78,0.26
							c-0.35,0.43,0.52,1.43,1.9,1.34c1.38-0.09,0.35-0.95,1.04-1.25c0.69-0.3,0.6-0.65-0.39-1.26c-0.99-0.61-2.29-0.22-3.16-0.61
							c-0.92-0.42-2.03-0.43-2.08,0.52c-0.04,0.95-1.86-1.04-2.81-1.34C448.82,24.79,445.87,27.79,447.31,28.51z M456.35,34.05
							c0.78-0.17-0.56-1.6-1.9-1.82c-1.34-0.22-2.59,0.89-2.42,1.13C452.41,33.92,455.57,34.22,456.35,34.05z M452.89,31.19
							c0-0.65-2.36,0.24-1.38,0.56C452.02,31.93,452.89,31.84,452.89,31.19z M460.42,28.46c1.04,0.09,1.56,1.08,3.55,1.21
							c1.99,0.13,3.46-0.26,3.5-0.69c0.04-0.43-1.82-1.08-2.38-0.74c-0.56,0.35-1-0.56-1.69-0.35c-0.69,0.22-1.64,0.17-1.99-0.43
							C461.06,26.86,459.9,28.42,460.42,28.46z M315.85,10.9c1-0.91,2.29-0.04,2.47-0.48c0.17-0.43-1.86-0.56-2.6-0.17
							c-0.74,0.39-2.46,0.08-2.25,0.56C313.61,11.11,314.86,11.81,315.85,10.9z M456,84.02c-0.48-1.25,0.69-2.47-0.17-3.12
							c-0.86-0.65-0.52-1.99-0.95-1.73c-0.43,0.26,0,1.43-0.69,1.51c-0.69,0.09-0.04,0.74-0.3,1.6c-0.26,0.87,0.13,2.12,0.48,2.9
							c0.35,0.78-0.48,4.93-0.13,5.63c0.35,0.69-0.56,3.75-0.26,4.11c0.52,0.61,0.17-0.82,0.95-0.95c0.78-0.13,0.78,0.86,1.26,1.08
							c0.48,0.22,0.17-1.38-0.26-1.3c-0.43,0.09-0.78-1.04-1-1.73c-0.22-0.69,0.43-1.51,0.43-2.38c0-0.86,1.08-0.82,1.73-0.17
							c0.65,0.65,0.73,0.22,0.48-0.26C457.3,88.74,456.48,85.28,456,84.02z M319.92,10.51c0.61,0.43-1.69,0.3-1.99,0.74
							c-0.3,0.43-1.64,0.28-1.38,0.78c0.26,0.52,2.42,0.56,2.42,0.13c0-0.43,1.13-0.04,1.17-0.43c0.04-0.39,0.52-0.82,1.73-0.91
							c1.21-0.09,1.21-0.52,0.09-1.04C320.83,9.25,319.32,10.08,319.92,10.51z M318.06,45.47c1.17,1.08,2.16-0.48,2.73-0.56
							c0.56-0.09-0.35-0.69-1.3-0.95C318.54,43.7,317.18,44.65,318.06,45.47z M525.34,52.85c-0.43-0.15-1.82-1.04-2.16-1.54
							c-0.35-0.5-2.01-0.74-2.01-0.48c0,0.26-0.54-0.06-0.67-0.35c-0.13-0.28-1.88-0.32-1.9-0.02c-0.02,0.3,0.41,0.32,0.67,0.67
							c0.26,0.35-0.35,0.54-0.22,0.93c0.13,0.39-0.65-0.19-0.93-0.41c-0.28-0.22-0.15-1.06-0.06-1.49c0.09-0.43-0.5-0.35-0.56-0.78
							c-0.07-0.43-2.01-1.32-2.83-1.67c-0.82-0.35-1.88-0.65-2.16-1.08c-0.28-0.43-1.75-0.48-2.31-1.06
							c-0.56-0.58-3.03-1.62-4.09-1.69c-1.06-0.07-0.82-0.65-1.34-0.56c-0.52,0.09-2.75-0.02-3.44-0.13
							c-0.69-0.11-0.84,0.41-1.34,0.22c-0.5-0.19-3.07-1.13-3.38-0.78c-0.3,0.35-0.17,0.84-0.48,0.93c-0.3,0.09,0,0.52,0.71,1.17
							c0.71,0.65,0.28,1.15-0.37,1.43c-0.65,0.28-1.69-0.26-2.01-0.76c-0.32-0.5-1.21-0.28-1.43-0.93
							c-0.22-0.65,0.26-0.76,0.69-0.39c0.43,0.37,1.02,0.06,1.08-0.41c0.07-0.48-1.17-0.8-1.84-0.76c-0.67,0.04-1.04,0.97-1.73,1.36
							c-0.69,0.39-3.37-0.15-3.66-0.41c-0.28-0.26-4.02,0-4.41,0.26c-0.39,0.26-0.11,1.28-0.3,1.36c-0.19,0.09-0.39-1.17-0.39-1.41
							c0-0.24-0.45-0.41-1.13-0.41c-0.67,0-1.06-0.02-0.78-0.3c0.28-0.28-0.26-0.56,0.26-0.93c0.52-0.37-0.95-1.52-2.38-2.08
							c-1.43-0.56-4.67-0.24-5.54,0.02c-0.86,0.26-2.6,0.17-3.42,0.19c-0.82,0.02-0.15-0.32-0.39-0.71
							c-0.24-0.39-1.64-0.86-1.84-0.54c-0.19,0.32-0.56,0-0.56-0.24c0-0.24-1.32-0.71-1.84-0.61c-0.52,0.11-0.76-0.48-0.15-0.48
							c0.61,0,1.64,0.13,1.19-0.56c-0.45-0.69-4.35-0.91-4.72-0.78c-0.37,0.13-0.02,0.63-0.87,1.43c-0.84,0.8-1.67,0.26-1.56-0.13
							c0.11-0.39,1.15-0.32,1.15-0.71c0-0.39-1.43-0.17-1.64-0.5c-0.22-0.32,0.3-0.54,0.82-0.39c0.52,0.15,1.43,0.24,1.47,0
							c0.04-0.24-0.54-0.28-1.41-0.43c-0.87-0.15-2.96-0.65-4.07-0.54c-1.1,0.11-1.6-0.41-2.21-0.45c-0.61-0.04-0.61,0.32-0.32,0.67
							c0.28,0.35-0.58,0.56-1.3,0.45c-0.71-0.11-1.75,0.39-1.34,0.91c0.41,0.52,0.82-0.15,1.17,0.02c0.35,0.17-0.91,0.54-0.39,0.78
							c0.52,0.24,0.43,0.73,0.45,0.99c0.02,0.26-0.87,0.39-1.19,0c-0.32-0.39-0.78-0.04-1.39-0.09c-0.61-0.04-1.06,0.24-0.41,0.32
							c0.65,0.09,0.86,0.5,0,0.58c-0.87,0.09-0.65-0.8-1.17-0.69c-0.52,0.11-1.64-0.41-2.25-0.39c-0.61,0.02-0.89,0.5-1.69,0.56
							c-0.8,0.07-1.88-0.41-2.21-0.84c-0.32-0.43-0.43-0.56-0.82-0.07c-0.39,0.5-0.41,1.45-0.8,1.51c-0.39,0.07-0.54,0.91-0.93,1.13
							c-0.39,0.22-0.41-0.32-0.91-0.28c-0.5,0.04-2.1-1.51-2.19-1.97c-0.09-0.45-1.08-1.32-1.34-1.49
							c-0.26-0.17,0.26-0.26,0.58,0.02c0.32,0.28,0.73,0.28,1.06,0.11c0.32-0.17,0.28-0.97-0.28-1c-0.56-0.02-0.35-0.5-0.07-0.58
							c0.28-0.09-0.65-0.93-0.3-1.02c0.35-0.09,0.19-0.45-0.28-0.52c-0.48-0.07-0.97-0.45-1-0.69c-0.02-0.24-2.03-0.32-1.95,0.02
							c0.09,0.35-0.65,0.24-0.54-0.04c0.11-0.28-0.71-0.19-1.67-0.45c-0.95-0.26-0.97-0.93-1.3-0.97c-0.32-0.04-0.22,0.93-0.69,0.71
							c-0.48-0.22-1,0.2-0.74,0.76c0.26,0.56,0.02,0.65-0.02,1.1c-0.04,0.45-0.3,0.26-0.87,0.06c-0.56-0.19-0.56,0.48-1.77,0.17
							c-1.21-0.3-1.75,0.02-1.97-0.37c-0.22-0.39-0.58-0.43-0.63-0.11c-0.04,0.32-1.9,0.19-2.05-0.28
							c-0.15-0.48,0.32-0.56,0.61-0.69c0.28-0.13-0.3-0.32-1.32-0.26c-1.02,0.06-1.67-0.52-2.7-0.41c-1.04,0.11-3.35,0.32-3.68,0.48
							c-0.32,0.15,0.3,0.74-0.19,0.78c-0.5,0.04-0.15-0.95-0.24-1.43c-0.09-0.48-0.84-0.54-0.74-0.17c0.11,0.37-1.19,0.5-1.51,0.22
							c-0.32-0.28-0.84-0.65-1.69-0.86c-0.84-0.22-1.88,0.69-1.64,0.95c0.24,0.26,0.86,0.06,0.89,0.24
							c0.04,0.36-1.79,0.22-1.79,0.52c0,0.3-1.71,0.71-2.23,0.78c-1.42,0.18-1.73,0.17-2.4,0.74c-0.67,0.56-0.15-0.5,0.37-1.02
							c0.52-0.52,1.06-0.3,1.36-0.71c0.3-0.41,0.78-0.37,1.6-0.5c0.82-0.13,0.78-0.61,1.17-0.78c0.39-0.17,1.34-0.54,1.51-0.93
							c0.17-0.39,2.12-1.04,2.51-1.02c0.39,0.02,0.48-0.78,0.69-0.78c0.22,0,1.34-0.5,1.77-0.69c0.43-0.19,0.65-0.61,0.67-0.89
							c0.02-0.28-0.58-0.24-0.65-0.5c-0.06-0.26,0.28-0.09,0.74-0.26c0.45-0.17,0-0.78-0.37-0.67c-0.37,0.11,0.19-0.32-0.13-0.71
							c-0.32-0.39-0.97,0.24-0.95-0.17c0.02-0.41-0.04-0.93-0.3-1.19c-0.26-0.26-0.74,0.45-1.28,0.04c-0.54-0.41-2.12-0.67-2.12-0.5
							c0,0.17-0.76-0.26-0.91-0.09c-0.15,0.17-1.41,0.15-1.8,0.04c-0.39-0.11-0.93,0.15-0.95,0.54c-0.02,0.39-1.56,0.24-1.84,0.17
							c-0.28-0.06,1.06-1.17,1.06-1.36c0-0.19-2.27,0.04-2.4-0.17c-0.13-0.22-1.23-0.28-1.73-0.26c-0.5,0.02-0.02-0.13,0.43-0.17
							c0.45-0.04,0.87-0.43,1.36-0.52c0.5-0.09,0.39-0.26,0.17-0.56c-0.22-0.3-0.74-0.15-1.23-0.3c-0.5-0.15-1.1-0.37-1.54-0.39
							c-0.43-0.02-0.91,0.22-1.93,0.41c-1.02,0.19-1.1,0.97-1.62,1.21c-0.52,0.24-1.43,1.19-1,1.28c0.43,0.09,0.22,0.35,0.28,0.63
							c0.07,0.28-0.32,0.35-0.82,0.26c-0.5-0.09-2.25-0.06-2.23,0.3c0.02,0.37,1.02,0.54,0.95,0.73c-0.06,0.19-0.76-0.02-1.1-0.22
							c-0.35-0.19-1.13-0.09-1.28,0.28c-0.15,0.37-0.76,0.28-0.95,0.19c-0.19-0.09-0.63,0.17-0.95,0.07
							c-0.32-0.11,0.65-0.58,0.41-0.82c-0.24-0.24-1.28-0.02-1.38,0.37c-0.11,0.39-0.74-0.28-0.97-0.15
							c-0.24,0.13-0.5,0.28-0.95,0.48c-0.45,0.19-1.23-0.22-1.41,0.13c-0.17,0.35,0.87,0.45,0.89,0.61
							c0.02,0.15-2.36,0.13-2.42,0.37c-0.06,0.24-0.87,0.24-1.84,0.26c-0.97,0.02-0.58,0.5-1.36,0.65
							c-0.78,0.15-1.12,0.17-1.38,0.56c-0.26,0.39-0.87,0.45-1.13,0.15c-0.26-0.3-0.97,0.15-0.58,0.19
							c0.39,0.04,0.02,0.43-0.22,0.35c-0.24-0.09-0.93,0.41-0.54,0.37c0.39-0.04,0.58,0.28,0.39,0.52
							c-0.19,0.24-1.04-0.28-1.26-0.07c-0.22,0.22,0.41,0.5,0.8,0.45c0.39-0.04,0.61,0.22,0.65,0.5c0.04,0.28-0.91-0.17-1.1,0.07
							c-0.19,0.24,0.15,0.35,0.56,0.39c0.41,0.04,0.11,0.19,0.48,0.52c0.03,0.03,0.05,0.05,0.07,0.07c0.22,0.27-0.2,0.36-0.16,0.62
							c0.04,0.28-0.41,0.39-0.41,0.13c0-0.26-1.15-0.35-1.38-0.06c-0.24,0.28-0.41,0.5-0.69,0.24c-0.28-0.26-1.1,0.06-2.16,0.11
							c-1.06,0.04-3.24,0.13-3.79,0.32c-0.54,0.19-1,0.95-0.5,1.45c0.5,0.5,0.02,0.71,0.02,1.04c0,0.32,1.3,1,1.99,1.06
							c0.69,0.06,1.25,0.91,0.84,1.43c-0.41,0.52-1.67-0.15-2.49-0.8c-0.82-0.65-2.73-0.91-3.35-0.86
							c-0.63,0.04-0.26-0.56-1.06-0.54c-0.8,0.02-1.47,0.74-0.93,0.74c0.54,0,0.89-0.04,0.61,0.22c-0.28,0.26,0.15,0.19,0.8,0.48
							c0.65,0.28-0.54,0.67-1.21,0.22c-0.67-0.45-1.38-0.02-1.58,0.32c-0.19,0.35,0.78,1.34,1.95,1.51
							c1.17,0.17,0.78,0.54,1.32,0.74c0.54,0.19,0.15,0.41-0.22,0.43c-0.37,0.02-1.1-0.56-1.69-0.87c-0.58-0.3-2.16,0.13-2.49-0.22
							c-0.32-0.35,0.22-0.69-0.09-0.95c-0.3-0.26-0.04-0.84,0.35-1.45c0.39-0.61-0.06-1.64-0.63-1.82
							c-0.56-0.17-0.52,0.41-0.39,0.58c0.13,0.17,0.06,1-0.37,1.54c-0.43,0.54-1.75,0.54-1.75,0.91c0,0.37-0.89,0.65-0.58,0.91
							c0.3,0.26,1.49,1.6,1.67,2.01c0.17,0.41-1.06,1.71-0.87,2.47c0.19,0.76-0.17,1.1,0.06,1.47c0.24,0.37,0.78,0.04,1.13,0.19
							c0.35,0.15,0.91-0.22,1.71-0.37c0.8-0.15,2.27,0.69,2.81,0.95c0.54,0.26,0.26,0.69,0.54,1.12c0.28,0.43-0.65,0.43-0.67,1.08
							c-0.02,0.65,1.62,0.93,1.69,1.12c0.07,0.19-1.17,0.04-1.64-0.13c-0.48-0.17-0.52-0.8-0.67-0.93
							c-0.15-0.13,0.32-0.65,0.37-1.06c0.04-0.41-0.54-0.65-0.73-0.93c-0.2-0.28-0.54-0.69-0.93-0.61
							c-0.39,0.09-1.82,0.22-2.25,0.61c-0.43,0.39,0.06,1.49,0.3,2.03c0.24,0.54-1.26,1.3-1.21,1.73c0.04,0.43-0.45,0.63-1.1,0.97
							c-0.65,0.35-1.36,0.6-1.34,1.17c0.02,0.56-0.86,0.26-1.32,0c-0.45-0.26-1.04,0.15-1.69,0.13c-0.65-0.02-0.65-0.56-1.17-0.37
							c-0.52,0.19-0.86-0.24-0.71-0.63c0.15-0.39,0.73-0.09,0.78,0.19c0.04,0.28,0.41,0.15,0.84-0.09c0.43-0.24,0.22,0.3,0.84,0.35
							c0.63,0.04,0.28-0.32,0.76-0.28c0.48,0.04,0.5-0.11,0.35-0.35c-0.15-0.24,0.35-0.41,0.65-0.54c0.34-0.15,0.17-0.63,0.45-0.78
							c0.28-0.15,0.09-0.45,0.39-0.48c0.3-0.02,0.43-0.48,0.71-0.48c0.28,0,0.43-0.45,0.26-0.69c-0.17-0.24,0.11-0.89,0.39-0.97
							c0.28-0.09,0.3-0.35,0.04-0.54c-0.26-0.19-1.21-0.84-1.15-1.25c0.06-0.41-0.17-1.17-0.04-1.49c0.13-0.32,0.02-1-0.02-1.39
							c-0.04-0.39,0.24-0.82,0.37-1.54c0.13-0.71-0.76-1.47-1.19-1.67c-0.43-0.19-0.15-0.58,0.48-1.1c0.63-0.52,0.67-2.14,0.63-2.49
							c-0.04-0.35-1.32-0.65-1.82-0.61c-0.5,0.04-2.34-0.04-2.79-0.13c-0.45-0.09-0.61,0.39-0.82,0.82
							c-0.22,0.43-0.69,1.1-0.97,2.08c-0.28,0.97-1.43,1.28-1.95,1.49c-0.52,0.22-0.84,0.93-0.61,1.28
							c0.24,0.35,0.58,0.07,0.82,0.19c0.24,0.13-0.02,1.04-0.17,1.17c-0.15,0.13,0.28,0.45-0.09,0.69c-0.37,0.24-0.71,0.74-0.5,1
							c0.22,0.26,1.28,0.39,1.73,0.65c0.45,0.26,0.28,0.74,0.63,1.19c0.35,0.45,0.71,0.17,0.89,0.45c0.17,0.28-0.63,1.41-0.91,1.45
							c-0.28,0.04-1.43-1.15-1.77-1.41c-0.35-0.26-1.69-0.67-2.36-1.02c-0.67-0.35-1.21-0.3-1.95-0.8c-0.73-0.5-1.36-0.74-2.7-0.71
							c-1.34,0.02-2.21-0.3-2.66-0.24c-0.45,0.06-0.28-0.45-1.08-0.8c-0.8-0.35-1.32-0.82-1.77-0.58c-0.45,0.24-0.28,1.1,0.41,1.21
							c0.69,0.11,0.35,0.48,1.08,0.43c0.73-0.04,0.95,0.22,0.93,0.67c-0.02,0.45,0.35,0.91,0.67,1.21c0.32,0.3,0.13,0.8-0.45,0.8
							c-0.58,0-1.25,0.09-0.99,0.5c0.26,0.41-0.17,0.52-0.76,0.3c-0.58-0.22-0.24-0.63,0-0.87c0.24-0.24-0.52-0.54-0.89-0.67
							c-0.37-0.13-1.84,0.95-2.25,1.12c-0.41,0.17-1.43-0.26-2.47,0c-1.04,0.26-0.97,1.08-1.36,1c-0.39-0.09-1.54,0.11-1.97-0.15
							c-0.43-0.26-0.09-0.41,0.37-0.39c0.45,0.02,0.61-0.13,0.35-0.43c-0.26-0.3,0.35-0.78,0.32-1c-0.02-0.22-1.71,0.35-1.99,0.58
							c-0.28,0.24,0.02,0.67-0.28,0.82c-0.3,0.15-0.3-0.26-0.65-0.39c-0.35-0.13-2.88,0.45-3.35,1c-0.48,0.54-1.41,0.56-1.41,0.87
							c0,0.3-1.15,0.28-1.6,0.5c-0.45,0.22-0.15,1.1-0.32,1.54c-0.17,0.43-1.95,0.3-2.49,0.32c-0.54,0.02-0.74-1.04-1.17-1.1
							c-0.43-0.07-0.17-0.67-0.04-0.93c0.13-0.26,0.48-0.04,1.02-0.39c0.54-0.35,1.08,0.17,1.3-0.07c0.22-0.24-0.82-0.87-0.89-1.41
							c-0.07-0.54-1.21-0.69-1.86-0.52c-0.65,0.17-1.36,0.02-1.82-0.19c-0.45-0.22-0.41,0.39,0.26,0.56
							c0.67,0.17,0.32,0.69,0.43,1.02c0.11,0.32-0.17,1.06-0.56,1.62c-0.39,0.56-0.11,0.54,0.48,0.52c0.58-0.02,0.54,0.74,0.54,1.3
							c0,0.56-0.45,0.71-0.45,1.13c0,0.41-0.48-0.19-0.63-0.02c-0.15,0.17-0.35-0.09-0.43-0.43c-0.09-0.35-0.97-0.15-1.47-0.28
							c-0.5-0.13-0.69,0.09-0.93,0.45c-0.24,0.37-0.93,0.52-1.3,0.56c-0.41,0.05-1.02,0.76-1.41,1.02
							c-0.39,0.26-0.45,0.76,0.09,1.28c0.54,0.52,0.65,0.87,0.61,1.15c-0.04,0.28-1.51,0.2-1.75-0.11c-0.24-0.3-1.19-0.41-1.51-0.35
							c-0.32,0.07-1.21-0.97-1.67-0.99c-0.45-0.02-0.74,0.54-0.89,0.82c-0.15,0.28,0.22,0.37,0.43,0.84c0.22,0.48,1.02,0.5,1.43,0.5
							c0.41,0,0.26,0.63,0.13,0.89c-0.13,0.26-0.89,0.45-1.1,0.17c-0.22-0.28-1.13,0-1.15-0.35c-0.02-0.35-0.67-0.86-1.34-0.76
							c-0.67,0.11-0.8-0.32-0.76-0.84c0.04-0.52-0.37-0.82-0.35-1.06c0.02-0.24-0.3-0.71,0.04-0.95c0.35-0.24,0.09-0.6,0.11-0.93
							c0.02-0.32-0.58-0.82-1.13-0.82c-0.54,0-0.43-0.54-0.89-0.65c-0.45-0.11-1.36-0.87-1.43-1.19c-0.07-0.32-0.76-0.37-0.52-0.48
							c0.24-0.11,0.78,0.04,1.17,0.43c0.39,0.39,1.02,0.74,2.03,0.87c1.02,0.13,1.43,0.6,2.55,0.8c1.13,0.2,1.8,0.32,3.42,0.56
							c1.62,0.24,3.79-1.41,4.24-1.86c0.45-0.45-0.13-1.38-0.13-1.73c0-0.35-0.74-0.39-0.84-0.65c-0.11-0.26-0.58-0.73-1.21-0.73
							s-0.71-0.71-1.21-0.67c-0.5,0.04-0.84-0.22-2.14-1.08c-1.3-0.86-3.09-1.49-3.42-1.34c-0.32,0.15-0.54,0.24-0.76-0.09
							c-0.22-0.32-0.71-0.28-1.06-0.02c-0.35,0.26-0.69-0.24-1.34-0.09c-0.65,0.15-0.99-0.28-0.82-0.48
							c0.17-0.19,1.13-0.02,1.08-0.35c-0.04-0.32-0.39,0-0.97-0.39c-0.58-0.39-0.91-0.13-1.04,0.19c-0.13,0.32-0.58,0.24-0.89-0.04
							c-0.03-0.03-0.06-0.05-0.1-0.07c-0.1,0.54-0.42,0.6-0.74,0.52c-0.39-0.09-0.78,0.56-1.21,0.56c-0.43,0-0.91,0.48-1,0.87
							c-0.09,0.39-0.56,0.17-0.56,0.43c0,0.26,0,0.52-0.22,0.78c-0.22,0.26,0,0.39,0.22,0.82c0.22,0.43,0.69,0.39,0.91,0.52
							c0.22,0.13,1.04,0.82,1.04,1.08c0,0.26-0.65,0.91-0.91,1.08c-0.26,0.17-0.61,0.6-0.43,0.86c0.17,0.26,0.91,1.34,1.38,2.21
							c0.48,0.87-0.17,0.48-0.48,0.86c-0.3,0.39,0.13,0.87,0.22,1.13c0.09,0.26-0.39,0.22-0.35,0.43c0.04,0.22,0.69,0.3,0.74,0.48
							c0.04,0.17-0.43,0.39-0.22,0.74c0.22,0.35,0.74,0.35,0.78,0.78c0.04,0.43-0.95,0.48-0.91,0.82c0.04,0.35,1.3,0.78,1.86,1.43
							c0.56,0.65,0.52,0.82,0.39,1.17c-0.26,0.7-1.82,1.3-2.16,2.08c-0.35,0.78-1.64,1.21-2.25,1.82c-0.25,0.25-0.49,0.58-0.7,0.89
							c0.26-0.07,0.46-0.14,0.61-0.22c0.35-0.17,0.58,0.67,0.93,0.95c0.35,0.28,1.04,0.02,1.28,0.28c0.24,0.26-0.19,0.28-0.69,0.19
							c-0.5-0.09-0.63,0.22-1.41,0.48c-0.58,0.19-0.52,0.4-0.72,0.56c0.35,0.51-0.12,0.57-0.3,0.96c-0.19,0.42-0.45,0.49-0.49,0.75
							c-0.03,0.26,0.06,0.45,0.1,0.84c0.03,0.39,0.1,0.45,0.32,0.78c0.23,0.32-0.19,0.32-0.39,0.49c-0.19,0.16-0.06,0.52,0.19,0.78
							c0.26,0.26,0.26,0.58,0.16,0.81c-0.1,0.23,0.23,0.62,0.45,0.84c0.23,0.23,0.19,0.84,0.39,1.01c0.19,0.16,0.42,0.32,0.78,0.23
							c0.36-0.1,0.58,0.07,0.75,0.36c0.16,0.29,0.42,0.16,0.78,0c0.36-0.16,1.17,0.29,1.36,0.39c0.19,0.1,0.1,0.65,0.13,1.01
							c0.03,0.36-0.19,0.81,0.16,1.04c0.36,0.23,0.26,0.75,0.68,0.91c0.42,0.16,0.58,0.75,0.84,0.81c0.26,0.06,0.75,0.45,0.81,0.75
							c0.06,0.29-0.45,0.55-0.75,0.68c-0.29,0.13-0.84-0.39-1.04-0.16c-0.19,0.23,0.07,0.65,0.23,1.36
							c0.16,0.71,0.29,0.78,0.62,0.78c0.32,0,0.45-0.23,0.68-0.36c0.23-0.13,0.75,0.06,0.97-0.03c0.23-0.1,1.04-0.13,1.2,0.13
							c0.16,0.26,0.32,0.88,0.19,1.1c-0.13,0.23,0.16,0.49,0.32,0.81c0.16,0.32,0.84,0.16,1.07,0.26c0.23,0.1,0.75,0.81,0.65,1.14
							c-0.1,0.32,0.32,0.39,0.55,0.29c0.23-0.1,0.45,0.16,0.71,0.32c0.26,0.16,0.49,0.1,0.75-0.07c0.26-0.16,0.68-0.29,0.81-0.06
							c0.13,0.23,0.65,0.65,0.65,0.78c0,0.13,0.81,0,1.2,0.19c0.39,0.19,0.81,0.1,1.23,0.32c0.42,0.23,0.81,0.26,0.84,0.52
							c0.03,0.26-0.42,0.49-0.42,0.62c0,0.13,0.36,0.23,0.36,0.42c0,0.19-0.45,0.19-0.52,0.36c-0.07,0.16,0.26,0.39,0.32,0.55
							c0.07,0.16-0.26,0.71-0.26,0.88c0,0.16-0.97-0.03-1.2-0.03c-0.23,0-0.91,0.75-1.14,0.78c-0.19,0.03-0.1,0.39-0.03,0.69
							c0.7-0.13,1.42-0.19,1.49-0.03c0.13,0.26-1.51,0.95-1.82,1.06c-0.3,0.11,0.58,0.56,0.56,0.82c-0.02,0.26-0.95,0.32-0.87,0.84
							c0.09,0.52-0.3,0.63-0.8,0.63c-0.5,0-0.63,0.3-0.39,0.34c0.24,0.04,0.22,0.69,1.15,0.93c0.93,0.24,1.93,1.1,2.73,1.97
							c0.11,0.12,0.24,0.22,0.38,0.3c0.23-0.14,0.43-0.24,0.55-0.26c0.45-0.06,1.3,0.19,1.75,0.52c0.45,0.32,2.08-0.06,2.27,0.19
							c0.19,0.26,0.58,0.58,0.91,0.58c0.32,0,0.65,0.65,0.91,0.45c0.26-0.19,1.23-0.19,1.49-0.19c0.26,0,0.26,0.19,0.52,0.19
							c0.26,0,0.26,0.97,0.45,0.91c0.19-0.07,0.91,0.58,1.36,0.65c0.45,0.06,0.91,0.84,1.1,0.78c0.19-0.07,0.52,0.32,0.65,0
							c0.12-0.31,0.76-0.16,0.94-0.69c-0.82-1.01-1.7-2.12-1.68-2.55c0.04-0.74,0.35-1.21-0.56-1.99c-0.91-0.78,0.09-1.3,0.74-2.12
							c0.58-0.73,1.81-1.06,2.6-1.58c-0.16-0.11-0.32-0.21-0.48-0.28c-0.58-0.26-0.78-0.52-0.32-0.65c0.45-0.13,0.26-0.39-0.19-0.91
							c-0.45-0.52-0.65-1.04-0.91-1.04c-0.26,0-0.65-0.06-1.04,0.07c-0.39,0.13-0.32-0.32-0.26-0.78c0.06-0.45-0.71-0.39-0.84-0.58
							c-0.13-0.19,0.19-0.84,0.32-0.91c0.13-0.07,0.52-0.26,0.52-0.45c0-0.19-0.71-0.26-0.45-0.71c0.26-0.45,0.78-0.45,0.78-0.91
							s0.39-0.84,0.84-0.52c0.45,0.32,0.65,1.17,1.17,0.97c0.52-0.19,0.19-0.65,0.13-0.91c-0.06-0.26-0.19-0.65,0.39-0.78
							c0.58-0.13,0.32-0.71,0.78-0.71c0.45,0,1.3-0.58,1.56-0.71c0.26-0.13,0.84-0.45,1.1-0.32c0.26,0.13,0.58,0.45,0.78,0
							c0.19-0.45,0.97-0.26,1.04,0c0.07,0.26,1.3,0.26,1.69,0.52c0.39,0.26,1.23,0.78,1.23,1.04c0,0.26,0.39,0.65,0.39,0.19
							c0-0.45,0.32-0.32,0.84,0c0.52,0.32,1.23,0.19,1.36-0.13c0.13-0.32,1.1-0.71,1.43-0.45c0.32,0.26,0.45,0.45,0.84,0.07
							c0.39-0.39,1.3-0.32,1.43,0.06c0.13,0.39,0.58,0.45,0.97,0.45c0.39,0,0.45,0.58,0.71,0.45c0.26-0.13,0.2-0.78,0.52-0.71
							c0.32,0.07,0.58,0.45,1.1,0.45c0.52,0,0.91-0.13,0.91-0.52c0-0.39-0.06-1.04-0.39-1.04c-0.32,0-0.65-0.32-1.04-0.32
							s-0.78-0.65-0.39-0.78s1.04-0.45,0.91-0.78c-0.13-0.32-0.26-0.97,0.26-1.04c0.52-0.06,1.62-0.06,1.56-0.26
							c-0.06-0.19-1.1-0.32-1.3-0.65c-0.19-0.32-0.26-1.1-0.07-1.23c0.2-0.13,0.84,0.06,1.23-0.06c0.39-0.13,1.23,0.19,1.75-0.2
							c0.52-0.39,1.62-0.45,2.21-0.45c0.58,0,1.04-0.39,1.49-0.45c0.45-0.06,1.75-0.26,2.08-0.39c0.32-0.13,2.08-0.39,2.14-0.71
							c0.07-0.32,1.46-0.58,1.82-0.42c0.36,0.16,0.81,0.29,1.14,0.16c0.32-0.13,1.01,0.23,0.94,0.58c-0.06,0.36,0.29,0.75,0.26,1.04
							c-0.03,0.29-0.36,0.39-0.16,0.62c0.19,0.23,1.07,0.06,1.33-0.23c0.26-0.29,0.55,0.13,0.55,0.36c0,0.23,0.42,0.39,0.42,0.1
							c0-0.29,0.52-0.1,0.68,0.1c0.16,0.19,0.78-0.23,0.78,0.1c0,0.32-0.91,0.58-0.52,0.94c0.39,0.36,0.62-0.42,1.1-0.26
							c0.49,0.16,1.14-0.55,1.59-0.65c0.45-0.1,0.94-0.52,1.33-0.75c0.39-0.23,1.23-0.16,0.97,0.1c-0.26,0.26-0.55,0.75,0.42,1.1
							c0.97,0.36,2.08,2.24,2.69,3.18c0.62,0.94,1.2,2.63,1.53,2.63c0.32,0,0.42-0.84,0.84-0.94c0.42-0.1,0.68,0.71,1.14,0.81
							c0.45,0.1,1.07,0.36,1.43,0.23c0.36-0.13,1.17-0.58,1.46-0.42c0.29,0.16,0.68,0.16,0.94,0.78c0.26,0.62,0.65,0.75,0.91,0.75
							c0.26,0,0.31,0.29,0.31,0.55c0,0.26,0.48,0.61,0.73,0.61s0.95-0.09,1.17-0.22c0.22-0.13,0.48-0.26,0.52,0.04
							c0.04,0.3,0.43,0.78,0.69,0.78c0.26,0,0.78,0.22,0.82,0c0.04-0.22,0.74-0.35,1-0.39c0.26-0.04,1.17-0.22,1.34-0.61
							c0.17-0.39,0.91-0.39,1.13-0.65c0.22-0.26,0.82-0.39,0.95-0.65c0.13-0.26,0.91-0.22,1-0.43c0.09-0.22,0.73-0.26,1.04-0.17
							c0.3,0.09,1.82,0.39,2.03,0.35c0.22-0.04,0.26,0.78,0.48,0.82c0.22,0.04,1,0.65,1.21,0.48c0.22-0.17,0.74-0.35,1.17-0.09
							c0.43,0.26,0.87,0.61,1.04,0.3c0.17-0.3,1.08-0.43,1.21-0.61c0.13-0.17,0.26-0.69,0.04-0.87c-0.22-0.17-0.43-0.87-0.52-1.21
							c-0.09-0.35,0.52-0.43,0.61-0.74c0.09-0.3,0.61-0.35,0.78-0.52c0.17-0.17,0.22-0.56,0.52-0.48c0.3,0.09,0.95,0.3,1.08,0.43
							c0.13,0.13,0.61,0.22,0.91,0.22c0.3,0,0.78,0.26,1.08,0.43c0.3,0.17,1.26,0.17,1.43,0.43c0.17,0.26-0.04,1,0.17,1.26
							c0.22,0.26,0.73,0.82,0.91,0.69c0.17-0.13,1,0.43,1.3,0.39c0.3-0.04,0.78-0.52,1.04-0.52c0.26,0,1.08-0.22,1.34-0.17
							c0.26,0.04,1.21,0.39,1.34,0.3c0.13-0.09,0.99,0.04,1.12,0.3c0.13,0.26,1.21,0.26,1.21,0.56c0,0.3,0.61,0.48,0.78,0.82
							c0.17,0.35,1.6,0.13,1.86,0.35c0.26,0.22,1.9,0.17,1.95,0c0.04-0.17,1.6-0.26,1.9-0.48c0.3-0.22,1.12-0.13,1.17-0.43
							c0.04-0.3,0.82-0.52,1.17-0.78c0.35-0.26,1.73-0.22,1.82,0.09c0.09,0.3,0.91,0.39,1.25,0.26c0.35-0.13,1.26,0.04,1.39,0.35
							c0.13,0.3,1.17,0.48,1.43,0.39c0.26-0.09,1.08-0.69,1.38-0.74c0.3-0.04,0.73-0.35,0.65-0.65c-0.09-0.3,0.61-1.17,0.69-1.51
							c0.09-0.35,0.69-1.3,0.91-1.39c0.22-0.09,0.69-0.48,0.65-0.74c-0.04-0.26-0.17-1.04-0.43-1.04c-0.26,0-0.73,0.04-0.13-0.78
							c0.6-0.82,1.69-0.6,1.86-0.6c0.17,0,1.56-0.35,2.16-0.17c0.61,0.17,0.99-0.09,1.64,0.26c0.65,0.35,1.56,0.22,1.77,0.52
							c0.22,0.3,1.08,0.56,0.95,1.08c-0.13,0.52,0.48,0.22,0.69,1.34c0.22,1.13,0.73,1.21,0.87,1.73c0.13,0.52,0.82,1.47,0.74,1.77
							c-0.09,0.3-0.17,0.78,0.56,0.82c0.74,0.04,1.26,0.61,1.43,0.52c0.17-0.09,1.04,0.22,1.47,0.65c0.43,0.43,1.08,0.22,1.04,0.69
							c-0.04,0.48,0.35,0.86,0.3,1.25c-0.04,0.39,0.82,0.65,1.43,0.56c0.61-0.09,1.04,0.22,1.3-0.3c0.26-0.52,1.13-0.3,1.43-0.52
							c0.3-0.22,1.21-0.48,1.17,0.3c-0.04,0.78,0.48,0.95,0.04,1.21c-0.43,0.26-0.65,1.39-0.78,1.86c-0.13,0.48-0.78,0.61-0.87,1.12
							c-0.09,0.52-0.69,0.56-0.69,0.95c0,0.39-0.86,0.3-1.3,0c-0.43-0.3-0.86,0.56-1.21,0.56c-0.35,0-0.3,0.73-0.22,1.04
							c0.09,0.3-0.13,0.69,0.09,1.21c0.12,0.28,0.04,0.81,0.1,1.4c0.53-0.38,1.01-0.66,1.22-0.66c0.54,0,1.08,0.93,1.69,0.95
							c0.61,0.02,3.35-1.82,3.37-2.12c0.02-0.3,1.62-1.84,2.38-2.64c0.76-0.8,1.75-1.97,2.14-2.88c0.24-0.56,1.3-1.64,1.56-2.08
							c0.26-0.43,0.43-0.48,0.91-1.49c0.47-1.02,0.47-3.68,0.63-3.85c0.15-0.17,0.06-0.63,0.28-0.91c0.22-0.28,0.11-0.67,0.54-1.04
							c0.43-0.37,0.35-0.69,0.24-0.97c-0.11-0.28,0.02-0.91-0.09-1.1c-0.11-0.2-0.09-0.28,0.17-0.43c0.26-0.15-0.17-0.54-0.54-0.71
							c-0.37-0.17-0.84-0.32-0.89-0.74c-0.04-0.41-0.67-0.91-1.15-0.87c-0.48,0.04-1.36-0.26-1.25,0.04c0.11,0.3-0.04,0.56-0.28,0.5
							c-0.24-0.06-0.35,0.13-0.63,0.56c-0.28,0.43-1.28,0.48-0.82,0.06c0.45-0.41-0.15-0.43-0.07-0.86
							c0.09-0.43,0.45-0.87-0.04-0.58c-0.5,0.28-0.5,0.95-0.91,1.04c-0.41,0.09-0.28-1.17-0.17-1.49c0.11-0.32-0.76,0.02-1.67-0.04
							c-0.91-0.06-0.67-0.69,0.06-1.08c0.74-0.39,0.61-0.76,0.97-0.91c0.37-0.15,1.32-0.78,1.88-1.1c0.56-0.32,0.67-0.69,0.8-1.06
							c0.13-0.37,1.17-0.82,2.1-1.58c0.93-0.76,1.38-1.19,1.6-1.65c0.22-0.45,1.6-1.02,1.62-1.36c0.02-0.35,1.64-1.21,2.75-1.43
							c1.1-0.22,2.86,0.07,3.18,0.39c0.32,0.32,0.56,0.19,0.76-0.02c0.19-0.22,0.63-0.11,1.36-0.02c0.74,0.09,0.91-0.35,1.41-0.19
							c0.5,0.15,0.91,0.24,1.21-0.35c0.3-0.58,1.73-0.48,2.06-0.24c0.32,0.24,0.59,0.56,1.1,0.22c0.52-0.35,0.52,0.45,1.02,0.52
							c0.5,0.06,0.17,0.5-0.26,0.45c-0.43-0.04-0.97,0.22-0.48,0.5c0.5,0.28,1.08-0.26,1.47-0.22c0.39,0.04,0.99,0.09,1.53-0.26
							c0.54-0.35,0.69,0.28,1.04-0.02c0.35-0.3,1.13-0.39,1.51-0.37c0.39,0.02,0.07-0.45-0.58-0.45c-0.65,0-0.63-0.35-0.11-1.23
							c0.52-0.89,1.51-1.36,2.14-1.9s1.08-0.28,1.08-0.63c0-0.35,0.3-1.32,0.78-1.34c0.47-0.02,1.66,0.19,2.4-0.22
							c0.74-0.41,0.67,0.3,0.97,0.41c0.3,0.11,0.71-0.63,1.06-0.41c0.35,0.22-0.52,0.74-0.71,1.36c-0.19,0.63,0.45,0.39,0.82,0.56
							c0.37,0.17-0.48,0.43-0.39,0.6c0.09,0.17,0.82,0.17,1.77-0.78c0.95-0.95,1.82-1.21,2.42-1.15c0.61,0.07,0.26-0.54,0.3-1.43
							c0.04-0.89,1.9-1.15,2.51-0.89c0.6,0.26,0.56,0.5-0.02,0.35c-0.58-0.15-1.12,0.37-1.1,1.1c0.02,0.74-0.52,0.97-0.24,1.26
							c0.28,0.28-0.41,0.35-0.43,0.65c-0.02,0.3,0,0.54-0.43,0.67c-0.43,0.13-1.88,0.28-1.92,0.84c-0.04,0.56-0.82,0.63-1.19,1.32
							c-0.37,0.69-1.77,1.1-2.7,2.49c-0.93,1.38-2.4,1.38-2.4,1.64c0,0.26-1.02,0.24-1.36,0.28c-0.35,0.04,0.32,0.89-0.5,1.77
							c-0.82,0.89-1.36,2.12-1.34,3.35c0.02,1.23,0.43,4.78,0.8,5.41c0.37,0.63,0.15,2.16,0.45,2.49c0.3,0.32,0.19,0.86,0.39,1.06
							c0.19,0.19,1-0.87,1.54-1.32c0.54-0.45,0.37-0.6,0.74-0.84c0.37-0.24,0.22-1.23,0.32-1.49c0.11-0.26,0.78-0.39,0.99-0.67
							c0.22-0.28,0.78-0.11,1.08-0.22c0.3-0.11-0.02-0.82-0.13-1.23c-0.11-0.41,0.89-0.97,1.41-1.34c0.52-0.37,1.17,0.24,1.75-0.3
							c0.58-0.54-0.04-1.17-0.26-1.45c-0.22-0.28,0.58-1.65,1.04-1.77c0.45-0.13,0.69,0.32,1.13-0.06c0.43-0.39-0.39-1.02-0.69-0.97
							c-0.3,0.04-0.41-1.1,0.3-1.64c0.71-0.54,0.43-0.65-0.04-0.78c-0.48-0.13-0.56,0.04-0.93,0.06c-0.37,0.02-0.74-0.76-0.17-1.51
							c0.56-0.76,1.1-0.67,1.23-1.3c0.13-0.63,0.91-1.52,1.1-1.84c0.19-0.32,0.97,0,1.28-0.06c0.3-0.07,0.2,0.58,0.56,0.26
							c0.37-0.32,0.97-1.41,1.47-1.41c0.5,0,0.17,0.8,0.3,1.28c0.13,0.48,0.41-0.13,1.36-0.78c0.95-0.65,3.03-0.58,3.66-0.28
							c0.63,0.3,0.74,1.02,1.08,0.93c0.53-0.13,0.09-0.71,0.61-0.87c0.52-0.15,1.43-0.69,2.12-1.19c0.69-0.5,0.5-0.13,0.86-0.58
							c0.37-0.45,0.74-0.26,0.84-0.56c0.11-0.3,1.1-0.8,2.47-1.1c1.36-0.3,3.14-1.21,3.05-1.49c-0.09-0.28,0.52-0.41,0.58-0.13
							c0.06,0.28,0.58,0.17,1.3,0.32c0.71,0.15,1.04,0.43,1.6-0.13c0.56-0.56-0.07-0.76-0.04-1.19c0.02-0.43-0.97-0.89-0.84-1.34
							c0.13-0.45-0.74-1.67-0.99-1.43c-0.26,0.24-0.87-0.19-0.87-0.54c0-0.35-0.04-0.76-0.45-0.5c-0.41,0.26-1.1,0.13-1.23-0.26
							c-0.13-0.39,0.8-0.67,1.45-0.41c0.65,0.26,0.45,0.52,0.78,0.71c0.32,0.19,1.21,0.11,1.67-0.11c0.45-0.22,1.73-0.63,1.95-1
							c0.22-0.37-0.02-0.54,0.41-0.76c0.43-0.22,0.11-0.67-0.26-0.86c-0.37-0.19-0.28-0.71,0.11-0.71c0.39,0,0.28-0.45,0.54-0.48
							c0.26-0.02,0.3,0.19,0.78-0.04c0.48-0.24,0.02,0.37-0.19,0.78c-0.22,0.41,0.45,0.76,0.52,1c0.06,0.24,0.99,0.32,1.51,0.04
							c0.52-0.28,2.19,0.35,2.29,0.76c0.11,0.41,0.35,0.91,1.04,1.26c0.69,0.35,1.32,0.19,1.45,0.52c0.13,0.32,0.39,0.5,0.78,0.41
							c0.39-0.09,0.61,0.35,0.89,0.09c0.28-0.26,0.43,0.15,0.8-0.17c0.37-0.32-0.8-0.54-0.65-0.8c0.15-0.26,0.39,0.19,0.71,0.17
							c0.32-0.02-0.09-0.65,0.15-0.71c0.24-0.06,0.04-1.36-0.22-1.45c-0.26-0.09-0.04-0.54,0.45-0.17c0.5,0.37,1.28,0.35,1.64,0.35
							c0.37,0,0.02-0.35-0.35-0.41c-0.37-0.06,0.06-0.37,0.32-0.13c0.26,0.24,0.76,0.35,0.8,0.09c0.04-0.26,0.3-0.82,0.76-0.8
							C525.62,53.26,525.77,53,525.34,52.85z M404.69,81.23c-1.23,1.1-3.7,1.36-3.7,2.27c0,0.91-2.99,1.36-3.25,0.97
							c-0.23-0.34,2.21-0.45,2.79-1.75c0.58-1.3,2.8-1.97,3.89-3.7c0.78-1.23,1.43-3.11,1.88-2.99
							C406.76,76.17,405.92,80.13,404.69,81.23z M410.18,30.41c-0.17,0.35-1.11,0.37-0.87,0.69c0.43,0.56,2.6,0.26,2.64-0.43
							C412,29.98,410.35,30.07,410.18,30.41z M331.39,9.04c1.04,0,0.73-0.65,1.43-0.56c0.69,0.09,1.6,0.04,1.25-0.39
							c-0.34-0.43-2.86-0.26-2.6,0.09c0.26,0.35-2.03,0.22-1.99,0.35C329.57,8.78,330.35,9.04,331.39,9.04z M326.02,11.94
							c0-0.69-2.25,0.07-1.82,0.22C324.59,12.28,326.02,12.63,326.02,11.94z M331.6,11.37c0.13,0.35-0.22,0.39-1.08,0.39
							c-0.87,0-1.32,0.49-0.87,0.86c0.52,0.43,2.81,0.26,3.2-0.26c0.39-0.52,1.38-0.09,1.56-0.61
							C334.59,11.24,331.47,11.03,331.6,11.37z M339,10.55c0.3-0.39-0.56-0.39-0.65-0.78c-0.09-0.39-2.51-0.48-2.38-0.04
							c0.13,0.43-1.68,0.9-1.12,1.34C335.89,11.89,338.7,10.94,339,10.55z M332.12,9.86c0.13-0.91-1.38-0.17-2.51-0.65
							c-1.12-0.48-1.79-0.44-1.04,0.22c0.35,0.3-1.93,0.54-1.56,0.91C327.84,11.16,331.99,10.77,332.12,9.86z M325.68,33.87
							c0.04,0.43-0.22,0.74-1.04,0.74c-0.82,0,0.39,0.52,0.48,0.99c0.09,0.48-0.82,0.13-0.86,0.87c-0.04,0.74-1.6,0-1.82,0.95
							c-0.22,0.95,0.78,0.82,1.47,0.87c0.69,0.04-0.26,0.69,0.26,1.08c0.52,0.39,0.91,0.26,0.56-0.48
							c-0.35-0.74,1.82,0.26,1.17,0.82c-0.65,0.56,1.04,0.99,1.86,1.04c0.82,0.04,3.74,0.68,3.81,0c0.04-0.43-1.08-0.82-1.99-1.77
							c-0.91-0.95-1.56-2.51-0.52-3.16c1.04-0.65,0.13-0.95,1.17-1.69c1.04-0.74,0.43-1.43,1.3-1.52c0.87-0.09-0.09-0.86,0.78-0.99
							c0.87-0.13,1.04-1.08,0.91-1.43c-0.13-0.35,0.91,0.04,1.38-0.48c0.48-0.52,1.6-0.17,1.95-0.91c0.35-0.74,5.32-2.42,8.14-3.07
							c2.81-0.65,4.76-1.82,3.76-2.6c-1-0.78-3.81,0.3-4.5,0.87c-0.69,0.56-1.69,0.17-2.38,0.56c-0.69,0.39-2.16,0.74-2.9,0.26
							c-0.74-0.48-1.6,0.56-2.16,0.52c-0.56-0.04-1.21,0.56-1.82,0.52c-0.61-0.04-1.82,0.48-1.9,0.82
							c-0.09,0.35-1.13,0.26-1.13,0.69c0,0.43-0.82,0.82-1.26,0.43c-0.43-0.39-0.95,0.43-0.35,1c0.61,0.56-1.12,0.61-0.82,0.95
							c0.3,0.35-0.65,0.56-0.43,0.95c0.22,0.39-0.61,0.52-1.26,0.65c-0.65,0.13-1,0.99-0.17,1.04c0.82,0.04-0.48,0.17-0.39,0.82
							C327.06,33.87,325.63,33.44,325.68,33.87z"/>
              <g className="hoverEf">
				<path d="M385.45,41.53c0.3-0.06,0.75-0.09,1.21-0.09c0.63,0,1.06,0.11,1.35,0.35c0.24,0.2,0.38,0.5,0.38,0.88
					c0,0.56-0.38,0.95-0.8,1.09v0.02c0.32,0.12,0.5,0.42,0.61,0.85c0.14,0.55,0.26,1.05,0.35,1.22h-0.82
					c-0.07-0.13-0.17-0.49-0.29-1.03c-0.13-0.57-0.33-0.76-0.79-0.77h-0.4v1.8h-0.79V41.53z M386.25,43.46h0.47
					c0.54,0,0.88-0.29,0.88-0.72c0-0.48-0.34-0.7-0.85-0.7c-0.25,0-0.42,0.02-0.5,0.04V43.46z"/>
				<path d="M391.95,44.9c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V44.9z"/>
				<path d="M392.78,45.11c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
					c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
					c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
					c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L392.78,45.11z"/>
				<path d="M395.49,45.11c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
					c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
					c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
					c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L395.49,45.11z"/>
				<path d="M399.09,41.8c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C398.91,41.37,399.09,41.55,399.09,41.8z M398.24,45.85v-3.16h0.8v3.16H398.24z"/>
				<path d="M401.69,45.85l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H401.69z M401.58,44.3c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V44.3z"/>
			</g>
					</g>
				</g>
			</g>
			<path class="st0" d="M289.74,178.16c-0.71-0.39-1.01-5.29-0.65-5.42c0.05-0.02,0.09,0.03,0.14,0.14l-0.07-1.11
				c0,0-0.04-0.01-0.1-0.03c-0.11-0.05-0.29-0.16-0.29-0.42c0-0.39,0.32-0.42,0.32-0.42s-0.06-0.81,0.29-0.94
				c0.07-0.02,0.13-0.08,0.18-0.14c0.2-0.25,0.24-0.72,0.24-1.06c0-0.42,0.1-0.91,0.26-1.26c0.16-0.36,0.1-0.84,0.36-1.04
				c0.26-0.19,0.23-0.71,0.55-0.81c0.32-0.1,0.58-0.23,0.62-0.42c0.03-0.19,0.42-0.45,0.42-0.75c0-0.29-0.49-0.36-0.49-0.71
				c0-0.36,0.13-1.27,0.13-1.27s0.01,0,0.02-0.01c-0.7-0.64-1.51-1.3-1.69-1.43c-0.27-0.18-0.72,0.18-0.72,0.18
				s-0.45-0.45-0.81-0.09c-0.36,0.36-0.9,0-0.9,0s-0.45-0.27-0.63-0.72c-0.07-0.18-0.21-0.29-0.38-0.38l0.02,0.06l-1.08,0.18
				c0,0-1.35-0.72-1.62-0.18c-0.27,0.54-0.54,0.18-1.17,0.18c-0.63,0-1.17,0.27-1.53,0.45c-0.36,0.18-0.9-0.18-1.17,0
				c-0.27,0.18-0.45,0.99-0.45,0.99l-2.8-0.54c0,0-0.54-0.81-1.17-0.81c-0.63,0-1.53,1.17-1.53,1.17v0.98
				c0.11,0.59-0.03,1.2-0.22,1.52c-0.27,0.45-0.54,0.63-0.54,1.54s-1.09,2.35-1.09,3.08c0,0.72-1.27,1.18-1.27,1.63
				c0,0.45-0.09,1.45-0.09,2.44c0,1-0.54,0.18-0.54,1.08c0,0.91-0.45,0.36-0.9,0.82c-0.45,0.45-0.63,0.54-0.63,0.09
				c0-0.45-0.63-0.54-1.18-0.09c-0.54,0.45-0.72,0-0.99-0.09c-0.15-0.05-0.77,0.32-1.37,0.73c0.27,0.56,0.41,1.12,0.52,1.61
				c0.01,0.05,0.02,0.1,0.04,0.15c0.32-0.23,0.64-0.42,0.83-0.45c0.45-0.06,4.74,0,4.74,0s0.39,0.65,0.26,1.23
				c-0.13,0.58,0.26,0.32,0.58,1.1c0.32,0.78,0.58,1.1,1.23,0.91c0.65-0.19,1.43-0.32,1.69-0.26c0.26,0.06,0.19-1.1,0.58-1.3
				c0.39-0.19,1.17,0,1.17,0s0.06,0.32,0.71,0.32s0.97-0.26,0.97,0.19c0,0.45,0.06,0.97,0.26,1.17c0.19,0.19-0.52,1.82-0.19,2.08
				c0.32,0.26,0.78,0.84,0.71,1.17c-0.06,0.32-0.26,1.1,0,1.17c0.26,0.07,0.32-0.39,0.78-0.39c0.45,0,0.84,0.06,1.3-0.13
				c0.15-0.06,0.28-0.04,0.4,0.01c0.23,0.11,0.38,0.37,0.38,0.37s0.13,0.45,0.39,0.32c0.26-0.13,0.97-0.32,0.91-0.07
				c-0.06,0.26,0.19,0.84,0.45,0.71c0.26-0.13,0.91,0.39,1.23,0.39c0.32,0,0.65,0,0.65-0.2c0-0.19,0.39-0.52,0.52-0.26
				c0.13,0.26,0.45,0.84,0.78,0.84c0.32,0,1.1,0,1.1,0.26c0,0.26,0.26,0.97,0.58,1.23c0.32,0.26,1.04,0,1.04,0s0.26-0.19,0.26-0.65
				c0-0.45,0-1.04-0.19-1.04c-0.19,0-0.52,0.45-0.97,0.32c-0.45-0.13-0.78-1.04-0.97-1.17c-0.19-0.13,0.32-0.97,0.32-1.36
				s0.32-1.49,0.06-1.62c-0.26-0.13-0.13-0.52,0.19-0.78c0.32-0.26-0.13-0.97,0.39-0.97c0.49,0,1.48,0.05,1.76-0.26
				c0.02-0.02,0.04-0.04,0.06-0.06c0.04-0.07,0.07-0.15,0.09-0.23C290.48,179.11,290.14,178.38,289.74,178.16z"/>
			<path class="st0" d="M289.23,172.88c0.11,0.26,0.23,0.85,0.36,1.52c0.29-0.19,0.53-0.33,0.63-0.37c0.37-0.15,1.2-1.72,1.2-1.72
				l-0.37-0.22c0,0,0.04-0.33-0.06-0.77c-0.34,0.04-0.69,0.02-0.69,0.02s0.07,0.6-0.75,0.45c-0.13-0.02-0.3-0.04-0.48-0.05
				c0.05,0.02,0.1,0.03,0.1,0.03L289.23,172.88z"/>
			<path class="st0" d="M306.01,189.81c-0.15-0.39-0.45-2.14-0.37-2.86c0.09-0.71-0.13-2.6-0.13-3.5c0-0.02,0-0.04,0-0.06
				c-0.72,0.32-1.49,0.7-1.55,0.83c-0.13,0.26-0.69-0.04-1.04,0.26c-0.35,0.3-0.56,0.04-0.86-0.04c-0.3-0.09-0.43,0.3-0.48,0.56
				c-0.04,0.26-0.65,0.13-0.87,0.09c-0.22-0.04-0.91,0.17-1.25,0.17c-0.35,0-0.09-0.3-0.48-0.43c-0.39-0.13-0.65,0.17-0.87,0.17
				c-0.13,0-0.53-0.05-0.83-0.09c0,0.2-0.03,0.38-0.08,0.51c-0.28,0.73,0.58,2.39,0.63,3.09c0.32,0.28,0.69,0.61,0.89,0.85
				c0.39,0.48,0,0.43,0.04,1.47c0.04,1.04-0.43,0.61-0.82,0.95c-0.39,0.35-0.04,1.13-0.13,1.34c-0.09,0.22-1.26-1.51-1.34-1.77
				c-0.09-0.26,0.43-1.3,0.3-1.9c-0.13-0.61-0.91-0.22-1.17-0.17c-0.26,0.04-0.87-0.69-1-0.69c-0.13,0-4.07,1.47-4.07,1.47l0.3,1.08
				l-0.09,0l0.22,0.47c0,0,1.3,0.06,1.88,0.45c0.58,0.39,1.49,0.45,1.69,0.58c0.19,0.13,0,1.56,0.06,2.27
				c0.07,0.71-0.78,0.91-0.39,1.3c0.39,0.39,0.19,1.04,0.45,1.1c0.26,0.07-0.45,0.91-0.78,1.1c-0.32,0.19,0,0.91-0.39,1.43
				c-0.25,0.33-0.98,0.98-1.48,1.48c0.02,0.01,0.05,0.03,0.06,0.05c0.13,0.23,0.52,2.5,0.91,2.73c0.39,0.23,0.16,2.47,0.13,2.66
				c-0.03,0.19,0.16,1.69,0.16,1.69s0.58-0.09,1.09-0.08c0.03-0.3,0.06-0.55,0.07-0.73c0.04-0.69-0.43-0.13-0.58-0.56
				c-0.15-0.43,1.06-1.23,2.29-1.69c1.23-0.45,1.71-0.71,1.84-1.15c0.13-0.43-0.02-1.64,0.15-2.12c0.17-0.48-0.11-0.76-0.26-1.84
				c-0.15-1.08-0.54-1.26-0.71-1.86c-0.17-0.61-0.13-1.08,0.24-1.1c0.37-0.02,0.37-0.19,0.67-0.78c0.3-0.58,0.65-0.35,1.23-0.78
				c0.58-0.43,0.6-0.97,1.53-1.69c0.93-0.71,1.95-0.69,2.86-1.23C304.61,192.32,306.16,190.2,306.01,189.81z"/>
			<path class="st0" d="M304.48,182.07c-0.04-0.89-0.56-0.89-0.54-1.49c0.02-0.6,0.07-1.15-0.15-1.21c-0.22-0.07-0.09-0.58,0.26-1.1
				c0.35-0.52-0.63-0.58-0.78-1.23c-0.06-0.28,0.25-1.21,0.64-2.22c-1.21-0.84-2.46-1.74-2.46-1.74l0.07-0.9l-5.53-2.93
				c-0.04,0.67-0.31,1.2-0.71,1.38c-0.42,0.19,0.58,0.81,0.1,0.91c-0.49,0.1-1.59-0.45-2.37-0.13c-0.52,0.22-0.42-1.09-0.01-2.24
				l-1.22,0.1c0,0-0.33,0.08-0.69,0.18l0.24,0.64c0,0,0.3,0.6,0.22,0.97c-0.03,0.17-0.3,0.25-0.58,0.28
				c0.1,0.44,0.06,0.77,0.06,0.77l0.37,0.22c0,0-0.82,1.57-1.2,1.72c-0.1,0.04-0.34,0.18-0.63,0.37c0.22,1.11,0.49,2.42,0.9,2.79
				c0.75,0.68,0.62,1.46,0.78,1.91c0.16,0.45,0.81,0.97,0.36,1.17c-0.29,0.13-0.55-0.19-0.81-0.61c-0.03,0.08-0.06,0.15-0.09,0.23
				c-0.01,0.02-0.04,0.04-0.06,0.06c0.4,0.29,0.86,0.6,1.06,0.6c0.37,0,0.97,0.45,1.35,0.52c0.37,0.07,1.71,0.61,1.87,0.82
				c0.03,0.04,0.06,0.09,0.09,0.14c0.32-0.02,0.74-0.03,0.99,0c0.05-0.05,0.12-0.08,0.21-0.08c0.49,0,1.08,1.87,1.05,2.97
				c0.3,0.04,0.7,0.09,0.83,0.09c0.22,0,0.48-0.3,0.87-0.17c0.39,0.13,0.13,0.43,0.48,0.43c0.35,0,1.04-0.22,1.25-0.17
				c0.22,0.04,0.82,0.17,0.87-0.09c0.04-0.26,0.17-0.65,0.48-0.56c0.3,0.09,0.52,0.35,0.86,0.04c0.35-0.3,0.91,0,1.04-0.26
				c0.07-0.14,0.84-0.51,1.55-0.83C305.47,182.57,304.52,182.94,304.48,182.07z"/>
			<path class="st0" d="M298.72,189.38c-0.2-0.24-0.57-0.58-0.89-0.85c0.01,0.11-0.01,0.19-0.04,0.25
				c-0.38,0.55-1.46-1.17-1.59-2.24c-0.13-1.07,0.62-1.59,0.16-2.6c-0.38-0.84-0.62-1.65-0.34-1.9c-0.25-0.03-0.67-0.02-0.99,0
				c0.24,0.39,0.58,1.17,0.58,1.43c0,0.3-0.37-0.08-0.52,0.3c-0.15,0.38-0.22,2.1,0.07,2.4c0.3,0.3-0.6,0.08-0.6,0.38
				c0,0.3-0.07,1.35-0.22,1.42c-0.08,0.04,0.09,0.3,0.26,0.64c0,0,0.01,0,0.01,0c0.13,0,0.74,0.74,1,0.69
				c0.26-0.04,1.04-0.43,1.17,0.17c0.13,0.6-0.39,1.64-0.3,1.9c0.09,0.26,1.26,1.99,1.34,1.77c0.09-0.22-0.26-1,0.13-1.34
				c0.39-0.34,0.87,0.09,0.82-0.95C298.72,189.81,299.11,189.86,298.72,189.38z"/>
			<path class="st0" d="M486.42,198.91c-0.09-0.54,4.76,2.6,4.24,2.94C490.14,202.2,486.51,199.43,486.42,198.91z"/>
			<path class="st0" d="M355.4,110.1c0.32,0.19,0.26,0.45,0.56,0.41c0.25-0.03,0.77,0.08,1.02,0.32c0.27-0.06,0.49-0.09,0.49-0.09
				s-0.23-1.5-0.29-1.96c-0.06-0.46-1.45-0.93-1.45-0.93l-0.23-1.27c0,0-1.62,0.23-1.91,0.12c-0.29-0.12-0.92,0.17-1.21-0.23
				c-0.29-0.4-0.81,0.29-1.33-0.06c-0.52-0.35-2.02,0.12-2.08-0.17c-0.06-0.29,0.64-0.92,1.04-0.87c0.1,0.01,0.26,0.02,0.45,0.01
				c0.19-0.31,0.62-1.03,0.52-1.2c-0.13-0.22-0.65-0.04-1,0.22c-0.35,0.26-0.99-0.13-1.13,0.26c-0.13,0.39-0.3,0.78-0.69,0.78
				c-0.39,0-0.09,0.65-0.48,0.82c-0.39,0.17-1.3,0.13-1.38,0.52c-0.09,0.39,0.99,0.65,0.91,1.12c-0.09,0.48,0.56,1,0.17,1.3
				c-0.33,0.26-0.63,0.68-0.6,1.53c0.28,0.18,0.55,0.34,0.69,0.07c0.23-0.46,1.5,0,1.5-0.4c0-0.41,1.39-0.29,1.39-0.69
				c0-0.41,0.81-1.33,1.33-1.27c0.52,0.06-0.06,0.92,0.58,1.21c0.64,0.29-0.29,1.33,0.06,1.73c0.13,0.15,0.38,0.13,0.69,0.03
				c0.24-0.07,0.5-0.17,0.78-0.26C354.37,110.65,355.14,109.95,355.4,110.1z"/>
			<path class="st0" d="M132.88,132.13c-0.09,0.19-0.57-0.25-0.59-0.59c-0.01-0.34-0.5-0.4-0.5-0.99c0-0.59,0.24-0.94,0.54-0.43
				c0.3,0.51,0.1,0.85,0.15,1.1C132.53,131.48,133.05,131.8,132.88,132.13z"/>
			<path class="st0" d="M156.26,153.08c-0.19-0.18,0.29-0.54-0.04-0.69c-0.32-0.15,0.74-0.59,1.08-0.3
				c0.34,0.29,0.37,0.73,0.07,0.85C157.07,153.06,156.44,153.24,156.26,153.08z"/>
			<path class="st0" d="M314.82,106.48c0-0.35-0.91-0.65-0.91-1.08c0-0.43-0.61-0.82-0.52-1.17c0.06-0.24-0.25-0.58-0.4-0.85
				c-0.4-0.09-0.91-0.18-1.3-0.13c-0.54,0.06-0.95,0.22-1.48,0.3c0.29,0.43,0.08,0.55,0.33,1.16c0.26,0.65,0.91,0.3,1.12,0.48
				c0.08,0.07,0.18,0.29,0.27,0.54c0.04,0,0.08-0.01,0.14,0c0.24,0.04,0.33,0.17,0.39,0.32c0.3,0.07,0.57,0.27,0.72,0.34
				c0.19,0.08,0.31-0.17,0.69,0.25c0.33,0.36,0.35,0.46,0.31,0.78c0.34,0,0.65-0.07,0.94-0.2
				C314.96,106.95,314.82,106.65,314.82,106.48z"/>
			<path class="st0" d="M319.32,104.23c-0.18-0.32-0.73-0.98-1.31-1.69c-0.18,0.53-0.82,0.38-0.94,0.69c-0.13,0.32-0.45-0.07-0.65,0
				c-0.19,0.06-0.65-0.71-1.1-0.78c-0.14-0.02-0.3-0.09-0.46-0.19c-0.19,0.2-0.36,0.42-0.29,0.53c0.13,0.22,0.69,0.43,0.48,0.65
				c-0.22,0.22-0.91,0.13-1.12-0.09c-0.22-0.22-0.87-0.56-1-0.3c-0.13,0.26,0.56,0.82,0.48,1.17c-0.09,0.35,0.52,0.73,0.52,1.17
				c0,0.43,0.91,0.74,0.91,1.08c0,0.17,0.14,0.47,0.29,0.73c0.71-0.3,1.29-0.86,1.75-1.09c0.69-0.35,0.4,1.1,0.29,1.33
				c-0.08,0.16,0.47,0.54,1.05,0.76c-0.02-0.31,0.06-0.62,0.26-0.87C319.19,106.44,319.75,105.01,319.32,104.23z M313.16,106.38
				c-0.14-0.06-0.41-0.27-0.72-0.34c0.09,0.26,0.08,0.59,0.48,0.77c0.63,0.29-0.29,0.4,0.98,0.58c0.08,0.01,0.17,0.01,0.25,0.01
				c0.04-0.32,0.01-0.42-0.31-0.78C313.48,106.21,313.35,106.46,313.16,106.38z"/>
			<path class="st0" d="M355.96,110.52c-0.3,0.04-0.24-0.22-0.56-0.41c-0.26-0.15-1.03,0.55-1.6,1.05
				c-0.27,0.09-0.53,0.19-0.76,0.26c-0.32,0.09-0.58,0.13-0.71-0.03c-0.35-0.4,0.58-1.44-0.06-1.73c-0.64-0.29-0.06-1.16-0.58-1.21
				c-0.52-0.06-1.33,0.87-1.33,1.27c0,0.4-1.39,0.29-1.39,0.69c0,0.4-1.27-0.06-1.5,0.4c-0.23,0.46-0.87-0.35-1.27-0.35
				c-0.41,0-0.69-0.58-1.21-0.41c-0.52,0.17-0.81-0.17-1.21-0.23c-0.4-0.06-0.69,0.87-1.15,0.69c-0.46-0.17-0.69,0.87-0.87,1.44
				c-0.17,0.58-2.14,0.58-2.08,1.21c0.06,0.64-1.15,0.87-1.27,0.46c-0.12-0.4-0.87-0.17-1.04-0.29c-0.17-0.12-0.69,0.12-0.64,0.69
				c0.06,0.58-0.52,0.92-0.58,1.15c-0.06,0.23-0.4,0.93-0.17,1.27c0.23,0.35-0.29,0.92,0,1.56c0.29,0.64,0.12,1.44,0.23,1.68
				c0.12,0.23,1.21,0.12,1.44,0.75c0.23,0.64-1.44,1.73-1.5,2.08c-0.02,0.14,0.11,0.31,0.3,0.49c0.66,0.03,1.49,0.11,2.25,0.25
				c0.95,0.17,5.8-0.56,5.88-0.73c0.09-0.17-0.17-1.26-0.13-1.47c0.04-0.22,0.65-0.74,1.17-0.69c0.52,0.04,1,0.13,1-0.26
				c0-0.39,1.43-0.86,1.56-0.52c0.13,0.35,0.65,0.22,0.69-0.65c0.04-0.87-0.04-1.51,0.48-1.56c0.52-0.04,1.04-0.35,1-0.61
				c-0.04-0.26-0.65-0.78-0.48-0.95c0.17-0.17,1.47,0.43,1.64,0.04c0.17-0.39-0.09-0.82,0.13-1.17c0.22-0.35,0.74-0.69,0.69-1.04
				c-0.04-0.35,0.04-0.56-0.26-0.87c-0.3-0.3-0.17-0.69,0.22-0.74c0.07-0.01,0.14-0.04,0.22-0.09c0.3-0.06,0.56-0.12,0.76-0.26
				c0.54-0.4,1.58-0.73,1.98-0.59c0.33,0.12,1.04,0.02,1.48-0.45C356.46,110.54,356.14,110.49,355.96,110.52z"/>
			<path class="st0" d="M359.51,112.94c-0.68-0.32-0.75-0.91-0.75-1.1c0-0.17-0.77-0.64-1.33-1.08c-0.07,0.01-0.25,0.04-0.44,0.09
				c-0.07-0.07-0.17-0.13-0.27-0.17c-0.44,0.47-1.16,0.57-1.48,0.45c-0.4-0.15-1.43,0.19-1.98,0.59c-0.2,0.15-0.46,0.2-0.76,0.26
				c-0.08,0.05-0.15,0.09-0.22,0.09c-0.39,0.04-0.52,0.43-0.22,0.74c0.3,0.3,0.22,0.52,0.26,0.87c0.04,0.35-0.48,0.69-0.69,1.04
				c-0.22,0.35,0.04,0.78-0.13,1.17c-0.17,0.39-1.47-0.22-1.64-0.04c-0.17,0.17,0.43,0.69,0.48,0.95c0.04,0.26-0.48,0.56-1,0.61
				c-0.52,0.04-0.43,0.69-0.48,1.56c-0.04,0.87-0.56,1-0.69,0.65c-0.13-0.35-1.56,0.13-1.56,0.52c0,0.39-0.48,0.3-1,0.26
				c-0.52-0.04-1.12,0.48-1.17,0.69c-0.04,0.22,0.22,1.3,0.13,1.47c-0.09,0.17-4.93,0.91-5.88,0.73c-0.76-0.14-1.59-0.22-2.25-0.25
				c0.25,0.25,0.59,0.52,0.69,0.79c0.19,0.51,0.81,0.98,1.5,1.27c0.69,0.29,0.35,1.5,0.69,1.62c0.35,0.12,0.81,1.04,0.35,1.1
				c-0.46,0.06-0.87,0.17-1.33,0.41c-0.46,0.23-0.98,0.81-0.93,1.5c0.01,0.09,0.02,0.22,0.04,0.36c1.18,0.03,2.58-0.03,2.73-0.2
				c0.24-0.26,0.8-0.39,1.13-0.17c0.32,0.22,1.77,0.3,2.36-0.02c0.71-0.4,1.15,0,1.17,0.35c0.02,0.35,0.35,0.5,0.67,0.8
				c0.32,0.3,0.09,0.65,0.3,1c0.22,0.35,0.65,0.28,1.28,0.56c0.09,0.04,0.16,0.09,0.23,0.15c0.04-0.05,0.07-0.08,0.09-0.11
				c0.35-0.4,0.4-1.1,1.33-0.92c0.92,0.17,0.87-0.17,1.39-0.06c0.52,0.12,0.87,0.06,1.1-0.29c0.23-0.35-0.23-1.04-0.52-1.33
				c-0.29-0.29-0.69-0.92-0.64-1.5c0.06-0.58-0.93-0.58-0.93-1.04c0-0.46,0.93-1.21,1.1-1.39c0.17-0.17,0.92,0.46,1.1,0.12
				c0.17-0.35,0.69-0.12,0.98-0.06c0.29,0.06,0.75-0.58,0.81-1.1c0.06-0.52,1.04-0.58,1.21-0.75c0.17-0.17,0.46-1.33,0.58-1.56
				c0.12-0.23,0.87-0.06,0.87-0.69c0-0.64,0.35-0.75,0.69-0.81c0.35-0.06,0.23-0.63,0.29-1.1c0.06-0.46,0.29-0.98,0.75-1.1
				c0.46-0.12-0.17-0.75-0.69-0.63c-0.52,0.12-0.87-1.39-0.87-1.39s0.06-1.21-0.23-1.39s0.12-0.81,0.75-0.81
				c0.64,0,1.79,0.69,2.2,0.4c0.4-0.29,1.21-0.46,1.44-0.58c0.17-0.09,0.42-0.64,0.52-0.9
				C360.39,113.36,359.94,113.14,359.51,112.94z"/>
		<g className="mouseOver nepalCount">
			<path class="lightBlue" d="M375.72,125.96c-0.1-0.01-0.21-0.01-0.34-0.01c-0.84,0.03-1.36-0.39-1.91-0.36
				c-0.55,0.03-2.14-0.58-2.82-1.14c-0.68-0.55-2.53-1.72-2.99-2.17c-0.45-0.45-1.1-0.45-1.17-0.23c-0.06,0.21-0.39,0.23-0.9,0.28
				c-0.13,0.23-0.66,0.53-0.66,0.84c0,0.35-0.23,0.81-0.23,1.1c0,0.29,0.58,0.35,0.75,0.58c0.17,0.23,1.1,0.35,1.16,0.58
				c0.06,0.23,0.69,0.52,1.1,0.58c0.4,0.06,0.98,0.52,1.21,0.69c0.23,0.17,1.1-0.23,1.39-0.23c0.29,0,0.75,0.52,0.81,0.81
				c0.06,0.29,1.56,0.46,1.91,0.75c0.35,0.29,2.2,0.12,2.54-0.23c0.35-0.35,0.06-0.98,0.06-1.5
				C375.62,126.12,375.66,126.02,375.72,125.96z"/>
			<g className="hoverEf nepal">
			<g>
				<path d="M370.55,126.83v-4.38h0.91l1.13,1.88c0.29,0.48,0.54,0.99,0.75,1.47l0.02,0c-0.06-0.58-0.07-1.15-0.07-1.82v-1.53h0.74
					v4.38h-0.83l-1.14-1.93c-0.28-0.48-0.57-1.01-0.79-1.51l-0.02,0c0.03,0.57,0.04,1.15,0.04,1.87v1.57H370.55z"/>
				<path d="M375.48,125.47c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H375.48z
					 M376.87,124.92c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H376.87z"/>
				<path d="M378.27,124.72c0-0.41-0.01-0.75-0.03-1.05h0.7l0.04,0.49H379c0.23-0.36,0.6-0.56,1.08-0.56c0.72,0,1.33,0.62,1.33,1.61
					c0,1.15-0.72,1.7-1.44,1.7c-0.4,0-0.73-0.17-0.89-0.42h-0.01v1.64h-0.8V124.72z M379.07,125.51c0,0.08,0.01,0.15,0.02,0.22
					c0.08,0.33,0.36,0.56,0.7,0.56c0.51,0,0.81-0.42,0.81-1.04c0-0.56-0.27-1.02-0.79-1.02c-0.33,0-0.63,0.24-0.71,0.59
					c-0.02,0.06-0.03,0.13-0.03,0.2V125.51z"/>
				<path d="M383.85,126.83l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H383.85z M383.74,125.28c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V125.28z"/>
				<path d="M385.37,122.22h0.8v4.62h-0.8V122.22z"/>
			</g>
		</g>
		</g>
		<g className="mouseOver bangladeshCount">
			<path class="lightBlue" d="M381.93,135.21c-0.26-1.43-0.4-2.83-0.59-2.83c-0.26,0-0.61,1.23-1.05,1.14c-0.44-0.09-0.44-1.58,0.09-1.58
				s1.14-1.14,1.14-1.49c0-0.35-0.52-0.35-1.05-0.35c-0.53,0-1.67,0.09-1.67,0.09s-0.7-0.26-0.7-0.7c0-0.44-0.26-0.79-0.96-0.79
				c-0.7,0-0.7-0.7-1.23,0c-0.53,0.7,1.14,0.7,0.96,1.23c-0.18,0.52-0.7,0.17-0.79,0.61c-0.09,0.44,0.09,1.58,0.35,2.19
				c0.2,0.47,0.5,1.67,0.52,2.68c0.41-0.08,0.6-0.27,0.98-0.27c0.48,0,1.12-0.06,1.17-0.56c0.04-0.5,0.58-0.87,0.89-0.71
				c0.3,0.15,0.76,1.36,0.78,1.99c0.01,0.2,0.09,0.46,0.23,0.74c0.24-0.24,0.52-0.43,0.52-0.43S381.69,135.5,381.93,135.21z"/>
				<g className="hoverEf bang">
			<g>
				<path d="M360.63,130.41c0.25-0.05,0.72-0.09,1.17-0.09c0.59,0,0.96,0.08,1.25,0.28c0.27,0.17,0.45,0.45,0.45,0.82
					c0,0.4-0.25,0.77-0.73,0.94v0.02c0.46,0.12,0.89,0.48,0.89,1.08c0,0.39-0.17,0.69-0.42,0.9c-0.31,0.27-0.83,0.4-1.63,0.4
					c-0.45,0-0.78-0.03-0.98-0.06V130.41z M361.42,132.14h0.41c0.55,0,0.86-0.26,0.86-0.63c0-0.41-0.31-0.6-0.82-0.6
					c-0.24,0-0.37,0.02-0.46,0.04V132.14z M361.42,134.14c0.1,0.02,0.24,0.02,0.42,0.02c0.51,0,0.97-0.19,0.97-0.73
					c0-0.51-0.45-0.72-1-0.72h-0.4V134.14z"/>
				<path d="M366.13,134.73l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H366.13z M366.02,133.18c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V133.18z"/>
				<path d="M367.65,132.5c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V132.5z"/>
				<path d="M374.26,131.56c-0.02,0.21-0.03,0.48-0.03,0.91v1.79c0,0.66-0.13,1.14-0.47,1.43c-0.33,0.29-0.79,0.38-1.22,0.38
					c-0.4,0-0.83-0.09-1.1-0.25l0.17-0.61c0.2,0.12,0.54,0.24,0.92,0.24c0.52,0,0.91-0.28,0.91-0.96v-0.27h-0.01
					c-0.18,0.28-0.5,0.46-0.92,0.46c-0.78,0-1.34-0.65-1.34-1.54c0-1.04,0.68-1.67,1.43-1.67c0.49,0,0.77,0.23,0.92,0.49h0.01
					l0.03-0.42H374.26z M373.43,132.83c0-0.08-0.01-0.15-0.03-0.22c-0.08-0.29-0.31-0.51-0.65-0.51c-0.44,0-0.78,0.39-0.78,1.03
					c0,0.54,0.27,0.97,0.77,0.97c0.3,0,0.56-0.19,0.65-0.49c0.03-0.08,0.04-0.19,0.04-0.28V132.83z"/>
				<path d="M375.09,130.11h0.8v4.61h-0.8V130.11z"/>
				<path d="M378.54,134.73l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H378.54z M378.44,133.18c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V133.18z"/>
				<path d="M382.98,130.11v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
					c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01v-1.79H382.98z M382.18,132.88
					c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
					c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V132.88z"/>
				<path d="M384.4,133.37c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H384.4z
					 M385.79,132.81c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H385.79z"/>
				<path d="M387.15,133.99c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
					c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
					c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
					c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L387.15,133.99z"/>
				<path d="M389.9,130.11h0.8v1.88h0.01c0.1-0.15,0.23-0.27,0.4-0.36c0.16-0.09,0.35-0.14,0.55-0.14c0.54,0,1.11,0.36,1.11,1.37
					v1.86h-0.8v-1.78c0-0.46-0.17-0.81-0.61-0.81c-0.31,0-0.53,0.21-0.62,0.45c-0.03,0.07-0.04,0.15-0.04,0.24v1.89h-0.8V130.11z"/>
			</g>
		</g>
	    </g>
		<g className="mouseOver uzbeCount">
			<path class="lightBlue" d="M354.18,104.1c-0.69-0.58-1.91-1.27-2.02-0.92c-0.12,0.35-0.87,0.4-1.16,0.06c-0.29-0.35-0.52-0.75-1.39-0.4
				c-0.87,0.35-1.44,0.81-1.5,1.15c-0.06,0.35-0.64,0.69-0.69,0.23c-0.06-0.46-0.75-0.64-1.21-0.75c-0.46-0.12-1.21,0.11-1.27-0.35
				c-0.06-0.46-0.75-0.98-0.81-1.5c-0.06-0.52,0.12-1.27-0.35-1.39c-0.46-0.12-0.75-0.87-1.15-0.87c-0.41,0-2.66,0-2.95-0.12
				c-0.29-0.11-1.79,0.46-1.91,0.12c-0.09-0.28-1.14-1.4-1.71-1.87c-0.06,0.24-0.19,0.46-0.43,0.63c-1.08,0.78-2.12,0.65-1.77-0.3
				c0.35-0.95,0.39-2.08-0.17-2.08c-0.56,0-0.17,1.99-0.61,2.12c-0.38,0.11-0.53-1.45-0.21-2.26l-3.7,0.99l-0.17,6.47
				c0,0-0.01,0.01-0.02,0.01c0.35,0.17,0.75,0.22,1.03,0.24c0.56,0.04,0.52-0.39,0.52-0.69c0-0.3,0.61-0.82,1.04-0.78
				c0.43,0.04,0.39-0.56,0.52-0.56c0.13,0,0.48-0.09,0.6-0.3c0.13-0.22,0.82,0.04,1.04,0.26c0.22,0.22,0.73,0.3,1.12,0.39
				c0.39,0.09,0.52,1.3,0.52,1.56c0,0.26,1.86,0.22,2.16,0.26c0.3,0.04,0.3,0.56,0.6,0.95c0.3,0.39,0.52,0.91,0.65,1.21
				c0.13,0.3,1.21,0.69,1.47,1.08c0.26,0.39,1.08,0.78,1.69,1c0.61,0.22,1.17,1.04,1.51,1c0.35-0.04,1.3,0.43,1.3,0.43l0.11,0.96
				c0.04-0.01,0.08-0.01,0.13-0.03c0.52-0.17,0.81,0.41,1.21,0.41c0.17,0,0.38,0.14,0.58,0.27c-0.03-0.85,0.27-1.27,0.6-1.53
				c0.39-0.3-0.26-0.82-0.17-1.3c0.09-0.48-1-0.74-0.91-1.12c0.09-0.39,0.99-0.35,1.38-0.52c0.39-0.17,0.09-0.82,0.48-0.82
				c0.39,0,0.56-0.39,0.69-0.78c0.13-0.39,0.78,0,1.13-0.26c0.35-0.26,0.87-0.43,1-0.22c0.1,0.17-0.33,0.9-0.52,1.2
				c0.6-0.02,1.51-0.13,1.69-0.3c0.23-0.23,1.1,0.35,1.39-0.23C353.83,104.28,354.87,104.68,354.18,104.1z"/>
			<g className="hoverEf uzbe">
			<g>
				<path d="M338.16,99.71v2.55c0,0.86,0.36,1.25,0.9,1.25c0.58,0,0.92-0.39,0.92-1.25v-2.55h0.8v2.5c0,1.35-0.69,1.95-1.75,1.95
					c-1.01,0-1.67-0.57-1.67-1.95v-2.51H338.16z"/>
				<path d="M341.37,103.63l1.19-1.52c0.16-0.2,0.3-0.35,0.46-0.53v-0.01h-1.54v-0.64H344l0,0.49l-1.16,1.49
					c-0.16,0.19-0.3,0.37-0.47,0.54v0.01h1.67v0.64h-2.67V103.63z"/>
				<path d="M344.57,104.09c0.01-0.21,0.03-0.56,0.03-0.89v-3.73h0.8v1.89h0.01c0.19-0.31,0.54-0.51,1.01-0.51
					c0.77,0,1.33,0.64,1.32,1.6c0,1.14-0.72,1.7-1.44,1.7c-0.41,0-0.77-0.16-1-0.55h-0.01l-0.04,0.48H344.57z M345.39,102.78
					c0,0.07,0.01,0.13,0.02,0.19c0.08,0.32,0.36,0.56,0.71,0.56c0.5,0,0.8-0.4,0.8-1.04c0-0.56-0.27-1.01-0.8-1.01
					c-0.32,0-0.62,0.23-0.71,0.58c-0.01,0.06-0.03,0.13-0.03,0.21V102.78z"/>
				<path d="M348.96,102.73c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H348.96z
					 M350.35,102.17c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H350.35z"/>
				<path d="M352.55,102.31h0.01c0.08-0.12,0.17-0.26,0.25-0.37l0.77-1.02h0.96l-1.17,1.29l1.34,1.87h-0.98l-0.91-1.4l-0.27,0.32
					v1.08h-0.8v-4.62h0.8V102.31z"/>
				<path d="M355.9,100.04c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C355.73,99.6,355.9,99.79,355.9,100.04z M355.06,104.09v-3.16h0.8v3.16H355.06z"/>
				<path d="M356.68,103.35c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
					c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
					c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
					c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L356.68,103.35z"/>
				<path d="M360.35,100.02v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L360.35,100.02z"/>
				<path d="M363.49,104.09l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H363.49z M363.39,102.54c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V102.54z"/>
				<path d="M365.02,101.86c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V101.86z"/>
			</g>
		</g>
		</g>
		<g className="mouseOver tunisiaCOunt">
			<path class="lightBlue" d="M259.95,113.01c0.35,0.52-0.09,1.38-0.43,1.56c-0.35,0.17-0.61,0.95-0.95,0.95c-0.35,0,0,1.3,0.61,1.56
				c0.61,0.26,0.61,0.69,0.61,0.95c0,0.26,0.87,0.52,1.13,0.87c0.26,0.35,0.69,1.99,0.69,2.6c0,0.15,0.05,0.28,0.13,0.38
				c0.22-0.15,0.49-0.33,0.61-0.54c0.19-0.37,0.35-1,0.26-1.17c-0.09-0.17-0.06-0.48,0.13-0.65c0.19-0.17,0.97-1,1.12-1.06
				c0.15-0.07,0.65,0.02,0.63-0.45c-0.01-0.19,0.01-0.48,0.04-0.76c-0.35-0.23-0.56-0.49-0.56-0.62c0-0.28-0.39-0.76-0.61-0.5
				c-0.22,0.26-1.04,0.04-1.15-0.5c-0.11-0.54,0.54-0.52,1.15-1.02c0.61-0.5,0.69-1.45,0.04-1.97c-0.65-0.52-0.19-0.8,0.26-1.28
				c0.45-0.48,0.04-0.74-0.19-0.52c-0.24,0.22-0.63,0.28-0.69-0.24c-0.06-0.52-1.47-0.6-2.01-0.11c-0.19,0.17-0.48,0.23-0.79,0.23
				C259.84,111.59,259.72,112.67,259.95,113.01z"/>
				<g className="hoverEf tunisia">
			<g>
				<path d="M255.04,113.3h-1.25v-0.67h3.31v0.67h-1.26v3.71h-0.8V113.3z"/>
				<path d="M260.42,116.06c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V116.06z"/>
				<path d="M261.29,114.79c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V114.79z"/>
				<path d="M265.85,112.96c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C265.68,112.53,265.85,112.72,265.85,112.96z M265,117.01v-3.16h0.8v3.16H265z"/>
				<path d="M266.63,116.28c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
					c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
					c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
					c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L266.63,116.28z"/>
				<path d="M270.23,112.96c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C270.05,112.53,270.22,112.72,270.23,112.96z M269.38,117.01v-3.16h0.8v3.16H269.38z"/>
				<path d="M272.82,117.01l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H272.82z M272.72,115.46c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V115.46z"/>
			</g>
		</g>
		</g>
		<g className="mouseOver indonesiaCount">
    		<path class="lightBlue" d="M389.84,169.78c-0.09,0.22,0.39,1.08,0.69,0.82C390.84,170.34,390.12,169.1,389.84,169.78z M403.99,172.42
				c0-0.39-1.12-0.2-0.78,0.35C403.56,173.33,403.99,172.81,403.99,172.42z M387.72,166.06c-0.09,0.43,0.39,1.69,0.91,1.17
				C389.15,166.71,387.81,165.63,387.72,166.06z M420.39,173.46c0.65,0.04,0.56,1,0.43,1.56c-0.13,0.56-0.39,1.9,0.17,1.69
				c0.69-0.26,1.12-1.12,0.73-1.43c-0.39-0.3-0.35-2.21-0.17-2.68c0.17-0.48,1.34-0.13,0.95,0.61c-0.39,0.74,0.74,0.65,0.74,1.47
				s0.65,0.39,1.34,0.17c0.69-0.22,0.56-0.61,0.09-1.13c-0.48-0.52,0.35-1.25-0.3-1.69c-0.65-0.43-1.08-1.08-0.48-1.21
				c0.61-0.13,2.42-1.17,2.21-1.6c-0.22-0.43-2.77,0.09-2.99,0.87c-0.22,0.78-2.03-0.09-1.82-1.43c0.22-1.34,3.81-1.56,4.85-1.08
				c1.04,0.48,2.16-0.78,2.55-1.51c0.39-0.74-0.82,0.13-1.56,0.61c-0.73,0.48-2.68-0.04-3.68-0.39c-1-0.35-0.95,0.61-1.77,0.74
				c-0.82,0.13-0.65,1.95-1.04,2.16c-0.39,0.22-0.39,1.56-1.17,2.81C418.7,173.24,419.74,173.41,420.39,173.46z M400.62,174.58
				c-0.04-0.61,0.22-1.21,0.22-1.52c0-0.3,0.22-0.43,0.6-0.35c0.39,0.09,0.35-0.82,0.04-0.91c-0.3-0.09-0.48-1.17-1.21-1.17
				c-0.73,0-0.6,0.6-0.17,0.65c0.43,0.04,0.69,0.39,0.56,0.69c-0.13,0.3-0.3-0.3-0.91-0.26c-0.61,0.04-0.22-0.74-0.91-0.78
				c-0.69-0.04-0.56-1.3-1.3-1.3c-0.74,0-0.78-0.61-0.3-1.04c0.48-0.43-0.74-0.82-0.74-1.34c0-0.52-0.78-0.22-0.78-0.65
				c0-0.43-0.35-0.22-0.74-0.73c-0.39-0.52-0.56-0.04-0.91-0.43c-0.35-0.39-0.82-0.61-1.12-0.48c-0.3,0.13-1-0.56-1.26-1.26
				c-0.21-0.55-2.21-1.47-2.47-2.21c-0.26-0.73-0.95-1.04-2.12-1c-1.17,0.04-1.63-1.14-1.9-0.56c-0.35,0.74,1.26,2.38,1.99,2.81
				c0.73,0.43,1.12,1.99,1.99,2.08c0.87,0.09,0.95,2.81,1.6,2.94c0.65,0.13,2.04,2.25,2.25,3.37c0.22,1.13,1.64,1.69,2.12,2.51
				c0.48,0.82,2.38,1.95,2.64,2.29c0.26,0.35,0.52,1.13,0.74,0.74c0.22-0.39,1.12,0,1.56,0.04
				C400.53,176.79,400.66,175.19,400.62,174.58z M433.68,167.66c0.95,0.09,0.52-0.48,0.52-1.08c0-0.61-0.86-0.09-0.86-0.39
				c0-0.3-0.3-1.51-1-0.56c-0.69,0.95,0.22,3.3,0.78,3.2C433.59,168.74,432.72,167.57,433.68,167.66z M430.13,173.07
				c0.17,0.69,1.43,1.08,1.77,0.48C432.25,172.94,429.93,172.27,430.13,173.07z M426.71,180.38c-1.17-0.17-1.95,0.69-3.12,0.26
				c-1.17-0.43-2.92,0.01-2.73,0.39c0.17,0.35,1.21,0.39,2.38,0.43c1.17,0.04,2.21-0.61,2.99-0.65
				C427.01,180.77,427.88,180.55,426.71,180.38z M435.97,172.46c-1.12-0.35-3.05-0.05-2.86,0.52c0.17,0.52,1.08,0.3,1.99,0.3
				c0.91,0,1.38,0.65,1.86,0.65C437.44,173.93,437.09,172.81,435.97,172.46z M413.17,179.9c-0.04-0.52-1.73-0.04-2.55-0.39
				c-0.82-0.35,0.91-0.65,1.47-1c0.56-0.35-0.35-0.48-1.73-0.04c-1.39,0.43-2.99-0.95-3.03-0.35c-0.04,0.61-2.51-0.26-3.12-0.69
				c-0.6-0.43-2.94-1-3.42-0.17c-0.48,0.82-0.89,0.08-0.69,0.87c0.13,0.52,0.69,0.22,1.08,0.22c0.39,0,0.22,0.74,0.78,0.82
				c0.56,0.09,2.42,0.61,2.6,0.22c0.17-0.39,1.9,0,2.77,0.69c0.87,0.69,2.86,0.74,3.63,0.61c0.78-0.13,1.64,0.52,1.95,0.09
				c0.3-0.43,0.86,0.69,1.73,0.04C415.5,180.17,413.21,180.42,413.17,179.9z M447.22,170.47c-0.65,0.04-0.74,1.17-1.3,1.17
				c-0.56,0-1.34,1.17-2.38,1.52c-1.04,0.35-1.08-2.99-1.56-3.63c-0.48-0.65-3.07-1.17-3.33-0.43c-0.26,0.74-1.16,0.35-1.26,0.95
				c-0.09,0.56,0.22,0.39,0.78,0.39c0.56,0,0.56,0.43,0.82,1c0.26,0.56,1.82,0.17,2.34,0.13c0.52-0.04,0.61,0.69-0.13,0.43
				c-0.74-0.26-0.74,0.35-1.26,0.22c-0.52-0.13-1.21-0.09-0.87,0.22c0.35,0.3,0.91,0.61,0.91,1.3c0,0.69,1,0.56,1,0
				c0-0.56,0.52,0.17,1.51,0.43c1,0.26,0.3,0.65,1.3,0.69c0.99,0.04,3.24,0.78,4.11,1.43c0.87,0.65,0.26,1.08,0.82,1.69
				c0.56,0.6,0.73,1.3-0.04,1.3c-0.78,0-1.6,0.78-1.38,1.17c0.22,0.39,2.77-0.17,3.37-0.17c0.4,0,0.8,0.7,1.56,1.22v-9.24
				C450.36,171.6,447.75,170.44,447.22,170.47z M428.49,181.44c-0.13,0.09-0.29,0.16-0.48,0.2c-0.06,0.16-0.12,0.34-0.22,0.42
				c-0.12,0.1-0.33-0.09-0.48-0.26c-0.84,0.35-1.28,1.35-0.7,1.66c0.56,0.3,1.37-0.49,1.84-1.05c0.08-0.09,0.14-0.18,0.2-0.25
				c0.03-0.04,0.08-0.09,0.15-0.13c0.04-0.03,0.09-0.06,0.15-0.1C428.81,181.74,428.64,181.55,428.49,181.44z M416.46,170.3
				c0.78-0.48,0.95-1.39,0.86-2.25c-0.09-0.87,1.9-0.91,2.21-1.25c0.3-0.35-0.43-0.91-1-1.17c-0.56-0.26-0.09-0.82-0.48-1.17
				c-0.39-0.35-1.04-1.26-0.48-1.3c0.56-0.04-0.39-0.82,0.09-1.08c0.06-0.03,0.14-0.07,0.22-0.11c-0.28-0.14-0.53-0.24-0.69-0.24
				c-0.69,0-2.34-0.17-2.25,0.95c0.09,1.13-0.95,0.69-0.86,1.3c0.09,0.61-0.52,0.61-0.43,1.38c0.09,0.78-0.43,0.43-1.3,0.87
				c-0.86,0.43-0.52-0.26-1.38-0.26c-0.87,0-0.95,0.43-1.47,0.61c-0.52,0.17-1.56-0.09-1.9,0.17c-0.35,0.26-0.86-0.6-1.21-0.6
				c-0.18,0-0.33-0.47-0.44-0.94c-0.06-0.01-0.12-0.02-0.17-0.02c-0.56,0.04-1.49,1.53-0.95,2.03c0.65,0.61,0.48,1.56,0.78,2.03
				c0.3,0.48,1.26,0.56,1.26,1.47c0,0.91,0.82,2.34,1.38,1.95c0.56-0.39,0.99,0.17,1.17,0.56c0.17,0.39,1.51-0.3,1.95-0.22
				c0.43,0.09,1.77,0.35,1.86,0.86c0.09,0.52,1.3-0.04,2.12-0.35C416.15,173.24,415.68,170.78,416.46,170.3z M419.7,182.41
				c0.26,0.39,1,0.39,1.3,0.65c0.3,0.26,1.38,0.82,1.38,0.09C422.38,182.41,419.41,181.98,419.7,182.41z M417.54,180.29
				c-0.17,0.39-0.91,0.48-1.34,0.17c-0.43-0.3-0.97,0.41-0.69,0.78c0.13,0.17,1.04,0.87,2.12,0.35c1.08-0.52,1.82-0.04,2.08-0.61
				C419.96,180.42,417.71,179.9,417.54,180.29z"/>
		<g>
			<g className="hoverEf indo">
				<path d="M380.12,167.43v4.38h-0.8v-4.38H380.12z"/>
				<path d="M381.01,169.59c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V169.59z"/>
				<path d="M387.65,167.19v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
					c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01v-1.79H387.65z M386.85,169.96
					c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
					c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V169.96z"/>
				<path d="M391.51,170.2c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
					C390.88,168.57,391.51,169.24,391.51,170.2z M389.13,170.23c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
					c0-0.5-0.22-1.06-0.77-1.06C389.36,169.16,389.13,169.7,389.13,170.23z"/>
				<path d="M392.17,169.59c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V169.59z"/>
				<path d="M396.45,170.45c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H396.45z
					 M397.85,169.89c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H397.85z"/>
				<path d="M399.21,171.07c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
					c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
					c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
					c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L399.21,171.07z"/>
				<path d="M402.8,167.76c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C402.63,167.33,402.8,167.51,402.8,167.76z M401.95,171.81v-3.16h0.8v3.16H401.95z"/>
				<path d="M405.4,171.81l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H405.4z M405.29,170.26c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V170.26z"/>
			</g>
		</g>
    </g>
      <path class="st0" d="M427.79,181.68c-0.17,0.01-0.33,0.06-0.47,0.12c0.15,0.17,0.36,0.36,0.48,0.26c0.1-0.08,0.16-0.26,0.22-0.42
				c-0.06,0.01-0.13,0.03-0.19,0.03C427.81,181.68,427.8,181.68,427.79,181.68z M429.87,180.55c-0.58-0.03-0.82,0.45-1.25,0.8
				c-0.04,0.03-0.08,0.06-0.13,0.09c0.15,0.11,0.33,0.3,0.46,0.49c0.84-0.5,2.97-1.12,3.08-1.42
				C432.16,180.17,430.73,180.6,429.87,180.55z"/>
		<g className="mouseOver italyCount">
			<path class="yellowDark" d="M268.91,108.66c-0.89,0.19-1.25,0.26-1.77,0c-0.52-0.26-1.36,0.24-1.23,0.58c0.09,0.24,0.43,0.54,1.51,0.82
				c1.08,0.28,1.21,1.02,1.67,1.04c0.45,0.02,0.5-0.41,0.39-1c-0.11-0.58,0.54-1.25,0.6-1.36
				C270.14,108.64,269.8,108.47,268.91,108.66z M260.54,103.75c-0.48,0.41-0.87-0.07-1.06,0.26c-0.24,0.41,0.74,1.04,0.54,1.88
				c-0.19,0.84,0.11,1.9,0.61,1.41c0.5-0.5,0.74-0.26,1.02-0.63c0.28-0.37,0.04-1.73,0.24-2.38
				C262.07,103.64,261.01,103.34,260.54,103.75z M271.14,103.06c-0.56-0.35-0.19-0.91-1.08-0.87c-0.89,0.04-1.95-1.06-2.27-2.34
				c-0.32-1.28-1.71-1.34-2.06-2.1c-0.35-0.76,0.32-0.84,0.02-1.47c-0.17-0.35,0.2-0.66,0.64-0.83c-0.04-0.69,0.02-1.45,0.08-1.94
				c-0.34-0.04-0.64-0.09-0.74-0.15c-0.16-0.1-0.19-0.62-0.29-0.62c-0.1,0-1.01,0-1.17,0.16c-0.16,0.16-0.65,0.45-0.91,0.29
				c-0.06-0.03-0.12-0.07-0.18-0.1l-0.28,0.26c0,0-0.04,0.35-0.22,0.35c-0.17,0-0.43-0.13-0.43,0.17c0,0.3-0.48,0.22-0.69,0.17
				c-0.22-0.04-0.52,0.04-0.52,0.3c0,0.26-0.04,0.82-0.39,0.52c-0.35-0.3-0.69-1.08-0.82-1c-0.13,0.09-0.43,1.04-0.74,1.04
				c-0.19,0-0.8,0.05-1.25,0.09c-0.12,0.37,0.01,0.59,0.17,0.69c0.16,0.1-0.1,0.49-0.29,0.58c-0.19,0.1-0.52,0-0.29,0.36
				c0.23,0.36,0.65,0.39,0.42,0.75c-0.23,0.36,0,0.68,0.32,0.71c0.32,0.03,0.78-0.03,0.68,0.23c-0.06,0.15-0.15,0.33-0.16,0.5
				c0.26-0.07,0.48-0.09,0.71-0.2c0.45-0.24,0.26-0.65,1.02-0.87c0.76-0.22,1.75,0.41,2.06,0.87c0.3,0.45,0.39,0.5,0.45,0.87
				c0.07,0.37,0.35,1.1,1.38,1.77c1.04,0.67,1.45,1.43,1.99,1.71c0.54,0.28,1.08,0.22,1.41,0.61c0.32,0.39,0.76,0.61,1.28,0.84
				c0.52,0.24,0.54,0.8,1.04,0.87c0.5,0.06,0.17,0.5,0.48,0.87c0.3,0.37,0.39,1.15,0.07,1.58c-0.32,0.43-0.15,1.17,0.06,1.17
				c0.22,0,0.67-0.78,0.74-1.21c0.06-0.43,0.5-0.41,0.84-0.86c0.35-0.45-0.52-0.56-0.67-1.15c-0.15-0.58,0.65-1.1,1.41-0.63
				c0.76,0.48,1.06,0.8,1.1,0.07C274.08,104.38,271.7,103.41,271.14,103.06z"/>
		</g>
			<path class="st0" d="M274.68,99.5c-0.3,0.41-0.59,0.92-0.56,1.09c0.01,0.07-0.01,0.24-0.06,0.45c0.39,0.31,0.76,0.63,1.03,0.77
				c0.03,0.01,0.05,0.03,0.08,0.04c0.09-0.48,0.42-0.98,0.66-1.03C275.49,100.4,275,99.82,274.68,99.5z"/>
			<path class="st0" d="M279.81,99.14c-0.32-0.29-0.29-0.81,0-1.01c0.29-0.19,0.03-0.45,0.03-0.78c0-0.32-1.39-0.42-1.62-0.49
				c-0.23-0.06,0.16-0.91-0.19-0.91c-0.36,0-0.49-0.26-0.49-0.52c0-0.26-0.55-0.68-0.52-0.84c0.01-0.02,0.02-0.04,0.04-0.07
				c-0.2-0.03-0.35-0.06-0.41-0.07c-0.24-0.05-0.63-0.19-0.63-0.19s-0.9,0.29-1.52,0.48c0.04,0.38,0.14,0.8,0.38,0.89
				c0.46,0.17,0.29,0.64,0.46,1.05c0.17,0.41,0.12,2.27-0.12,2.27c-0.1,0-0.32,0.24-0.55,0.54c0.32,0.32,0.81,0.9,1.15,1.31
				c0.01,0,0.03-0.01,0.04-0.01c0.29,0,1.35,0.77,1.35,0.77s1.51-0.31,2.52-0.46c0.01-0.15,0.05-0.33,0.03-0.44
				c-0.03-0.19,0.2-0.58,0.49-0.58C280.56,100.09,280.14,99.44,279.81,99.14z"/>
		</g>
		<g className="mouseOver southKCount">
			<path class="greenBlue" d="M431.83,109.01c-0.13,0.13-0.53,0.38-0.86,0.65c0.17,0.28,0.13,0.74,0.45,0.77
				c0.45,0.04,0.41,0.74-0.09,0.63c-0.5-0.11-0.5,0.67-0.07,1.34c0.43,0.67-0.82,1.43-0.47,1.77c0.35,0.34,0.06,0.76,0.22,0.97
				c0.15,0.22,1.99-0.09,2.79-0.91c0.8-0.82,1.02-0.71,1.43-0.61c0.41,0.11,0.82-0.3,0.6-0.63c-0.22-0.32-0.45-1.12-0.39-2.12
				c0.04-0.58-0.75-1.62-1.51-2.46c-0.24,0.19-0.45,0.29-0.77,0.29C432.64,108.72,432.06,108.78,431.83,109.01z"/>
			<g className='hoverEf southK'>
				<g>
					<path d="M412.79,109.79c0.26,0.15,0.65,0.28,1.06,0.28c0.51,0,0.8-0.24,0.8-0.6c0-0.33-0.22-0.53-0.78-0.73
						c-0.72-0.26-1.19-0.64-1.19-1.27c0-0.72,0.6-1.26,1.55-1.26c0.48,0,0.82,0.1,1.05,0.22l-0.19,0.65
						c-0.16-0.08-0.46-0.21-0.88-0.21c-0.51,0-0.72,0.27-0.72,0.53c0,0.34,0.25,0.49,0.84,0.72c0.76,0.29,1.13,0.67,1.13,1.3
						c0,0.71-0.53,1.32-1.66,1.32c-0.46,0-0.94-0.13-1.18-0.28L412.79,109.79z"/>
					<path d="M419.16,109.05c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
						C418.53,107.42,419.16,108.09,419.16,109.05z M416.77,109.08c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
						c0-0.5-0.22-1.06-0.77-1.06C417,108.01,416.77,108.55,416.77,109.08z"/>
					<path d="M422.65,109.71c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
						c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
						c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V109.71z"/>
					<path d="M424.43,106.59v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
						c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
						L424.43,106.59z"/>
					<path d="M425.8,106.04h0.8v1.88h0.01c0.1-0.15,0.23-0.27,0.4-0.36c0.16-0.09,0.35-0.14,0.55-0.14c0.54,0,1.11,0.36,1.11,1.37
						v1.86h-0.8v-1.78c0-0.46-0.17-0.81-0.61-0.81c-0.31,0-0.53,0.21-0.62,0.45c-0.03,0.07-0.04,0.15-0.04,0.24v1.89h-0.8V106.04z"/>
					<path d="M430.89,106.28h0.79v2.02h0.02c0.11-0.17,0.22-0.32,0.32-0.47l1.15-1.54h0.98l-1.51,1.86l1.61,2.52h-0.94l-1.24-2.01
						l-0.39,0.47v1.54h-0.79V106.28z"/>
					<path d="M437.65,109.05c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
						C437.02,107.42,437.65,108.09,437.65,109.05z M435.26,109.08c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
						c0-0.5-0.22-1.06-0.77-1.06C435.49,108.01,435.26,108.55,435.26,109.08z"/>
					<path d="M438.31,108.51c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
						v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V108.51z"/>
					<path d="M441.19,109.3c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
						c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H441.19z
						M442.58,108.74c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H442.58z"/>
					<path d="M445.76,110.66l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H445.76z M445.65,109.11c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V109.11z"/>
				</g>
			</g>
		</g>
		<g className="hoverEf aust">
			<g>
				<path d="M296.95,75.5l-0.37,1.24h-0.83l1.41-4.38h1.02l1.42,4.38h-0.86l-0.4-1.24H296.95z M298.21,74.89l-0.35-1.08
					c-0.08-0.27-0.16-0.57-0.22-0.82h-0.01c-0.06,0.26-0.13,0.56-0.21,0.82l-0.34,1.08H298.21z"/>
				<path d="M303.01,75.79c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V75.79z"/>
				<path d="M303.84,76c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
					c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
					c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
					c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L303.84,76z"/>
				<path d="M307.5,72.66v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L307.5,72.66z"/>
				<path d="M308.87,74.59c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V74.59z"/>
				<path d="M312.03,72.69c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C311.85,72.25,312.02,72.44,312.03,72.69z M311.18,76.74v-3.16h0.8v3.16H311.18z"/>
				<path d="M314.62,76.74l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H314.62z M314.52,75.19c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V75.19z"/>
			</g>
		</g>
		<g className="hoverEf czechia">
			<g>
				<path d="M292.67,67.23c-0.19,0.09-0.61,0.2-1.14,0.2c-1.39,0-2.24-0.87-2.24-2.21c0-1.44,1.01-2.31,2.35-2.31
					c0.53,0,0.9,0.11,1.06,0.19l-0.17,0.64c-0.2-0.09-0.5-0.17-0.85-0.17c-0.89,0-1.54,0.56-1.54,1.61c0,0.97,0.56,1.58,1.53,1.58
					c0.32,0,0.66-0.06,0.87-0.16L292.67,67.23z"/>
				<path d="M293.01,66.9l1.19-1.52c0.16-0.2,0.3-0.35,0.46-0.53v-0.01h-1.54V64.2h2.51l0,0.49l-1.16,1.49
					c-0.16,0.19-0.3,0.37-0.47,0.54v0.01h1.67v0.64h-2.67V66.9z"/>
				<path d="M296.8,66c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H296.8z
					 M298.19,65.44c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H298.19z"/>
				<path d="M301.91,67.27c-0.17,0.08-0.5,0.16-0.89,0.16c-0.99,0-1.63-0.64-1.63-1.62c0-0.95,0.65-1.68,1.76-1.68
					c0.3,0,0.59,0.06,0.77,0.14l-0.14,0.6c-0.13-0.06-0.32-0.12-0.61-0.12c-0.61,0-0.97,0.45-0.97,1.03c0,0.65,0.43,1.02,0.97,1.02
					c0.27,0,0.47-0.06,0.63-0.12L301.91,67.27z"/>
				<path d="M302.51,62.75h0.8v1.88h0.01c0.1-0.15,0.23-0.27,0.4-0.36c0.16-0.09,0.35-0.14,0.55-0.14c0.54,0,1.11,0.36,1.11,1.37
					v1.86h-0.8v-1.78c0-0.46-0.17-0.81-0.61-0.81c-0.31,0-0.53,0.21-0.62,0.45c-0.03,0.07-0.04,0.15-0.04,0.24v1.89h-0.8V62.75z"/>
				<path d="M307.6,63.04c0.3-0.06,0.75-0.09,1.21-0.09c0.63,0,1.06,0.11,1.35,0.35c0.24,0.2,0.38,0.5,0.38,0.88
					c0,0.56-0.38,0.95-0.8,1.09v0.02c0.32,0.12,0.5,0.42,0.61,0.85c0.14,0.55,0.26,1.05,0.35,1.22h-0.82
					c-0.07-0.13-0.17-0.49-0.29-1.03c-0.13-0.57-0.33-0.76-0.79-0.77h-0.4v1.8h-0.79V63.04z M308.4,64.97h0.47
					c0.54,0,0.88-0.29,0.88-0.72c0-0.48-0.34-0.7-0.85-0.7c-0.25,0-0.42,0.02-0.5,0.04V64.97z"/>
				<path d="M311.84,66c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H311.84z
					 M313.23,65.44c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H313.23z"/>
				<path d="M314.63,65.25c0-0.41-0.01-0.75-0.03-1.05h0.7l0.04,0.49h0.01c0.23-0.36,0.6-0.56,1.08-0.56c0.72,0,1.33,0.62,1.33,1.61
					c0,1.15-0.72,1.7-1.44,1.7c-0.4,0-0.73-0.17-0.89-0.42h-0.01v1.64h-0.8V65.25z M315.43,66.04c0,0.08,0.01,0.15,0.02,0.22
					c0.08,0.33,0.36,0.56,0.7,0.56c0.51,0,0.81-0.42,0.81-1.04c0-0.56-0.27-1.02-0.79-1.02c-0.33,0-0.63,0.24-0.71,0.59
					c-0.02,0.06-0.03,0.13-0.03,0.2V66.04z"/>
				<path d="M321.26,66.41c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38V64.2h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25V64.2h0.8V66.41z"/>
				<path d="M322.1,67.36c0.01-0.21,0.03-0.56,0.03-0.89v-3.73h0.8v1.89h0.01c0.19-0.31,0.54-0.51,1.01-0.51
					c0.77,0,1.33,0.64,1.32,1.6c0,1.14-0.72,1.7-1.44,1.7c-0.41,0-0.77-0.16-1-0.55h-0.01l-0.04,0.48H322.1z M322.93,66.05
					c0,0.07,0.01,0.13,0.02,0.19c0.08,0.32,0.36,0.56,0.71,0.56c0.5,0,0.8-0.4,0.8-1.04c0-0.56-0.27-1.01-0.8-1.01
					c-0.32,0-0.62,0.23-0.71,0.58c-0.01,0.06-0.03,0.13-0.03,0.21V66.05z"/>
				<path d="M325.93,62.75h0.8v4.62h-0.8V62.75z"/>
				<path d="M328.45,63.31c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C328.27,62.88,328.45,63.06,328.45,63.31z M327.6,67.36V64.2h0.8v3.16H327.6z"/>
				<path d="M331.59,67.27c-0.17,0.08-0.5,0.16-0.89,0.16c-0.99,0-1.63-0.64-1.63-1.62c0-0.95,0.65-1.68,1.76-1.68
					c0.3,0,0.59,0.06,0.77,0.14l-0.14,0.6c-0.13-0.06-0.32-0.12-0.61-0.12c-0.61,0-0.97,0.45-0.97,1.03c0,0.65,0.43,1.02,0.97,1.02
					c0.27,0,0.47-0.06,0.63-0.12L331.59,67.27z"/>
			</g>
		</g>
		<g>
			
		</g>
		<g className="hoverEf finland">
			<g>
				<path d="M268.04,59.97h2.53v0.66h-1.73v1.21h1.61v0.65h-1.61v1.86h-0.8V59.97z"/>
				<path d="M272.17,60.3c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C272,59.87,272.17,60.06,272.17,60.3z M271.33,64.35v-3.16h0.8v3.16H271.33z"/>
				<path d="M272.99,62.13c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V62.13z"/>
				<path d="M276.71,59.74h0.8v4.62h-0.8V59.74z"/>
				<path d="M280.16,64.35L280.1,64h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H280.16z M280.05,62.8c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V62.8z"/>
				<path d="M281.68,62.13c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V62.13z"/>
				<path d="M288.31,59.74v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
					c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01v-1.79H288.31z M287.51,62.51
					c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
					c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V62.51z"/>
			</g>
		</g>
		<g className="hoverEf norway">
			<g>
				<path d="M243.21,61.93v-4.38h0.91l1.13,1.88c0.29,0.48,0.54,0.99,0.75,1.47l0.02,0c-0.06-0.58-0.07-1.15-0.07-1.82v-1.53h0.74
					v4.38h-0.83l-1.14-1.93c-0.28-0.48-0.57-1.01-0.79-1.51l-0.02,0c0.03,0.57,0.04,1.15,0.04,1.87v1.57H243.21z"/>
				<path d="M250.58,60.32c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
					C249.95,58.7,250.58,59.36,250.58,60.32z M248.2,60.35c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
					c0-0.5-0.22-1.06-0.77-1.06C248.43,59.28,248.2,59.83,248.2,60.35z"/>
				<path d="M251.24,59.79c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V59.79z"/>
				<path d="M254.03,58.77l0.33,1.43c0.08,0.34,0.15,0.68,0.21,1.03h0.01c0.06-0.34,0.16-0.69,0.25-1.02l0.42-1.44h0.65l0.4,1.4
					c0.1,0.37,0.19,0.71,0.25,1.06h0.01c0.05-0.34,0.12-0.69,0.21-1.06l0.35-1.4h0.79l-0.99,3.16h-0.74l-0.38-1.29
					c-0.1-0.33-0.17-0.63-0.24-1.01h-0.01c-0.07,0.39-0.15,0.7-0.24,1.01l-0.4,1.29h-0.75l-0.94-3.16H254.03z"/>
				<path d="M260.21,61.93l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H260.21z M260.1,60.38c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V60.38z"/>
				<path d="M262.23,58.77l0.57,1.7c0.06,0.2,0.13,0.44,0.18,0.61h0.02c0.05-0.18,0.11-0.42,0.17-0.62l0.5-1.69h0.85l-0.79,2.16
					c-0.43,1.18-0.73,1.71-1.1,2.03c-0.31,0.27-0.64,0.38-0.84,0.4l-0.18-0.67c0.13-0.03,0.3-0.11,0.46-0.22
					c0.15-0.1,0.33-0.29,0.44-0.5c0.03-0.05,0.05-0.1,0.05-0.14c0-0.04-0.01-0.08-0.04-0.16l-1.16-2.91H262.23z"/>
			</g>
		</g>
		<g className="hoverEf sweden">
			<g>
				<path d="M251.25,68.06c0.26,0.15,0.65,0.28,1.06,0.28c0.51,0,0.8-0.24,0.8-0.6c0-0.33-0.22-0.53-0.78-0.73
					c-0.72-0.26-1.19-0.64-1.19-1.27c0-0.72,0.6-1.26,1.55-1.26c0.48,0,0.82,0.1,1.05,0.22l-0.19,0.65
					c-0.16-0.08-0.46-0.21-0.88-0.21c-0.51,0-0.72,0.27-0.72,0.53c0,0.34,0.25,0.49,0.84,0.72c0.76,0.29,1.13,0.67,1.13,1.3
					c0,0.71-0.53,1.32-1.66,1.32c-0.46,0-0.94-0.13-1.18-0.28L251.25,68.06z"/>
				<path d="M255.08,65.77l0.33,1.43c0.08,0.34,0.15,0.68,0.21,1.03h0.01c0.06-0.34,0.16-0.69,0.25-1.02l0.42-1.44h0.65l0.4,1.4
					c0.1,0.37,0.19,0.71,0.25,1.06h0.01c0.05-0.34,0.12-0.69,0.21-1.06l0.35-1.4h0.79l-0.99,3.16h-0.74l-0.38-1.29
					c-0.1-0.33-0.17-0.63-0.24-1.01h-0.01c-0.07,0.39-0.15,0.7-0.24,1.01l-0.4,1.29h-0.75l-0.94-3.16H255.08z"/>
				<path d="M260.04,67.57c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H260.04z
					 M261.43,67.02c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H261.43z"/>
				<path d="M265.75,64.32v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
					c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01v-1.79H265.75z M264.95,67.09
					c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
					c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V67.09z"/>
				<path d="M267.17,67.57c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H267.17z
					 M268.56,67.02c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H268.56z"/>
				<path d="M269.96,66.71c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V66.71z"/>
			</g>
		</g>
		<g className="mouseOver uaeCount">
			<path class="st0" d="M323.83,133.47c0.18,0.11,2.5,0.37,3.38,0.41l0.49-1.23l0.92-1.17v-1.11h0.55l0.51-0.29
				c-0.21-0.9-0.25-1.81-0.4-1.82c-0.26-0.02-1.32,1.13-1.75,1.97c-0.43,0.84-1.69,1.49-2.77,1.3c-1.08-0.19-1.45,0.26-1.73,0.45
				c-0.07,0.05-0.19,0.01-0.31-0.08C322.84,132.75,323.66,133.37,323.83,133.47z"/>
				<g className="hoverEf uae">
			<g>
				<path d="M324.03,128.69v2.55c0,0.86,0.36,1.25,0.9,1.25c0.58,0,0.92-0.39,0.92-1.25v-2.55h0.8v2.5c0,1.35-0.69,1.95-1.75,1.95
					c-1.01,0-1.67-0.57-1.67-1.95v-2.51H324.03z"/>
				<path d="M328.44,131.83l-0.37,1.24h-0.83l1.41-4.38h1.02l1.42,4.38h-0.86l-0.4-1.24H328.44z M329.7,131.23l-0.35-1.08
					c-0.08-0.27-0.16-0.57-0.22-0.82h-0.01c-0.06,0.26-0.13,0.56-0.21,0.82l-0.34,1.08H329.7z"/>
				<path d="M334.15,131.13h-1.65v1.29h1.85v0.66h-2.65v-4.38h2.55v0.66h-1.75v1.13h1.65V131.13z"/>
			</g>
		</g>
		</g>
		<g className="hoverEf lithu">
			<g>
				<path d="M272.38,68.84h0.8v3.71h1.8v0.67h-2.6V68.84z"/>
				<path d="M276.4,69.18c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C276.23,68.74,276.4,68.93,276.4,69.18z M275.55,73.23v-3.16h0.8v3.16H275.55z"/>
				<path d="M278.13,69.15v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L278.13,69.15z"/>
				<path d="M279.5,68.61h0.8v1.88h0.01c0.1-0.15,0.23-0.27,0.4-0.36c0.16-0.09,0.35-0.14,0.55-0.14c0.54,0,1.11,0.36,1.11,1.37
					v1.86h-0.8v-1.78c0-0.46-0.17-0.81-0.61-0.81c-0.31,0-0.53,0.21-0.62,0.45c-0.03,0.07-0.04,0.15-0.04,0.24v1.89h-0.8V68.61z"/>
				<path d="M286.05,72.28c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V72.28z"/>
				<path d="M288.69,73.23l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H288.69z M288.59,71.68c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V71.68z"/>
				<path d="M290.22,71c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55c0.55,0,1.12,0.36,1.12,1.36
					v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V71z"/>
				<path d="M294.78,69.18c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C294.61,68.74,294.78,68.93,294.78,69.18z M293.93,73.23v-3.16h0.8v3.16H293.93z"/>
				<path d="M297.38,73.23l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H297.38z M297.27,71.68c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V71.68z"/>
			</g>
		</g>
		<g className="hoverEf denmark">
			<g>
				<path d="M246.26,70.67c0.36-0.06,0.8-0.09,1.28-0.09c0.82,0,1.38,0.17,1.79,0.51c0.43,0.35,0.69,0.87,0.69,1.61
					c0,0.79-0.27,1.37-0.68,1.74c-0.44,0.39-1.13,0.6-1.98,0.6c-0.47,0-0.82-0.02-1.08-0.06V70.67z M247.06,74.38
					c0.11,0.02,0.28,0.02,0.45,0.02c1.04,0,1.66-0.57,1.66-1.68c0-0.97-0.55-1.52-1.56-1.52c-0.25,0-0.43,0.02-0.55,0.05V74.38z"/>
				<path d="M251.23,73.63c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H251.23z
					 M252.62,73.07c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H252.62z"/>
				<path d="M254.02,72.76c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8V73.2c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V72.76z"/>
				<path d="M257.74,72.76c0-0.37-0.01-0.67-0.03-0.94h0.68l0.04,0.46h0.02c0.15-0.25,0.44-0.54,0.97-0.54
					c0.42,0,0.74,0.23,0.88,0.58h0.01c0.11-0.17,0.24-0.31,0.39-0.4c0.18-0.12,0.38-0.19,0.64-0.19c0.52,0,1.06,0.36,1.06,1.37v1.86
					h-0.78v-1.75c0-0.53-0.18-0.84-0.56-0.84c-0.27,0-0.47,0.19-0.56,0.42c-0.03,0.08-0.04,0.18-0.04,0.27v1.9h-0.78v-1.83
					c0-0.45-0.18-0.76-0.55-0.76c-0.3,0-0.5,0.23-0.58,0.45c-0.03,0.08-0.04,0.17-0.04,0.26v1.87h-0.78V72.76z"/>
				<path d="M265.03,74.99l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H265.03z M264.93,73.44c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V73.44z"/>
				<path d="M266.55,72.84c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V72.84z"/>
				<path d="M269.67,73.21h0.01c0.08-0.12,0.17-0.26,0.25-0.37l0.77-1.02h0.96l-1.17,1.29l1.34,1.87h-0.98l-0.91-1.4l-0.27,0.32
					v1.08h-0.8v-4.62h0.8V73.21z"/>
			</g>
		</g>
		
		<g className="hoverEf uk">
			<g>
				<path d="M234.82,69.75v2.55c0,0.86,0.36,1.25,0.9,1.25c0.58,0,0.92-0.39,0.92-1.25v-2.55h0.8v2.5c0,1.35-0.69,1.95-1.75,1.95
					c-1.01,0-1.67-0.57-1.67-1.95v-2.51H234.82z"/>
				<path d="M238.36,69.75h0.79v2.02h0.02c0.11-0.17,0.22-0.32,0.32-0.47l1.15-1.54h0.98l-1.51,1.86l1.61,2.52h-0.94l-1.24-2.01
					l-0.39,0.47v1.54h-0.79V69.75z"/>
			</g>
		</g>
		<g className="hoverEf croatia">
			<g>
				<path d="M296.7,87.46c-0.19,0.09-0.61,0.2-1.14,0.2c-1.39,0-2.24-0.87-2.24-2.21c0-1.44,1.01-2.31,2.35-2.31
					c0.53,0,0.9,0.11,1.06,0.19l-0.17,0.64c-0.2-0.09-0.5-0.17-0.85-0.17c-0.89,0-1.54,0.56-1.54,1.61c0,0.97,0.56,1.58,1.53,1.58
					c0.32,0,0.66-0.06,0.87-0.16L296.7,87.46z"/>
				<path d="M297.35,85.44c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V85.44z"/>
				<path d="M302.67,85.97c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
					C302.04,84.35,302.67,85.01,302.67,85.97z M300.28,86.01c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
					c0-0.5-0.22-1.06-0.77-1.06C300.51,84.93,300.28,85.48,300.28,86.01z"/>
				<path d="M305.11,87.59l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H305.11z M305,86.04c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V86.04z"/>
				<path d="M307.54,83.52v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L307.54,83.52z"/>
				<path d="M309.76,83.54c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C309.58,83.1,309.75,83.29,309.76,83.54z M308.91,87.59v-3.16h0.8v3.16H308.91z"/>
				<path d="M312.35,87.59l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H312.35z M312.25,86.04c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V86.04z"/>
			</g>
		</g>
		<g className="hoverEf hungary">
			<g>
				<path d="M273.27,76.67v1.75h1.85v-1.75h0.8v4.38h-0.8v-1.93h-1.85v1.93h-0.8v-4.38H273.27z"/>
				<path d="M279.64,80.1c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V80.1z"/>
				<path d="M280.51,78.82c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V78.82z"/>
				<path d="M287.12,77.88c-0.02,0.21-0.03,0.48-0.03,0.91v1.79c0,0.66-0.13,1.14-0.47,1.43c-0.33,0.29-0.79,0.38-1.22,0.38
					c-0.4,0-0.83-0.09-1.1-0.25l0.17-0.61c0.2,0.12,0.54,0.24,0.92,0.24c0.52,0,0.91-0.28,0.91-0.96v-0.27h-0.01
					c-0.18,0.28-0.5,0.46-0.92,0.46c-0.78,0-1.34-0.65-1.34-1.54c0-1.04,0.68-1.67,1.43-1.67c0.49,0,0.77,0.23,0.92,0.49h0.01
					l0.03-0.42H287.12z M286.29,79.15c0-0.08-0.01-0.15-0.03-0.22c-0.08-0.29-0.31-0.51-0.65-0.51c-0.44,0-0.78,0.39-0.78,1.03
					c0,0.54,0.27,0.97,0.77,0.97c0.3,0,0.56-0.19,0.65-0.49c0.03-0.08,0.04-0.19,0.04-0.28V79.15z"/>
				<path d="M289.73,81.05l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H289.73z M289.62,79.5c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V79.5z"/>
				<path d="M291.25,78.9c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V78.9z"/>
				<path d="M294.06,77.88l0.57,1.7c0.06,0.2,0.13,0.44,0.18,0.61h0.02c0.05-0.18,0.11-0.42,0.17-0.62l0.5-1.69h0.85l-0.79,2.16
					c-0.43,1.18-0.73,1.71-1.1,2.03c-0.32,0.27-0.64,0.38-0.84,0.4l-0.18-0.67c0.13-0.03,0.3-0.11,0.46-0.22
					c0.15-0.1,0.33-0.29,0.44-0.5c0.03-0.05,0.05-0.1,0.05-0.14c0-0.04-0.01-0.08-0.04-0.16l-1.16-2.91H294.06z"/>
			</g>
		</g>
		<g className="hoverEf belgium">
			<g>
				<path d="M238.49,86.4c0.25-0.05,0.72-0.09,1.17-0.09c0.59,0,0.96,0.08,1.25,0.28c0.27,0.17,0.45,0.45,0.45,0.82
					c0,0.4-0.25,0.77-0.73,0.94v0.02c0.46,0.12,0.89,0.48,0.89,1.08c0,0.39-0.17,0.69-0.42,0.9c-0.31,0.27-0.83,0.4-1.63,0.4
					c-0.45,0-0.78-0.03-0.98-0.06V86.4z M239.28,88.13h0.41c0.55,0,0.86-0.26,0.86-0.63c0-0.41-0.31-0.6-0.82-0.6
					c-0.24,0-0.37,0.02-0.46,0.04V88.13z M239.28,90.14c0.1,0.02,0.24,0.02,0.42,0.02c0.51,0,0.97-0.19,0.97-0.73
					c0-0.51-0.45-0.72-1-0.72h-0.4V90.14z"/>
				<path d="M242.77,89.36c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H242.77z
					 M244.16,88.8c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H244.16z"/>
				<path d="M245.56,86.1h0.8v4.62h-0.8V86.1z"/>
				<path d="M250.12,87.56c-0.02,0.21-0.03,0.48-0.03,0.91v1.79c0,0.66-0.13,1.14-0.47,1.43c-0.33,0.29-0.79,0.38-1.22,0.38
					c-0.4,0-0.83-0.09-1.1-0.25l0.17-0.61c0.2,0.12,0.54,0.24,0.92,0.24c0.52,0,0.91-0.28,0.91-0.96v-0.27h-0.01
					c-0.18,0.28-0.5,0.46-0.92,0.46c-0.78,0-1.34-0.65-1.34-1.54c0-1.04,0.68-1.67,1.43-1.67c0.49,0,0.77,0.23,0.92,0.49h0.01
					l0.03-0.42H250.12z M249.29,88.82c0-0.08-0.01-0.15-0.03-0.22c-0.08-0.29-0.31-0.51-0.65-0.51c-0.44,0-0.78,0.39-0.78,1.03
					c0,0.54,0.27,0.97,0.77,0.97c0.3,0,0.56-0.19,0.65-0.49c0.03-0.08,0.04-0.19,0.04-0.28V88.82z"/>
				<path d="M251.8,86.67c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C251.63,86.24,251.8,86.42,251.8,86.67z M250.96,90.72v-3.16h0.8v3.16H250.96z"/>
				<path d="M255.45,89.77c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V89.77z"/>
				<path d="M256.32,88.5c0-0.37-0.01-0.67-0.03-0.94h0.68l0.04,0.46h0.02c0.15-0.25,0.44-0.54,0.97-0.54
					c0.42,0,0.74,0.23,0.88,0.58h0.01c0.11-0.17,0.24-0.31,0.39-0.4c0.18-0.12,0.38-0.19,0.64-0.19c0.52,0,1.06,0.36,1.06,1.37v1.86
					h-0.78v-1.75c0-0.53-0.18-0.84-0.56-0.84c-0.27,0-0.47,0.19-0.56,0.42c-0.03,0.08-0.04,0.18-0.04,0.27v1.9h-0.78v-1.83
					c0-0.45-0.18-0.76-0.55-0.76c-0.3,0-0.5,0.23-0.58,0.45c-0.03,0.08-0.04,0.17-0.04,0.26v1.87h-0.78V88.5z"/>
			</g>
		</g>
		<g className="hoverEf nether">
			<g>
				<path d="M222.84,86.91v-4.38h0.91l1.13,1.88c0.29,0.48,0.54,0.99,0.75,1.47l0.02,0c-0.06-0.58-0.07-1.15-0.07-1.82v-1.53h0.74
					v4.38h-0.83l-1.14-1.93c-0.28-0.48-0.57-1.01-0.79-1.51l-0.02,0c0.03,0.57,0.04,1.15,0.04,1.87v1.57H222.84z"/>
				<path d="M227.77,85.55c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H227.77z
					 M229.16,84.99c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H229.16z"/>
				<path d="M231.48,82.84v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L231.48,82.84z"/>
				<path d="M232.84,82.3h0.8v1.88h0.01c0.1-0.15,0.23-0.27,0.4-0.36c0.16-0.09,0.35-0.14,0.55-0.14c0.54,0,1.11,0.36,1.11,1.37
					v1.86h-0.8v-1.78c0-0.46-0.17-0.81-0.61-0.81c-0.31,0-0.53,0.21-0.62,0.45c-0.03,0.07-0.04,0.15-0.04,0.24v1.89h-0.8V82.3z"/>
				<path d="M237.12,85.55c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H237.12z
					 M238.52,84.99c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H238.52z"/>
				<path d="M239.91,84.76c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V84.76z"/>
				<path d="M242.23,82.3h0.8v4.62h-0.8V82.3z"/>
				<path d="M245.68,86.91l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H245.68z M245.57,85.36c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V85.36z"/>
				<path d="M247.2,84.69c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55c0.55,0,1.12,0.36,1.12,1.36
					v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V84.69z"/>
				<path d="M253.83,82.3v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
					c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01V82.3H253.83z M253.03,85.07
					c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
					c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V85.07z"/>
				<path d="M254.66,86.18c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
					c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
					c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
					c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L254.66,86.18z"/>
			</g>
		</g>
		<g className="hoverEf luxem">
			<g>
				<path d="M254.2,90.04h0.8v3.71h1.8v0.67h-2.6V90.04z"/>
				<path d="M260.21,93.48c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V93.48z"/>
				<path d="M261.61,91.26l0.38,0.6c0.1,0.17,0.2,0.33,0.29,0.49h0.02c0.09-0.18,0.18-0.34,0.28-0.5l0.35-0.58h0.87l-1.06,1.52
					l1.08,1.65h-0.91l-0.38-0.63c-0.1-0.17-0.19-0.34-0.29-0.51h-0.02c-0.09,0.18-0.18,0.34-0.28,0.51l-0.37,0.63h-0.88l1.1-1.61
					l-1.06-1.55H261.61z"/>
				<path d="M264.85,93.06c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H264.85z
					 M266.24,92.5c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H266.24z"/>
				<path d="M267.64,92.2c0-0.37-0.01-0.67-0.03-0.94h0.68l0.04,0.46h0.02c0.15-0.25,0.44-0.54,0.97-0.54
					c0.42,0,0.74,0.23,0.88,0.58h0.01c0.11-0.17,0.24-0.31,0.39-0.4c0.18-0.12,0.38-0.19,0.64-0.19c0.52,0,1.06,0.36,1.06,1.37v1.86
					h-0.78v-1.75c0-0.53-0.18-0.84-0.56-0.84c-0.27,0-0.47,0.19-0.56,0.42c-0.03,0.08-0.04,0.18-0.04,0.27v1.9h-0.78v-1.83
					c0-0.45-0.18-0.76-0.55-0.76c-0.3,0-0.5,0.23-0.58,0.45c-0.03,0.08-0.04,0.17-0.04,0.26v1.87h-0.78V92.2z"/>
				<path d="M273.13,94.42c0.01-0.21,0.03-0.56,0.03-0.89v-3.73h0.8v1.89h0.01c0.19-0.31,0.54-0.51,1.01-0.51
					c0.77,0,1.33,0.64,1.32,1.6c0,1.14-0.72,1.7-1.44,1.7c-0.41,0-0.77-0.16-1-0.55h-0.01l-0.04,0.48H273.13z M273.95,93.11
					c0,0.07,0.01,0.13,0.02,0.19c0.08,0.32,0.36,0.56,0.71,0.56c0.5,0,0.8-0.4,0.8-1.04c0-0.56-0.27-1.01-0.8-1.01
					c-0.32,0-0.62,0.23-0.71,0.58c-0.01,0.06-0.03,0.13-0.03,0.21V93.11z"/>
				<path d="M279.96,92.81c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
					C279.33,91.19,279.96,91.85,279.96,92.81z M277.58,92.84c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
					c0-0.5-0.22-1.06-0.77-1.06C277.81,91.77,277.58,92.32,277.58,92.84z"/>
				<path d="M283.45,93.48c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V93.48z"/>
				<path d="M284.32,92.28c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V92.28z"/>
				<path d="M289.53,91.26c-0.02,0.21-0.03,0.48-0.03,0.91v1.79c0,0.66-0.13,1.14-0.47,1.43c-0.33,0.29-0.79,0.38-1.22,0.38
					c-0.4,0-0.83-0.09-1.1-0.25l0.17-0.61c0.2,0.12,0.54,0.24,0.92,0.24c0.52,0,0.91-0.28,0.91-0.96v-0.27h-0.01
					c-0.18,0.28-0.5,0.46-0.92,0.46c-0.78,0-1.34-0.65-1.34-1.54c0-1.04,0.68-1.67,1.43-1.67c0.49,0,0.77,0.23,0.92,0.49h0.01
					l0.03-0.42H289.53z M288.7,92.53c0-0.08-0.01-0.15-0.03-0.22c-0.08-0.29-0.31-0.51-0.65-0.51c-0.44,0-0.78,0.39-0.78,1.03
					c0,0.54,0.27,0.97,0.77,0.97c0.3,0,0.56-0.19,0.65-0.49c0.03-0.08,0.04-0.19,0.04-0.28V92.53z"/>
			</g>
		</g>
		<g className="hoverEf roma">
			<g>
				<path d="M282.75,96.16c0.3-0.06,0.75-0.09,1.21-0.09c0.63,0,1.06,0.11,1.35,0.35c0.24,0.2,0.38,0.5,0.38,0.88
					c0,0.56-0.38,0.95-0.8,1.09v0.02c0.32,0.12,0.5,0.42,0.61,0.85c0.14,0.55,0.26,1.05,0.35,1.22h-0.82
					c-0.07-0.13-0.17-0.49-0.29-1.03c-0.13-0.57-0.33-0.76-0.79-0.77h-0.4v1.8h-0.79V96.16z M283.54,98.09h0.47
					c0.54,0,0.88-0.29,0.88-0.72c0-0.48-0.34-0.7-0.85-0.7c-0.25,0-0.42,0.02-0.5,0.04V98.09z"/>
				<path d="M289.43,98.87c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
					C288.8,97.25,289.43,97.91,289.43,98.87z M287.04,98.9c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
					c0-0.5-0.22-1.06-0.77-1.06C287.27,97.83,287.04,98.38,287.04,98.9z"/>
				<path d="M290.09,98.26c0-0.37-0.01-0.67-0.03-0.94h0.68l0.04,0.46h0.02c0.15-0.25,0.44-0.54,0.97-0.54
					c0.42,0,0.74,0.23,0.88,0.58h0.01c0.11-0.17,0.24-0.31,0.39-0.4c0.18-0.12,0.38-0.19,0.64-0.19c0.52,0,1.06,0.36,1.06,1.37v1.86
					h-0.78v-1.75c0-0.53-0.18-0.84-0.56-0.84c-0.27,0-0.47,0.19-0.56,0.42c-0.03,0.08-0.04,0.18-0.04,0.27v1.9h-0.78v-1.83
					c0-0.45-0.18-0.76-0.55-0.76c-0.3,0-0.5,0.23-0.58,0.45c-0.03,0.08-0.04,0.17-0.04,0.26v1.87h-0.78V98.26z"/>
				<path d="M297.38,100.48l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H297.38z M297.27,98.93c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V98.93z"/>
				<path d="M298.9,98.26c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55c0.55,0,1.12,0.36,1.12,1.36
					v1.88h-0.8V98.7c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V98.26z"/>
				<path d="M303.47,96.43c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C303.29,96,303.46,96.19,303.47,96.43z M302.62,100.48v-3.16h0.8v3.16H302.62z"/>
				<path d="M306.06,100.48l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H306.06z M305.96,98.93c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V98.93z"/>
			</g>
		</g>
		
		<g className="hoverEf poland">
			<g>
				<path d="M262.36,82.59c0.29-0.05,0.69-0.09,1.22-0.09c0.58,0,1.01,0.12,1.29,0.36c0.26,0.21,0.43,0.56,0.43,0.97
					c0,0.42-0.13,0.76-0.37,0.99c-0.32,0.32-0.82,0.47-1.39,0.47c-0.15,0-0.28-0.01-0.39-0.03v1.65h-0.79V82.59z M263.15,84.63
					c0.1,0.03,0.23,0.04,0.39,0.04c0.6,0,0.96-0.3,0.96-0.81c0-0.48-0.34-0.74-0.89-0.74c-0.22,0-0.38,0.02-0.46,0.04V84.63z"/>
				<path d="M268.97,85.3c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
					C268.34,83.68,268.97,84.34,268.97,85.3z M266.59,85.33c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
					c0-0.5-0.22-1.06-0.77-1.06C266.82,84.26,266.59,84.81,266.59,85.33z"/>
				<path d="M269.63,82.3h0.8v4.62h-0.8V82.3z"/>
				<path d="M273.08,86.91l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H273.08z M272.98,85.36c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V85.36z"/>
				<path d="M274.61,84.69c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V84.69z"/>
				<path d="M281.24,82.3v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
					c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01V82.3H281.24z M280.44,85.07
					c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
					c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V85.07z"/>
			</g>
		</g>
		<g className="hoverEf slova">
			<g>
				<path d="M282.32,89.08c0.26,0.15,0.65,0.28,1.06,0.28c0.51,0,0.8-0.24,0.8-0.6c0-0.33-0.22-0.53-0.78-0.73
					c-0.72-0.26-1.19-0.64-1.19-1.27c0-0.72,0.6-1.26,1.55-1.26c0.48,0,0.82,0.1,1.05,0.22l-0.19,0.65
					c-0.16-0.08-0.46-0.21-0.88-0.21c-0.51,0-0.72,0.27-0.72,0.53c0,0.34,0.25,0.49,0.84,0.72c0.76,0.29,1.13,0.67,1.13,1.3
					c0,0.71-0.53,1.32-1.66,1.32c-0.46,0-0.94-0.13-1.18-0.28L282.32,89.08z"/>
				<path d="M285.68,85.34h0.8v4.62h-0.8V85.34z"/>
				<path d="M290.36,88.34c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
					C289.73,86.71,290.36,87.38,290.36,88.34z M287.97,88.37c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
					c0-0.5-0.22-1.06-0.77-1.06C288.2,87.3,287.97,87.84,287.97,88.37z"/>
				<path d="M291.52,86.79l0.51,1.61c0.09,0.28,0.16,0.53,0.22,0.79h0.02c0.06-0.26,0.13-0.51,0.21-0.79l0.5-1.61h0.84l-1.21,3.16
					h-0.79l-1.17-3.16H291.52z"/>
				<path d="M296.1,89.95l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24L294.32,87c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H296.1z M296,88.4c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V88.4z"/>
				<path d="M298.42,88.17h0.01c0.08-0.12,0.17-0.26,0.25-0.37l0.77-1.02h0.96l-1.17,1.29l1.34,1.87h-0.98l-0.91-1.4l-0.27,0.32
					v1.08h-0.8v-4.62h0.8V88.17z"/>
				<path d="M301.78,85.9c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C301.61,85.47,301.78,85.65,301.78,85.9z M300.93,89.95v-3.16h0.8v3.16H300.93z"/>
				<path d="M304.38,89.95l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24L302.6,87c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33v1.14
					c0,0.29,0.01,0.57,0.05,0.76H304.38z M304.27,88.4c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V88.4z"/>
			</g>
		</g>
		<g className="hoverEf germany">
			<g>
				<path d="M242.05,79.34c-0.28,0.1-0.83,0.24-1.41,0.24c-0.74,0-1.3-0.19-1.72-0.58c-0.39-0.37-0.62-0.95-0.62-1.61
					c0-1.39,0.99-2.28,2.44-2.28c0.54,0,0.96,0.11,1.16,0.21l-0.18,0.64c-0.24-0.11-0.53-0.19-1-0.19c-0.93,0-1.59,0.55-1.59,1.57
					c0,1,0.61,1.58,1.52,1.58c0.29,0,0.5-0.04,0.6-0.09v-1.09h-0.77v-0.63h1.55V79.34z"/>
				<path d="M243.4,78.18c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H243.4z
					 M244.79,77.62c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H244.79z"/>
				<path d="M246.19,77.4c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V77.4z"/>
				<path d="M248.51,77.32c0-0.37-0.01-0.67-0.03-0.94h0.68l0.04,0.46h0.02c0.15-0.25,0.44-0.54,0.97-0.54
					c0.42,0,0.74,0.23,0.88,0.58h0.01c0.11-0.17,0.24-0.31,0.39-0.4c0.18-0.12,0.38-0.19,0.64-0.19c0.52,0,1.06,0.36,1.06,1.37v1.86
					h-0.78v-1.75c0-0.53-0.18-0.84-0.56-0.84c-0.27,0-0.47,0.19-0.56,0.42c-0.03,0.08-0.04,0.18-0.04,0.27v1.9h-0.78v-1.83
					c0-0.45-0.18-0.76-0.55-0.76c-0.3,0-0.5,0.23-0.58,0.45c-0.03,0.08-0.04,0.17-0.04,0.26v1.87h-0.78V77.32z"/>
				<path d="M255.8,79.54l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H255.8z M255.69,77.99c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V77.99z"/>
				<path d="M257.32,77.32c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V77.32z"/>
				<path d="M261.54,76.38l0.57,1.7c0.06,0.2,0.13,0.44,0.18,0.61h0.02c0.05-0.18,0.11-0.42,0.17-0.62l0.5-1.69h0.85l-0.79,2.16
					c-0.43,1.18-0.73,1.71-1.1,2.03c-0.31,0.27-0.64,0.38-0.84,0.4l-0.18-0.67c0.13-0.03,0.3-0.11,0.46-0.22
					c0.15-0.1,0.33-0.29,0.44-0.5c0.03-0.05,0.05-0.1,0.05-0.14c0-0.04-0.01-0.08-0.04-0.16l-1.16-2.91H261.54z"/>
			</g>
		</g>
		
		
		<g className="hoverEf greece">
			<g>
				<path d="M271.96,110.24c-0.28,0.1-0.83,0.24-1.41,0.24c-0.74,0-1.3-0.19-1.72-0.58c-0.39-0.37-0.62-0.95-0.62-1.61
					c0-1.39,0.99-2.28,2.44-2.28c0.54,0,0.96,0.11,1.16,0.21l-0.18,0.64c-0.24-0.11-0.53-0.19-1-0.19c-0.93,0-1.59,0.55-1.59,1.57
					c0,1,0.61,1.58,1.52,1.58c0.29,0,0.5-0.04,0.6-0.09v-1.09h-0.77v-0.63h1.55V110.24z"/>
				<path d="M272.75,108.29c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V108.29z"/>
				<path d="M275.62,109.08c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H275.62z
					 M277.02,108.52c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H277.02z"/>
				<path d="M278.98,109.08c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H278.98z
					 M280.37,108.52c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H280.37z"/>
				<path d="M284.09,110.34c-0.17,0.08-0.5,0.16-0.89,0.16c-0.99,0-1.63-0.64-1.63-1.62c0-0.95,0.65-1.68,1.76-1.68
					c0.3,0,0.59,0.06,0.77,0.14l-0.14,0.6c-0.13-0.06-0.32-0.12-0.61-0.12c-0.61,0-0.97,0.45-0.97,1.03c0,0.65,0.43,1.02,0.97,1.02
					c0.27,0,0.47-0.06,0.63-0.12L284.09,110.34z"/>
				<path d="M285.25,109.08c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H285.25z
					 M286.64,108.52c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H286.64z"/>
			</g>
		</g>
		
		<g className="hoverEf italy">
			<g>
				<path d="M258.6,101.12v4.38h-0.8v-4.38H258.6z"/>
				<path d="M260.41,101.43v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L260.41,101.43z"/>
				<path d="M263.55,105.5l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H263.55z M263.45,103.95c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V103.95z"/>
				<path d="M265.07,100.88h0.8v4.62h-0.8V100.88z"/>
				<path d="M267.24,102.33l0.57,1.7c0.06,0.2,0.13,0.44,0.18,0.61h0.02c0.05-0.18,0.11-0.42,0.17-0.62l0.5-1.69h0.85l-0.79,2.16
					c-0.43,1.18-0.73,1.71-1.1,2.03c-0.31,0.27-0.64,0.38-0.84,0.4l-0.18-0.67c0.13-0.03,0.3-0.11,0.46-0.22
					c0.15-0.1,0.33-0.29,0.44-0.5c0.03-0.05,0.05-0.1,0.05-0.14c0-0.04-0.01-0.08-0.04-0.16l-1.16-2.91H267.24z"/>
			</g>
		</g>
		<g className="hoverEf switz">
			<g>
				<path d="M246.7,100.84c0.26,0.15,0.65,0.28,1.06,0.28c0.51,0,0.8-0.24,0.8-0.6c0-0.33-0.22-0.53-0.78-0.73
					c-0.72-0.26-1.19-0.64-1.19-1.27c0-0.72,0.6-1.26,1.55-1.26c0.48,0,0.82,0.1,1.05,0.22L249,98.12
					c-0.16-0.08-0.46-0.21-0.88-0.21c-0.51,0-0.72,0.27-0.72,0.53c0,0.34,0.25,0.49,0.84,0.72c0.76,0.29,1.13,0.67,1.13,1.3
					c0,0.71-0.53,1.32-1.66,1.32c-0.46,0-0.94-0.13-1.18-0.28L246.7,100.84z"/>
				<path d="M250.54,98.55l0.33,1.43c0.08,0.34,0.15,0.68,0.21,1.03h0.01c0.06-0.34,0.16-0.69,0.25-1.02l0.42-1.44h0.65l0.4,1.4
					c0.1,0.37,0.19,0.71,0.25,1.06h0.01c0.05-0.34,0.12-0.69,0.21-1.06l0.35-1.4h0.79l-0.99,3.16h-0.74l-0.38-1.29
					c-0.1-0.33-0.17-0.63-0.24-1.01h-0.01c-0.07,0.39-0.15,0.7-0.24,1.01l-0.4,1.29h-0.75l-0.94-3.16H250.54z"/>
				<path d="M255.78,97.66c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C255.6,97.23,255.77,97.42,255.78,97.66z M254.93,101.71v-3.16h0.8v3.16H254.93z"/>
				<path d="M257.51,97.64v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L257.51,97.64z"/>
				<path d="M258.57,101.25l1.19-1.52c0.16-0.2,0.3-0.35,0.46-0.53v-0.01h-1.54v-0.64h2.51l0,0.49l-1.16,1.49
					c-0.16,0.19-0.3,0.37-0.47,0.54v0.01h1.67v0.64h-2.67V101.25z"/>
				<path d="M262.36,100.35c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H262.36z
					 M263.75,99.79c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H263.75z"/>
				<path d="M265.15,99.56c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V99.56z"/>
				<path d="M267.47,97.1h0.8v4.62h-0.8V97.1z"/>
				<path d="M270.92,101.71l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H270.92z M270.81,100.16c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V100.16z"/>
				<path d="M272.44,99.49c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V99.49z"/>
				<path d="M279.07,97.1v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
					c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01V97.1H279.07z M278.27,99.86
					c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
					c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V99.86z"/>
			</g>
		</g>
		<g className="hoverEf bahrani">
			<g>
				<path d="M307.59,123.98c0.25-0.05,0.72-0.09,1.17-0.09c0.59,0,0.96,0.08,1.25,0.28c0.27,0.17,0.45,0.45,0.45,0.82
					c0,0.4-0.25,0.77-0.73,0.94v0.02c0.46,0.12,0.89,0.48,0.89,1.08c0,0.39-0.17,0.69-0.42,0.9c-0.31,0.27-0.83,0.4-1.63,0.4
					c-0.45,0-0.78-0.03-0.98-0.06V123.98z M308.39,125.71h0.41c0.55,0,0.86-0.26,0.86-0.63c0-0.41-0.31-0.6-0.82-0.6
					c-0.24,0-0.37,0.02-0.46,0.04V125.71z M308.39,127.72c0.1,0.02,0.24,0.02,0.42,0.02c0.51,0,0.97-0.19,0.97-0.73
					c0-0.51-0.45-0.72-1-0.72h-0.4V127.72z"/>
				<path d="M313.09,128.3l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H313.09z M312.98,126.75c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V126.75z"/>
				<path d="M314.61,123.68h0.8v1.88h0.01c0.1-0.15,0.23-0.27,0.4-0.36c0.16-0.09,0.35-0.14,0.55-0.14c0.54,0,1.11,0.36,1.11,1.37
					v1.86h-0.8v-1.78c0-0.46-0.17-0.81-0.61-0.81c-0.31,0-0.53,0.21-0.62,0.45c-0.03,0.07-0.04,0.15-0.04,0.24v1.89h-0.8V123.68z"/>
				<path d="M318.33,126.15c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V126.15z"/>
				<path d="M322.42,128.3l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H322.42z M322.32,126.75c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V126.75z"/>
				<path d="M324.79,124.25c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C324.62,123.82,324.79,124,324.79,124.25z M323.94,128.3v-3.16h0.8v3.16H323.94z"/>
				<path d="M325.61,126.07c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V126.07z"/>
			</g>
		</g>
		
	
		<g className="hoverEf somali">
			<g>
				<path d="M289.15,146.4c0.26,0.15,0.65,0.28,1.06,0.28c0.51,0,0.8-0.24,0.8-0.6c0-0.33-0.22-0.53-0.78-0.73
					c-0.72-0.26-1.19-0.64-1.19-1.27c0-0.72,0.6-1.26,1.55-1.26c0.48,0,0.82,0.1,1.05,0.22l-0.19,0.65
					c-0.16-0.08-0.46-0.21-0.88-0.21c-0.51,0-0.72,0.27-0.72,0.53c0,0.34,0.25,0.49,0.84,0.72c0.76,0.29,1.13,0.67,1.13,1.3
					c0,0.71-0.53,1.32-1.66,1.32c-0.46,0-0.94-0.13-1.18-0.28L289.15,146.4z"/>
				<path d="M295.52,145.66c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
					C294.89,144.04,295.52,144.7,295.52,145.66z M293.13,145.7c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
					c0-0.5-0.22-1.06-0.77-1.06C293.36,144.62,293.13,145.17,293.13,145.7z"/>
				<path d="M296.18,145.05c0-0.37-0.01-0.67-0.03-0.94h0.68l0.04,0.46h0.02c0.15-0.25,0.44-0.54,0.97-0.54
					c0.42,0,0.74,0.23,0.88,0.58h0.01c0.11-0.17,0.24-0.31,0.39-0.4c0.18-0.12,0.38-0.19,0.64-0.19c0.52,0,1.06,0.36,1.06,1.37v1.86
					h-0.78v-1.75c0-0.53-0.18-0.84-0.56-0.84c-0.27,0-0.47,0.19-0.56,0.42c-0.03,0.08-0.04,0.18-0.04,0.27v1.9h-0.78v-1.83
					c0-0.45-0.18-0.76-0.55-0.76c-0.3,0-0.5,0.23-0.58,0.45c-0.03,0.08-0.04,0.17-0.04,0.26v1.87h-0.78V145.05z"/>
				<path d="M303.47,147.28l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H303.47z M303.36,145.73c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V145.73z"/>
				<path d="M304.99,142.66h0.8v4.62h-0.8V142.66z"/>
				<path d="M307.51,143.23c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C307.33,142.79,307.51,142.98,307.51,143.23z M306.66,147.28v-3.16h0.8v3.16H306.66z"/>
				<path d="M308.32,142.66h0.8v4.62h-0.8V142.66z"/>
				<path d="M311.78,147.28l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H311.78z M311.67,145.73c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V145.73z"/>
				<path d="M313.3,145.05c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V145.05z"/>
				<path d="M319.93,142.66v3.73c0,0.32,0.01,0.67,0.03,0.89h-0.71l-0.04-0.5h-0.01c-0.19,0.35-0.57,0.57-1.03,0.57
					c-0.76,0-1.35-0.64-1.35-1.62c0-1.06,0.66-1.69,1.42-1.69c0.43,0,0.75,0.18,0.89,0.41h0.01v-1.79H319.93z M319.13,145.43
					c0-0.06,0-0.14-0.02-0.21c-0.07-0.31-0.33-0.56-0.69-0.56c-0.52,0-0.8,0.46-0.8,1.04c0,0.58,0.29,1.01,0.79,1.01
					c0.33,0,0.61-0.22,0.69-0.57c0.02-0.07,0.02-0.15,0.02-0.23V145.43z"/>
			</g>
		</g>
		
		
		
		<g className="hoverEf kuwait">
			<g>
				<path d="M308.23,118.45h0.79v2.02h0.02c0.11-0.17,0.22-0.32,0.32-0.47l1.15-1.54h0.98l-1.51,1.86l1.61,2.52h-0.94l-1.24-2.01
					l-0.39,0.47v1.54h-0.79V118.45z"/>
				<path d="M314.81,121.89c0,0.38,0.01,0.69,0.03,0.95h-0.7l-0.04-0.48h-0.02c-0.13,0.22-0.45,0.55-1.01,0.55
					c-0.58,0-1.1-0.35-1.1-1.38v-1.86h0.8v1.72c0,0.53,0.17,0.86,0.59,0.86c0.32,0,0.53-0.22,0.61-0.43
					c0.03-0.07,0.04-0.16,0.04-0.25v-1.91h0.8V121.89z"/>
				<path d="M316.16,119.67l0.33,1.43c0.08,0.34,0.15,0.68,0.21,1.03h0.01c0.06-0.34,0.16-0.69,0.25-1.02l0.42-1.44h0.65l0.4,1.4
					c0.1,0.37,0.19,0.71,0.25,1.06h0.01c0.05-0.34,0.12-0.69,0.21-1.06l0.35-1.4h0.79l-0.99,3.16h-0.74l-0.38-1.29
					c-0.1-0.33-0.17-0.63-0.24-1.01h-0.01c-0.07,0.39-0.15,0.7-0.24,1.01l-0.4,1.29h-0.75l-0.94-3.16H316.16z"/>
				<path d="M322.33,122.84l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H322.33z M322.22,121.29c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V121.29z"/>
				<path d="M324.7,118.79c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C324.52,118.35,324.7,118.54,324.7,118.79z M323.85,122.84v-3.16h0.8v3.16H323.85z"/>
				<path d="M326.43,118.76v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L326.43,118.76z"/>
			</g>
		</g>
		
		<g className="hoverEf israel">
			<g>
				<path d="M292.48,117.79v4.38h-0.8v-4.38H292.48z"/>
				<path d="M293.33,121.43c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
					c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
					c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
					c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L293.33,121.43z"/>
				<path d="M296.08,120.02c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V120.02z"/>
				<path d="M300.18,122.17l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H300.18z M300.07,120.62c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V120.62z"/>
				<path d="M302.26,120.81c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H302.26z
					 M303.65,120.25c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H303.65z"/>
				<path d="M305.05,117.55h0.8v4.62h-0.8V117.55z"/>
			</g>
		</g>
		
		
		
		<g className="hoverEf sing">
			<g>
				<path d="M381.43,164.75c0.26,0.15,0.65,0.28,1.06,0.28c0.51,0,0.8-0.24,0.8-0.6c0-0.33-0.22-0.53-0.78-0.73
					c-0.72-0.26-1.19-0.64-1.19-1.27c0-0.72,0.6-1.26,1.55-1.26c0.48,0,0.82,0.1,1.05,0.22l-0.19,0.65
					c-0.16-0.08-0.46-0.21-0.88-0.21c-0.51,0-0.72,0.27-0.72,0.53c0,0.34,0.25,0.49,0.84,0.72c0.76,0.29,1.13,0.67,1.13,1.3
					c0,0.71-0.53,1.32-1.66,1.32c-0.46,0-0.94-0.13-1.18-0.28L381.43,164.75z"/>
				<path d="M385.64,161.57c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C385.46,161.14,385.64,161.32,385.64,161.57z M384.79,165.62v-3.16h0.8v3.16H384.79z"/>
				<path d="M386.45,163.4c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.24,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.06-0.04,0.16-0.04,0.25v1.86h-0.8V163.4z"/>
				<path d="M393.06,162.46c-0.02,0.21-0.03,0.48-0.03,0.91v1.79c0,0.66-0.13,1.14-0.47,1.44c-0.33,0.29-0.79,0.38-1.22,0.38
					c-0.4,0-0.83-0.09-1.1-0.25l0.17-0.61c0.2,0.12,0.54,0.24,0.92,0.24c0.52,0,0.91-0.28,0.91-0.96v-0.27h-0.01
					c-0.18,0.28-0.5,0.46-0.92,0.46c-0.78,0-1.34-0.65-1.34-1.54c0-1.04,0.68-1.67,1.43-1.67c0.49,0,0.77,0.23,0.92,0.49h0.01
					l0.03-0.42H393.06z M392.24,163.72c0-0.08-0.01-0.15-0.03-0.21c-0.08-0.29-0.31-0.52-0.65-0.52c-0.44,0-0.78,0.39-0.78,1.04
					c0,0.54,0.27,0.97,0.77,0.97c0.3,0,0.56-0.2,0.65-0.49c0.03-0.08,0.04-0.19,0.04-0.28V163.72z"/>
				<path d="M395.68,165.62l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.14,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.34
					v1.14c0,0.29,0.01,0.57,0.05,0.76H395.68z M395.57,164.07c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V164.07z"/>
				<path d="M397.2,163.5c0-0.41-0.01-0.75-0.03-1.05h0.7l0.04,0.49h0.01c0.23-0.36,0.6-0.56,1.08-0.56c0.72,0,1.33,0.62,1.33,1.61
					c0,1.15-0.72,1.7-1.44,1.7c-0.4,0-0.73-0.17-0.89-0.42H398v1.64h-0.8V163.5z M398,164.3c0,0.08,0.01,0.15,0.02,0.22
					c0.08,0.32,0.36,0.56,0.7,0.56c0.51,0,0.81-0.42,0.81-1.04c0-0.56-0.27-1.02-0.79-1.02c-0.33,0-0.63,0.24-0.71,0.59
					c-0.02,0.06-0.03,0.13-0.03,0.2V164.3z"/>
				<path d="M404.01,164.01c0,1.16-0.82,1.69-1.62,1.69c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
					C403.38,162.38,404.01,163.05,404.01,164.01z M401.62,164.04c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
					c0-0.5-0.22-1.06-0.77-1.06C401.85,162.97,401.62,163.51,401.62,164.04z"/>
				<path d="M404.67,163.47c0-0.42-0.01-0.74-0.03-1.02h0.69l0.03,0.6h0.02c0.16-0.45,0.53-0.67,0.87-0.67c0.08,0,0.12,0,0.19,0.02
					v0.75c-0.07-0.01-0.14-0.02-0.23-0.02c-0.38,0-0.65,0.24-0.72,0.6c-0.01,0.07-0.02,0.16-0.02,0.24v1.64h-0.8V163.47z"/>
				<path d="M407.55,164.26c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H407.55z
					 M408.94,163.7c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H408.94z"/>
			</g>
		</g>
		
		
		<g className="hoverEf india">
			<g>
			<path d="M347.9,134.8v1.8h1.9v-1.8h0.8v4.4h-0.8v-1.9h-1.9v1.9h-0.8v-4.4L347.9,134.8L347.9,134.8z"/>
		<path d="M352,136l0.6,1.7c0.1,0.2,0.1,0.4,0.2,0.6h0c0-0.2,0.1-0.4,0.2-0.6l0.5-1.7h0.9l-0.8,2.2c-0.4,1.2-0.7,1.7-1.1,2
			c-0.3,0.3-0.6,0.4-0.8,0.4l-0.2-0.7c0.1,0,0.3-0.1,0.5-0.2c0.1-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.1s0-0.1,0-0.2l-1.2-2.9
			L352,136L352,136z"/>
		<path d="M357.6,134.6v3.7c0,0.3,0,0.7,0,0.9H357l0-0.5h0c-0.2,0.4-0.6,0.6-1,0.6c-0.8,0-1.4-0.6-1.4-1.6c0-1.1,0.7-1.7,1.4-1.7
			c0.4,0,0.8,0.2,0.9,0.4h0v-1.8C356.9,134.6,357.6,134.6,357.6,134.6z M356.8,137.3c0-0.1,0-0.1,0-0.2c-0.1-0.3-0.3-0.6-0.7-0.6
			c-0.5,0-0.8,0.5-0.8,1c0,0.6,0.3,1,0.8,1c0.3,0,0.6-0.2,0.7-0.6c0-0.1,0-0.1,0-0.2L356.8,137.3L356.8,137.3z"/>
		<path d="M359.1,137.8c0,0.6,0.5,0.8,1,0.8c0.4,0,0.6-0.1,0.9-0.1l0.1,0.6c-0.3,0.1-0.6,0.2-1.1,0.2c-1,0-1.6-0.6-1.6-1.6
			c0-0.9,0.5-1.7,1.5-1.7c1,0,1.4,0.9,1.4,1.5c0,0.1,0,0.3,0,0.3H359.1z M360.5,137.3c0-0.3-0.1-0.8-0.7-0.8c-0.5,0-0.7,0.4-0.7,0.8
			H360.5z"/>
		<path d="M361.9,137c0-0.4,0-0.7,0-1h0.7l0,0.6h0c0.2-0.4,0.5-0.7,0.9-0.7c0.1,0,0.1,0,0.2,0v0.7c-0.1,0-0.1,0-0.2,0
			c-0.4,0-0.6,0.2-0.7,0.6c0,0.1,0,0.2,0,0.2v1.6h-0.8V137z"/>
		<path d="M365.9,139.2l0-0.4h0c-0.2,0.2-0.5,0.4-0.9,0.4c-0.6,0-1-0.5-1-0.9c0-0.8,0.7-1.2,1.9-1.2v-0.1c0-0.2-0.1-0.6-0.6-0.6
			c-0.3,0-0.6,0.1-0.9,0.2l-0.2-0.5c0.2-0.1,0.6-0.3,1.1-0.3c1,0,1.3,0.6,1.3,1.3v1.1c0,0.3,0,0.6,0,0.8L365.9,139.2L365.9,139.2z
			 M365.8,137.6c-0.6,0-1.1,0.1-1.1,0.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.6-0.4c0-0.1,0-0.1,0-0.2L365.8,137.6L365.8,137.6z"
			/>
		<path d="M367.4,139.2c0-0.2,0-0.6,0-0.9v-3.7h0.8v1.9h0c0.2-0.3,0.5-0.5,1-0.5c0.8,0,1.3,0.6,1.3,1.6c0,1.1-0.7,1.7-1.4,1.7
			c-0.4,0-0.8-0.2-1-0.6h0l0,0.5L367.4,139.2L367.4,139.2z M368.3,137.9c0,0.1,0,0.1,0,0.2c0.1,0.3,0.4,0.6,0.7,0.6
			c0.5,0,0.8-0.4,0.8-1c0-0.6-0.3-1-0.8-1c-0.3,0-0.6,0.2-0.7,0.6c0,0.1,0,0.1,0,0.2L368.3,137.9L368.3,137.9z"/>
		<path d="M373.1,139.2l0-0.4h0c-0.2,0.2-0.5,0.4-0.9,0.4c-0.6,0-1-0.5-1-0.9c0-0.8,0.7-1.2,1.9-1.2v-0.1c0-0.2-0.1-0.6-0.6-0.6
			c-0.3,0-0.6,0.1-0.9,0.2l-0.2-0.5c0.2-0.1,0.6-0.3,1.1-0.3c1,0,1.3,0.6,1.3,1.3v1.1c0,0.3,0,0.6,0,0.8L373.1,139.2L373.1,139.2z
			 M372.9,137.6c-0.6,0-1.1,0.1-1.1,0.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.6-0.4c0-0.1,0-0.1,0-0.2L372.9,137.6L372.9,137.6z"
			/>
		<path d="M377.5,134.6v3.7c0,0.3,0,0.7,0,0.9h-0.7l0-0.5h0c-0.2,0.4-0.6,0.6-1,0.6c-0.8,0-1.4-0.6-1.4-1.6c0-1.1,0.7-1.7,1.4-1.7
			c0.4,0,0.8,0.2,0.9,0.4h0v-1.8C376.7,134.6,377.5,134.6,377.5,134.6z M376.7,137.3c0-0.1,0-0.1,0-0.2c-0.1-0.3-0.3-0.6-0.7-0.6
			c-0.5,0-0.8,0.5-0.8,1c0,0.6,0.3,1,0.8,1c0.3,0,0.6-0.2,0.7-0.6c0-0.1,0-0.1,0-0.2L376.7,137.3L376.7,137.3z"/>
			</g>
		</g>
		
		
		
		
		
		<g className="mouseOver vietnamCount">
      <path class="lightBlue" d="M403.06,135.71c-0.4-0.06-1.44-0.81-1.44-1.1c0-0.29,0.52-0.75-0.4-0.87c-0.92-0.12-1.04-0.46-1.21-0.64
				c-0.17-0.17-0.93,0.35-1.16,0.75c-0.23,0.4-0.46-0.12-0.98,0.17c-0.52,0.29-1.04-0.4-1.27,0c-0.23,0.4-0.81-0.23-1.1,0.12
				c-0.11,0.13-0.25,0.24-0.4,0.31c0.25,0.28,0.53,0.56,0.53,0.77c0,0.37,0.74,0.65,0.74,1.11c0,0.46,0.93,0.84,1.21,0.65
				c0.28-0.19,0.93-0.37,1.02,0.28c0.09,0.65,0.46,0.65,0.46,1.11c0,0.46-0.93,0.19-1.11,0.65c-0.19,0.46,1.58,1.02,1.58,1.39
				c0,0.37,0.56,0.83,0.74,1.3c0.19,0.46,1.02,1.02,1.2,1.58c0.19,0.56,0.93,0.83,1.21,1.11c0.28,0.28,0.37,0.93,0.28,1.48
				c-0.09,0.56-0.09,1.58,0.46,2.41c0.56,0.84-0.65,1.2-1.02,1.67c-0.37,0.46-0.19,1.11-1.3,0.83c-1.11-0.28,0.19,0.93,0,1.3
				c-0.19,0.37-1.67-0.37-1.67,0c0,0.22-0.5,0.51-0.9,0.71c0.16,0.21,0.38,0.32,0.65,0.49c0.35,0.22-0.43,0.58-0.41,1.28
				c0.02,0.69,0.54,0.87,0.73,0.58c0.19-0.28,1-0.91,1.32-1.02c0.32-0.11,0-0.58,0.35-0.56c0.35,0.02,0.24-0.5,0.39-0.78
				c0.15-0.28,0.39-0.17,0.91-0.15c0.52,0.02,1.25-0.48,1.99-0.95c0.74-0.48,0.87-0.58,1.15-1.95c0.28-1.36-0.32-2.99-0.35-3.4
				c-0.02-0.41-1.02-2.31-1.38-2.29c-0.37,0.02-2.05-1.71-2.36-1.99c-0.3-0.28-0.22-0.76-0.65-1.13c-0.43-0.37-0.48-1.32-0.37-1.99
				c0.11-0.67,1.06-0.82,1.04-1.28c-0.02-0.45,0.09-0.84,0.56-0.86c0.48-0.02,0.93-0.41,1.15-0.76c0.03-0.05,0.08-0.08,0.12-0.11
				C403.24,135.81,403.13,135.72,403.06,135.71z"/>
      <g className="hoverEf viet">
			<g>
				<path d="M401.87,144.72l-1.42-4.38h0.87l0.6,1.99c0.17,0.55,0.31,1.07,0.43,1.59h0.01c0.12-0.52,0.28-1.05,0.45-1.58l0.64-2
					h0.85l-1.51,4.38H401.87z"/>
				<path d="M405.6,140.67c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C405.42,140.24,405.59,140.43,405.6,140.67z M404.75,144.72v-3.16h0.8v3.16H404.75z"/>
				<path d="M406.98,143.36c0.02,0.57,0.46,0.82,0.98,0.82c0.37,0,0.63-0.06,0.87-0.15l0.12,0.55c-0.27,0.12-0.65,0.2-1.11,0.2
					c-1.03,0-1.63-0.63-1.63-1.6c0-0.87,0.53-1.7,1.55-1.7c1.03,0,1.36,0.85,1.36,1.54c0,0.15-0.01,0.27-0.03,0.34H406.98z
					 M408.37,142.8c0-0.29-0.12-0.77-0.66-0.77c-0.49,0-0.7,0.45-0.73,0.77H408.37z"/>
				<path d="M410.68,140.65v0.91h0.76v0.6h-0.76v1.4c0,0.39,0.1,0.58,0.41,0.58c0.14,0,0.24-0.02,0.31-0.04l0.02,0.61
					c-0.12,0.04-0.33,0.08-0.58,0.08c-0.29,0-0.54-0.1-0.69-0.26c-0.17-0.18-0.25-0.47-0.25-0.89v-1.49h-0.45v-0.6h0.45v-0.72
					L410.68,140.65z"/>
				<path d="M412.05,142.5c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
					c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
					c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V142.5z"/>
				<path d="M417.55,144.72l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H417.55z M417.44,143.17c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V143.17z"/>
				<path d="M419.07,142.5c0-0.37-0.01-0.67-0.03-0.94h0.68l0.04,0.46h0.02c0.15-0.25,0.44-0.54,0.97-0.54
					c0.42,0,0.74,0.23,0.88,0.58h0.01c0.11-0.17,0.24-0.31,0.39-0.4c0.18-0.12,0.38-0.19,0.64-0.19c0.52,0,1.06,0.36,1.06,1.37v1.86
					h-0.78v-1.75c0-0.53-0.18-0.84-0.56-0.84c-0.27,0-0.47,0.19-0.56,0.42c-0.03,0.08-0.04,0.18-0.04,0.27v1.9h-0.78v-1.83
					c0-0.45-0.18-0.76-0.55-0.76c-0.3,0-0.5,0.23-0.58,0.45c-0.03,0.08-0.04,0.17-0.04,0.26v1.87h-0.78V142.5z"/>
			</g>
		</g>
    </g>	
	<g className="mouseOver malaysiaCount">
    	<path class="lightBlue" d="M397.01,161.32c0.02-0.97-0.91-0.86-1.13-1.53c-0.22-0.67-0.58-0.28-0.71-0.71
				c-0.02-0.06-0.06-0.14-0.11-0.21c-0.49,0.16-0.31,0.56-0.45,0.59c-0.18,0.04-1.15,0.15-1.15-0.14c0-0.29-0.14-0.76-0.72-0.86
				c-0.29-0.06-0.59-0.07-0.85-0.06c0.35,0.77,0.39,1.59,0.41,2.24c0.02,0.87,1.3,2.25,1.49,2.81c0.19,0.56,0.56,0.74,1.41,1.34
				c0.84,0.61,2.49,1.58,2.81,1.43c0.32-0.15-0.5-1.58-0.97-2.08C396.55,163.64,396.98,162.29,397.01,161.32z M419.14,159.66
				c-0.39,0.09-1.3,0.04-1.3-0.35c0-0.39-0.82-1.12-1.34-1.12c-0.52,0-1.13,1.04-1.17,1.47c-0.04,0.43-0.87,0.39-0.78,0.99
				c0.03,0.24-0.12,0.34-0.32,0.4c0.13,0.52,0.21,0.9,0.01,0.85c-0.35-0.09-0.43,0.35-0.95,0.35c-0.18,0-0.48-0.45-0.79-1.03
				c-0.01,0-0.02-0.01-0.03-0.01c-0.35,0-1.12,1.21-1.3,2.03c-0.17,0.82-0.95,0.56-2.12,0.65c-1.17,0.09-0.69,1.3-0.95,1.86
				c-0.24,0.51-1.52-0.42-2.17-0.55c0.11,0.46,0.26,0.94,0.44,0.94c0.35,0,0.87,0.87,1.21,0.6c0.35-0.26,1.38,0,1.9-0.17
				c0.52-0.17,0.61-0.61,1.47-0.61c0.86,0,0.52,0.69,1.38,0.26c0.87-0.43,1.39-0.09,1.3-0.87c-0.09-0.78,0.52-0.78,0.43-1.38
				c-0.09-0.61,0.95-0.17,0.86-1.3c-0.09-1.12,1.56-0.95,2.25-0.95c0.16,0,0.41,0.09,0.69,0.24c0.51-0.25,1.25-0.58,0.91-0.88
				c-0.39-0.35,1.17-0.04,1.34-0.56C420.31,160,419.53,159.57,419.14,159.66z"/>
		<g className="hoverEf mala">
			<g>
				<path d="M416.28,162.4c-0.03-0.57-0.06-1.25-0.05-1.84h-0.02c-0.14,0.54-0.33,1.13-0.52,1.68l-0.64,1.9h-0.62l-0.58-1.87
					c-0.17-0.56-0.33-1.16-0.44-1.71h-0.01c-0.02,0.57-0.04,1.27-0.08,1.87l-0.1,1.76h-0.75l0.29-4.38h1.05l0.57,1.76
					c0.16,0.52,0.3,1.04,0.4,1.53h0.02c0.12-0.48,0.27-1.01,0.44-1.53l0.61-1.76h1.04l0.25,4.38h-0.78L416.28,162.4z"/>
				<path d="M419.71,164.19l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H419.71z M419.61,162.64c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V162.64z"/>
				<path d="M421.23,159.57h0.8v4.62h-0.8V159.57z"/>
				<path d="M424.68,164.19l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H424.68z M424.58,162.64c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V162.64z"/>
				<path d="M426.7,161.02l0.57,1.7c0.06,0.2,0.13,0.44,0.18,0.61h0.02c0.05-0.18,0.11-0.42,0.17-0.62l0.5-1.69h0.85l-0.79,2.16
					c-0.43,1.18-0.73,1.71-1.1,2.03c-0.31,0.27-0.64,0.38-0.84,0.4l-0.18-0.67c0.13-0.03,0.3-0.11,0.46-0.22
					c0.15-0.1,0.33-0.29,0.44-0.5c0.03-0.05,0.05-0.1,0.05-0.14c0-0.04-0.01-0.08-0.04-0.16l-1.16-2.91H426.7z"/>
				<path d="M429.42,163.45c0.18,0.11,0.52,0.23,0.81,0.23c0.35,0,0.51-0.15,0.51-0.36c0-0.21-0.13-0.32-0.52-0.46
					c-0.62-0.21-0.88-0.55-0.87-0.92c0-0.56,0.46-0.99,1.2-0.99c0.35,0,0.65,0.09,0.84,0.19l-0.16,0.56
					c-0.13-0.08-0.39-0.18-0.67-0.18c-0.28,0-0.44,0.14-0.44,0.33c0,0.2,0.15,0.3,0.55,0.44c0.58,0.2,0.84,0.5,0.84,0.96
					c0,0.58-0.45,0.99-1.29,0.99c-0.38,0-0.73-0.09-0.96-0.22L429.42,163.45z"/>
				<path d="M433.01,160.14c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
					C432.84,159.7,433.01,159.89,433.01,160.14z M432.17,164.19v-3.16h0.8v3.16H432.17z"/>
				<path d="M435.61,164.19l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
					c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
					v1.14c0,0.29,0.01,0.57,0.05,0.76H435.61z M435.5,162.64c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
					c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V162.64z"/>
			</g>
		</g>
	
    </g>
	<g className="hoverEf china">
				<g>
					<path d="M384.98,118.87c-0.19,0.09-0.61,0.2-1.14,0.2c-1.39,0-2.24-0.87-2.24-2.21c0-1.44,1.01-2.31,2.35-2.31
						c0.53,0,0.9,0.11,1.06,0.19l-0.17,0.64c-0.2-0.09-0.5-0.17-0.85-0.17c-0.89,0-1.54,0.56-1.54,1.61c0,0.97,0.56,1.58,1.53,1.58
						c0.32,0,0.66-0.06,0.87-0.16L384.98,118.87z"/>
					<path d="M385.62,114.38h0.8v1.88h0.01c0.1-0.15,0.23-0.27,0.4-0.36c0.16-0.09,0.35-0.14,0.55-0.14c0.54,0,1.11,0.36,1.11,1.37
						V119h-0.8v-1.78c0-0.46-0.17-0.81-0.61-0.81c-0.31,0-0.53,0.21-0.62,0.45c-0.03,0.07-0.04,0.15-0.04,0.24V119h-0.8V114.38z"/>
					<path d="M390.19,114.95c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
						C390.02,114.52,390.19,114.7,390.19,114.95z M389.34,119v-3.16h0.8V119H389.34z"/>
					<path d="M391.01,116.78c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
						c0.55,0,1.12,0.36,1.12,1.36V119h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
						c-0.02,0.07-0.04,0.16-0.04,0.25V119h-0.8V116.78z"/>
					<path d="M396.5,119l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H396.5z M396.4,117.45c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V117.45z"/>
				</g>
			</g>
			<g className="hoverEf china">
				<g>
					<path d="M402.66,132.26v1.75h1.85v-1.75h0.8v4.38h-0.8v-1.93h-1.85v1.93h-0.8v-4.38H402.66z"/>
					<path d="M409.2,135.03c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
						C408.57,133.4,409.2,134.07,409.2,135.03z M406.81,135.06c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
						c0-0.5-0.22-1.06-0.77-1.06C407.05,133.99,406.81,134.53,406.81,135.06z"/>
					<path d="M409.86,134.42c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
						c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
						c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V134.42z"/>
					<path d="M416.47,133.48c-0.02,0.21-0.03,0.48-0.03,0.91v1.79c0,0.66-0.13,1.14-0.47,1.43c-0.33,0.29-0.79,0.38-1.22,0.38
						c-0.4,0-0.83-0.09-1.1-0.25l0.17-0.61c0.2,0.12,0.54,0.24,0.92,0.24c0.52,0,0.91-0.28,0.91-0.96v-0.27h-0.01
						c-0.18,0.28-0.5,0.46-0.92,0.46c-0.78,0-1.34-0.65-1.34-1.54c0-1.04,0.68-1.67,1.43-1.67c0.49,0,0.77,0.23,0.92,0.49h0.01
						l0.03-0.42H416.47z M415.64,134.75c0-0.08-0.01-0.15-0.03-0.22c-0.08-0.29-0.31-0.51-0.65-0.51c-0.44,0-0.78,0.39-0.78,1.03
						c0,0.54,0.27,0.97,0.77,0.97c0.3,0,0.56-0.19,0.65-0.49c0.03-0.08,0.04-0.19,0.04-0.28V134.75z"/>
					<path d="M418.68,132.26h0.79v2.02h0.02c0.11-0.17,0.22-0.32,0.32-0.47l1.15-1.54h0.98l-1.51,1.86l1.61,2.52h-0.94l-1.24-2.01
						l-0.39,0.47v1.54h-0.79V132.26z"/>
					<path d="M425.44,135.03c0,1.16-0.82,1.68-1.62,1.68c-0.89,0-1.58-0.62-1.58-1.63c0-1.04,0.68-1.68,1.64-1.68
						C424.81,133.4,425.44,134.07,425.44,135.03z M423.05,135.06c0,0.61,0.3,1.07,0.79,1.07c0.46,0,0.78-0.44,0.78-1.08
						c0-0.5-0.22-1.06-0.77-1.06C423.28,133.99,423.05,134.53,423.05,135.06z"/>
					<path d="M426.1,134.42c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
						c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
						c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V134.42z"/>
					<path d="M432.71,133.48c-0.02,0.21-0.03,0.48-0.03,0.91v1.79c0,0.66-0.13,1.14-0.47,1.43c-0.33,0.29-0.79,0.38-1.22,0.38
						c-0.4,0-0.83-0.09-1.1-0.25l0.17-0.61c0.2,0.12,0.54,0.24,0.92,0.24c0.52,0,0.91-0.28,0.91-0.96v-0.27h-0.01
						c-0.18,0.28-0.5,0.46-0.92,0.46c-0.78,0-1.34-0.65-1.34-1.54c0-1.04,0.68-1.67,1.43-1.67c0.49,0,0.77,0.23,0.92,0.49h0.01
						l0.03-0.42H432.71z M431.88,134.75c0-0.08-0.01-0.15-0.03-0.22c-0.08-0.29-0.31-0.51-0.65-0.51c-0.44,0-0.78,0.39-0.78,1.03
						c0,0.54,0.27,0.97,0.77,0.97c0.3,0,0.56-0.19,0.65-0.49c0.03-0.08,0.04-0.19,0.04-0.28V134.75z"/>
				</g>
			</g>
			<g className="hoverEf china">
				<g>
					<path d="M417.73,127.4h-1.25v-0.67h3.31v0.67h-1.26v3.71h-0.8V127.4z"/>
					<path d="M422.06,131.1l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H422.06z M421.95,129.55c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V129.55z"/>
					<path d="M424.43,127.05c0,0.24-0.17,0.43-0.46,0.43c-0.27,0-0.44-0.19-0.44-0.43c0-0.24,0.18-0.43,0.45-0.43
						C424.25,126.62,424.43,126.81,424.43,127.05z M423.58,131.1v-3.16h0.8v3.16H423.58z"/>
					<path d="M425.72,127.94l0.33,1.43c0.08,0.34,0.15,0.68,0.21,1.03h0.01c0.06-0.34,0.16-0.69,0.25-1.02l0.42-1.44h0.65l0.4,1.4
						c0.1,0.37,0.19,0.71,0.25,1.06h0.01c0.05-0.34,0.12-0.69,0.21-1.06l0.35-1.4h0.79l-0.99,3.16h-0.74l-0.38-1.29
						c-0.1-0.33-0.17-0.63-0.24-1.01h-0.01c-0.07,0.39-0.15,0.7-0.24,1.01l-0.4,1.29h-0.75l-0.94-3.16H425.72z"/>
					<path d="M431.89,131.1l-0.05-0.35h-0.02c-0.19,0.25-0.52,0.42-0.93,0.42c-0.64,0-1-0.46-1-0.94c0-0.8,0.71-1.2,1.88-1.2v-0.05
						c0-0.21-0.08-0.55-0.64-0.55c-0.31,0-0.63,0.1-0.85,0.24l-0.16-0.52c0.24-0.15,0.65-0.28,1.15-0.28c1.02,0,1.3,0.64,1.3,1.33
						v1.14c0,0.29,0.01,0.57,0.05,0.76H431.89z M431.79,129.55c-0.56-0.01-1.1,0.11-1.1,0.59c0,0.31,0.2,0.46,0.46,0.46
						c0.32,0,0.55-0.21,0.63-0.44c0.02-0.06,0.02-0.12,0.02-0.17V129.55z"/>
					<path d="M433.42,128.88c0-0.37-0.01-0.67-0.03-0.94h0.7l0.04,0.47h0.02c0.14-0.25,0.48-0.55,1.01-0.55
						c0.55,0,1.12,0.36,1.12,1.36v1.88h-0.8v-1.79c0-0.45-0.17-0.8-0.6-0.8c-0.32,0-0.54,0.23-0.62,0.47
						c-0.02,0.07-0.04,0.16-0.04,0.25v1.86h-0.8V128.88z"/>
				</g>
			</g>
	</g>
</g>
<path class="st8" d="M363.7,144.7l-1.3-2c-0.3-0.5-0.4-1.2,0-1.8c0.1-0.2,0.3-0.5,0.6-0.7c0.2-0.1,0.5-0.2,0.9-0.2
	c0.2,0,0.4,0,0.6,0.1c0.4,0.1,0.7,0.4,1,0.8c0.3,0.6,0.3,1.2,0,1.8l-1.3,2C364,144.9,363.8,144.9,363.7,144.7L363.7,144.7z
	 M363.9,141.1c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7C364.6,141.4,364.3,141.1,363.9,141.1z"/>
	 <g className="mouseOver indiaCount">
<path class="brownBlue" d="M356.4,117.5c0,0-1.9-0.8-1.7-2.5c0-0.3-0.2-0.6-0.2-0.6l-0.3-0.2c0,0,0.9-0.6,0.7-0.8s-0.8-1.1-1.1-1.3
	c-0.4-0.1-0.2-0.5-0.2-0.5s0.9-0.5,1.1-0.5s0.3,0,0.4,0c0.1,0,0.2,0.2,0.8,0.1c0.4-0.2,0.7-0.3,0.8-0.5c0.2,0.1,0.3,0.2,0.3,0.2
	l0.3-0.2c0,0,1.1,0.9,1.2,1c0,0.2,0.1,0.8,0.6,1.1c0.4,0.3,1.7,0.9,1.7,0.9l0.9,0c0,0,0.1-0.1,0.3-0.3c0.7-0.4,1.6-0.4,2.2,0.1
	c0,0,0,0,0,0c0.3,0.3,0.4,0.7,0.2,1.1c-0.3,0.5-0.9,1.1-1.5,1.1c-0.3,0.1-0.3,0.1-0.3,0.1"/>
	</g>
<polygon class="st9" points="356.5,117.3 356.5,117.5 356.4,117.5 "/>
</svg>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Global;
