import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import BtnStyle from '../../Style/Button.module.css';
import { Link, useLocation } from "react-router-dom";
import { getServicesTypeDetailAPI, getXLSDownloadAPI } from '../../../services/routes.services';
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import { IoCloseSharp } from "react-icons/io5";
import Breadcrumb from "./BreadCrumb";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
import axios from 'axios';



const UASCertification = ()=>{ 
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [organicData, setOrganicData] = useState([]);
    const [indgapData, setIndgapData] = useState([]);
    const [vcsmppData, setVcsmppData] = useState([]);
    const [uasData, setUasData] = useState([])
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        setLoading(true)
        Promise.all([
            getServicesTypeDetailAPI('organic-certification'),
            getXLSDownloadAPI('INDGAP'),
            getXLSDownloadAPI('VCSMPP'),
            getXLSDownloadAPI('UAS')
        ]).then(([orgRes, indgapRes, vcsmppRes, uasRes])=>{
            setOrganicData(orgRes.data.servicedetails[0]);
            setIndgapData(indgapRes.data.customers);
            setVcsmppData(vcsmppRes.data.customers);
            setUasData(uasRes.data.customers)
        }).catch(error =>{ 
            console.log(error)
        }).finally(()=>{
            setLoading(false)
        });
    }, [])
    console.log(organicData)
    if (loading) {
        return <Loader />;
    }
    const downloadXLSFile = async (image, name) => {
        // try {
        //     const response = await axios.get(image, {
        //         responseType: 'blob',
        //     });
        //     const blob = new Blob([response.data], {
        //         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        //     });
        //     const link = document.createElement('a');
        //     link.href = window.URL.createObjectURL(blob);
        //     document.body.appendChild(link);
        //     link.download = name;
        //     link.click();
        //     link.parentNode.removeChild(link);
        // } catch (error) {
        //     console.error('Error downloading the file', error);
        // }
        const link = document.createElement('a');
    link.href = image; // Use the direct image link
    link.target = '_blank'; // Open in a new tab
    link.download = name; // Specify the download name
    document.body.appendChild(link);
    link.click(); // Programmatically click the link
    link.remove(); // Remove the link from the DOM
    };

    const handleScroll = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return(
        <div className={'CertificationPage ' + Styles.OrganicPage  + ' ' + Styles.CFTStyleSame }>
             {organicData.fields && organicData.fields.length > 0 &&
             <>
                <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
                    <img src={require('../../../assest/images/organic_banner.png')} className={Styles.innerBannerimg} />
                    <div className={Styles.innerBannerContent}>
                        <Container>
                            <Row>
                                <Col>
                                    <div className={Styles.detail}>
                                        <h2>UAS-Drone</h2>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div className={'breadCrmStyle ' + Styles.IDList}>
                    <Container>
                        <Row>
                            <Col>
                                <ul>
                                    <li><Link to='/organic-certification'>Organic</Link></li>
                                    <li><Link to='/indgap-certification'>INDGAP Certification</Link></li>
                                    <li><Link to='/global-certification'>GLOBAL G.A.P. Certification</Link></li>
                                    <li><Link to='/rmc-certification'>RMC Certification</Link></li>
                                    <li><Link to='/uas-certification' className="active">UAS-Drone</Link></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={Styles.UASSce} id="uas">
                    <Container className={Styles.organicSpace}>
                        <Row>
                            <Col md={12}>
                                <h3>{organicData.fields[6].title}</h3>
                            </Col>
                        </Row>
                    </Container>
                    <div className={Styles.UASImg}>
                        
                    </div>
                    <Container className={Styles.organicSpace}>
                        <Row>
                            <Col>
                                {parse(`${organicData.fields[6].description}`)}
                                {parse(`${organicData.fields[6].description2}`)}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={Styles.AsPdf}>
                    <Container className={Styles.organicSpace}>
                        <Row>
                            <Col md={3} className={Styles.flexCenter}>
                                <h3>Our Clients</h3>
                            </Col>
                            <Col md={9}>
                                <div className={Styles.AsPDFContainer}>
                                    {uasData &&
                                        uasData.map((item)=>{
                                            return(
                                                <div className={Styles.box} key={item.order}>
                                                    <button type="button" onClick={() => downloadXLSFile(constants.Image_BASE_URL + item.image, item.name)}>
                                                        <figure>
                                                            <img src={require('../../../assest/images/xlxs_icon.png')} className={Styles.xlxsIcon} alt={item.name} />
                                                        </figure>
                                                        <h6>{item.name}</h6>
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
            }
        </div>
    )
}

export default UASCertification;