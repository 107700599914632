import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import { Link, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import constants from '../../services/constants';
const SectorDetail = ()=>{
    let location = useLocation();
    const { Id, Title, Description } = location.state || {}; 
    console.log(Title)

    return(
        <div className={'directorsPage ' + Styles.boardPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
                <img src={require('../../assest/images/Sectors-We-Operate_Realstate.jpg')} className={Styles.innerBannerimg} />
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>Sectors we Operate</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.TQValue}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.TQvalueContainer}>
                                <Row>
                                    <Col md={8}>
                                        <div className={Styles.TQElem}>
                                            <h3>{Title}</h3>
                                            {parse(Description)}
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className={Styles.TQvalueImg}>
                                            <figure className={Styles.imgBgStyle}>
                                                <img src={require('../../assest/images/sector-real-img.png')} />
                                            </figure>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default SectorDetail;