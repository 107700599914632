import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import { getFoodKeyAPI, getServicesTypeDetailAPI } from '../../../services/routes.services';
import { Link, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import constants from '../../../services/constants';
import parse from 'html-react-parser';
import Breadcrumb from "./BreadCrumb";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
const FoodCertification=()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : '';

    const [data, setData] = useState([]);
    const [foodContent, setFoodContent] = useState({ fields: [] });
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getFoodKeyAPI().then((res) => {
            setData(res.data.iconservice[0].fields);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });

        getServicesTypeDetailAPI('food-certification').then((res) => {
            setFoodContent(res.data.servicedetails[0]);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    }, []);
    //console.log(foodContent)
    if (loading) {
        return <Loader />;
    }
    return(
        <div className={'tabbingStyle ' + Styles.FoodPage + ' ' + Styles.CFTStyleSame}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
              {BannerImage &&  <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Breadcrumb />
            <div className={Styles.aboutFood}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.foodContainer}>
                                {foodContent.fields && foodContent.fields.length > 0 && (
                                    <>
                                    <div className={Styles.foodContent}>
                                        <h3>{foodContent.fields[0].title}</h3>
                                        {parse(`${foodContent.fields[0].description}`)}
                                    </div>
                                    <div className={Styles.foodContentImg}>
                                        <figure>
                                          {foodContent.fields[0].image[0]&&  <img src={constants.Image_BASE_URL+""+foodContent.fields[0].image[0].fileName} alt={foodContent.fields[0].title} width='472' height='334' />}
                                        </figure>
                                    </div>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.foodListing}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.foodContainer}>
                                <div className={Styles.ListElem}>
                                    {data &&
                                        data.map((item)=>{
                                            return(
                                                <div className={Styles.listBox} key={item._id}>
                                                    <div>
                                                        <figure>
                                                          {item.icon &&  <img src={constants.Image_BASE_URL+""+item.icon} alt='' width='35' height='37' />}
                                                        </figure>
                                                        {parse(`${item.description}`)}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.foodTabbing}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.foodContainer}>
                                <Tabs className={Styles.foodtabContainer}>
                                    <TabList className={Styles.tabStyleFood}>
                                    {foodContent.fields && foodContent.fields.length > 1 &&
                                        foodContent.fields.slice(1).map((item) => {
                                            return(
                                                <Tab key={item._id}>{item.title}</Tab>
                                            )   
                                    }
                               ) }
                                    </TabList>
                                    <div className={Styles.foodBodyContainer}>
                                        {foodContent.fields && foodContent.fields.length > 1 &&
                                            foodContent.fields.slice(1).map((item) => (
                                                
                                               
                                               
                                                <TabPanel className={Styles.foodBody} key={item._id}>
                                                   
                                                    <div className={Styles.foodTabContent}>
                                                        {item.title!=="Eat Right Initiative"?(
                                                        <h3>{item.title}</h3>):null}
                                                        {parse(`${item.description}`)}
                                                        <figure>
                                                        {item.image[0] && <img src={constants.Image_BASE_URL+""+item.image[0].fileName} width='896' height='322' />}
                                                        </figure>
                                                        <div className={Styles.secondClm}>
                                                        {parse(`${item.description2}`)}
                                                        <figure>
                                                            {item.image[1] &&<img src={constants.Image_BASE_URL+""+item.image[1].fileName} alt='Fassi' width='258' height='141' className={Styles.TabIcon} />}
                                                        </figure>
                                                        </div>
                                                    </div>
                                                  
                                                </TabPanel>
                                           
                                            ))
                                        }
                                    </div>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default FoodCertification;