import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import HeaderAdmin from '../../Components/Common/Header/HeaderAdmin';
import styles from './Layout.module.css';

const Layout = () => {
  return (
    <div>
        <HeaderAdmin/>
    
    <div className={styles.container}>
      <div className={styles.sidebar}>
      
        <nav>
          <ul>
            <li>
              <NavLink
                to="/admin/menu"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Header Menu
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/home-page"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Home Page
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/about-page"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                About Page
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/carrer"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Carrer Page
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/services"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/mission-value"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Mission Value
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/team-member"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Team Member
              </NavLink>
            </li>
            
            <li>
              <NavLink
                to="/admin/address"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Contact Manager
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/key-projects"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Key Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/blog-page"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Blog Page
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/policie-page"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Policies Page
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/custmores"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Customers
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/downloads"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Downloads
              </NavLink>
            </li>
            
            <li>
              <NavLink
                to="/admin/contact"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Contact Us Query
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/admin/socialmedia"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Social Media
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/admin/jobs"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
                Job Posting
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/map-locations"
                className={({ isActive }) => (isActive ? styles.active : undefined)}
              >
               Map Location
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
    </div>
  );
};

export default Layout;
