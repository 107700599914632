import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import Loader from "../Common/PageLoader/LoadingSpinner";
import axiosService from "../../services/AxiosConfig";
import { getTQAddressAPI,getCsrfToken } from '../../services/routes.services';
import { IoLocationOutline } from "react-icons/io5";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { GoMail } from "react-icons/go";
import parse from 'html-react-parser';
import axios from 'axios';
import constants from "../../services/constants";

const ContactUs = () => {
    const [loading, setLoading] = useState(true);
    const [csrfToken, setCsrfToken] = useState('');
    const [formData, setFormData] = useState({
        title: '', organization: '', email: '', phoneNumber: '', query: '', city: '', message: ''
    });
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [addressData, setAddressData] = useState([]);
    const [emailError, setEmailError] = useState('');  // State for email validation
    const [phoneError, setPhoneError] = useState('');  // State for phone validation


    useEffect(() => {
        fetchLinks();
    }, []);
    useEffect(() => {
        getCsurfToken();
    }, []);
    const getCsurfToken = async () => {
        //const response = await axios.get('/api/csrf-token');
        const response = await getCsrfToken();
        console.log('csrfToken=========',response.data.csrfToken)
        setCsrfToken(response.data.csrfToken);
    };
    const fetchLinks = async () => {
        try {
          const response = await getTQAddressAPI('Contact');
          setAddressData(response.data.addresses);
          setLoading(false)
        } catch (error) {
          console.log(error.message);
        }
      };
      console.log(addressData)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'title' || name === 'city') && !/^[A-Za-z]*$/.test(value)) {
            return;
        }
        setFormData({ ...formData, [name]: value });
        if (name === 'email') {
            validateEmail(value);  // Validate email on input change
        }
        else if (name === 'phoneNumber') {
            validatePhoneNumber(value);  // Validate phone number on input change
        }
    };
 const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
    };
    // Phone number validation
    const validatePhoneNumber = (phoneNumber) => {
        if (phoneNumber.length !== 10) {
            setPhoneError('Phone number must be exactly 10 digits.');
        } else if (!/^\d+$/.test(phoneNumber)) {
            setPhoneError('Phone number must contain only digits.');
        } else {
            setPhoneError('');
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setResponse(null);
        
        try {
            const res  = await axios.post(`${constants.API_BASE_URL}contactus`, formData, { headers: { 'X-CSRF-Token': csrfToken } });
            setResponse(res.data);
            setFormData({
                title: '', organization: '', email: '', phoneNumber: '', query: '', city: '', message: ''
            });
            console.log(response)
            // Handle success, maybe update state or navigate
          } catch (error) {
            console.error('Error during POST request:', error);
            // Handle error, show message to user, etc.
          } finally {
            setLoading(false);
        }
    };
   
           // Split address data for sorting: Corporate Address at top, others below
    const corporateAddress = addressData.filter(item => item.title === 'Corporate Address');
    const otherAddresses = addressData.filter(item => 
        ['Branch Office', 'International Offices', 'Overseas Branch', 'Subsidiary Office', 'JV Office'].includes(item.title)
    );

   
    return (
        <div className={`directorsPage ${Styles.boardPage}`}>
            <div className={`${Styles.innerBanner} ${Styles.InnerSmallBanner} ${Styles.noneMask} ${Styles.smallBanner}`}>
                <img src={require('../../assest/images/blog-banner.jpg')} className={Styles.innerBannerimg} alt="Banner" />
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>Get in touch</h2> 
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.contactForm}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.FormContainer}>
                                <h4>Send us a message</h4>
                                {error && <p className={Styles.error}>{error}</p>}
                                {response && <p className={Styles.success}>Thank you! We'll get in touch with you soon.</p>}
                                <form onSubmit={handleSubmit}>
                                    <div className={Styles.fromElem}>
                                        <div className={Styles.formGroup}>
                                            <div className={Styles.group}>
                                                <input type="text" id="title" name="title" value={formData.title} placeholder="Name*" onChange={handleInputChange} required />
                                            </div>
                                            <div className={Styles.group}>
                                                <input type="text" id="organization" name="organization" value={formData.organization} placeholder="Organization*" onChange={handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className={Styles.formGroup}>
                                            <div className={Styles.group}>
                                                <input type="email" id="email" name="email" value={formData.email} placeholder="Email Id*" onChange={handleInputChange} required />
                                                {emailError && <p className={Styles.error}>{emailError}</p>}
                                                </div>
                                            <div className={Styles.group}>
                                                <input type="tel" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} placeholder="Phone Number*" onChange={handleInputChange} required />
                                                {phoneError && <p className={Styles.error}>{phoneError}</p>}
                                          
                                            </div>
                                        </div>
                                        <div className={Styles.formGroup}>
                                            <div className={Styles.group}>
                                                <input type="text" id="query" name="query" value={formData.query} placeholder="What is my query about?*" onChange={handleInputChange} required />
                                            </div>
                                            <div className={Styles.group}>
                                                <input type="text" id="city" name="city" value={formData.city} placeholder="City" onChange={handleInputChange} required />
                                                {/* <select id="city" name="city" value={formData.city} onChange={handleInputChange} required>
                                                    <option value="">City</option>
                                                    <option value="City1">City1</option>
                                                    <option value="City2">City2</option>
                                                </select> */}
                                            </div>
                                        </div>
                                        <div className={Styles.formGroup}>
                                            <div className={Styles.group}>
                                                <textarea id="message" name="message" value={formData.message} placeholder="Message*" onChange={handleInputChange} required ></textarea>
                                            </div>
                                        </div>
                                        <div className={Styles.formGroup}>
                                            <button type="submit" className={Styles.submitButton}>
                                                {loading ? <Loader /> : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
           { console.log(addressData)}
            <div className={Styles.TQMap}>
                <div className={Styles.TQMapContainer}>
                     <div className={Styles.addressBg}>
                        <Container>
                            <Row><Col className={Styles.centerAlight+" "+ Styles.addressList}><h5>TQ Cert Services Private Limited  </h5></Col></Row>
                            <Row>
                                
                                <Col className={Styles.TQMapAddress}>
                                    <div className={Styles.addressList}>
                                        {/* <h5>{item.city}</h5> */}
                                        <h6>Registered Address - Hyderabad</h6>
                                        <ul>
                                            <li><span className={Styles.icon}><IoLocationOutline /></span>Room No 3, Fourth Floor, Mithona Towers-1 1-7-80 to 87, Prender Ghast Road, Hyderabad, Secunderabad, Telangana, India, 500003</li>                                            
                                            <li><span className={Styles.icon}><GoMail /></span>cstpl@tataprojects.com</li>
                                            <li><span className={Styles.icon}><LiaPhoneVolumeSolid /></span>+91-40-66238801</li>
                                            <li><span className={Styles.cinIcon}>CIN</span>U74220TG2003PTC040523</li>
                                        </ul>
                                    </div>
                                    {corporateAddress &&
                                        corporateAddress.map((item)=>{
                                            return(
                                                <div className={Styles.addressList} key={item._id}>
                                                    {/* <h5>{item.city}</h5> */}
                                                    <h6>{item.title} - {item.city}</h6>
                                                    <ul>
                                                        <li><span className={Styles.icon}><IoLocationOutline /></span>{parse(`${item.addressName}`)}</li>
                                                        
                                                        <li><span className={Styles.icon}><GoMail /></span>{item.email}</li>
                                                        {item.phoneNumber!=='1'&&
                                                        <li><span className={Styles.icon}><LiaPhoneVolumeSolid /></span>{item.phoneNumber}</li>}
                                                    </ul>
                                                </div>
                                            )
                                        })
                                    }
                                     {/* Other addresses */}
                                     {otherAddresses.map(item => (
                                        <div className={Styles.addressList} key={item._id}>
                                            {/* <h5>{item.city}</h5> */}
                                            <h6>{item.title} - {item.city}</h6>
                                            <ul>
                                                <li><span className={Styles.icon}><IoLocationOutline /></span>{parse(`${item.addressName}`)}</li>
                                                <li><span className={Styles.icon}><GoMail /></span>{item.email}</li>
                                                {item.phoneNumber !== '1' &&
                                                    <li><span className={Styles.icon}><LiaPhoneVolumeSolid /></span>{item.phoneNumber}</li>}
                                            </ul>
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </Container>
                    </div> 
                    {/* <div className={Styles.TQMapAddress}>
                            <div className={Styles.addressList}>
                                <h5>{addressData.city}</h5>
                                <h6>{addressData.title}</h6>
                                <ul>
                                    <li><span className={Styles.icon}><IoLocationOutline /></span>{addressData.addressName}</li>
                                    <li><span className={Styles.icon}><GoMail /></span>{addressData.email}</li>
                                    <li><span className={Styles.icon}><LiaPhoneVolumeSolid /></span>{addressData.phoneNumber}</li>
                                </ul>
                            </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
