import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import BtnStyle from '../../Style/Button.module.css';
import { Link, useLocation } from "react-router-dom";
import { getServicesTypeDetailAPI } from '../../../services/routes.services';
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import { IoCloseSharp } from "react-icons/io5";
import Breadcrumb from "./BreadCrumb";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";

const IBSCertification = ()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [bodyData, setBodyData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        getServicesTypeDetailAPI('she-services').then((res)=>{
            setBodyData(res.data.servicedetails[0]);
            setLoading(false)
        }).catch(error =>{ 
            console.log(error)
            setLoading(false)
        })
    }, [])
    console.log(bodyData)
    if (loading) {
        return <Loader />;
    }


    return(
        <div className={'CertificationPage ' + Styles.OrganicPage  + ' ' + Styles.CFTStyleSame}>
            {bodyData.fields && bodyData.fields.length > 0 &&
            <>
                <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
                  {BannerImage &&  <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                    <div className={Styles.innerBannerContent}>
                        <Container>
                            <Row>
                                <Col>
                                    <div className={Styles.detail}>
                                        <h2>{BannerTitle}</h2>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Breadcrumb />
                <div className={Styles.IBSOverview}>
                    <Container className={Styles.organicSpace}>
                        <Row>
                            <Col md={4}>
                                <figure className={Styles.flexCntr}>
                                 {bodyData.fields[0].image[0] &&   <img src={constants.Image_BASE_URL+""+bodyData.fields[0].image[0].fileName} />}
                                </figure>
                            </Col>
                            <Col md={8}>
                                <div className={Styles.content}>
                                    <h3>{bodyData.fields[0].title}</h3>
                                    {parse(`${bodyData.fields[0].description}`)}
                                </div>
                            </Col>
                            <Col md={8}>
                                <div className={Styles.flexSpace}>
                                    <h3>{bodyData.fields[1].title}</h3>
                                    {parse(`${bodyData.fields[1].description}`)}
                                    {parse(`${bodyData.fields[1].description2}`)}
                                </div>
                            </Col>
                            <Col md={4}>
                               {bodyData.fields[1].image[0] && <figure className={Styles.flexHeight}>
                                    <img src={constants.Image_BASE_URL+""+bodyData.fields[1].image[0].fileName} />
                                </figure>}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={Styles.T4SAudits}>
                    <Container className={Styles.organicSpace}>
                        <Row>
                            <Col md={12}>
                                <h3>{bodyData.fields[2].title}</h3>
                            </Col>
                            <Col md={7}>
                                <div className={Styles.content}>
                                    {parse(`${bodyData.fields[2].description}`)}
                                </div>
                            </Col>
                           {bodyData.fields[2].image[0]&&  <Col md={5}>
                                <figure className={Styles.flexRight}>
                                    <img src={constants.Image_BASE_URL+""+bodyData.fields[2].image[0].fileName} />
                                </figure>
                            </Col>}
                        </Row>
                        <Row className={Styles.secSpace}>
                           {bodyData.fields[3].image[0] && <Col md={4}>
                                <figure>
                                    <img src={constants.Image_BASE_URL+""+bodyData.fields[3].image[0].fileName} />
                                </figure>
                            </Col>}
                            <Col md={8}>
                                <div className={Styles.content}>
                                    <h5>{bodyData.fields[3].title}</h5>
                                    {parse(`${bodyData.fields[3].description}`)}
                                    {parse(`${bodyData.fields[3].description2}`)}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={Styles.IMSBlock}>
                    <Container className={Styles.organicSpace}>
                        <Row>
                            <Col md={12}>
                                <h3>{bodyData.fields[4].title}</h3>
                            </Col>
                            <Col md={7}>
                                <div className={Styles.content}>
                                    {parse(`${bodyData.fields[4].description}`)}
                                </div>
                            </Col>
                           {bodyData.fields[4].image[0] && <Col md={5}>
                                <figure className={Styles.flexRight}>
                                    <img src={constants.Image_BASE_URL+""+bodyData.fields[4].image[0].fileName} />
                                </figure>
                            </Col>}
                            <Col md={12}>
                                {parse(`${bodyData.fields[4].description2}`)}
                            </Col>
                            <Col md={12}>
                                <div className={Styles.IMSEmel}>
                                   {bodyData.fields[5].image[0] &&<figure>
                                        <img src={constants.Image_BASE_URL+""+bodyData.fields[5].image[0].fileName} />
                                    </figure>}
                                    <div className={Styles.content}>
                                        <h5>{bodyData.fields[5].title}</h5>
                                        {parse(`${bodyData.fields[5].description}`)}
                                        {parse(`${bodyData.fields[5].description2}`)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
            }
        </div>
    )
}

export default IBSCertification;