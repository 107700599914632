import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import { getServicesTypeDetailAPI } from '../../../services/routes.services';
import { Link, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import 'react-tabs/style/react-tabs.css';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import constants from "../../../services/constants";
import Breadcrumb from "./BreadCrumb";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
const YogaCertification=()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : '';

    const [yogaContent, setYogaContent] = useState({ fields: [] });
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getServicesTypeDetailAPI('yoga-certification').then((res) => {
            setYogaContent(res.data.servicedetails[0]);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    }, []);
    if (loading) {
        return <Loader />;
    }
    
    console.log(yogaContent)
    return(
        <div className={'CertificationPage ' + Styles.FoodPage + ' ' + Styles.CFTStyleSame}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
               {BannerImage && <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Breadcrumb />
            <div className={Styles.aboutFood}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.foodContainer}>
                                {yogaContent.fields && yogaContent.fields.length > 0 &&(
                                    <>
                                        <div className={Styles.foodContent}>
                                            <h3>{yogaContent.fields[0].title}</h3>
                                            {parse(`${yogaContent.fields[0].description}`)}
                                        </div>
                                        <div className={Styles.foodContentImg}>
                                            <figure>
                                              {yogaContent.fields[0].image[0] &&  <img src={constants.Image_BASE_URL+""+yogaContent.fields[0].image[0].fileName} alt={yogaContent.fields[0].title} width='462' height='334' />}
                                            </figure>
                                        </div>
                                    </>
                                )}
                                
                            </div>
                            <div className={Styles.yogaTagLine}>
                                {parse(`${yogaContent.description}`)}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.YogaListBlock}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.foodContainer}>
                                <div className={Styles.yogaImg}>
                                    <figure>
                                      {yogaContent.fields[2].image[0] &&  <img src={constants.Image_BASE_URL+""+yogaContent.fields[2].image[0].fileName} alt="" width='654' height='451' />}
                                    </figure>
                                </div>
                                <div className={Styles.yogaContent}>
                                    {parse(`${yogaContent.fields[2].description}`)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.specificBloxk}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.foodContainer}>
                                <div className={Styles.specificContainer}>
                                    <div className={Styles.specificContent}>
                                        {parse(`${yogaContent.fields[3].description}`)}
                                    </div>
                                    <div className={Styles.specificImg}>
                                        <figure>
                                         {yogaContent.fields[3].image[0]  && <img src={constants.Image_BASE_URL+""+yogaContent.fields[3].image[0].fileName} />}
                                        </figure>
                                        <h6>Our Employees with Sri Sri Ravi Shankar Guruji at Art of Living Ashram, Bangalore</h6>
                                    </div>
                                </div>
                            </div>
                            <div className={Styles.foodContainer}>
                                <h5>{yogaContent.fields[4].title}</h5>
                                {parse(`${yogaContent.fields[4].description}`)}
                                <div className={Styles.ChartImg}>
                                {yogaContent.fields[4].image[0]  &&      <img src={constants.Image_BASE_URL+""+yogaContent.fields[4].image[0].fileName} />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default YogaCertification;