import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import { Link, useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
   
    return (
        <div className={'directorsPage ' + Styles.boardPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner + ' ' + Styles.noneMask + " " + Styles.smallBanner}>
           <img src={require('../../assest/images/policy_banner.png')} className={Styles.innerBannerimg} alt="Banner" />
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>Privacy Policy</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.privatPolicySec}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.polictContant}>
                                <h4>Introduction</h4>
                                <ul>
                                    <li>TQ Cert Services Private Limited (TQ Cert)(“We”) is committed to safeguard the privacy of the information shared on our Website by visitors. This privacy policy (“Privacy Policy”) explains what personal data we collect from you and how we may collect, we use, store, disclose or otherwise process your personal data and how we treat your personal information when using our website or through any other mean that we may employee your personal data. </li>
                                    <li>This Privacy Policy applies to <Link to="https://tqcert.org/" target="_blank">tqcert.org</Link> Website, which may contain links to Websites of third parties whose privacy policy may differ from those of Tata Projects. If you provide data to any of those Websites, then your data is governed by their privacy policy.</li>
                                    <li>By accessing or using our Website you confirm that you have read, understood, and agreed with the privacy practices and policies outlined in this Privacy Policy and agree to be bound by the Privacy Policy.</li>
                                    <li>You hereby consent to our collection, storage, and processing of your information in accordance with the terms of this Policy.</li>
                                    <li>If you do not agree with any of the terms and conditions of this Privacy Policy, please do not proceed further to use this Site or any services. This Privacy Policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy on <Link to="https://tqcert.org/" target="_blank">tqcert.org</Link> Website periodically.</li>
                                </ul>
                                <h4>Collecting Personal Information</h4>
                                <ul>
                                    <li>We may collect, store and use the following kinds of personal information:
                                        <ol>
                                            <li>Information about your computer and about your visits to and use of this Website (including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and Website navigation paths);</li>
                                            <li>Information that you provide to us when registering with our Website including but not limited to your name, email address, mobile number;</li>
                                            <li>Information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters (including [your name and email address);</li>
                                            <li>Information that you provide to us when using the services on our Website, or that is generated in the course of the use of those services (including the timing, frequency and pattern of service use);</li>
                                            <li>Information contained in or relating to any communications that you send to us or send through enquiry forms or otherwise on our Website (including the communication content and meta data associated with the communication);</li>
                                            <li>Any other personal information that you choose to send to us.</li>
                                        </ol>
                                    </li>
                                    <li>Before you disclose personal information of another person, you must obtain that person’s consent to both the disclosure and the processing of that personal information in accordance with this policy.</li>
                                </ul>
                                <h4>Access, Correction and Deletion</h4>
                                <ul>
                                    <li>We take all reasonable precautions to ensure that the personal information that we collect, use and disclose is accurate, complete and up to date. However, the accuracy of the information depends to a large extent on the information that you provide.</li>
                                    <li>We therefore recommend you keep us updated with changes to your personal information such as your name, mobile number or address and/ or if there are any errors in your personal information.</li>
                                    <li>We reserve the right to charge a fee for searching for and providing access to your information.</li>
                                    <li>You may write to us in connection with deleting or amending your information maintained and retained with us, by sending an email @ <Link to="https://www.tqcert.org/" target="_blank">www.tqcert.org</Link>. However, you will also agree that certain data or information cannot be corrected or deleted or is prohibited to be deleted as required under any applicable law, law enforcement requests or under any judicial proceedings and thus, shall continue to be maintained by us, as per mandates.</li>
                                </ul>
                                <h4>Using Your Personal Information</h4>
                                <ul>
                                    <li>Personal information submitted to us through our Website will be used for the purposes specified in this policy or on the relevant pages of the Website.</li>
                                    <li>We may use your personal information to:
                                        <ol>
                                            <li>Administer our Website and business;</li>
                                            <li>Personalise our Website for you;</li>
                                            <li>Enable your use of the services available on our Website;</li>
                                            <li>Send you email notifications that you have specifically requested;</li>
                                            <li>Send you our email newsletter if you have requested it;</li>
                                            <li>Deal with enquiries and complaints made by or about you relating to our Website;</li>
                                            <li>Transmission by email or any other form of communication of marketing materials to you;</li>
                                            <li>contact for survey or feedback which may be done using email or mail;</li>
                                            <li>to execute other activities such as marketing campaign, promotional communications for which consent is taken appropriately;</li>
                                            <li>Keep our Website secure and prevent fraud;</li>
                                            <li>Communicate with you (including to respond your queries, requests, questions, feedback, claims or dispute) and to customize and improve its services.</li>
                                        </ol>
                                    </li>
                                    <li>If you submit personal information for publication on our Website, we will publish and otherwise use that information in accordance with the licence you grant to us.</li>
                                </ul>
                                <h4>Disclosing Personal Information</h4>
                                <ul>
                                    <li>We may disclose your personal information to any of our employees, officers, advisers, agents, insofar as reasonably necessary for the purposes set out in this policy.</li>
                                    <li>We may disclose your personal information to service -providers who assist in protecting and securing our systems and provide services to us which require the processing of personal data, such as to host your information or to process your information for data profiling and user analysis.</li>
                                    <li>We may disclose your personal information to any member of our group of companies (this means our affiliates and subsidiaries,) insofar as reasonably necessary for the purposes set out in this policy.</li>
                                    <li>We may disclose your personal information to Government and regulatory authorities, agencies and other organizations, as required or authorized by law;</li>
                                    <li>We may disclose your personal information:
                                        <ol>
                                            <li>To the extent that we are required to do so by law;</li>
                                            <li>In order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk);</li>
                                            <li>To the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating) selling; and</li>
                                            <li>To any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information.</li>
                                        </ol>
                                    </li>
                                    <li>Except as provided in this policy, we will not provide your personal information to third parties.</li>
                                </ul>
                                <h4>International Data Transfers</h4>
                                <ul>
                                    <li>Information that we collect may be stored and processed in and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this policy.</li>
                                    <li>Personal information that you publish on our Website or submit for publication on our Website may be available, via the internet, around the world. We cannot prevent the use or misuse of such information by others.</li>
                                    <li>You expressly agree to the transfers of personal information described in this Section.</li>
                                </ul>
                                <h4>Retaining Personal Information</h4>
                                <ul>
                                    <li>This Section sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal information.</li>
                                    <li>Personal information that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</li>
                                    <li>Notwithstanding the other provisions, we retain documents (including electronic documents) containing personal data:
                                        <ol>
                                            <li>To the extent that we are required to do so by law;</li>
                                            <li>If we believe that the documents may be relevant to any ongoing or prospective legal proceedings; and</li>
                                            <li>To establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).</li>
                                        </ol>
                                    </li>
                                    <li>Even if we delete your data, it may persist on backup or archival media for audit, legal, tax or regulatory purposes.</li>
                                </ul>
                                <h4>Security of your Personal Information</h4>
                                <ul>
                                    <li>We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.</li>
                                    <li>Technical and organisational measures include measures to deal with any suspected data breach. If you suspect any misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us via e-mail, social media or telephone.</li>
                                    <li>We will store all the personal information you provide on our secure (password- and firewall-protected) servers.</li>
                                    <li>You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</li>
                                    <li>We believe that there are various factors due to which the compliance of the above Policy may go out of our control, including factors of force majeure and in such situations, we shall not be held responsible.</li>
                                </ul>
                                <h4>Third Party Websites</h4>
                                <ul>
                                    <li>· Our Website includes hyperlinks to, and details of, third party Websites.</li>
                                    <li>· We have no control over, and are not responsible for, the privacy policies and practices of third parties.</li>
                                </ul>
                                <h4>Cookies</h4>the right to change, modify, add or delete portions of the terms of this Privacy Policy, at Our sole discretion, at any time, and any continued use of the Website, the services or the platform, following any such amendments to the Privacy Policy, will be deemed as an implicit acceptance of the Privacy Policy in its amended form. You are requested to review the Privacy Policy from time to time to keep yourself 
                                <ul>
                                    <li>Our Website may use cookies to enhance your experience of using the Website.</li>
                                    <li>Your web browser may place cookies on your hard drive for record-keeping purpose and sometimes to track your information.</li>
                                    <li>Before the Website places cookies on your computer, you will be presented with a message bar requesting your consent to set those cookies. You may, if you wish, deny consent to the placing of cookies; however certain features of the Website may not function fully or as-intended.</li>
                                    <li>You can choose to enable or disable cookies in your internet browser. By default, most internet browsers accept cookies, but this can be changed. For further details, please consult the help menu in your internet browser.</li>
                                    <li>You can choose to delete cookies at any time however, you may lose any information that enables you to access the Website more quickly and efficiently including, but not limited to, personalisation settings.</li>
                                    <li>It is recommended that you ensure that your internet browser is up-to-date and that you consult the help and guidance provided by the developer of your internet browser if you are unsure about adjusting your privacy settings.</li>
                                </ul>
                                <h4>Contact Us</h4>
                                <ul>
                                    <li>If you have any questions regarding this Privacy Policy or the protection of your personal data, you may contact us at <Link to="mailto:tqcert@tataprojects.com">tqcert@tataprojects.com</Link></li>
                                </ul>
                                <h4>General</h4>
                                <ul>
                                    <li>We reserve the right to change, modify, add or delete portions of the terms of this Privacy Policy, at Our sole discretion, at any time, and any continued use of the Website, the services or the platform, following any such amendments to the Privacy Policy, will be deemed as an implicit acceptance of the Privacy Policy in its amended form. You are requested to review the Privacy Policy from time to time to keep yourself updated with any changes; modifications made to the terms hereof.</li>
                                    <li>If any court or competent authority finds that any provision of this Privacy Policy (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this Privacy Policy will not be affected.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
