import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import { Link, useLocation } from "react-router-dom";

const CookiePolicy = () => {
   
    return (
        <div className={'directorsPage ' + Styles.boardPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner + ' ' + Styles.noneMask + " " + Styles.smallBanner}>
           <img src={require('../../assest/images/policy_banner.png')} className={Styles.innerBannerimg} alt="Banner" />
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>Cookie Policy</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.privatPolicySec}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.polictContant}>
                                <h4>INTRODUCTION</h4>
                                <p>This Cookie Policy (hereinafter referred to as “Policy”) explains how TQ Cert Services Private Limited (TQ Cert) (hereinafter referred to as “TQ CERT”, “we” “us” and “our”) uses cookies and similar tracking technologies when you use our website and services. By using our website and services, you consent to the use of cookies and tracking technologies as described in this Policy.</p>
                                <h4>WHAT ARE COOKIES?</h4>
                                <p>Cookies are small pieces of data, stored in text files, that are stored on your computer or other devices when websites are loaded in a browser. They are widely used to “remember” you and your preferences, either for a single visit (through a “session cookie”) or for multiple repeat visits (using a “functional cookie”). Cookies may be set by the site that you are visiting (known as “first-party cookies”), or by third parties, such as those who serve content or provide advertising or analytics services on the website (“third-party cookies”).</p>
                                <h4>OTHER SIMILAR TECHNOLOGIES</h4>
                                <p>Websites may also contain other tracking technologies such as “web beacons” or “pixels” to collect statistical information about user behavior for similar purposes as cookies. These are typically small transparent images that provide us with statistics. They are often used in conjunction with cookies, though they are not stored on your computer in the same way. If you disable cookies, web beacons may still load, but their functionality will be restricted.</p>
                                <h4>WHY DO WE USE COOKIES AND SIMILAR TECHNOLOGIES?</h4>
                                <p>We use cookies for different purposes, including:</p>
                                <p><strong>Enabling our websites to function properly:</strong> Some cookie are essential for the operation of our websites. For example, sign-in and authentication, we use cookie to ensure that you are shown the correct webpages for your location, or to identify when we have broken links or other technical issues with the website.</p>
                                <p><strong>Analysing visitor behaviour</strong>: We may use cookie to analyse how our visitors use our websites and to monitor website performance. This allows us to provide an experience by making sure that our content and layout remains relevant to you. For example, we use cookie to keep track of which pages and links are most popular and which are not providing the information that’s required by our visitors.</p>
                                <p><strong>Optimising and personalising</strong>: cookie are also used to optimise the webpages that you visit, and to personalise the content that we show you across all our digital assets, based on what we understand about your requirements.</p>
                                <h4>WHAT TYPES OF COOKIES DO WE USE?</h4>
                                <p>We use both first-party and third-party cookies on our website.</p>
                                <p>First-party cookie are cookies issued from the tataprojects.com domain.</p>
                                <p>Third-party cookie belong to and are managed by other parties, such as those who serve content or provide advertising or analytics services on the website.</p>
                                <p><strong>Session cookies:</strong> Session cookie enable the website you are visiting to keep track of your movement from page to page, so you don’t get asked for the same information you’ve already given to the site. cookie allow you to proceed through many pages of a site quickly and easily without having to authenticate or reprocess each new area you visit. These only last for as long as the session (usually the current visit to a website or a browser session).</p>
                                <p><strong>Functional cookies:</strong> These cookies allow our services to remember choices you make, such as:</p>
                                <ul>
                                    <li>remembering your username, preferences, and settings.</li>
                                    <li>remembering if you’ve filled in a survey or taken part in a poll or contest or otherwise reacted to something on through the services, so you’re not asked to do it again.</li>
                                    <li>remembering if you’ve used any of our services before; restricting the number of times you are shown a particular advertisement.</li>
                                    <li>remembering your location; and enabling social media components</li>
                                    <li>The aim of these cookie is to provide you with a more personal experience so that you don’t have to reset your preferences each time you use our services. If you do not allow these cookies, then some or all these services may not function properly.</li>
                                </ul>
                                <p>Advertising cookies: These cookies record your visit to our websites, the pages you have visited, and the links you have clicked. They gather information about your browsing habits and remember that you have visited a website. We (and third-party advertising platforms or networks) may use this information to make our websites, content, and advertisements displayed on them more relevant to your interests (this is sometimes called “behavioural” or “targeted” advertising). These types of cookies are also used to limit the number of times you see an advertisement as well as to help measure the effectiveness of advertising campaigns. If you do not allow these cookies, you will experience less targeted advertising.</p>
                                <p>Analytics cookies: These cookies also enable us to personalize content and remember your preferences (e.g., your choice of language, country, or region). They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous.</p>
                                <h4>HOW DO I CONTROL COOKIE PREFERENCES?</h4>
                                <p>You can choose to reject or block all or specific types of cookies by clicking on the cookie preferences on our website(s). Most browsers automatically accept cookies. Therefore, if you do not wish cookie to be used, you may need to actively delete or block the cookie. You can manage cookie in the privacy settings of the web browser you are using. Please note that rejecting cookies may impact some functions of our websites.</p>
                                <h4>UPDATE AND REVIEW</h4>
                                <p>We may update this Cookie Policy from time to time, and we encourage you to periodically review this page for the latest information.</p>
                                <h4>CONTACT US</h4>
                                <p>You may contact us at <Link to="mailto:tqcert@tataprojects.com">tqcert@tataprojects.com</Link> to know further about the use of cookie on our websites.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default CookiePolicy;
