import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css';
import { getTQTeams } from '../../services/routes.services';
import parse from 'html-react-parser';
import constants from '../../services/constants';
import Loader from "../Common/PageLoader/LoadingSpinner";
import { IoCloseSharp } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath"; 
const BoardDirectors = ()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    console.log(currentMenuItem)
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 
   
    const [boardMembers, setBoardMembers] = useState([]);
    const [shoeMemberMore, setShowMemberMore] = useState(null)
    const [loading, setLoading] = useState(true);
    const MemberShowHandeler=(boardMember)=>{
        console.log(boardMember)
        setShowMemberMore(boardMember)
    }
    const CloseMemberHandler=()=>{
        setShowMemberMore(null)
    }
    useEffect(() => {
        fetchLinks();
      }, []);
    
      const fetchLinks = async () => {
        try {
          const response = await getTQTeams('Head');
          setBoardMembers(response.data.teams);
          setLoading(false);
        
        } catch (error) {
          console.log(error.message);
          setLoading(false);
        }
      };
    if (loading) {
        return <Loader />;
    }
    return(
        <div className={'directorsPage ' + Styles.boardPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
               {BannerImage && <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.directoeList}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.directoerContainer}>
                               {boardMembers &&
                                    boardMembers.map((boardMember)=>{
                                        return(
                                            <div className={Styles.directoerElem} key={boardMember.order}>
                                                <div className={Styles.BDdetail}>
                                                    <h4>{boardMember.name}</h4>
                                                    <h5>{boardMember.position}</h5>
                                                    <p>{parse(`${boardMember.description}`)}</p>
                                                    <button type='button' onClick={()=>{MemberShowHandeler(boardMember)}} className={BtnStyle.btn + ' ' + BtnStyle.BlurBtn}>Know More</button>
                                                </div>
                                                <div className={Styles.BDImage}>
                                                    <figure>
                                                        <img src={constants.Image_BASE_URL+""+boardMember.image} />
                                                    </figure>
                                                </div>
                                            </div>
                                        )
                                    })
                               }
                            </div>
                        </Col>
                    </Row>
                </Container>
                {shoeMemberMore &&
                <div className={Styles.memberPopop}>
                    <div className={Styles.directoerContainer}>
                            <div className={Styles.directoerElem} key={shoeMemberMore.order}>
                                <button type="button" onClick={CloseMemberHandler} className={Styles.closePopup}><IoCloseSharp /></button>
                                <div className={Styles.BDImage}>
                                    <figure>
                                        <img src={constants.Image_BASE_URL+""+shoeMemberMore.image} />
                                    </figure>
                                </div>
                                <div className={Styles.BDdetail}>
                                    <h4>{shoeMemberMore.name}</h4>
                                    <h5>{shoeMemberMore.position}</h5>
                                    <p>{parse(`${shoeMemberMore.description}`)}</p>
                                </div>
                            </div>
                    </div>
                </div>
}
            </div>
        </div>
    )
}

export default BoardDirectors;