import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Styles from '../../Style/Common.module.css';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import $ from 'jquery';

const Breadcrumb=()=>{
    const { menu } = useSelector(state => state.header);
    const handleClickMenu = (e) => {
        $('.mobMenuInner').toggleClass('active-class');
      };
    const location = useLocation();
    if (!menu || menu.length === 0) {
        return null;
    }

    const sortedMenu = [...menu].sort((a, b) => a.orderNumber - b.orderNumber);
    const allSubMenu = sortedMenu[1].children[2].children
   
    const allSubMenu1 = [...allSubMenu].sort((a, b) => a.orderNumber - b.orderNumber);
   
  
    return(
        <div className={'breadCrmStyle ' + Styles.IDList}>
            <Container>
                <Row>
                    <Col>
                        <button type="button" onClick={handleClickMenu}>Menu</button>
                        <ul className="mobMenuInner">
                            {allSubMenu1.map((item) => {
                                    const isActive = location.pathname === item.linkUrl;
                                    return (
                                        <>
                                        {item.linkText !=='Wearhousing' &&
                                        <li key={item.orderNumber}>
                                            <Link
                                                to={item.linkUrl}
                                                title={item.linkText}
                                                className={isActive ? 'active' : ""}
                                            >
                                                {item.shorttitle || item.linkText}
                                            </Link>
                                        </li>
                            }</>
                                    );
                                })}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Breadcrumb;