import React, { useState, useEffect, useRef } from 'react';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css'
const EmployeeDetail = ({ nextStep, handleChange, formData,errors,handleSubmit }) => {
  
  return (
    <div>
      <h5>Employee Detail</h5>
      
      <div className={Styles.FormControl}>
          <div className={Styles.group}>
          <input
            type="text"
            name="empId"
            placeholder="empId"
            value={formData.empDetail.empId}
            onChange={handleChange('empId', 'empDetail')}
           />
              {errors.empId && <span>{errors.empId}</span>}
          </div>
          <div className={Styles.group}>
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={formData.empDetail.location}
            onChange={handleChange('location', 'empDetail')}
           />
          {errors.location && <span>{errors.location}</span>}
          </div>
      </div>
      <div className={Styles.FormControl}>
          <div className={Styles.group}>
          {/* <label htmlFor="dateApplied">Date Applied (YYYY-MM-DD):</label> */}
          <input
            type="text"
            name="designation"
            placeholder="Designation"
            value={formData.empDetail.designation}
            onChange={handleChange('designation', 'empDetail')}
           />
          {errors.designation && <span>{errors.designation}</span>}
          </div>
          <div className={Styles.group}>
            <input
            type="text"
            name="duration"
            placeholder="Duration"
            value={formData.empDetail.duration}
            onChange={handleChange('duration', 'empDetail')}
           />
           {errors.duration && <span>{errors.duration}</span>}
          </div>
      </div>
      <div className={Styles.FormControl}>
          <div className={Styles.group}>
          <input
            type="text"
            name="entityName"
            placeholder="Entity Name"
            value={formData.empDetail.entityName}
            onChange={handleChange('entityName', 'empDetail')}
          />
          {errors.entityName && <span>{errors.entityName}</span>}
         </div>
        <div className={Styles.group}>
        <input
          type="text"
          name="reportingAuthority"
          placeholder="Reporting Authority"
          value={formData.empDetail.reportingAuthority}
          onChange={handleChange('reportingAuthority', 'empDetail')}
        />
        {errors.reportingAuthority && <span>{errors.reportingAuthority}</span>}
        </div>
      </div>
      
      
      {/* <button type="button" onClick={nextStep} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Next</button> */}
      {/* <button type="button" onClick={handleSubmit} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Submit</button> */}
      <button type="button" onClick={nextStep} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Next</button>
    </div>
  );
};

export default EmployeeDetail;
