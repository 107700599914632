import config from '../../src/config';
// if (window.location.hostname === "tqcert") {
//      module.exports = {
//           API_BASE_URL: '', // Base url 
          
//      }

   
// }else {
//      module.exports = {
//         //  API_BASE_URL: 'https://cqcert.olive.co.in/api/v1/', // Base url 
//          API_BASE_URL:config.API_BASE_URL,
//         Image_BASE_URL:config.Image_BASE_URL, //
//         // Image_BASE_URL:'http://cqcert.olive.co.in/uploads/', //
//      }

// }
// // 
const constants = {};

if (window.location.hostname === "tqcert") {
  constants.API_BASE_URL = ''; // Base url
} else {
  constants.API_BASE_URL = config.API_BASE_URL;
  constants.Image_BASE_URL = config.Image_BASE_URL;
  constants.File_BASE_URL = config.File_BASE_URL;
}

export default constants;
