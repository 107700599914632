import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import { getServicesTypeDetailAPI, getXLSDownloadAPI } from '../../../services/routes.services';
import { Link, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import Breadcrumb from "./BreadCrumb";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
import axios from 'axios';
const MSCertification=()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [managementCTF, setManagementCTF] = useState([]);
    const [qmsData, setQmsData] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        Promise.all([
            getServicesTypeDetailAPI('management-system-certification'),
            getXLSDownloadAPI('QMS')
        ]).then(([MSCRes, QMSRes])=>{
            setManagementCTF(MSCRes.data.servicedetails || []);
            setQmsData(QMSRes.data.customers)
        }).catch(error => {
            console.log(error);
        }).finally(()=>{
            setLoading(false)
        });
    }, []);
    if (loading || managementCTF.length === 0) {
        return <Loader />;
    }
    const sectionData = managementCTF[0]?.fields || [];
    const downloadXLSFile = async (image, name) => {
        // try {
        //     const response = await axios.get(image, {
        //         responseType: 'blob',
        //     });
        //     const blob = new Blob([response.data], {
        //         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        //     });
        //     const link = document.createElement('a');
        //     link.href = window.URL.createObjectURL(blob);
        //     document.body.appendChild(link);
        //     link.download = name;
        //     link.click();
        //     link.parentNode.removeChild(link);
        // } catch (error) {
        //     console.error('Error downloading the file', error);
        // }
        const link = document.createElement('a');
    link.href = image; // Use the direct image link
    link.target = '_blank'; // Open in a new tab
    link.download = name; // Specify the download name
    document.body.appendChild(link);
    link.click(); // Programmatically click the link
    link.remove(); // Remove the link from the DOM
    };

    return(
        <div className={'CertificationPage ' + Styles.MSCPage + ' ' + Styles.CFTStyleSame}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
               {BannerImage && <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Breadcrumb />
            <div className={Styles.QMSblock} id="QMS">
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.innerContainer+" " + Styles.QMSElem}>
                                <div className={Styles.contentSide}>
                                    <h5>{sectionData[0].title}</h5>
                                    {parse(`${sectionData[0].description}`)}
                                </div>
                                <div className={Styles.imgSide}>
                                    <figure>
                                       {sectionData[0].image[0]&& <img src={constants.Image_BASE_URL+""+sectionData[0].image[0].fileName} alt={sectionData[0].title} width='469' height='302' />}
                                    </figure>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.AsPdf}>
                <Container className={Styles.organicSpace}>
                    <Row>
                        <Col md={3} className={Styles.flexCenter}>
                            <h3>Our Clients</h3>
                        </Col>
                        <Col md={9}>
                            <div className={Styles.AsPDFContainer}>
                                {qmsData &&
                                    qmsData.map((item)=>{
                                        return(
                                            <div className={Styles.box} key={item.order}>
                                                <button type="button" onClick={() => downloadXLSFile(constants.Image_BASE_URL + item.image, item.name)}>
                                                    <figure>
                                                        <img src={require('../../../assest/images/xlxs_icon.png')} className={Styles.xlxsIcon} alt={item.name} />
                                                    </figure>
                                                    <h6>{item.name}</h6>
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.benefitblock}>
                <img src={require('../../../assest/images/befefit_bg.jpg')} alt={sectionData[2].title} width='1512' height='501' className={Styles.benefitBG} />
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.innerContainer+" " + Styles.benefitElem}>
                                <img src={require('../../../assest/images/ios_img.png')} className={Styles.IOSTag} alt="" width='166' height='165' />
                                <div className={Styles.contentSideLeft}>
                                    {parse(`${sectionData[1].description}`)}
                                </div>
                                <div className={Styles.contentSideRight}>
                                    <h5>{sectionData[2].title}</h5>
                                    {parse(`${sectionData[2].description}`)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.EMSbllock} id="">
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.innerContainer}>   
                                <h3>{sectionData[3].title}</h3>
                                <div className={Styles.EMSElem}>
                                    <div className={Styles.contentSide}>
                                        {parse(`${sectionData[3].description}`)}
                                    </div>
                                    <div className={Styles.contentSideimg}>
                                        <figure>
                                           {sectionData[3].image[0] && <img src={constants.Image_BASE_URL+""+sectionData[3].image[0].fileName} alt={sectionData[3].title} width='467' height='467' />}
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
            <div className={Styles.TQEMSbllock} id="">
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.innerContainer}>   
                                <h3>{sectionData[4].title}</h3>
                                <div className={Styles.EMSElem}>
                                   {sectionData[4].image[0] && <figure>
                                        <img src={constants.Image_BASE_URL+""+sectionData[4].image[0].fileName} alt={sectionData[4].title} width='467' height='467' />
                                    </figure>}
                                    <div className={Styles.contentSide}>
                                        {parse(`${sectionData[4].description}`)}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
            <div className={Styles.energyBlock + " " + Styles.TQEMSbllock}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.innerContainer}>
                            <h3>{sectionData[5].title}</h3>
                            <div className={Styles.EMSElem}>
                               {sectionData[5].image[0] && <figure><img src={constants.Image_BASE_URL+""+sectionData[5].image[0].fileName} alt={sectionData[5].title} width='1175' height='353' /></figure>}
                                <div className={Styles.contentSide}>
                                    {parse(`${sectionData[5].description}`)}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.IOSBlock}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.innerContainer}>
                                <h3>{sectionData[6].title}</h3>
                                <div className={Styles.IOSElem}>
                                    <div className={Styles.leftSide}>
                                    {parse(`${sectionData[6].description}`)}
                                    </div>
                                    <div className={Styles.rightSide}>
                                      {sectionData[6].image[0] &&  <figure>
                                            <img src={constants.Image_BASE_URL+""+sectionData[6].image[0].fileName} alt={sectionData[6].title} width='' height='' />
                                        </figure>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default MSCertification;