import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Styles from "../Style/Common.module.css";
import BtnStyle from "../Style/Button.module.css";
import parse from "html-react-parser";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../Common/PageLoader/LoadingSpinner";
import { IoMdArrowBack } from "react-icons/io";
import axios from "axios";
import constants from "./../../services/constants";
const JobDetail = () => {
  const location = useLocation();
  const { jobId } = useParams(); // Get job ID from the URL
  //const { job } = location.state || {}; // Extract job from location state
  console.log(jobId);
  const [job, setJob] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    // Fetch job details
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(
          `${constants.API_BASE_URL}jobs/${jobId}`
        );
        setJob(response.data.data);
      } catch (err) {
        setError(err.response?.data?.message || "Error fetching job details");
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);
  //console.log(job);
  return (
    <div className={"CertificationPage " + Styles.CareerPage}>
      <div className={Styles.jobDetailSec}>
        <Container>
          <Row>
            <Col>
              <div className={Styles.positionContainer}>
                <div className={Styles.jobHeading}>
                  <h5>JobDetail of {job && job.positionTitle}</h5>

                  <Link
                    to={`/apply-job`}
                    state={{ jobId: job && job._id }}
                    className={
                      BtnStyle.btn +
                      " " +
                      BtnStyle.RedBtn +
                      " " +
                      Styles.btnLook
                    }
                  >
                    APPLY NOW
                  </Link>
                </div>
                <ul className={Styles.aboutJob}>
                  <li>
                    <img
                      src={require("../../assest/images/locatio_icon.png")}
                    />
                    Location: {job && job.location}{" "}
                  </li>
                  <li>
                    <img src={require("../../assest/images/role_icon.png")} />{" "}
                    Project role: {job && job.preferredIndustry}{" "}
                  </li>
                  <li>
                    <img src={require("../../assest/images/skill_icon.png")} />
                    Skills: Others{" "}
                  </li>
                  <li>
                    <img src={require("../../assest/images/skill_icon.png")} />
                    No. of positions: {job && job.numberOfVacancies}
                  </li>
                  <li>
                    <img src={require("../../assest/images/skill_icon.png")} />
                    Employment Type: {job && job.employmentType}
                  </li>
                </ul>
                <div className={Styles.jobDes}>
                  <h5>Job Description</h5>
                  {job && job.jobResponsibilities && (
                    <p> {job && parse(`${job && job.jobResponsibilities}`)}</p>
                  )}
                  {job && job.requirments && (
                    <>
                      {" "}
                      <h5>Job Requirement</h5>
                      <p> {parse(`${job.requirments}`)}</p>
                    </>
                  )}

                  {job && job.expDetail && (
                    <>
                      <h5>Job Experiance </h5>
                      <p> {parse(`${job.expDetail}`)}</p>
                    </>
                  )}
                  <Link
                    to={`/apply-job`}
                    state={{ jobId: job && job._id }}
                    className={BtnStyle.btn + " " + BtnStyle.RedBtn}
                  >
                    APPLY NOW
                  </Link>
                  <div className={Styles.disclamierBlock}>
                    <h5>Disclaimer</h5>
                    <p>
                      TQCert is an equal opportunity employer, committed to
                      providing equal employment opportunities to all applicants
                      and employees regardless of race, religion, sex, color,
                      age, national origin, pregnancy, sexual orientation,
                      physical disability or genetic information, military or
                      veteran status, or any other protected classification, in
                      accordance with federal, state, and/or local law. Should
                      any applicant have concerns about discrimination in the
                      hiring process, they should provide a detailed report of
                      those concerns to{" "}
                      <Link to="mailto:secure@tqcert.com" target="_blank">
                        secure@tqcert.com
                      </Link>{" "}
                      for investigation.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default JobDetail;
