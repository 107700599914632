import React, { useState, useEffect, useCallback } from "react";
import Banner from "./HomeBanner";
import WhoWeAreSection from "./WhoWeAre";
import WeOfferSection from "./WeOffer";
import LeaderShipSection from "./LeaderShip";
import JoiTeamSection from "./JoinTeam";
import Improvement from "./Improvement";
import Sectors from "./Sectors";
import KeyProjects from "./KeyProjects";
import Global from "./Global";
import { getBannerAPI, 
    getWhoWeAreAPI, 
    getWhatWeOfferAPI, 
    getOfferTabAPI, 
    getLeadershipAPI, 
    getJoinTeamspAPI, 
    getOfferTabImageAPI, 
    getImprovementAPI,
    getSectorsAPI,
    getKeyProjectsAPI } from "../../services/routes.services";

import Loader from "../Common/PageLoader/LoadingSpinner";
    

const HomeRoute = ()=>{
    const [bannerData, setBannerData] = useState([]);
    const [weAreData, setWeAreData] = useState([]);
    const [weOffer, setWeOffer] = useState([]);
    const [weOfferTab, setWeOfferTab] = useState([]);
    const [weOfferTabImage, setWeOfferTabImage] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [joinTeamData, setJoinTeamData] = useState([]);
    const [improveData, setImproveData] = useState([]);
    const [sectorData, setSectorData] = useState([]);
    const [keyData, setKeyData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback(async () => {
        try {
            const [bannerRes, weAreRes, weOfferRes, weOfferTabRes, weOfferTabImageRes, teamRes, joinTeamRes, improveRes, sectorRes, keyRes] = await Promise.all([
                getBannerAPI(),
                getWhoWeAreAPI(),
                getWhatWeOfferAPI(),
                getOfferTabAPI(),
                getOfferTabImageAPI(),
                getLeadershipAPI(),
                getJoinTeamspAPI(),
                getImprovementAPI(),
                getSectorsAPI(),
                getKeyProjectsAPI()
            ]);

            setBannerData(bannerRes.data.banners);
            setWeAreData(weAreRes.data.whoweare[0]);
            setWeOffer(weOfferRes.data.whoweoffermain[0]);
            setWeOfferTab(weOfferTabRes.data.homeservice);
            setWeOfferTabImage(weOfferTabImageRes.data.homeserviceimage);
            setTeamData(teamRes.data.teams);
            setJoinTeamData(joinTeamRes.data.jointeams[0]);
            setImproveData(improveRes.data.improvments[0]);
            setSectorData(sectorRes.data.sector);
            setKeyData(keyRes.data.keyproject);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (loading) {
        return <Loader />;
    }
    //console.log("12",joinTeamData)
    if (!weAreData || !joinTeamData || !teamData || !weOffer || !weOfferTab) {
        return null;
    }
    
    return (
        <div>
            <Banner Slider={bannerData} />
            <WhoWeAreSection WeAre={weAreData} />
            <WeOfferSection Offer={weOffer} OffeTab={weOfferTab} />
            <Sectors />
            <Improvement Improve={improveData} />
            {/* <KeyProjects Project={keyData} />
            <LeaderShipSection leaderShip={teamData} /> */}
            <Global />
            <JoiTeamSection Team={joinTeamData} />
        </div>
    );
};

export default HomeRoute;
