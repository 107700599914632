import React,{useState} from 'react';
import BtnStyle from '../Style/Button.module.css'
import Styles from '../Style/Common.module.css'
const SubmitDetail = ({ prevStep, handleFileChange, handleArrayChange, formData, handleSubmit,updateConsent }) => {
  console.log('Complete Form data==',formData)
  const [consent, setConsent] = useState(false);
  return (
    <div>
      <h5>Please check all filled form data before submit</h5>
      <h5>Application Summary</h5>
      <div className={Styles.applyDetail}>
      <div>
        <h4>Employee Details</h4>
        <p><strong>EmpID:</strong> {formData.empDetail.empId}</p>
        <p><strong>Location:</strong> {formData.empDetail.location}</p>
        <p><strong>Designation:</strong> {formData.empDetail.designation}</p>
        <p><strong>Duration:</strong> {formData.empDetail.duration}</p>
        <p><strong>Entity Name:</strong> {formData.empDetail.entityName}</p>
        <p><strong>Reporting Authority:</strong> {formData.empDetail.reportingAuthority}</p>
      </div>
      <div>
        <h4>Personal Details</h4>
        <p><strong>First Name:</strong> {formData.personalDetails.firstName}</p>
        <p><strong>Middle Name:</strong> {formData.personalDetails.middleName}</p>
        <p><strong>Last Name:</strong> {formData.personalDetails.lastName}</p>
        <p><strong>Date of Birth:</strong> {formData.personalDetails.dateOfBirth}</p>
        <p><strong>Gender:</strong> {formData.personalDetails.gender}</p>
        <p><strong>Nationality:</strong> {formData.personalDetails.nationality}</p>
      </div>
      <div>
        <h4>Contact Details</h4>
        <p><strong>Mobile No.:</strong> {formData.contactDetails.mobileNo}</p>
        <p><strong>Email ID:</strong> {formData.contactDetails.emailId}</p>
        <p><strong>Current Location:</strong> {formData.contactDetails.currentLocation}</p>
      </div>
      <div>
        <h4>Education Details</h4>
        {formData.educationDetails.map((edu, index) => (
          <div key={index}>
            <p><strong>Relevant Qualification:</strong> {edu.relevantQualification}</p>
            <p><strong>Specialization:</strong> {edu.specialization}</p>
            <p><strong>Mode:</strong> {edu.mode}</p>
          </div>
        ))}
      </div>
      <div>
        <h4>Professional Certifications</h4>
        {formData.professionalCertifications.map((cert, index) => (
          <div key={index}>
            <p><strong>Certificate Name:</strong> {cert.certificateName}</p>
            <p><strong>Validity:</strong> {cert.validity}</p>
          </div>
        ))}
      </div>
      <div>
        <h4>Experience</h4>
        {formData.experience.map((exp, index) => (
          <div key={index}>
            <p><strong>Expertise:</strong> {exp.expertise}</p>
            <p><strong>Years of Experience:</strong> {exp.yearsOfExperience}</p>
          </div>
        ))}
      </div>
      <div>
        <h4>CTC Details</h4>
        <p><strong>Current CTC:</strong> {formData.ctcDetails.currentCTC}</p>
        <p><strong>Net Salary:</strong> {formData.ctcDetails.netSalary}</p>
      </div>
      <div>
        <h4>Joining Time</h4>
        <p><strong>Joining Time:</strong> {formData.joiningTime}</p>
      </div>
      <input
      type="checkbox"
      id="consent"
      checked={consent}
      onChange={(e) => setConsent(updateConsent(e.target.checked))}
      required
    />{' '}
    <label htmlFor="consent">
      I hereby declare that the information provided by me is true and correct to the best of my knowledge.
    </label>
    </div>
      
      <button onClick={prevStep} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Previous</button>
      <button type="button" onClick={handleSubmit} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Submit</button>
    </div>
  );
};

export default SubmitDetail;
