import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css';
import { Link } from "react-router-dom";
import constants from '../../services/constants';
const JoiTeamSection = (props)=>{
    const joinData = props.Team;
   // console.log(joinData)
    return(
        <div className={'ourTeam ' + Styles.JoinTeamSec}>
            <Container>
                <Row>
                    <Col>
                        {joinData && 
                        <div className={Styles.InnerContainer}>
                            <Row>
                                <Col md={7}>
                                    <div className={Styles.teamContent}>
                                        <h3>{joinData.title}</h3>
                                        <p>{joinData.description}</p>
                                        <Link to='/careers' title={joinData.title} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Open Positions</Link>
                                    </div>
                                </Col>
                                <Col md={5} className={Styles.gridContainer}>
                                {joinData.image &&
                                <>
                                    <div className={Styles.teamGrid}>
                                        {joinData.image.slice(0, 5).map((item, index)=>{
                                                return(
                                                    <div key={index}>
                                                    <figure key={item._id}>
                                                       {item.fileName && <img alt={joinData.title} src={constants.Image_BASE_URL+""+item.fileName} width='400' height='560' />}
                                                    </figure>
                                                    </div>
                                                )
                                            })
                                        }
                                        {joinData.image.slice(0, 5).map((item, index)=>{
                                                return(
                                                    <div key={index}>
                                                    <figure key={item._id}>
                                                       {item.fileName && <img alt={joinData.title} src={constants.Image_BASE_URL+""+item.fileName} width='400' height='560' />}
                                                    </figure>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    </>
                                    }
                                      {joinData.image &&
                                    <div className={Styles.teamGrid}>
                                        {joinData.image.slice(5, 10).map((item, index)=>{
                                                return(
                                                    <div key={index}>
                                                    <figure key={item._id}>
                                                      {item.fileName &&  <img alt={joinData.title} src={ constants.Image_BASE_URL+""+item.fileName} width='400' height='560' />
                                        }
                                                    </figure>
                                                    </div>
                                                )
                                            })
                                        }
                                        {joinData.image.slice(5, 10).map((item, index)=>{
                                                return(
                                                    <div key={index}>
                                                    <figure key={item._id}>
                                                      {item.fileName &&  <img alt={joinData.title} src={ constants.Image_BASE_URL+""+item.fileName} width='400' height='560' />
                                        }
                                                    </figure>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
}
                                </Col>
                            </Row>
                        </div>
                        }
                    </Col>
                </Row>
            </Container>
            <img src={require('../../assest/images/Mask.png')} className={Styles.maskImg} />
            <div className={Styles.blankDiv}></div>
        </div>
    )
}

export default JoiTeamSection;