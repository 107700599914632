import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css';
import { Link, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import constants from '../../services/constants';
import { getBlogsAPI } from '../../services/routes.services';
import Loader from "../Common/PageLoader/LoadingSpinner";

const BlogDetails = () => {
    let location = useLocation();
    const { Id, Title, Description, BlogTime, Authorpost, Author, AboutAuthor } = location.state || {}; 
    console.log(BlogTime)
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    return (
        <div className={'blogDetailPage ' + Styles.blogDetail}>
            <div className={Styles.fullBolg}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.detailContainer}>
                                <h3>{Title}</h3>
                                <ul className={Styles.authorBlog}>
                                    <li>{formatDate(BlogTime)}</li>
                                    <li>/</li>
                                    <li>{Author}, {Authorpost}</li>
                                </ul>
                                <img src={require('../../assest/images/blog-detail-img.png')} className={Styles.blogDtlImg} />
                                <div className={Styles.blogContent}>
                                    {parse(`${Description}`)}
                                    <p className={Styles.aboutAuth}>{parse(`${AboutAuthor}`)}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default BlogDetails;
