import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Style from "./adminstyle/LoginForm.module.css";
import constants from "../services/constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userlogin } from "../store/authSlice";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Get the navigate function
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation
    if (!email || !password) {
      setError("Please enter both email and password.");
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Password validation
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (!passwordRegex.test(password)) {
      setError(
        "Password must be at least 8 characters long and include symbols, lowercase and uppercase letters."
      );
      return;
    }

    try {
      // API call for login
      const response = await fetch(`${constants.API_BASE_URL}login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      console.log(response);
      if (!response.ok) {
        throw new Error("Invalid email or password.");
      }

      const data = await response.json();
      console.log("userData===", data);
      // const { token } = data;

      // // Store the token in local storage
      localStorage.setItem("token", data.token);
      localStorage.setItem("userDetail", data.user);
      const user = data.user;
      const token = data.token;

      const userState = {
        isAuthenticatedTq: true,
        user: user,
        token: token,
      };
      localStorage.setItem("authData", JSON.stringify(userState));
      // Dispatch login action
      dispatch(userlogin({ user, token }));

      // Reset error and navigate to the Admin section
      setError("");
      navigate("/AdminSection");
    } catch (err) {
      setError("An error occurred. Please try again later.");
    }
    handelRedirection();
  };
  const handelRedirection = () => {
    if (localStorage.getItem("token")) {
      navigate("/admin/menu"); // Redirect to Admin page if already logged in
    }
  };

  return (
    <div className={Style.Loginheader}>
      <div className={Style.loginContainer}>
        <h2>Login</h2>
        {error && <p className={Style.error}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className={Style.formGroup}>
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={Style.formGroup}>
            <label>Password</label>
            <div className={Style.passwordContainer}>
              <input
                type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
                className={Style.showPasswordButton}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <button className={Style.ButtonStyle} type="submit">
            Login
          </button>
        </form>
        {/* <Link to="/register-user" className={Style.rgisterpassword}>Register</Link> */}
        <Link to="/forget-password" className={Style.forgotPasswordLink}>
          Forgot Password?
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;
