import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import { Link, useLocation } from "react-router-dom";

const DisclamierPolicy = () => {
   
    return (
        <div className={'directorsPage ' + Styles.boardPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner + ' ' + Styles.noneMask +" " + Styles.smallBanner}>
           <img src={require('../../assest/images/policy_banner.png')} className={Styles.innerBannerimg} alt="Banner" />
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>Disclaimer</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.privatPolicySec}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.polictContant}>
                                <p>TQ Cert Services Private Limited (TQ Cert). has taken all reasonable care in developing the website, and we believe that all information is accurate at the time of publication or last modification. The information, material or services included in or available through this site may include inaccuracies or typographical errors. TQ Cert Services  reserves the right to make changes to this site without notice.</p>
                                <p>This site contains links to sites outside tqcert.org TQ Cert Services  is not responsible for the content of such other websites. No information on this site shall constitute an invitation to invest in TQ Cert Services  or any of its affiliates. The linked sites are not under our control, and we are not responsible for the contents of any linked sites, or any link contained in a linked site, or any changes or updates to such sites. We are providing these links to you only as a matter of convenience, and the inclusion of any link does not imply endorsement by us of the site. Neither TQ Cert Services  nor its officers, employees or agents shall be liable for any loss, damage or expense, of any nature, arising out of any access to or use of this site or any site linked to it.  If you are dissatisfied with any portion of this site / services, or with any of these terms of use, your sole and exclusive remedy is to discontinue using this site / services.</p>
                                <p>You may print copies of the information on this site for your personal use and download files for personal use and may not, without our permission, copy and distribute this information on any other work, publication or website. No reproduction of any part of the site may be used for commercial gain.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default DisclamierPolicy;
