import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import { getServicesTypeAPI } from '../../../services/routes.services';
import { Link, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import Sectors from "../../HomePage/Sectors";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
import KeyProjects from "../../HomePage/KeyProjects";
import Breadcrumb from "./BreadCrumb";
const ServicesOverView=()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.parentTab : ''; 

    const [projectServices, setProjectServices] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        Promise.all([
            getServicesTypeAPI('Project Services')
        ]).then(([projectRes]) => {
            setProjectServices(projectRes.data.homeserviceimage);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    }, []);
   
    const filteredArray = projectServices.slice(1);
    console.log(filteredArray)
    if (loading) {
        return <Loader />;
    }
    return(
        <div className={'CertificationPage ' + Styles.MSCPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
                {BannerImage && <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Breadcrumb />
            {projectServices &&
                <div className={Styles.projectOverView}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.proContainer}>
                                    <Row>
                                        <Col>
                                            <div className={Styles.imgSide}>
                                                <figure className={Styles.imgBgStyle}>
                                           {projectServices[0].innerimage && <img src={`${ constants.Image_BASE_URL}${projectServices[0].innerimage}`} alt="Services" width='524' height='341' />}
                                                </figure>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className={Styles.content}>
                                               {projectServices[0].text && <h3>{projectServices[0].text}</h3>}
                                               {projectServices[0].description && <p> {parse(`${projectServices[0].description}`)}</p>}
                                              
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
            <div className={Styles.projectNeed}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.proContainer}>
                                <div className={Styles.centerAlight}>
                                    <h3>Service Offerings</h3>
                                </div>
                                {filteredArray && 
                                <div className={Styles.needContainer}>
                                 {filteredArray.map((item)=>{
                                    return(
                                    <div className={Styles.needElem}>
                                        <div>
                                            <figure>
                                            {item.imageicon && <img src={`${ constants.Image_BASE_URL}${item.imageicon}`} alt={item.text} width='' height='' />}
                                               
                                            </figure>
                                            {item.text && <h4>{item.text}</h4>}
                                            {item.description && <p> {parse(`${item.description}`)}</p>}
                                        </div>
                                    </div>
                                    )
                                 })
                                    
                                        }
                                    {/* <div className={Styles.needElem}>
                                        <div>
                                            <figure>
                                                <img src={require('../../../assest/images/need_icon02.png')} alt="" width='' height='' />
                                            </figure>
                                            <h4>Design & Engineering Reviews</h4>
                                            <p>Futuristic and Functional - Architectural and Interior Designs for projects which transform our client’s vision to reality. The Design Review service entails in-depth study and independent expert opinion on the Design given by Client, to add Value to them. The Value engineering done for Design optimizations benefits our client in time and cost optimizations.</p>
                                        </div>
                                    </div>
                                    <div className={Styles.needElem}>
                                        <div>
                                            <figure>
                                                <img src={require('../../../assest/images/need_icon03.png')} alt="" width='' height='' />
                                            </figure>
                                            <h4>PMO Services</h4>
                                            <p>Single point management enabling agile decision making in diverse Fastrack projects. Program Management Office helping clients with similar projects across locations and achieving centralized framework of Program Governance, Project Controls, Management Information Dashboards, Smart Trigger alerts to fast-track Project Deliveries</p>
                                        </div>
                                    </div>
                                    <div className={Styles.needElem}>
                                        <div>
                                            <figure>
                                                <img src={require('../../../assest/images/need_icon04.png')} alt="" width='' height='' />
                                            </figure>
                                            <h4>BIM & VDC Services</h4>
                                            <p>BIM 3D,4D,5D,6 D solutions for faster QTO, CD, Time & Cost optimization. Clients are benefiting from minimized errors, perfected scheduling, and the elimination of wastage, ensuring unparalleled success in diverse construction projects across these key sectors.</p>
                                        </div>
                                    </div> */}
                                </div>
}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <div className={Styles.projectServices}>
                <Sectors />
            </div>
            <KeyProjects /> */}
        </div>
    )
}

export default ServicesOverView;